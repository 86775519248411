import { Button as FlowbiteButton, ButtonProps, Spinner } from 'flowbite-react';
import classNames from 'classnames';
import { useWindowDimensions } from 'hooks';

interface IButtonProps extends ButtonProps {
  text?: string;
  className?: string;
  icon?: string;
  preIcon?: boolean;
  postIcon?: boolean;
  isLoading?: boolean;
}

const Button = ({
  text,
  className,
  icon,
  preIcon,
  isLoading = false,
  postIcon,
  ...props
}: IButtonProps) => {
  const { isMobileScreen } = useWindowDimensions();
  return (
    <FlowbiteButton
      className={classNames(
        'enabled:hover:bg-initial ring-0 outline-none ring-offset-0 rounded-lg focus:ring-0 bg-white transition ease-linear delay-200 text-black',
        className,
      )}
      theme={{
        size: {
          md: 'justify-center text-sm px-2 py-2',
        },
      }}
      {...props}
    >
      <div className='flex items-center gap-2 capitalize body-semibold lg:text-base'>
        {isLoading ? (
          <Spinner size={isMobileScreen ? 'xs' : 'md'} color={'gray'} />
        ) : (
          <>
            {preIcon && <img src={icon} className='w-4 h-4 object-fit' alt='icon' />}
            {text}
            {postIcon && <img src={icon} className='w-4 h-4 object-fit' alt='icon' />}
          </>
        )}
      </div>
    </FlowbiteButton>
  );
};

export default Button;
