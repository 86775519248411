import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetClassTimelineFlowDetailsQuery } from 'store/apiSlices/parentDashboard.apiSlice';
import { ClassFlow, CoachBasic, IClassTimelineProps } from 'types/dashboard/parentDashboard';

import { Accordian, BouncingDotsLoader } from 'components/common';

import { BADGE_CONTENT } from 'configs';
import { useSocketSubscribe } from 'hooks';
import '../scrollstyle.css';
import { TeachPhase } from './MathClass';
import CoachPhase from './MathClass/CoachPhase';

const ClassTimeline = ({
  classData,
  secondsDifferenceInClassEnds,
  setClassStatus,
  isClassEnds,
}: IClassTimelineProps) => {
  const { studentId } = useParams();

  const [isVisible, setIsVisible] = useState<boolean>(true);

  /* API -> GET CLASS FLOW DATA */
  const {
    data: classTimelineFlowDetails,
    isLoading,
    refetch,
  } = useGetClassTimelineFlowDetailsQuery(
    {
      studentId: Number(studentId),
      classId: Number(classData?.data?.id),
    },
    {
      skip: !studentId && !classData?.data?.id && !isClassEnds,
    },
  );

  const subjectName = classData?.data?.subjectDetails?.name || 'Math';

  const classFlow: ClassFlow = classTimelineFlowDetails?.data?.classFlow || {
    teach: [],
    coach_basic: [],
    coach_intermediate: [],
    coach_advanced: [],
  };

  const isCoachFirstNodeCompleted = classFlow.coach_basic?.filter(
    (data: CoachBasic) => data?.type === 'coach' && data?.status,
  );

  useEffect(() => {
    if (classTimelineFlowDetails?.data) {
      if (setClassStatus) {
        setClassStatus(classTimelineFlowDetails?.data?.classStatus || '');
      }
    }
  }, [classTimelineFlowDetails, setClassStatus]);
  // Handle socket event for class flow updates
  const handleClassFlow = useCallback(() => {
    refetch();
  }, [refetch]);

  // Subscribe to socket event
  useSocketSubscribe('classFlowUpdateToClient', handleClassFlow);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible((prev) => !prev);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {!isEmpty(classTimelineFlowDetails) && (
        <div className='flex gap-2 bg-white rounded-xl p-5 mt-[6px]'>
          <Accordian
            title={`${subjectName} Class Timeline Flow`}
            badgeContent={BADGE_CONTENT}
            collapseAll={false}
          >
            <div className='relative xl:h-[20rem] '>
              {isLoading ? (
                <>
                  <div className='absolute flex items-center justify-center flex-1 mx-2 top-1/2 left-1/2 xl:mx-6'>
                    <BouncingDotsLoader />
                  </div>
                </>
              ) : (
                <div className='mt-4 xl:overflow-x-scroll class-timeline-custom-scroll'>
                  <div className='flex flex-col xl:flex-row gap-y-2 xl:gap-x-2 2xl:gap-x-2 xl:min-w-max'>
                    {/* TEACH PHASE DATA */}
                    {classFlow.teach && (
                      <>
                        <div className='flex flex-col flex-1 flex-shrink xl:flex-row gap-x-2 md:block'>
                          <div className='flex-1 hidden xl:block '>
                            <p className='text-neutral-900 capitalize font-semibold font-poppins  text-[10px] xl:text-xs'>
                              {`Teach Phase`}
                            </p>
                          </div>
                          <div className='xl:bg-[#09D6CE42] xl:mt-2 h-full xl:h-[18rem] rounded-xl xl:flex flex-1'>
                            <TeachPhase
                              studentDetails={classData?.data?.studentDetails}
                              steps={classFlow?.teach}
                              classId={classData?.data?.id}
                              segmentDataIndex={0}
                              isCoachFirstNodeCompleted={['inProgress', 'completed']?.includes(
                                isCoachFirstNodeCompleted?.[0]?.status,
                              )}
                              isVisible={isVisible}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {/* DISPLAYING THE COACH PHASES */}
                    <React.Fragment>
                      <React.Fragment>
                        <div className='relative flex items-center justify-center xl:hidden'>
                          {classFlow?.coach_basic && (
                            <>
                              <div
                                className={`w-[54px] h-[1.8rem] border-b-[7px] border-r-[6px]  ${classFlow?.coach_basic?.length > 0 && ['inProgress', 'completed']?.includes(classFlow?.coach_basic[0]?.status) ? 'border-primary-500' : 'border-neutral-200'} rounded-br-xl absolute  top-[-9px]  left-[29px]  right-[179px] `}
                              ></div>
                              <div
                                className={`w-[9px] h-[1rem] border-l-[6px] border-t-[7px] ${classFlow?.coach_basic?.length > 0 && ['inProgress', 'completed']?.includes(classFlow?.coach_basic[0]?.status) ? 'border-primary-500' : 'border-neutral-200'}   absolute  rounded-tl-xl top-[13px] left-[21px] `}
                              ></div>
                            </>
                          )}
                        </div>

                        <div className={`flex-1`}>
                          <h6 className='hidden xl:block text-neutral-900 font-semibold font-poppins  text-[10px] xl:text-xs text-right xl:text-left'>
                            Coach Phase
                          </h6>

                          <CoachPhase
                            studentDetails={classData?.data?.studentDetails}
                            classId={classData?.data?.id}
                            coachBasic={classFlow?.coach_basic || []}
                            coachIntermediate={classFlow?.coach_intermediate || []}
                            coachAdvance={classFlow?.coach_advanced || []}
                            secondsDifferenceInClassEnds={secondsDifferenceInClassEnds || 0}
                            isVisible={isVisible}
                            isClassEnds={
                              classTimelineFlowDetails?.data?.classStatus === 'ended' ? true : false
                            }
                          />
                        </div>
                      </React.Fragment>
                    </React.Fragment>
                  </div>
                </div>
              )}
            </div>
          </Accordian>
        </div>
      )}
    </>
  );
};

export default ClassTimeline;
