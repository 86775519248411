import { Badge, Popover } from 'flowbite-react';
import { isEmpty, lowerCase, startCase, upperCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { useLazyGetAggregatedAttentionQuery } from 'store/apiSlices/common.apiSlice';
import {
  useGetClassTimelineFlowDetailsQuery,
  useGetCurrentStatusOfStudentDetailsQuery,
  useGetElementWiseClassProgressDetailsQuery,
} from 'store/apiSlices/parentDashboard.apiSlice';
import { IClassOverProps } from 'types/dashboard/parentDashboard';

import {
  ClassOverEvaluationIcon,
  ClassOverEvaluationWhite,
  DateIcon,
  HelpIcon,
  StudentBookImg,
  Timer,
} from 'assets/svg';
import { Avatar, BouncingDotsLoader, Card, Carousel } from 'components/common';
import { useGetStudentsPerformance } from 'hooks/useGetStudentsPerformance';

import { RomanNumber, USER_TYPE } from 'configs';
import ClassTimeline from '../ClassTimeline';
// import '../scrollstyle.css';
import { AggreagetedAttention } from './AggregatedAttentionBar';
import ElementPerformance from './RenderElementPerformanceSection';

const ClassOver = ({ classDetails, classId, studentId, userType }: IClassOverProps) => {
  // get class flow data to show skeleton of what will be taugnt today if class ends.
  const { data: classTimelineFlowDetails } = useGetClassTimelineFlowDetailsQuery({
    studentId: Number(studentId),
    classId: Number(classId),
  });

  const classFlowTeachphaseDetails = classTimelineFlowDetails?.data?.classFlow?.teach || [];

  // get element wise class details
  const { data: elementWiseData, isLoading } = useGetElementWiseClassProgressDetailsQuery({
    classId: classId,
    studentId: studentId,
  });
  const elementDetails = elementWiseData?.data?.elements || [];
  const studentDetails = classDetails?.data?.studentDetails;
  const tutorDetails = classDetails?.data?.tutorDetails[0];
  const PerformanceDetails = useGetStudentsPerformance(elementDetails, classFlowTeachphaseDetails);

  // get current status of student
  const { data: currentStatus } = useGetCurrentStatusOfStudentDetailsQuery({
    classId: classId,
  });
  const [aggregatedAttentions, setAggregatedAttentions] = useState<number[]>([]);

  const selectedStudentStatus = currentStatus?.data.find(
    (data) => data.studentId === Number(studentId),
  );
  const grade = RomanNumber.find((r) => r.class === studentDetails?.gradeName)?.romanValue;

  //Attention meter
  const [trigger, { data: calcuatedData }] = useLazyGetAggregatedAttentionQuery();
  useEffect(() => {
    trigger({
      classId: Number(classId),
      studentId: Number(studentId),
    });
  }, [classId, studentId, trigger]);

  useEffect(() => {
    const aggreAttention = calcuatedData?.data?.aggreagetedAttention;

    const attention = aggreAttention?.map((attention) => attention?.averageAttention);

    setAggregatedAttentions(attention ? attention : []);
  }, [calcuatedData]);

  // tutor surname
  let tutorName = tutorDetails?.firstName
    ? tutorDetails?.firstName + ' ' + tutorDetails?.lastName
    : '';

  if (lowerCase(tutorDetails?.gender) === 'male') {
    tutorName = `Mr. ${tutorName}`;
  } else if (lowerCase(tutorDetails?.gender) === 'female') {
    tutorName = `Ms. ${tutorName}`;
  }

  const lectureName =
    classDetails?.data?.lectureConfigurationName ||
    classDetails?.data?.classConfigurationName ||
    classDetails?.data?.name;

  return (
    <>
      {isLoading ? (
        <>
          <div className='flex items-center justify-center flex-1 mx-2 xl:mx-6'>
            <BouncingDotsLoader />
          </div>
        </>
      ) : (
        <>
          <div className='flex gap-y-[6px] xl:gap-x-[6px] flex-col xl:flex-row'>
            {/* //basics information */}
            <Card className='flex-1 xl:w-[336px] p-5 rounded-[20px]' defaultColorCode='bg-white'>
              <h5 className='mb-8 text-lg font-normal tracking-tight font-sora xl:text-xl text-neutral-900 xl:mb-10'>
                {classDetails?.data?.batchType === 'Regular'
                  ? 'Regular Class Over'
                  : 'Demo Class Over'}
              </h5>
              {/* if Performance Details is there else only attention and tutor student details*/}
              {!isEmpty(PerformanceDetails) ? (
                <div className='flex flex-col gap-y-14'>
                  <div>
                    {/* //students photo and grade school */}
                    <div className='flex items-center justify-start pb-5 mb-6 border-b-2 gap-x-4 border-neutral-50'>
                      <div className='w-[3.8rem] h-[3.8rem] rounded-full border-4 border-[#30F2EA]'>
                        <Avatar
                          firstName={studentDetails?.firstName}
                          lastName={studentDetails?.lastName}
                          image={studentDetails?.profileImage}
                          className='object-cover w-full h-full rounded-full'
                          initialsClassName='w-full h-full rounded-full flex items-center justify-center'
                        />
                      </div>
                      <div>
                        <p className='mb-1 text-base font-semibold tracking-tight font-poppins text-neutral-900'>
                          {`${startCase(studentDetails?.firstName)} ${startCase(studentDetails?.lastName)}`}
                        </p>
                        <p className='text-xs font-semibold tracking-tight font-poppins text-neutral-500'>
                          {`Class ${grade}`}
                        </p>
                      </div>
                    </div>

                    {/* //basic information class , date and time type of class*/}
                    <div>
                      <span className='bg-primary-200 text-primary-600 font-poppins text-xs font-semibold mr-2 px-2.5 py-0.5 rounded'>
                        {classDetails?.data?.bookDetails?.map((book) =>
                          upperCase(book.subjectName),
                        ) || 'MATHS'}
                      </span>
                      <span className='text-xs text-white bg-primary-850 font-poppins font-semibold px-2.5 py-0.5 rounded'>
                        {classDetails?.data?.batchType === 'Regular'
                          ? 'REGULAR CLASS'
                          : 'DEMO CLASS'}
                      </span>
                      <div className='font-sora font-bold text-2xl tracking-tight mb-6 mt-[10px]'>
                        {lectureName}
                      </div>
                      <div className='flex flex-wrap gap-2 '>
                        <span className='bg-[#30F2EA] font-poppins rounded-2xl p-1 px-2 text-[10px] sm:text-xs lg:text-sm'>
                          <img className='inline w-5 p-1 pt-0' src={DateIcon} alt='' />{' '}
                          {moment(classDetails?.data?.meetingStartTime).format('DD MMM YYYY')}
                        </span>
                        <span className='bg-[#30F2EA] font-poppins rounded-2xl p-1 px-2 text-[10px] sm:text-xs lg:text-sm text-nowrap'>
                          <img className='inline w-5 p-1 pt-0' src={Timer} alt='' />{' '}
                          {moment(classDetails?.data?.meetingStartTime).format('h:mm A')} {'-'}
                          {moment(classDetails?.data?.meetingEndTime).format('h:mm A')}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* tutor basic details and connect*/}
                  <div>
                    <div className='flex items-center justify-start gap-x-4'>
                      <div className='w-[3.8rem] h-[3.8rem] xl:h-[5rem] xl:w-[5rem] rounded-full'>
                        <Avatar
                          firstName={tutorDetails?.firstName}
                          lastName={tutorDetails?.lastName}
                          image={tutorDetails?.profileImage}
                          className='object-cover w-full h-full rounded-full'
                          initialsClassName='w-full h-full rounded-full flex items-center justify-center'
                        />
                      </div>
                      <div>
                        <p className='mb-1 text-base font-semibold tracking-tight font-poppins text-neutral-900'>
                          {tutorName}
                        </p>
                        <p className='text-xs font-semibold tracking-tight font-poppins text-neutral-500'>
                          {tutorDetails?.subjects && Object.keys(tutorDetails?.subjects).length > 0
                            ? tutorDetails?.subjects?.find((subject) => subject?.primary === true)
                                ?.name || tutorDetails?.subjects[0]?.name
                            : 'Maths'}
                        </p>
                      </div>
                    </div>
                    {/* commenting this because connect with tutor api not integrated */}
                    {/* <Button
                text='Connect With Tutor'
                className='w-full px-5 text-xs font-semibold tracking-tight border-2 border-primary-500 text-primary-500 font-poppins'
              ></Button> */}
                  </div>
                </div>
              ) : (
                <div className='flex flex-col gap-2 lg:flex-row'>
                  {/* Student Details */}
                  <div className='flex items-center justify-start p-4 border-gray-200 lg:border-r-2 md:gap-x-4 '>
                    <div className='w-[3.8rem] h-[3.8rem] xl:h-[5rem] xl:w-[5rem] rounded-full border-4 border-[#30F2EA]'>
                      <Avatar
                        firstName={studentDetails?.firstName}
                        lastName={studentDetails?.lastName}
                        image={studentDetails?.profileImage}
                        className='object-cover w-full h-full rounded-full'
                        initialsClassName='w-full h-full rounded-full flex items-center justify-center'
                      />
                    </div>
                    <div>
                      <p className='mb-1 text-base font-semibold tracking-tight font-poppins text-neutral-900'>
                        {`${startCase(studentDetails?.firstName)} ${startCase(studentDetails?.lastName)}`}
                      </p>
                      <p className='text-xs font-semibold tracking-tight font-poppins text-neutral-500'>
                        {`Class ${grade}`}
                      </p>
                    </div>
                  </div>

                  {/* Basic Information */}
                  <div className='bg-gray-200/80 rounded-2xl'>
                    <div className='px-4'>
                      <div className='flex items-center mt-4'>
                        <span className='bg-primary-200 text-primary-600 font-poppins text-xs font-semibold mr-2 px-2.5 py-0.5 rounded'>
                          MATHS
                        </span>
                        <span className='text-xs text-white bg-primary-850 font-poppins font-semibold px-2.5 py-0.5 rounded'>
                          {classDetails?.data?.batchType === 'Regular' ? 'Regular' : 'Demo'}
                        </span>
                      </div>
                    </div>
                    <div className='px-4 pb-4 mt-4'>
                      <h4 className='text-2xl font-bold tracking-tight sm:text-2xl text-neutral-800 font-sora'>
                        {lectureName}
                      </h4>
                      <div className='flex items-center justify-between'>
                        <div>
                          <p className='text-[#8390A2] font-poppins font-medium mb-4 text-xs '>
                            Date & Time
                          </p>
                          <span className='bg-[#30F2EA] font-poppins rounded-2xl p-1 px-2 text-[10px] sm:text-xs lg:text-sm'>
                            <img className='inline w-5 p-1 pt-0' src={DateIcon} alt='' />
                            {moment(classDetails?.data?.meetingStartTime).format('DD MMM YYYY')}
                          </span>
                          <span className='bg-[#30F2EA] font-poppins rounded-2xl p-1 px-2 ml-2 text-[10px] sm:text-xs lg:text-sm'>
                            <img className='inline w-5 p-1 pt-0' src={Timer} alt='' />
                            {moment(classDetails?.data?.meetingStartTime).format('h')} {'-'}
                            {moment(classDetails?.data?.meetingEndTime).format('h A')}
                          </span>
                        </div>
                        <div className='hidden sm:block'>
                          <img className='' src={StudentBookImg} alt='' />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* TutorDetails */}
                  <div className='flex items-center justify-start p-4 border-gray-200 lg:border-l-2 gap-x-4'>
                    <div className='w-[3.8rem] h-[3.8rem] xl:h-[5rem] xl:w-[5rem] rounded-full'>
                      <Avatar
                        firstName={tutorDetails?.firstName}
                        lastName={tutorDetails?.lastName}
                        image={tutorDetails?.profileImage}
                        className='object-cover w-full h-full rounded-full'
                        initialsClassName='w-full h-full rounded-full flex items-center justify-center'
                      />
                    </div>
                    <div>
                      <p className='mb-1 text-base font-semibold tracking-tight font-poppins text-neutral-900'>
                        {tutorName}
                      </p>
                      <p className='text-xs font-semibold tracking-tight font-poppins text-neutral-500'>
                        {tutorDetails?.subjects && Object.keys(tutorDetails?.subjects).length > 0
                          ? tutorDetails?.subjects?.find((subject) => subject?.primary === true)
                              ?.name || tutorDetails?.subjects[0]?.name
                          : 'Maths'}
                      </p>
                    </div>
                  </div>

                  {!isEmpty(aggregatedAttentions) && (
                    <AggreagetedAttention
                      studentDetails={studentDetails}
                      isPerformanceDetails={isEmpty(PerformanceDetails)}
                      classId={classDetails?.data?.id}
                      aggregatedAttentions={aggregatedAttentions}
                    />
                  )}
                </div>
              )}
            </Card>

            {/* performance and attention meter */}
            {!isEmpty(PerformanceDetails) && !isEmpty(aggregatedAttentions) && (
              <div className='flex-1 flex flex-col gap-y-[6px]'>
                {!isEmpty(PerformanceDetails) && (
                  <Card
                    className='p-5 flex-1 overflow-y-scroll scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-700 scrollbar-track-gray-300 rounded-[20px]'
                    defaultColorCode='bg-black'
                  >
                    <div className='flex items-center justify-between mb-8 xl:mb-10'>
                      <h5 className='text-lg font-normal tracking-tight text-white font-sora xl:text-xl '>
                        {`${studentDetails?.firstName}'s Class Performance`}
                      </h5>
                      <Popover
                        trigger='click'
                        placement='right-start'
                        arrow={false}
                        content={
                          <Card
                            className='p-5 w-[252px] rounded-lg'
                            defaultColorCode='bg-neutral-700'
                          >
                            <div className='flex justify-between'>
                              <h6 className='mb-4 text-xs font-medium tracking-tight text-white font-poppins'>
                                How is performance measured?
                              </h6>
                            </div>

                            <div className='flex flex-col gap-y-3'>
                              <div className='flex items-center gap-x-2'>
                                <div className='flex items-center justify-center w-6 h-6 rounded-full bg-semantic-green-500'>
                                  <img
                                    className='object-cover w-3 h-3 rounded-full'
                                    src={ClassOverEvaluationWhite}
                                    alt='Evaluation'
                                  />
                                </div>
                                <p className='text-xs font-normal tracking-wider text-white font-poppins'>
                                  Oral Evaluation + Polls
                                </p>
                              </div>
                              <div className='flex gap-x-2'>
                                <div className='flex items-center justify-center w-8 h-6 rounded-full bg-semantic-green-500'>
                                  <img
                                    className='object-cover w-3 h-3 rounded-full'
                                    src={ClassOverEvaluationWhite}
                                    alt='Evaluation'
                                  />
                                </div>
                                <div>
                                  <p className='text-xs font-normal tracking-wider text-white font-poppins'>
                                    Workbook Evaluation:
                                  </p>
                                  <p className='text-xs font-normal text-neutral-200 font-poppins'>
                                    Basic Qs, Intermediate Qs & Advanced Qs
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Card>
                        }
                      >
                        <img className='cursor-pointer' src={HelpIcon} alt={'info'} />
                      </Popover>
                    </div>

                    {/* elememts name loop with performance data */}
                    <div className='flex justify-between xl:gap-x-16'>
                      <div className='flex flex-col justify-between overflow-y-scroll scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-700 scrollbar-track-gray-300 gap-y-4 max-h-[9rem]'>
                        {PerformanceDetails?.length > 1 ? (
                          <Carousel
                            rightControl={<></>}
                            leftControl={<></>}
                            theme={{
                              indicators: {
                                wrapper:
                                  'absolute bottom-0 left-1/2 flex -translate-x-1/2 space-x-3',
                              },
                            }}
                          >
                            {PerformanceDetails?.filter((data) => data?.performanceScore)?.map(
                              (data, index) => (
                                <ElementPerformance
                                  key={data?.elementId}
                                  PerformanceDetails={data}
                                  index={index}
                                />
                              ),
                            )}
                          </Carousel>
                        ) : (
                          <ElementPerformance
                            PerformanceDetails={PerformanceDetails[0]}
                            index={0}
                          />
                        )}
                      </div>
                    </div>
                  </Card>
                )}
                {!isEmpty(aggregatedAttentions) && (
                  <AggreagetedAttention
                    studentDetails={studentDetails}
                    isPerformanceDetails={isEmpty(PerformanceDetails)}
                    classId={classDetails?.data?.id}
                    aggregatedAttentions={aggregatedAttentions}
                  />
                )}
              </div>
            )}

            {/* what all happened in class */}
            {!isEmpty(PerformanceDetails) && (
              <Card className='flex-1 p-5 xl:w-[346px] rounded-[20px]' defaultColorCode='bg-white'>
                <div className='overflow-y-scroll md:h-full md:flex-1 scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-700 scrollbar-track-gray-300'>
                  <h5 className='mb-8 text-lg font-normal tracking-tight font-sora xl:text-xl text-neutral-900 xl:mb-8'>
                    What all happened in Class?
                  </h5>
                  <div className='flex flex-col gap-y-4 max-h-[13rem] mb-10I'>
                    {PerformanceDetails?.map((element, index) => {
                      return (
                        <React.Fragment key={element?.elementId}>
                          <p className='text-sm font-semibold tracking-tight font-poppins text-primary-500'>
                            {`${index < 9 ? `0${index + 1}.` : index + 1}  ${element?.elementName}`}
                          </p>

                          <div className='flex flex-row gap-x-1 xl:flex-col xl:gap-y-1'>
                            <div className='bg-[#E0F0FE] flex flex-col gap-y-8 py-5 px-4 rounded-[10px]'>
                              <div className='flex gap-x-2'>
                                <img src={ClassOverEvaluationIcon} alt='Evaluation' />
                                <p className='text-xs font-semibold tracking-tighter font-poppins xl:text-sm text-neutral-900'>
                                  MCQs
                                </p>
                              </div>
                              <div className='flex flex-col gap-y-5'>
                                <div className='flex items-center justify-between'>
                                  <p className='text-[10px] xl:text-xs font-semibold font-poppins tracking-widest text-neutral-500'>
                                    ORAL EVAL ANSWERED
                                  </p>
                                  <Badge className='bg-semantic-red-500 py-[2px] px-2 rounded-lg text-sm font-semibold font-poppins text-white'>
                                    {`${element?.evaluationType?.oralEvaluation?.correctAnswers}/${element?.evaluationType?.oralEvaluation?.totalQuestions}`}
                                  </Badge>
                                </div>
                                <div className='flex items-center justify-between'>
                                  <p className='text-[10px] xl:text-xs font-semibold font-poppins tracking-widest text-neutral-500'>
                                    POLLS ANSWERED
                                  </p>
                                  <Badge className='bg-semantic-green-500 py-[2px] px-2 rounded-lg text-sm font-semibold font-poppins text-white'>
                                    {`${element?.evaluationType?.pollsEvaluation?.correctAnswers}/${element?.evaluationType?.pollsEvaluation?.totalQuestions}`}
                                  </Badge>
                                </div>
                              </div>
                            </div>
                            <div className='bg-[#E0F0FE] flex flex-col gap-y-8 py-5 px-4 rounded-[10px]'>
                              <div className='flex gap-x-2'>
                                <img src={ClassOverEvaluationIcon} alt='Evaluation' />
                                <p className='text-xs font-semibold tracking-tighter font-poppins xl:text-sm text-neutral-900'>
                                  Workbook Eval
                                </p>
                              </div>
                              <div className='flex flex-col gap-y-5'>
                                <div className='flex items-center justify-between'>
                                  <p className='text-[10px] xl:text-xs font-semibold font-poppins tracking-widest text-neutral-500'>
                                    QUESTIONS ANSWERED
                                  </p>
                                  <Badge className='bg-semantic-orange-500 py-[2px] px-2 rounded-lg text-sm font-semibold font-poppins text-white'>
                                    {`${element?.evaluationType?.workbookEvaluation?.correctAnswers}/${element?.evaluationType?.workbookEvaluation?.totalQuestions}`}
                                  </Badge>
                                </div>
                                {selectedStudentStatus?.currentState && (
                                  <div className='flex items-center justify-between'>
                                    <p className='text-[10px] uppercase xl:text-xs font-semibold font-poppins tracking-widest text-neutral-500'>
                                      {`MASTERY: ${
                                        selectedStudentStatus?.currentState?.toLowerCase() ===
                                        'coach_basic'
                                          ? 'Foundation'
                                          : selectedStudentStatus?.currentState
                                              .replace(/coach_/i, '')
                                              .replace(/^./, (str) => str.toUpperCase())
                                      }`}
                                    </p>

                                    <div className='flex flex-col gap-y-[3px]'>
                                      <div
                                        className={`w-6 h-[6px] ${selectedStudentStatus?.currentState?.toLocaleLowerCase() === 'coach_advance' ? 'bg-knowledgeLevel-500' : 'bg-white'} rounded-sm`}
                                      ></div>
                                      <div
                                        className={`w-6 h-[6px] ${selectedStudentStatus?.currentState?.toLocaleLowerCase() === 'coach_intermediate' || selectedStudentStatus?.currentState?.toLocaleLowerCase() === 'coach_advance' ? 'bg-knowledgeLevel-500' : 'bg-white'} rounded-sm`}
                                      ></div>
                                      <div
                                        className={`w-6 h-[6px] ${selectedStudentStatus?.currentState?.toLocaleLowerCase() === 'coach_basic' || selectedStudentStatus?.currentState?.toLocaleLowerCase() === 'coach_intermediate' || selectedStudentStatus?.currentState?.toLocaleLowerCase() === 'coach_advance' ? 'bg-knowledgeLevel-500' : 'bg-white'} rounded-sm`}
                                      ></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </Card>
            )}

            {/* what was taught today */}
            {!isEmpty(classFlowTeachphaseDetails) && (
              <Card className='flex-1 p-5 xl:w-[260px] rounded-[20px]' defaultColorCode='bg-white'>
                <div className='overflow-y-scroll md:h-full md:flex-1 scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-700 scrollbar-track-gray-300'>
                  <h5 className='mb-8 text-lg font-normal tracking-tight font-sora xl:text-xl text-neutral-900 xl:mb-10'>
                    What was taught today?
                  </h5>

                  <div className='flex flex-col gap-y-4 max-h-[10rem]'>
                    {classFlowTeachphaseDetails && (
                      <>
                        <p className='text-sm font-semibold tracking-tight font-poppins text-primary-500'>
                          {`${PerformanceDetails?.length < 10 ? `0${PerformanceDetails?.length + 1}.` : PerformanceDetails?.length + 1}  ${PerformanceDetails[0]?.elementName || ''}`}
                        </p>

                        <div className='py-5 px-4 rounded-[10px] bg-[#F5F7F8]'>
                          <ul className='pl-4 list-disc'>
                            {classFlowTeachphaseDetails?.map((teach) => {
                              return (
                                <li
                                  key={teach?.elementId}
                                  className='mb-4 text-xs font-medium font-poppins text-neutral-500'
                                >
                                  {teach?.name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Card>
            )}
          </div>

          {userType === USER_TYPE.GUARDIAN && (
            <ClassTimeline
              classData={classDetails}
              secondsDifferenceInClassEnds={0}
              isClassEnds={true}
            />
          )}
        </>
      )}
    </>
  );
};
export default ClassOver;
