import { useFormik } from 'formik';
import { useRef, useState } from 'react';
import Countdown from 'react-countdown';
import toast from 'react-hot-toast';
import { ErrorResponse, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useLocalStorage } from 'hooks';

import {
  useInitialForgetPasscodeMutation,
  useVerifyOtpForgetPasscodeMutation,
} from 'store/apiSlices/forgetPasscode.apiSlice';

import { Button, OtpInput } from 'components/common';

import { BackIconBlue, ExclamationLight, TickRoundedLight } from 'assets/svg';
import {
  APP_DEFAULT_MESSAGES,
  COMMON_ERROR,
  FORGOT_PASSCODE_VERIFICATION_TEXT,
  LOGIN_OTP_COUNTDOWN,
  OTP_SENT_SUCCESS,
} from 'configs';
import { IOtpVerify } from 'types';

export default function OtpVerify({ isOtpValidated }: IOtpVerify) {
  const navigate = useNavigate();
  const { getFromLocalStorage } = useLocalStorage();
  const mobileNumber = getFromLocalStorage('mobileNumber');

  const [forgetPasscodeService, { isSuccess, isLoading }] = useVerifyOtpForgetPasscodeMutation();

  const [forgetPasscodeSet] = useInitialForgetPasscodeMutation();

  const [isValidOtp, setIsValidOtp] = useState<boolean | null>(null);
  const [isCountdownFinished, setIsCountdownFinished] = useState<boolean>(false);
  const countdownEndTime = useRef(Date.now() + LOGIN_OTP_COUNTDOWN);

  // Verify OTP (If Student/Guardian not set passcode during registration)
  const otpFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: '',
    },
    validationSchema: Yup.object({
      otp: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.OTP_REQUIRED),
    }),
    onSubmit: (values) => {
      const toastId = toast.loading('Loading...');
      forgetPasscodeService({
        otp: values.otp,
        mobileNumber: mobileNumber || '',
      })
        .unwrap()
        .then((data) => {
          if (data) {
            setIsValidOtp(true);
            isOtpValidated(true);
            toast.success('OTP successfully verified.', { id: toastId });
          }
        })
        .catch((error) => {
          isOtpValidated(false);
          const message = (error as ErrorResponse)?.data?.message || COMMON_ERROR;
          setIsValidOtp(false);
          toast.error(message || 'Failed to send OTP', { id: toastId });
        });
      setIsValidOtp(null);
    },
  });

  const handleResendOtp = () => {
    const toastId = toast.loading('Loading...');
    setIsCountdownFinished(false);
    countdownEndTime.current = Date.now() + LOGIN_OTP_COUNTDOWN;
    if (mobileNumber) {
      forgetPasscodeSet({ mobileNumber: mobileNumber })
        .unwrap()
        .then(() => {
          toast.success(OTP_SENT_SUCCESS, { id: toastId });
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message || 'Failed to send OTP', { id: toastId });
          setIsValidOtp(false);
        });
    }
  };

  return (
    <form onSubmit={otpFormik.handleSubmit} className='flex-1 h-full'>
      <div className='flex flex-col justify-center flex-1 h-full md:justify-around'>
        {/* go back */}
        <div
          onClick={() => navigate(-1)}
          className='items-center justify-start hidden pt-10 cursor-pointer md:flex'
        >
          <img src={BackIconBlue} alt='logo' className='object-contain w-5 h-5' />
          <h2 className='ml-2 text-base text-primary-500 body-semibold'>Back</h2>
        </div>
        <div className='flex flex-col md:text-center md:justify-center md:items-center'>
          <div
            onClick={() => navigate(-1)}
            className='flex items-center justify-start pt-10 cursor-pointer md:hidden'
          >
            <img src={BackIconBlue} alt='logo' className='object-contain w-5 h-5' />
            <h2 className='ml-2 text-base text-primary-500 body-semibold'>Back</h2>
          </div>
          <h2 className='my-2 hidden md:block text-2xl text-neutral-850 display-bold md:text-xl xl:text-[32px]'>
            OTP Verification
          </h2>
          <h3 className='w-full my-4 leading-6 text-neutral-800 body-medium lg:text-sm xl:text-base'>
            {FORGOT_PASSCODE_VERIFICATION_TEXT}{' '}
            <span className='font-bold leading-5'>+91 {mobileNumber}</span>
          </h3>
          <div className='mt-[32px] text-sm sm:mt-6 md:text-sm'>
            <p className='text-xs tracking-widest text-neutral-500 text-start cap-semibold lg:text-sm'>
              Verify Via OTP
            </p>
            <div className='mt-3'>
              <div className='flex items-center gap-3'>
                <OtpInput
                  handleChange={(otp) => {
                    otpFormik.setFieldValue('otp', otp);
                  }}
                  value={otpFormik.values.otp}
                  isValid={otpFormik.errors.otp ? false : isValidOtp}
                  fields={4}
                />
                {isSuccess ||
                  (otpFormik?.errors?.otp && (
                    <span
                      className={`rounded-lg p-2 ${isValidOtp || isSuccess ? 'bg-semantic-green-500' : 'bg-semantic-red-500'}`}
                    >
                      <img
                        src={isValidOtp || isSuccess ? TickRoundedLight : ExclamationLight}
                        alt='tick'
                        className='object-contain w-4 h-4'
                      />
                    </span>
                  ))}
              </div>

              {otpFormik.errors.otp ? (
                <p className='mt-2 leading-5 text-semantic-red-500 sm:text-sm body-regular'>
                  {otpFormik.errors.otp}
                </p>
              ) : (
                <p className='text-xs body-regular text-neutral-600 lg:text-sm'>
                  Didn’t receive the OTP?{' '}
                  {isCountdownFinished ? (
                    <span
                      onClick={handleResendOtp}
                      className='leading-5 underline cursor-pointer lg:text-xs body-semibold text-primary-500'
                    >
                      Resend OTP
                    </span>
                  ) : (
                    <>
                      <span className='leading-5 text-primary-800 body-semibold lg:text-xs'>
                        Resend OTP In{' '}
                        <Countdown
                          date={countdownEndTime?.current}
                          precision={0}
                          renderer={({ minutes, seconds, completed }) => {
                            if (completed) {
                              setIsCountdownFinished(true);
                              return null;
                            } else {
                              return (
                                <span>
                                  {String(minutes).padStart(2, '0')}:
                                  {String(seconds).padStart(2, '0')}
                                </span>
                              );
                            }
                          }}
                        />
                      </span>
                    </>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className='my-6'>
          <Button
            type='submit'
            isLoading={isLoading}
            className={`w-full bg-primary-500 rounded-[14px] focus:ring-0 ring-none outline-none  text-white enabled:hover:bg-primary-500 enabled:hover:text-white  `}
            text='Next'
            disabled={otpFormik.values.otp.length !== 4}
          />
        </div>
      </div>
    </form>
  );
}
