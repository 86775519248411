import { Badge, Popover } from 'flowbite-react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';

import {
  useGetCurrentClassDetailsQuery,
  useGetStudentParentDetailsQuery,
} from 'store/apiSlices/studentDashboard.apiSlice';

import {
  CompassLight,
  DateIcon,
  HeroSectionbottom1,
  MathBook,
  ScienceBook,
  ScienceIcon,
  SubjectIconWhite,
  Timer,
  WhiteExclamation,
} from 'assets/svg';
import { Button, Card, Modal, ModalBottomSheet } from 'components/common';
import { ParentOnboarding } from 'components/screens';
import { ICurrentClassDetails } from 'types/democlass';

import {
  CLASS_OVER_BUFFER_TIME,
  CLASS_PROGRESS,
  CLASS_STATUS,
  ROUTES,
  STUDENT_JOINING_NOTE,
  SUBJECTS,
  USER_TYPE,
} from 'configs';
import { IScheduledProps } from 'types';
import { formatTimeRange } from 'utils';
import { useWindowDimensions } from 'hooks';

export default function ClassSchedule({
  state,
  setStateOfClass,
  setRemainingTime,
}: IScheduledProps) {
  const navigate = useNavigate();
  const [filteredUpcomingClass, setFilteredUpcomingClass] = useState<ICurrentClassDetails>();
  const { isMobileScreen, width } = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isParentOnboarding, setIsParentOnboarding] = useState<boolean>(false);
  const [enableJoinButton, setEnableJoinButton] = useState<boolean>(false);

  /* API => get current class list */
  const { data: currentClassResponse } = useGetCurrentClassDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  /* API => get the student parent details */
  const { data: studentParentDetails } = useGetStudentParentDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!isEmpty(studentParentDetails)) {
      const parentData = studentParentDetails?.data?.filter(
        (data) => data?.members?.userType === USER_TYPE.GUARDIAN,
      ).length;
      if (parentData === 0) {
        setIsModalOpen(true);
      } else if (parentData > 0) {
        setIsModalOpen(false);
        setIsParentOnboarding(true);
      }
    }
  }, [studentParentDetails]);

  useEffect(() => {
    const handleBatchMeetingTiming = (meetingStringTime: string, meetingEndTime: string) => {
      const serverTime = moment();
      const meetingTime = moment(meetingStringTime);
      const meetingEnd = moment(meetingEndTime);

      const meetingEndBufferedTime = meetingEnd.clone().add(CLASS_OVER_BUFFER_TIME, 'minutes');

      const secondsDifference = meetingTime.diff(serverTime, 'seconds');

      const endSecondsDifference = meetingEndBufferedTime.diff(serverTime, 'seconds');
      let NEW_STATE = '';
      let NEW_REMAINING_TIME = 0;
      if (secondsDifference > 0 && secondsDifference <= 15 * 60) {
        NEW_STATE = CLASS_PROGRESS.NOT_STARTED;
        NEW_REMAINING_TIME = Math.ceil(secondsDifference / 60);
      } else if (secondsDifference <= 0 && endSecondsDifference > 0) {
        NEW_STATE = CLASS_PROGRESS.STARTED;
        NEW_REMAINING_TIME = Math.ceil(secondsDifference / 60);
      } else if (endSecondsDifference <= 0) {
        NEW_STATE = CLASS_PROGRESS.END;
      } else if (secondsDifference > 15 * 60) {
        NEW_STATE = CLASS_PROGRESS.NOT_STARTED;
        NEW_REMAINING_TIME = Math.ceil(secondsDifference / 60);
      }

      // auto enabling join button
      if (NEW_REMAINING_TIME <= 1 && NEW_STATE !== CLASS_PROGRESS.END) setEnableJoinButton(true);
      else if (NEW_STATE !== CLASS_PROGRESS.STARTED) setEnableJoinButton(false);
      setStateOfClass(NEW_STATE);
      setRemainingTime(NEW_REMAINING_TIME);
    };

    let intervalId: NodeJS.Timeout;
    setFilteredUpcomingClass(currentClassResponse?.data);
    if (currentClassResponse?.data?.classStatus === CLASS_STATUS.ENDED) {
      setStateOfClass(CLASS_PROGRESS.END);
    } else if (currentClassResponse) {
      intervalId = setInterval(() => {
        handleBatchMeetingTiming(
          currentClassResponse?.data?.meetingStartTime,
          currentClassResponse?.data?.meetingEndTime,
        );
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [currentClassResponse, setRemainingTime, setStateOfClass]);

  const handleJoin = () => {
    if (!isParentOnboarding) {
      setIsModalOpen(true);
    } else {
      const joinCode = filteredUpcomingClass?.studentJoinLinks?.joinCode;
      if (joinCode) {
        navigate(ROUTES.CLASSROOM_INVITE.replace(':id', joinCode));
      }
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (isParentOnboarding) {
      setIsModalOpen(false);
    }
  }, [isParentOnboarding]);

  const lectureName =
    filteredUpcomingClass?.lectureConfigurationName ||
    filteredUpcomingClass?.classConfigurationName ||
    filteredUpcomingClass?.name;

  return (
    <Card
      className={`w-full rounded-xl lg:h-[188px] xl:min-h-[196px] 2xl:min-h-[198px] my-4 ${state === CLASS_PROGRESS.STARTED ? 'bg-neutral-700' : 'bg-white'} my-3 p-2 xl:p-3`}
    >
      <div className='grid flex-1 h-full grid-rows-1'>
        {/* state for the class not started */}
        {Object.keys(currentClassResponse?.data || {}).length > 0 ? (
          <div
            className={`grid gap-3 lg:gap-5 md:grid-cols-3 ${state === CLASS_PROGRESS.NOT_STARTED && 'lg:grid-cols-4'}`}
          >
            {/* Countdown */}
            {state === CLASS_PROGRESS.NOT_STARTED && (
              <div className='lg:col-span-1'>
                <div className='w-full h-48 lg:h-[10rem] xl:h-[10.8rem] bg-[#000] mr-6 rounded-2xl'>
                  <div className='flex flex-col items-center justify-center h-full'>
                    <p className='text-base body-semiBold text-neutral-300 lg:text-sm'>
                      Class starts in:{' '}
                    </p>
                    <p className='my-1 text-4xl font-bold text-semantic-red-500'>
                      <Countdown
                        date={moment(filteredUpcomingClass?.meetingStartTime).toDate()}
                        renderer={({ hours, minutes, seconds }) => {
                          return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* state for the class started */}
            {state === CLASS_PROGRESS.STARTED && (
              <div className='flex-1 h-full md:mr-4 lg:col-span-1 lg:mr-5 xl:mr-0'>
                <Card className='w-full flex flex-col justify-start md:justify-center items-center md:h-48 lg:h-[9.5rem] xl:h-[10.6rem] bg-semantic-red-500 mr-6 rounded-2xl'>
                  <div className='p-5'>
                    <div className='flex gap-2'>
                      <img src={WhiteExclamation} className='w-4' alt='exclamation' />
                      <p className='text-base lg:text-sm body-semiBold text-neutral-25'>
                        Class started{' '}
                      </p>
                    </div>
                    <div className='my-2 text-xl text-white lg:text-3xl text-start display-bold'>
                      <div className='w-40'>
                        {/* Set a fixed width */}
                        <Countdown
                          date={moment(filteredUpcomingClass?.meetingEndTime).toDate()}
                          renderer={({ hours, minutes, seconds }) => {
                            return (
                              <>
                                <div>
                                  {`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}{' '}
                                  left
                                </div>
                              </>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            )}

            {/* Class details */}
            <div
              className={`lg:col-span-1 ${state === CLASS_PROGRESS.NOT_STARTED && 'lg:col-span-2'} flex-1 h-full flex flex-col justify-between ${state === CLASS_PROGRESS.STARTED ? 'py-4 lg:p-0 ' : 'lg:py-1 lg:px-4'} `}
            >
              <div className='flex items-center'>
                <Badge className='flex items-center p-1 rounded-lg w-28 bg-primary-800 lg:bg-primary-150'>
                  <span className='text-sm cap-semibold flex items-center gap-2 tracking-widest text-primary-200 lg:text-complementary-colors-50 md:text-base lg:text-xs font-semibold leading-[19.3px]'>
                    <img
                      src={
                        filteredUpcomingClass?.subjectDetails?.name === SUBJECTS.MATHS
                          ? CompassLight
                          : filteredUpcomingClass?.subjectDetails?.name === SUBJECTS.SCIENCE
                            ? ScienceIcon
                            : SubjectIconWhite
                      }
                      alt='compass'
                      className='w-5 p-1 rounded-md bg-primary-500'
                    />
                    {filteredUpcomingClass?.subjectDetails?.name || SUBJECTS.MATHS}
                  </span>
                </Badge>
                <Badge
                  className={`rounded-lg p-1 py-2 md:py-1 w-20 text-center inline mx-2 ${state === CLASS_PROGRESS.STARTED ? 'bg-white text-primary-850' : 'text-white bg-primary-850'}`}
                >
                  <h2 className='text-sm font-semibold body-medium lg:text-sm'>
                    {filteredUpcomingClass?.batchTypeDetails?.name}
                  </h2>
                </Badge>
              </div>
              {width > 150 ? (
                <Popover
                  trigger='hover'
                  placement='top-end'
                  content={
                    <div className='bg-neutral-50 text-primary-500 font-semibold text-[10px] font-poppins px-3 py-1 rounded-md'>
                      {lectureName}
                    </div>
                  }
                >
                  <div
                    className={`max-w-xs truncate ${state === CLASS_PROGRESS.STARTED ? 'text-white' : 'text-neutral-800'} display-bold text-xl leading-8 md:text-base lg:text-lg xl:text-2xl text-wrap lg:text-nowrap lg:w-60 xl:w-96 my-2 lg:my-0`}
                  >
                    {lectureName}
                  </div>
                </Popover>
              ) : (
                <h3
                  className={`${state === CLASS_PROGRESS.STARTED ? 'text-white' : 'text-neutral-800'} display-bold text-xl leading-8 md:text-base lg:text-lg xl:text-2xl text-wrap lg:text-nowrap lg:w-60 xl:w-96 my-2 lg:my-0`}
                >
                  {lectureName}
                </h3>
              )}

              <div className='flex justify-between'>
                <div className='flex items-center justify-center'>
                  <span
                    className={`flex items-center ${state === CLASS_PROGRESS.STARTED ? 'bg-complementary-colors-25 text-neutral-900' : 'bg-neutral-150 text-neutral-700'} font-poppins font-medium rounded-2xl whitespace-nowrap p-1 px-3 text-xs xl:text-sm`}
                  >
                    <img className='inline w-5 p-1' src={DateIcon} alt='' />
                    {moment(filteredUpcomingClass?.date).format('DD MMM YY')}
                  </span>
                  <span
                    className={` flex items-center ${state === CLASS_PROGRESS.STARTED ? 'bg-complementary-colors-25 text-neutral-900' : 'bg-neutral-150 text-neutral-700'} whitespace-nowrap font-poppins ml-2 font-medium rounded-2xl p-1 px-3 text-xs xl:text-sm`}
                  >
                    <img className='inline w-5 p-1' src={Timer} alt='' />
                    {filteredUpcomingClass?.meetingStartTime &&
                      formatTimeRange(
                        filteredUpcomingClass?.meetingStartTime,
                        filteredUpcomingClass?.meetingEndTime,
                      )}
                  </span>
                </div>
              </div>
            </div>

            {/* Subject image */}
            {state !== CLASS_PROGRESS.NOT_STARTED && state !== CLASS_PROGRESS.STARTED && (
              <div className='flex items-center justify-center lg:col-span-1 lg:justify-center'>
                <div className='rounded-2xl'>
                  <img
                    className='object-cover w-48 h-48 md:w-full md:h-full xl:object-fit xl:w-36'
                    src={
                      filteredUpcomingClass?.subjectDetails?.name === 'Maths'
                        ? MathBook
                        : filteredUpcomingClass?.subjectDetails?.name === 'Science'
                          ? ScienceBook
                          : MathBook
                    }
                    alt=''
                  />
                </div>
              </div>
            )}

            {/* Join now Button */}
            {state !== CLASS_PROGRESS.END && (
              <div className='flex flex-col items-center justify-center lg:col-span-1 lg:ml-5 xl:ml-0'>
                <Button
                  type='button'
                  text='Join Now'
                  onClick={handleJoin}
                  className='px-4 text-base font-medium text-white rounded-lg bg-semantic-green-500'
                  disabled={!enableJoinButton}
                />
                {state !== CLASS_PROGRESS.STARTED && (
                  <h6
                    className={`${state === CLASS_PROGRESS.STARTED ? 'text-white' : 'text-neutral-800'} font-poppins mt-5 w-60 lg:w-52 xl:w-56 font-bold text-sm md:text-sm lg:text-xs text-center`}
                  >
                    {STUDENT_JOINING_NOTE}
                  </h6>
                )}
              </div>
            )}
          </div>
        ) : (
          <>
            <div className='grid items-center justify-center grid-cols-1 md:grid-cols-3'>
              <div className='col-span-1 text-center'>
                <img
                  src={HeroSectionbottom1}
                  alt='HeroSectionRight'
                  className='md:h-44 rounded-xl'
                />
              </div>
              <p className='max-w-lg col-span-2 py-3 mt-2 text-base text-center md:py-0 display-bold md:text-lg lg:text-xl text-neutral-600'>
                Woohoo! You've aced the demo class! 🎉
              </p>
            </div>
          </>
        )}
      </div>

      {isModalOpen && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              setOpenModal={setIsModalOpen}
              openModal={isModalOpen}
              heading='Parent Onboarding'
              showCloseBtn={false}
              children={
                <div className=''>
                  <ParentOnboarding isParentOnboarding={setIsParentOnboarding} />
                </div>
              }
            />
          ) : (
            <Modal
              heading='Parent Onboarding'
              children={
                <div className=''>
                  <ParentOnboarding isParentOnboarding={setIsParentOnboarding} />
                </div>
              }
              showCloseBtn={false}
              openModal={isModalOpen}
              setOpenModal={setIsModalOpen}
            />
          )}
        </div>
      )}
    </Card>
  );
}
