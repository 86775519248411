import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IGuidedSolution {
  evalId: string;
  isCompleted: boolean;
  completedAt?: string;
}
interface IContentInfo {
  htmlLink: string;
  step: number;
  slide: number;
  evalId: string;
  tutorRoomChange: boolean;
  currentLectureId: string;
  guidedSolutions: IGuidedSolution[];
}

const initialState: IContentInfo = {
  htmlLink: '',
  step: 0,
  slide: 1,
  evalId: '',
  tutorRoomChange: false,
  currentLectureId: '',
  guidedSolutions: [],
};

export const contentInfo = createSlice({
  name: 'contentInfo',
  initialState,
  reducers: {
    setSlideData: (
      state,
      action: PayloadAction<{ step: number; slide: number; htmlLink: string }>,
    ) => {
      const { step, slide, htmlLink } = action.payload;
      state.htmlLink = htmlLink;
      state.step = step;
      state.slide = slide;
    },
    setEvalId: (state, action: PayloadAction<string>) => {
      state.evalId = action.payload;
    },
    setTutorRoomChange: (state, action: PayloadAction<boolean>) => {
      state.tutorRoomChange = action.payload;
    },
    setCurrentLectureId: (state, action: PayloadAction<string>) => {
      state.currentLectureId = action.payload;
    },
    //TODO: currently not handled the refresh state for guided solution and for that redux functionality DONE
    markGuidedSolutionComplete: (state, action: PayloadAction<string>) => {
      const evalId = action.payload;
      const existingSolution = state?.guidedSolutions?.find(
        (solution) => solution?.evalId === evalId,
      );

      if (!existingSolution) {
        state?.guidedSolutions?.push({
          evalId,
          isCompleted: true,
          completedAt: new Date().toISOString(),
        });
      } else {
        existingSolution.isCompleted = true;
        existingSolution.completedAt = new Date().toISOString();
      }
    },
    resetGuidedSolutions: (state) => {
      state.guidedSolutions = [];
    },
  },
  selectors: {
    selectHtmlLink: (state) => state?.htmlLink,
    selectHtmlSlide: (state) => state?.slide,
    selectHtmlStep: (state) => state?.step,
    selectEvalId: (state) => state?.evalId,
    selectTutorRoomChange: (state) => state?.tutorRoomChange,
    currentLectureId: (state) => state?.currentLectureId,
    selectIsGuidedSolutionCompleted: (state) => (evalId: string) =>
      state?.guidedSolutions?.some(
        (solution) => solution?.evalId === evalId && solution?.isCompleted,
      ),
  },
});

export const {
  setSlideData,
  setEvalId,
  setTutorRoomChange,
  setCurrentLectureId,
  markGuidedSolutionComplete,
  resetGuidedSolutions,
} = contentInfo.actions;

export const {
  selectHtmlLink,
  selectHtmlSlide,
  selectHtmlStep,
  selectEvalId,
  selectTutorRoomChange,
  currentLectureId,
  selectIsGuidedSolutionCompleted,
} = contentInfo.selectors;
