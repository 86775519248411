import { useDyteMeeting } from '@dytesdk/react-web-core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetStudentDetailsByIdQuery } from 'store/apiSlices/common.apiSlice';

import { PlayButton } from 'assets/svg';
import { Avatar } from 'components/common';

const SetupScreen = () => {
  const { meeting } = useDyteMeeting();
  const [isPlaying, setIsPlaying] = useState(false);
  const { studentId } = useParams();
  const { data: studentData } = useGetStudentDetailsByIdQuery({ studentId: Number(studentId) });
  const firstName = studentData?.data?.firstName;
  const lastName = studentData?.data?.lastName;

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const joinMeeting = () => {
    meeting.joinRoom();
  };

  return (
    <div className='relative flex items-center justify-center w-full h-full'>
      <Avatar
        image={studentData?.data?.profileImage}
        className='object-contain w-full h-full'
        firstName={firstName}
        lastName={lastName}
        initialsClassName='w-[3rem] h-[3rem] sm:w-[4rem] sm:h-[4rem] rounded-full bg-neutral-300 text-black flex items-center justify-center'
      />
      <div className='absolute p-3 top-[65%] left-[2%] bottom-0 cursor-pointer'>
        <img
          src={PlayButton}
          alt='PlayButton'
          onClick={() => {
            joinMeeting();
            togglePlayPause();
          }}
        />
      </div>
    </div>
  );
};

export default SetupScreen;
