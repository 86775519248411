import { Spinner, SpinnerProps } from 'flowbite-react';
import classNames from 'classnames';

interface LoaderProps extends SpinnerProps {
  className?: string;
  message?: string;
}

export default function Loader({ className, size, color, message }: LoaderProps) {
  return (
    <div
      className={classNames(
        'w-full top-1/2 bottom-1/2 absolute flex flex-col justify-center items-center space-y-3',
        className,
      )}
    >
      <Spinner size={size} color={color} light={true} />
      {message && (
        <span className='flex items-center justify-center text-sm md:text-md lg:text-lg'>
          {message}
        </span>
      )}
    </div>
  );
}
