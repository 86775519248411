import { NotificationContext } from 'contexts/NotificationContext';
import { useContext } from 'react';

const useNotification = () => {
  const { setNotification } = useContext(NotificationContext);

  const triggerNotification = (
    message: string,
    type: 'success' | 'error' | 'info' = 'info',
    notificationSound: string = '',
  ) => {
    setNotification({
      visible: true,
      message,
      type,
    });
    if (notificationSound) {
      const audio = new Audio(notificationSound);
      audio.play();
    }
    setTimeout(() => {
      setNotification({
        visible: false,
        message: '',
        type: 'info',
      });
    }, 5000);
  };

  return triggerNotification;
};

export default useNotification;
