import { TodayClassImg } from 'assets/svg';
import { ProgressBar } from 'components/common';

import { IPerformanceDetailsContainer } from 'types';

export default function ElementPerformance({
  PerformanceDetails,
  index,
}: IPerformanceDetailsContainer) {
  return (
    <div key={PerformanceDetails?.elementId} className='flex flex-col carousel-item'>
      <p className='text-sm font-semibold tracking-tight text-white font-poppins'>
        {`${index < 9 ? `0${index + 1}` : index + 1} ${PerformanceDetails?.elementName}`}
      </p>
      <div className='flex items-center justify-between mb-6'>
        <div className='bg-[#F59E0B] h-[69px] p-4 flex flex-col items-start gap-y-[4px] rounded-lg'>
          <p className='font-poppins font-semibold text-[10px] tracking-widest text-white'>
            PERFORMANCE
          </p>
          <p className='flex items-center justify-start'>
            <ProgressBar
              progress={
                PerformanceDetails?.performanceScore > 100
                  ? 100
                  : PerformanceDetails?.performanceScore
              }
              type='rounded'
              strokeColor='white'
              strokeWidth={4}
              radius={16}
            />
            <span className='text-xl font-bold tracking-tight text-white font-sora'>
              {PerformanceDetails?.performanceScore > 100
                ? 100
                : PerformanceDetails?.performanceScore}
              %
            </span>
          </p>
        </div>
        <div>
          <img className='h-20 w-29' src={TodayClassImg} alt='' />
        </div>
      </div>
    </div>
  );
}
