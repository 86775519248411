import Latex from 'react-latex-next';
import { IRenderContent } from 'types';

const RenderEvalContent = ({ data }: { data: IRenderContent[] }) => {
  return (
    <>
      <span>
        {data.map((item, index) => {
          switch (item.type) {
            case 'latex':
              return <Latex key={index}>{`$${item.content}$`}</Latex>;
            case 'break':
              return <br key={index} />;
            case 'text':
              return <span key={index}>{item.content}</span>;
            case 'image':
              return <img className='inline w-100' key={index} src={item.content} alt='' />;
            default:
              return null;
          }
        })}
      </span>
    </>
  );
};

export default RenderEvalContent;
