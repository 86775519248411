import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import * as Yup from 'yup';

import {
  useInitialMobileVerificationMutation,
  useUpdateMobileNumberMutation,
} from 'store/apiSlices/register.apiSlice';
import { setUpdateMobileNumber } from 'store/slice/studentRegister.slice';

import { ExclamationCircle, ExclamationLight, TickRoundedLight } from 'assets/svg';
import { Button, Input, Loader, OtpInput } from 'components/common';

import { APP_DEFAULT_MESSAGES, APP_REGEX, LOGIN_OTP_COUNTDOWN } from 'configs';
import { useLocalStorage, useWindowDimensions } from 'hooks';
import { useAppDispatch } from 'hooks/store';

import toast from 'react-hot-toast';
import { IUpdateMobileNumber } from 'types';

export default function UpdateMobileNumber({ studentData }: IUpdateMobileNumber) {
  const { isMobileScreen } = useWindowDimensions();
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const { setToLocalStorage, getFromLocalStorage } = useLocalStorage();
  const mobileNumber = getFromLocalStorage('mobileNumber');

  const [step, setStep] = useState<number>(1);
  const [isCountdownFinished, setIsCountdownFinished] = useState<boolean>(false);
  const [isValidOtp] = useState<boolean | null>(null);
  const [userUpdatedMobileNumber, setUserUpdatedMobileNumber] = useState<string>('');
  const countdownEndTime = useRef(Date.now() + LOGIN_OTP_COUNTDOWN);

  /* API => initial mobile number verification */
  const [initialMobileVerification, { isLoading }] = useInitialMobileVerificationMutation();

  /* API => otp verification and update mobile number */
  const [UpdateMobileNumber, { isLoading: otpLoading, isSuccess }] =
    useUpdateMobileNumberMutation();

  // Formik for mobile number input
  const mobileFormik = useFormik({
    initialValues: {
      mobileNumber: mobileNumber || '',
      studentId: studentData?.id,
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .required(APP_DEFAULT_MESSAGES.VALIDATION.MOBILE_NUMBER_REQUIRED)
        .matches(
          APP_REGEX.VALIDATION.MOBILE_NUMBER_VALIDATION,
          APP_DEFAULT_MESSAGES.VALIDATION.VALID_MOBILE_NO,
        ),
    }),
    onSubmit: (values) => {
      if (values?.mobileNumber !== mobileNumber) {
        setUserUpdatedMobileNumber(values?.mobileNumber || '');
        initialMobileVerification(values || '')
          .unwrap()
          .then((res) => {
            toast.success(res?.message || '');
            setStep(2);
            resetCountdown();
          })
          .catch((err) => {
            toast.error(err?.data?.message || '');
          });
      } else {
        toast.error(APP_DEFAULT_MESSAGES.VALIDATION.MOBILE_NUMBER_SAME);
      }
    },
  });

  // Formik for OTP verification
  const otpFormik = useFormik({
    initialValues: { otp: '' },
    validationSchema: Yup.object({
      otp: Yup.string().required(APP_DEFAULT_MESSAGES.VALIDATION.OTP_REQUIRED),
    }),
    onSubmit: (values) => {
      if (userUpdatedMobileNumber && values.otp) {
        UpdateMobileNumber({ mobileNumber: userUpdatedMobileNumber, otp: values.otp })
          .unwrap()
          .then((res) => {
            toast.success(res?.message || '');
            setToLocalStorage('mobileNumber', userUpdatedMobileNumber || '');
            dispatch(
              setUpdateMobileNumber({
                mobileNumber: userUpdatedMobileNumber,
                ismobileNumberUpdated: true,
              }),
            );
          })
          .catch((err) => {
            toast.error(err?.data?.message || '');
          });
      }
    },
  });

  const handleResendOtp = () => {
    mobileFormik.submitForm();
    resetCountdown();
  };

  const resetCountdown = () => {
    countdownEndTime.current = Date.now() + LOGIN_OTP_COUNTDOWN;
    setIsCountdownFinished(false);
  };

  useEffect(() => {
    if (inputRef) {
      inputRef.current?.focus();
    }
  }, [inputRef]);

  return (
    <>
      {isLoading || otpLoading ? (
        <Loader className='relative min-h-[10rem]' size={'lg'} />
      ) : (
        <div>
          {step === 1 && (
            <form onSubmit={mobileFormik.handleSubmit}>
              <div>
                <h3 className='w-full my-2 capitalize text-neutral-800 title-semibold lg:text-lg'>
                  current mobile Number:{' '}
                  <span className='font-bold leading-5'>
                    +91 {mobileNumber || studentData?.mobileNumber}
                  </span>
                </h3>
              </div>
              <Input
                ref={inputRef}
                type='number'
                id='mobileNumber'
                name='mobileNumber'
                placeholder='932xxxxxxx'
                label={isMobileScreen ? '' : 'Enter Mobile Number'}
                pattern='[0-9]*'
                autoComplete='on'
                className='w-full py-0 pl-10 pr-10 leading-8'
                isMobilenumber={true}
                value={mobileFormik.values.mobileNumber || ''}
                onChange={(e) => mobileFormik.setFieldValue('mobileNumber', e.target.value)}
                maxLength={APP_REGEX?.MAX_LENGTH?.MOBILE_NUMBER_INPUT}
                onBlur={mobileFormik.handleBlur}
                isValid={
                  mobileFormik.touched.mobileNumber && mobileFormik.errors.mobileNumber
                    ? true
                    : false
                }
                icon={
                  mobileFormik.errors.mobileNumber && mobileFormik.touched.mobileNumber
                    ? ExclamationCircle
                    : ''
                }
              />
              {mobileFormik.touched.mobileNumber && mobileFormik.errors.mobileNumber ? (
                <p className='mt-2 leading-5 text-semantic-red-500 sm:text-sm body-regular'>
                  {mobileFormik.errors.mobileNumber}
                </p>
              ) : null}

              <div className='mt-6 md:mt-10'>
                <Button
                  type='submit'
                  isLoading={isLoading}
                  disabled={
                    mobileNumber === mobileFormik.values.mobileNumber || !mobileFormik.isValid
                  }
                  className={`w-full bg-primary-500 enabled:hover:bg-primary-500 text-white rounded-lg`}
                  text='Submit'
                />
              </div>
            </form>
          )}

          {step === 2 && (
            <div>
              <form onSubmit={otpFormik.handleSubmit}>
                <div className='my-1 text-sm sm:mt-6 md:text-sm'>
                  <p className='text-xs text-neutral-500 cap-semibold lg:text-sm'>Verify Via OTP</p>
                </div>
                <div className='mt-3'>
                  <div className='flex items-center gap-3'>
                    <OtpInput
                      handleChange={(otp) => {
                        otpFormik.setFieldValue('otp', otp);
                      }}
                      value={otpFormik.values.otp}
                      isValid={otpFormik.errors.otp ? false : isValidOtp}
                      fields={4}
                    />
                    {isSuccess ||
                      (otpFormik?.errors?.otp && (
                        <span
                          className={`rounded-lg p-2 ${isValidOtp || isSuccess ? 'bg-semantic-green-500' : 'bg-semantic-red-500'}`}
                        >
                          <img
                            src={isValidOtp || isSuccess ? TickRoundedLight : ExclamationLight}
                            alt='tick'
                            className='object-contain w-4 h-4'
                          />
                        </span>
                      ))}
                  </div>

                  {otpFormik.errors.otp ? (
                    <p className='mt-2 leading-5 text-semantic-red-500 sm:text-sm body-regular'>
                      {otpFormik.errors.otp}
                    </p>
                  ) : (
                    <p className='text-xs body-regular text-neutral-600 lg:text-sm'>
                      Didn’t receive the OTP?{' '}
                      {isCountdownFinished ? (
                        <span
                          onClick={handleResendOtp}
                          className='leading-5 underline cursor-pointer lg:text-xs body-semibold text-primary-500'
                        >
                          Resend OTP
                        </span>
                      ) : (
                        <>
                          <span className='leading-5 text-primary-800 body-semibold lg:text-xs'>
                            Resend OTP In{' '}
                            <Countdown
                              date={countdownEndTime?.current}
                              precision={0}
                              renderer={({ minutes, seconds, completed }) => {
                                if (completed) {
                                  setIsCountdownFinished(true);
                                  return null;
                                } else {
                                  return (
                                    <span>
                                      {String(minutes).padStart(2, '0')}:
                                      {String(seconds).padStart(2, '0')}
                                    </span>
                                  );
                                }
                              }}
                            />
                          </span>
                        </>
                      )}
                    </p>
                  )}
                </div>
                <div className='mt-6 md:mt-10'>
                  <Button
                    type='submit'
                    isLoading={otpLoading}
                    className={`w-full bg-primary-500 enabled:hover:bg-primary-500 text-white rounded-lg`}
                    text='Submit'
                  />
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </>
  );
}
