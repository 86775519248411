import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  OpenRoutes,
  ProtectedRouteWithLoginToken,
  ProtectedRouteWithTempToken,
} from 'layouts/RoutesConfig';

import { OPEN_ROUTES, PROTECTED_ROUTES, ROUTES } from 'configs';
import { Accounts, PageNotFound, QrCodeFlow, StudentClassroom } from 'pages';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Open routes */}
        <Route element={<OpenRoutes />}>
          {OPEN_ROUTES.map(({ path, element }) => {
            return <Route key={path} path={path} element={element} />;
          })}
        </Route>

        <Route path={ROUTES.PRACTICE_CLASS_QRCODE_CAPTURE} element={<QrCodeFlow />} />
        {/* Student classroom route */}
        <Route path={ROUTES.CLASSROOM_INVITE} element={<StudentClassroom />} />
        {/* Account holder route */}
        <Route element={<ProtectedRouteWithTempToken />}>
          <Route path={ROUTES.ACCOUNTS} element={<Accounts />} />;
        </Route>

        {/* Protected routes */}
        <Route element={<ProtectedRouteWithLoginToken />}>
          {PROTECTED_ROUTES.map(({ path, element }) => {
            return <Route key={path} path={path} element={element} />;
          })}
        </Route>
        {/* Catch all route */}
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
