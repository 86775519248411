const Recording = () => {
  return (
    <>
      <div className='flex items-center justify-center w-32 h-10 text-sm text-white rounded-full bg-primary-850'>
        <span className='w-3 h-3 mr-3 bg-red-600 rounded-full ring-offset-primary-850 animate-pulse ring-2 ring-offset-4 ring-red-600'></span>
        Recording
      </div>
    </>
  );
};

export default Recording;
