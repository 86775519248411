import { useCallback, useEffect, useState } from 'react';

import { selectEvalId } from 'store/slice/content.slice';
import { selectMyCurrentState } from 'store/slice/inClassConfig.slice';
import { selectMultiLecture } from 'store/slice/lecture.slice';

import { GuidedSolutions, NotebookEval } from 'components/inClass';
import { useAppSelector } from 'hooks/store';

import { CONFIG, CONTENT_TYPE } from 'configs';
import { EvaluationState, IEvaluationProps } from 'types';
import { BouncingDotsLoader } from 'components/common';

export default function Evaluation({ written, loading }: IEvaluationProps) {
  // State
  const [evaluationState, setEvaluationState] = useState<EvaluationState>({
    showGuidedSolutions: false,
    hasGuidedSolutions: false,
  });

  // Redux
  const myCurrentState = useAppSelector(selectMyCurrentState) || CONFIG.ROOMS.TEACH;
  const lectureData = useAppSelector(selectMultiLecture);
  const evalId = useAppSelector(selectEvalId);

  // Initial flow for checking guided solution exists in the evaluations
  useEffect(() => {
    if (lectureData?.[myCurrentState]) {
      const currentLecture = lectureData[myCurrentState]?.find(
        (lecture) => lecture?.type === CONTENT_TYPE.EVALUATION && lecture?.evaluationId === evalId,
      );

      if (currentLecture) {
        const hasGuided = Boolean(currentLecture?.guidedSolutions?.length);
        setEvaluationState({
          showGuidedSolutions: hasGuided,
          hasGuidedSolutions: hasGuided,
        });
      }
    }
  }, [lectureData, myCurrentState, evalId]);

  const handleMoveToQuestions = useCallback(() => {
    setEvaluationState((prev) => ({
      ...prev,
      showGuidedSolutions: false,
    }));
  }, []);

  // Loading UI
  if (loading) {
    return (
      <div className='flex flex-col items-center justify-center text-white size-full'>
        <p className='mb-8 text-xl'>Waiting for tutor to start evaluation...</p>
        <BouncingDotsLoader />
      </div>
    );
  }

  return (
    <>
      {evaluationState.showGuidedSolutions && evaluationState.hasGuidedSolutions ? (
        <GuidedSolutions onMoveToQuestions={handleMoveToQuestions} />
      ) : (
        <NotebookEval written={written} />
      )}
    </>
  );
}
