import {
  IAnnotatedImageResponse,
  IClassFlowContent,
  ICurrentStatusOfStudentInClass,
  IParentClassToken,
  IElementsWiseClassProgress,
  ISiblingParentListResponse,
  IStudentDetailsResponse,
} from 'types/dashboard/parentDashboard';
import { ICurrentClass } from 'types/democlass';
import apiSlice from '.';

const parentDashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    /* get the list of classes */
    getClassTimelineFlowDetails: builder.query<
      IClassFlowContent,
      { studentId: number; classId: number }
    >({
      query: ({ studentId, classId }) => ({
        url: `/v2/in-class/class-flow?classId=${classId}&studentId=${studentId}`,
        method: 'GET',
      }),
    }),

    /* get the classToken parentDashboard*/
    getClassToken: builder.query<IParentClassToken, { studentId: number; classId: number }>({
      query: ({ studentId, classId }) => ({
        url: `/v2/parents/class-token?classId=${classId}&studentId=${studentId}`,
        method: 'GET',
      }),
    }),

    getSiblingParentListDetails: builder.query<ISiblingParentListResponse, undefined>({
      query: (body) => ({
        url: `/auth/student-guardians/sibling-guardians-list`,
        method: 'POST',
        body,
      }),
    }),

    getCurrentClassDetailsOfStudent: builder.query<ICurrentClass, { studentId: string }>({
      query: ({ studentId }) => ({
        url: `/classes/current?studentId=${studentId}`,
      }),
    }),

    getElementWiseClassProgressDetails: builder.query<
      IElementsWiseClassProgress,
      { studentId: string; classId: string }
    >({
      query: ({ studentId, classId }) => ({
        url: `/v2/classes/engagement/element-wise-class-progress?classId=${classId}&studentId=${studentId}`,
        method: 'GET',
      }),
    }),

    getCurrentStatusOfStudentDetails: builder.query<
      ICurrentStatusOfStudentInClass,
      { classId: string }
    >({
      query: ({ classId }) => ({
        url: `/in-class/engagement/current-state?classId=${classId}`,
      }),
    }),

    getAnnotatedImageOfStudent: builder.query<
      IAnnotatedImageResponse,
      { studentId: string; classId?: number }
    >({
      query: ({ studentId, classId }) => ({
        url: `v2/note-answers?studentId=${studentId}&classId=${classId}`,
        method: 'GET',
      }),
    }),
    getPastClassDataOfStudent: builder.query<ICurrentClass, { studentId: string; classId: string }>(
      {
        query: ({ studentId, classId }) => ({
          url: `/v2/classes/${classId}?studentId=${studentId}`,
          method: 'GET',
        }),
      },
    ),
    getStudentDetails: builder.query<IStudentDetailsResponse, { studentId?: string }>({
      query: ({ studentId }) => ({
        url: `/student/detail${studentId ? `?studentId=${studentId}` : ''}`,
        method: 'GET',
      }),
    }),

    // getConnectWithTutor = builder.query<IConnectWIthTutor, undefined>({
    //   query: (body) => ({
    //     url: `/in-class/class-content/evaluation/${evaluationId}/note-pages?studentId=${studentId}`,
    //     method: 'POST',
    //   }),
    // }),
  }),
});

export const {
  useGetClassTimelineFlowDetailsQuery,
  useGetClassTokenQuery,
  useGetSiblingParentListDetailsQuery,
  useGetCurrentClassDetailsOfStudentQuery,
  useGetPastClassDataOfStudentQuery,
  useGetElementWiseClassProgressDetailsQuery,
  useGetCurrentStatusOfStudentDetailsQuery,
  useLazyGetAnnotatedImageOfStudentQuery,
  useGetStudentDetailsQuery,
  useGetAnnotatedImageOfStudentQuery,
} = parentDashboardApiSlice;
