import { useEffect, useState } from 'react';
import Cookie from 'js-cookie';

import { useGetAccountHolderQuery } from 'store/apiSlices/login.apiSlice';
import { skipToken } from '@reduxjs/toolkit/query';

import { PlusIcon } from 'assets/svg';
import { Avatar, Modal, ModalBottomSheet } from 'components/common';
import { ParentOnboarding } from 'components/screens';

import { RomanNumber, USER_TYPE, CONFIG } from 'configs';
import { useLocalStorage, useWindowDimensions } from 'hooks';
import { IParentSectionProps, User } from 'types';

const ShowParentsSection = ({
  handleParentSelect,
  guardiansList,
  studentsList,
  selectedParentId,
  selectedStudentId,
  isParentSelected,
  refetch,
}: IParentSectionProps) => {
  const { isMobileScreen } = useWindowDimensions();
  const { getFromLocalStorage } = useLocalStorage();
  const mobileNumber = getFromLocalStorage('mobileNumber');
  const loginToken = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isParentOnboarding, setIsParentOnboarding] = useState<boolean>(false);
  const [matchedId, setMatchedId] = useState<number>();

  // Get account holder data
  const { data: accountHolderData } = useGetAccountHolderQuery(loginToken ?? skipToken, {
    skip: !mobileNumber || !loginToken,
    refetchOnMountOrArgChange: true,
  });

  const handleAddParent = () => {
    if (Array.isArray(accountHolderData?.data)) {
      const matchedMember = accountHolderData?.data?.find((entry: User) => {
        return entry?.members?.mobileNumber === mobileNumber;
      });

      if (matchedMember) {
        setMatchedId(matchedMember?.members?.id);
      }
    } else if (accountHolderData?.data) {
      if (accountHolderData?.data?.members?.mobileNumber === mobileNumber) {
        setMatchedId(accountHolderData?.data?.members?.id);
      }
    }
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (isParentOnboarding) {
      setIsModalOpen(false);
      refetch();
    }
  }, [isParentOnboarding, refetch]);

  const renderCardsForParentAndParentView = (
    list: User[],
    type: string,
    studentsForParentView: boolean = false,
  ) => {
    return (
      list &&
      list.map((account: User, index) => {
        const isSelected =
          isParentSelected &&
          (selectedParentId === account?.members?.id || selectedStudentId === account?.members?.id);
        const grade = RomanNumber.find((r) => r.class === account?.members?.grade)?.romanValue;
        const uniqueCardIdentification =
          account?.members?.userType === 'guardian'
            ? `parent-${index + 1}`
            : `student-${index + 1}`;
        return (
          <div
            key={account?.members?.id}
            id={uniqueCardIdentification}
            className={`${isSelected ? 'bg-primary-500' : 'bg-white'} rounded-xl px-2 pb-4 ${studentsForParentView ? 'w-[9rem] h-[9rem] sm:w-[12rem] sm:h-[12rem] pt-4' : ' w-[10rem] h-[10rem] sm:w-[13rem] sm:h-[13rem]'} shadow-lg flex flex-col items-center justify-center m-2 transition-transform transform hover:scale-105 hover:duration-300 cursor-pointer`}
            onClick={() => handleParentSelect(type, account?.members)}
          >
            <div
              className={`bg-white rounded-full ${studentsForParentView ? 'w-5 h-5 sm:w-7 sm:h-7 py-2' : 'w-5 h-5 sm:w-7 sm:h-7'} self-end flex justify-center items-center border-2 border-neutral-200`}
            >
              <div
                className={`${isSelected ? 'bg-primary-500' : 'bg-white'} rounded-full w-2 h-2`}
              ></div>
            </div>
            <div className='avatar'>
              <div className='rounded-full'>
                <Avatar
                  firstName={account?.members?.firstName}
                  lastName={account?.members?.lastName}
                  image={account?.members?.profileImage}
                  className={'rounded-full h-[70px] w-[70px] object-cover aspect-square'}
                  initialsClassName={`${studentsForParentView ? 'w-8 h-8 sm:w-12 sm:h-12' : 'w-10 h-10 sm:w-14 sm:h-14'} ${isSelected ? 'bg-white text-black' : 'bg-primary-500 text-white'} border-primary-500 rounded-full p-2 flex  justify-center items-center`}
                  initialsFontSizeClassName={`${studentsForParentView ? 'text-xs' : 'text-xs sm:text-base'}`}
                ></Avatar>
              </div>
            </div>
            <div className='mt-4'>
              <p
                className={`${isSelected ? 'text-neutral-200' : 'text-neutral-600'} ${studentsForParentView ? 'text-[10px] sm:text-sm' : 'text-xs sm:text-lg'} text-center font-medium font-sora`}
              >
                {type === USER_TYPE.STUDENT
                  ? `Class ${grade}`
                  : `${account?.members?.firstName + ' ' + account?.members?.lastName}`}
              </p>
              <p
                className={`${studentsForParentView ? 'text-xs sm:text-sm' : 'text-sm sm:text-lg'} font-semibold font-sora text-center ${isSelected ? 'text-white' : 'text-black'}`}
              >
                {type === USER_TYPE.STUDENT
                  ? `${account?.members?.firstName + ' ' + account?.members?.lastName}`
                  : 'Parent'}
              </p>
            </div>
          </div>
        );
      })
    );
  };

  return (
    <>
      <div className='flex flex-col flex-wrap items-center justify-center gap-x-2 gap-y-2'>
        <div className='flex flex-col gap-y-5'>
          <div className='flex flex-wrap justify-center'>
            {renderCardsForParentAndParentView(guardiansList, 'parent')}
            {guardiansList.length < 2 && (
              <div
                className={`${isParentSelected ? 'hidden' : 'flex'} w-[10rem] h-[10rem] sm:w-[13rem] sm:h-[13rem] rounded-xl bg-white shadow-lg flex flex-col items-center justify-center m-2 transition-transform transform hover:scale-105 hover:duration-300 cursor-pointer`}
                onClick={() => handleAddParent()}
              >
                <div className='mt-6 rounded-full'>
                  <Avatar
                    image={PlusIcon}
                    className={'rounded-full bg-neutral-50 p-4 sm:p-6'}
                    initialsFontSizeClassName='text-base'
                  ></Avatar>
                </div>
                <div className='mt-4'>
                  <p
                    className={`text-sm sm:text-lg font-semibold font-sora text-center text-black`}
                  >
                    Add
                  </p>
                  <p
                    className={` text-neutral-500 text-xs sm:text-lg text-center font-medium font-sora`}
                  >
                    Parent
                  </p>
                </div>
              </div>
            )}
          </div>

          {isParentSelected && (
            <div className={`bg-primary-50 py-6 px-10 rounded-lg shadow-lg mx-5`}>
              <h2 className='mb-4 text-sm font-semibold text-center sm:text-lg text-primary-850 font-sora'>
                Which child's profile you'd like to view?
              </h2>
              <div className='flex flex-wrap justify-center'>
                {renderCardsForParentAndParentView(studentsList, 'student', true)}
              </div>
            </div>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              setOpenModal={setIsModalOpen}
              openModal={isModalOpen}
              heading='Parent Onboarding'
              showCloseBtn={true}
              children={
                <div className=''>
                  <ParentOnboarding
                    isParentOnboarding={setIsParentOnboarding}
                    studentData={studentsList[0]?.members}
                    matchedId={matchedId}
                  />
                </div>
              }
            />
          ) : (
            <Modal
              heading='Parent Onboarding'
              children={
                <div className=''>
                  <ParentOnboarding
                    isParentOnboarding={setIsParentOnboarding}
                    studentData={studentsList[0]?.members}
                    matchedId={matchedId}
                  />
                </div>
              }
              showCloseBtn={true}
              openModal={isModalOpen}
              setOpenModal={setIsModalOpen}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ShowParentsSection;
