/* Dynamic Demo Class Dates Object */

import moment from 'moment';

const getDemoClassDatesByCount = (count: number) => {
  const demoDates = [];
  let date = moment();
  let i = 0;
  while (i <= count) {
    i++;
    date = date.add(1, 'days');
    const day = moment(date).tz('Asia/Kolkata').startOf('day');

    // If day is sunday, skip that day
    if (day.day() === 0) {
      continue;
    }
    demoDates.push({
      id: `demoId_${i}`,
      name: day.format('ddd'),
      value: day.format('MMM DD'),
      fullDate: day.toDate(),
    });
  }
  return demoDates;
};

export { getDemoClassDatesByCount };
