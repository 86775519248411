import { useEffect, useState } from 'react';

import { MobileVerify, OtpVerify, SetPasscode } from 'components/screens';

import { IPasscodeResetSettings } from 'types';
import { PASSCODE_TYPES } from 'types/auth';

export default function PasscodeSetting({
  handleOtpValidationStatusChange,
  isMobileVerified,
}: IPasscodeResetSettings) {
  const [isMobileValidated, setIsMobileValidated] = useState<boolean>(false);
  const [isOtpValidated, setIsOtpValidated] = useState<boolean>(false);

  useEffect(() => {
    if (isOtpValidated) {
      handleOtpValidationStatusChange(true);
    }
  }, [isOtpValidated, handleOtpValidationStatusChange]);

  useEffect(() => {
    if (isMobileValidated) {
      isMobileVerified(true);
    }
  }, [isMobileValidated, isMobileVerified]);

  return (
    <div className='flex-1 h-full'>
      {!isMobileValidated && (
        <MobileVerify
          isMobileValidated={(isMobileValidated: boolean) =>
            setIsMobileValidated(isMobileValidated)
          }
        />
      )}
      {isMobileValidated && !isOtpValidated && (
        <OtpVerify
          isOtpValidated={(isOtpValidated: boolean) => setIsOtpValidated(isOtpValidated)}
        />
      )}
      {isMobileValidated && isOtpValidated && <SetPasscode type={PASSCODE_TYPES.FORGET_PASSCODE} />}
    </div>
  );
}
