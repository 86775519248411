import apiSlice from 'store/apiSlices';
import { IClassConfig, IUpdateSession } from 'types/inClass/classConfig';

const classConfigApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClassConfig: builder.query<IClassConfig, { classId: string }>({
      query: ({ classId }) => ({
        url: `/v1/in-class/class-content/${classId}`,
      }),
    }),
    updateSession: builder.mutation<void, { body: IUpdateSession }>({
      query: ({ body }) => ({
        url: `v2/classes/sessions/`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetClassConfigQuery, useUpdateSessionMutation } = classConfigApiSlice;
