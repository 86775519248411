import { Card, OnboardingNav } from 'components/common';
import { TermsData } from 'configs/termsAndCondition';

const TermsAndCondition = () => {
  return (
    <div className='flex flex-col min-h-screen bg-neutral-25'>
      <OnboardingNav />
      <div className='flex-1 mx-2 my-10 bg-white md:container md:mx-auto rounded-xl'>
        <Card className='rounded-xl'>
          <div className='rounded-b-[50%] bg-primary-500 '>
            <h1 className='py-10 text-center text-white display-bold lg:text-2xl xl:text-4xl'>
              Terms and Conditions
            </h1>
          </div>
          <div className='p-5 md:p-10'>
            {TermsData?.map((item, index) => (
              <div key={`term-${index}`} className='py-3'>
                <h2 className='font-bold leading-8 text-justify text-primary-850 font-sora lg:text-xl'>
                  {item.title}
                </h2>
                <div className='pt-2 text-neutral-500 body-regular'>
                  {item.content.split('\n').map((content, contentIndex) => (
                    <p
                      className='py-1 leading-4 md:text-justify lg:text-base'
                      key={`line-${contentIndex}`}
                    >
                      {content}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TermsAndCondition;
