import { MobileNumber, OtpVerification } from 'components/screens';

import { useAppSelector } from 'hooks/store';
import { useEffect } from 'react';
import { IMobileStepsVerificationProps } from 'types/auth';

const MobileVerification = ({ isMobileStepsValidated }: IMobileStepsVerificationProps) => {
  const mobileVerifiedState = useAppSelector(
    (state) => state.studentRegister?.requestOTP?.mobileNumberVerifiedState,
  );

  const otpVerifiedState = useAppSelector(
    (state) => state.studentRegister?.verifyOTP?.otpVerifiedState,
  );

  useEffect(() => {
    if (otpVerifiedState) {
      isMobileStepsValidated(true);
    }
  }, [otpVerifiedState, isMobileStepsValidated]);

  return <>{mobileVerifiedState ? <OtpVerification /> : <MobileNumber />}</>;
};

export default MobileVerification;
