import { HuddleTransitionImg } from 'assets/svg';

const HuddleTransition = () => {
  return (
    <div>
      <img src={HuddleTransitionImg} alt='transition' />
    </div>
  );
};

export default HuddleTransition;
