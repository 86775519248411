import classNames from 'classnames';
interface ICardProps {
  className?: string;
  children: React.ReactNode;
  defaultColorCode?: string;
}

const Card = (props: ICardProps) => {
  const { className, children, defaultColorCode } = props;
  return (
    <>
      <div className={classNames(`${defaultColorCode ? defaultColorCode : ''}`, className)}>
        {children}
      </div>
    </>
  );
};

export default Card;
