import { Badge } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';

import {
  useGetAccountHolderQuery,
  useLoginWithTempTokenMutation,
} from 'store/apiSlices/login.apiSlice';
import {
  selectDemoClassBookedData,
  selectPasscodeResponseToken,
  setUserType,
} from 'store/slice/studentRegister.slice';

import { CheckVerified, CompassLight } from 'assets/svg';
import { Button, Card } from 'components/common';
import { USER_TYPE } from 'configs/userData';
import { useAppDispatch, useAppSelector } from 'hooks/store';

import { PARENT_PATHS, STUDENT_PATHS, SUBJECTS } from 'configs';
import { registrationSuccessTimeFormat } from 'utils';

export default function RegisterSuccessfully({ autoSetPasscode }: { autoSetPasscode: string }) {
  const navigate = useNavigate();
  const token = useAppSelector(selectPasscodeResponseToken);
  const dispatch = useAppDispatch();
  const demoClassBookedData = useAppSelector(selectDemoClassBookedData);

  const { data: profileData } = useGetAccountHolderQuery(token || '', { skip: !token });
  const fullName =
    profileData &&
    Array.isArray(profileData?.data) &&
    `${profileData?.data[0]?.members?.firstName}`;

  // Login user with tempToken
  const [loginWithTempToken] = useLoginWithTempTokenMutation();

  const handleMoveToDashboard = async () => {
    const id = profileData && Array.isArray(profileData?.data) && profileData?.data[0]?.members?.id;
    const userType =
      profileData && Array.isArray(profileData?.data) && profileData?.data[0]?.members?.userType;
    const userId = { tempToken: token || '', memberId: { memberId: id } };
    if (userId && id) {
      await loginWithTempToken(userId)
        .unwrap()
        .then(() => {
          dispatch(setUserType(userType || USER_TYPE.STUDENT));
          if (userType === USER_TYPE.STUDENT) {
            navigate(STUDENT_PATHS.STUDENT_DASHBOARD, { replace: true });
          } else if (userType === USER_TYPE.GUARDIAN) {
            navigate(PARENT_PATHS.PARENT_DASHBOARD.replace(':studentId', id.toString()), {
              replace: true,
            });
          }
        });
    }
  };

  return (
    <div className='flex flex-col items-center justify-between'>
      <div className='mb-5'>
        <div className='flex flex-col items-center justify-center'>
          <img src={CheckVerified} alt='check-verified' className='object-contain w-12 lg:w-16' />
          {profileData && Array.isArray(profileData.data) && profileData.data.length > 0 && (
            <h1 className='mt-3 text-lg text-center text-primary-850 font-sora display-regular text-wrap lg:text-lg xl:text-2xl 2xl:text-3xl'>
              {profileData?.data && profileData?.data[0]?.members?.firstName}'s demo class is
              booked!
            </h1>
          )}
          {demoClassBookedData && (
            <h6 className='mt-4 text-xs font-normal leading-5 text-center w-72 md:w-full text-wrap text-neutral-400 font-poppins md:text-sm lg:text-lg md:mt-1'>
              The demo class is on{' '}
              <span className='text-lg body-semibold text-primary-500'>
                {registrationSuccessTimeFormat(demoClassBookedData)}
              </span>
            </h6>
          )}
          <div className='flex items-center gap-2 my-5'>
            <h4 className='text-base font-normal leading-5 display-bold lg:text-lg xl:text-xl text-neutral-800'>
              Subject :{' '}
            </h4>
            <Badge className='p-1 rounded-lg bg-primary-100'>
              <span className='text-sm cap-semibold flex items-center gap-2 pr-4 tracking-widest text-primary-400 md:text-base lg:text-base leading-[19.3px]'>
                <img src={CompassLight} alt='compass' className='p-1 rounded-md bg-primary-500' />
                {demoClassBookedData?.subjectName || SUBJECTS.MATHS}
              </span>
            </Badge>
          </div>

          {/* //TODO: Tutor details auto assigned  */}

          {/* <span className='w-full md:w-[80%] border-1 my-3 border-[#CCCCCC]'>
          <div className='h-0.5 bg-[#CCCCCC]'></div>
        </span>

        <h3 className='text-[#37383E] font-normal text-sm md:text-base leading-5'>
          Tutor Details (Auto-assigned)
        </h3>
        <Card className='my-3 bg-white border-2 border-[#EAECF0] shadow-xl sm:w-[80%]'>
          <div className='flex items-center p-1'>
            <img src={Tutor} alt='tutor' className='object-contain w-12' />
            <div className='flex flex-col mx-3 text-start'>
              <h1 className='text-[#101828] leading-5 font-bold text-sm'>Mrs. Shweta Gupta</h1>
              <p className='text-[#37383E] md:leading-5 font-semibold text-xs'>
                Mathematics, Science, Social Science & Economics
              </p>
            </div>
          </div>
        </Card> */}
        </div>

        {/* Auto set passocde  */}
        <div className='flex flex-col items-center gap-y-2'>
          <div className='flex w-full justify-center items-center gap-x-5'>
            <p className='font-sora font-medium text-xl sm:text-[32px] tracking-tight text-center text-primary-700 '>
              Auto-set Passcode
            </p>
            <p className='font-sora font-medium text-3xl sm:text-5xl tracking-tight text-center'>
              {`${autoSetPasscode?.[0]}-${autoSetPasscode?.[1]}-${autoSetPasscode?.[2]}-${autoSetPasscode?.[3]}`}
            </p>
          </div>
          <div className='flex w-[20rem] justify-center text-center text-xs sm:text-sm text-neutral-500 mb-[10px] font-sora tracking-tight'>
            Your Passcode is set and sent to your Whatsapp! Share with your family member for join.
          </div>
        </div>

        {/* Importance Notes */}
        <Card className='mb-6 shadow-xl bg-neutral-800 rounded-2xl'>
          <>
            <div className='p-4'>
              <h1 className='text-base font-bold text-center text-complementary-colors-100 font-sora'>
                Important Notes
              </h1>
              <div>
                <ul className='my-3'>
                  <li className='pb-6 font-normal text-white text-start list-disc leading-[16.8px] list-inside body-regular lg:text-sm'>
                    {`Class can only be accessed by ${fullName} using a laptop or a desktop with camera.`}
                  </li>
                  <li className='font-normal text-white list-disc list-inside body-regular lg:text-sm'>
                    {`Please ensure ${fullName} camera is always on during class hours.`}
                  </li>
                </ul>
              </div>
            </div>
          </>
        </Card>

        <div className='flex mt-4 md:gap-4 md:mt-5'>
          <Button
            onClick={handleMoveToDashboard}
            className={`w-full enabled:hover:bg-primary-500 font-semibold whitespace-nowrap text-base rounded-xl text-white bg-primary-500`}
            text='View Dashboard'
          />
        </div>
      </div>
    </div>
  );
}
