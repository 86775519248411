import React from 'react';
import OtpInput from 'react-otp-input';

interface OTPInputProps {
  handleChange: (value: string) => void;
  isValid: boolean | null;
  value: string;
  fields: number;
}

const OTPInput = ({ handleChange, isValid, value, fields }: OTPInputProps) => {
  const acceptedOnlyNumbers = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <div className='mb-3'>
      <OtpInput
        onChange={handleChange}
        placeholder='----'
        containerStyle={{
          display: 'flex',
          gap: '10px',
        }}
        inputStyle={{
          width: '65px',
          height: '60px',
          textAlign: 'center',
          border: '1px solid #D0D5DC',
          borderRadius: '10px',
          backgroundColor: 'white',
          color: '#282E38',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          fontFamily: 'sora',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          outline: 'none',
          borderColor: isValid === true ? '#7ADC9E' : isValid === false ? '#F58F8F' : '#D0D5DC',
        }}
        renderInput={(props, i: number) => (
          <input key={i} maxLength={1} onKeyPress={acceptedOnlyNumbers} {...props} />
        )}
        value={value}
        numInputs={fields}
      />
    </div>
  );
};

export default OTPInput;
