import { useDyteMeeting } from '@dytesdk/react-web-core';
import moment from 'moment-timezone';
import { useState } from 'react';

import { CloseWhite, Send } from 'assets/svg';
import { Modal } from 'components/common';
import { IInClassChatProps } from 'types';
import './style.css';
import useMeeting from 'hooks/inClass/useMeeting';
import { getClassNames } from 'utils';
import { useWindowDimensions } from 'hooks';
import { useAppSelector } from 'hooks/store';
import { selectHtmlLink } from 'store/slice/content.slice';

const InClassChat = ({
  setChatToggle,
  chatToggle,
  isWrittenEvalOpen,
  isPollEvalOpen,
  isOralEvalOpen,
}: IInClassChatProps) => {
  // State variables
  const [message, setMessage] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { studentsWithOutSelf, tutors, tutorId, screenShareEnabled, studentsCount, activePlugins } =
    useMeeting();

  const { screenResolution } = useWindowDimensions();

  // Accessing meeting information using the provided hook
  const { meeting } = useDyteMeeting();

  const getHtmlLink = useAppSelector(selectHtmlLink);

  // Setting the tutor id and messages
  const messages = (meeting && meeting.chat?.messages) || [];

  const { chatClass } = getClassNames({
    tutorsLength: tutors.length,
    chatToggle: chatToggle,
    screenResolution: screenResolution as 'HD' | 'FHD',
    studentsWithoutSelfLength: studentsWithOutSelf.length,
    studentsCount: studentsCount,
    activePluginsLength: activePlugins.length,
    screenShareEnabled: screenShareEnabled,
    getHtmlLink: getHtmlLink,
    isWrittenEvalOpen: isWrittenEvalOpen,
    isPollEvalOpen: isPollEvalOpen,
    isOralEvalOpen: isOralEvalOpen,
  });

  // Function to send a message
  const sendMessage = async () => {
    if (message && meeting?.chat) {
      try {
        await meeting.chat.sendMessage({ type: 'text', message: message }, [tutorId]);
        setMessage('');
      } catch (error) {
        console.error('Failed to send message:', error);
      }
    }
  };

  // Function to handle image click and display it in a modal
  const handleImage = (image: string) => {
    setImage(image);
    setOpenModal(true);
  };

  const toggleChat = () => setChatToggle(false);
  const handleSendMessage = () => sendMessage();

  return (
    <div>
      <div className='border border-gray-600 bg-primary-850 rounded-xl'>
        <div className='flex justify-between px-4 py-2 border-b border-[#193b68]'>
          <p className='text-lg text-white'>Chat</p>
          <button onClick={toggleChat}>
            <img src={CloseWhite} alt='close' />
          </button>
        </div>
        <div className={chatClass}>
          {/* Rendering each message */}
          {messages.length ? (
            <>
              {messages.map((item) => {
                const isCurrentUser = meeting.self.userId === item.userId;
                return (
                  <div key={item.id}>
                    {item.type === 'text' && (
                      <div>
                        <div
                          className={`relative w-[70%] p-2 my-7 text-white bg-gray-900 rounded-b-md rounded- ${
                            isCurrentUser ? 'rounded-tl-md' : 'rounded-tr-md'
                          }  ${isCurrentUser ? 'float-right' : 'float-left'} `}
                        >
                          <div
                            className={`absolute text-xs ${
                              isCurrentUser ? 'right-0' : 'left-0'
                            } -top-5`}
                          >
                            {item.displayName}
                          </div>
                          {item.message}
                          <div className='absolute right-0 text-xs -bottom-5'>
                            {moment(item.time).tz('Asia/Kolkata').format('LT')}
                          </div>
                        </div>
                      </div>
                    )}
                    {item.type === 'image' && (
                      <div
                        className={`relative w-[70%] p-2 my-7 text-white bg-gray-900 rounded-b-md rounded- ${
                          isCurrentUser ? 'rounded-tl-md' : 'rounded-tr-md'
                        }  ${isCurrentUser ? 'floatRight' : 'floatLeft'} `}
                      >
                        <div
                          className={`absolute text-xs ${
                            isCurrentUser ? 'right-0' : 'left-0'
                          } -top-5`}
                        >
                          {item.displayName}
                        </div>
                        <img src={item.link} onClick={() => handleImage(item.link || '')} />
                        <div className='absolute right-0 text-xs -bottom-5'>
                          {moment(item.time).tz('Asia/Kolkata').format('LT')}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          ) : (
            <div className='flex flex-col items-center justify-start gap-3 mt-[30%] text-white h-fit'>
              <div className='flex flex-col flex-wrap items-center justify-center text-center text-primary-200'>
                <p>Hello there!😊</p>
                <p>Feel free to ask any questions about today's lesson.</p>
              </div>
              <div className='flex flex-col items-center justify-center text-center'>
                This chat will be only for you and your tutor.
              </div>
            </div>
          )}
        </div>
        <div className='flex p-4 pt-3 border-t border-[#193b68] bg-primary-800 rounded-b-xl'>
          <input
            type='text'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
            className='w-full text-base text-white bg-transparent focus:outline-none'
            placeholder='Write message here'
          />
          <button onClick={handleSendMessage} className='text-white '>
            <img src={Send} alt='send' />
          </button>
        </div>
      </div>

      {/* Modal to display the clicked image */}
      <Modal
        showCloseBtn={true}
        heading='Notebook Capture'
        openModal={openModal}
        setOpenModal={setOpenModal}
      >
        <div className='flex items-center justify-center w-full h-full'>
          <img src={image} onError={(e) => (e.currentTarget.src = 'fallback-image-url')} />
        </div>
      </Modal>
    </div>
  );
};

export default InClassChat;
