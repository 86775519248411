import { datadogLogs } from '@datadog/browser-logs';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';

import App from './App.tsx';
import './index.css';
import packageJson from '../package.json';

function getServiceNameFromEnv() {
  switch (import.meta.env.VITE_BASE_DOMAIN_ENV) {
    case 'development':
      return 'dev-frontend';
    case 'staging':
      return 'stage-frontend';
    case 'production':
      return 'frontend';
    default:
      return 'dev-frontend';
  }
}

datadogLogs.init({
  clientToken: import.meta.env.VITE_BASE_DATADOG_CLIENT_TOKEN,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: getServiceNameFromEnv(),
  env: import.meta.env.VITE_BASE_DOMAIN_ENV,
  version: packageJson.version,
});

if (import.meta.env.VITE_BASE_DOMAIN_ENV === 'production') {
  datadogRum.init({
    applicationId: import.meta.env.VITE_BASE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_BASE_DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: getServiceNameFromEnv(),
    env: import.meta.env.VITE_BASE_DOMAIN_ENV,
    version: packageJson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
