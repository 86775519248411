export default function removeKeys(input, keysToRemove) {
  if (Array.isArray(input)) {
    return input.map((item) => removeKeys(item, keysToRemove));
  } else if (typeof input === 'object' && input !== null) {
    for (const key in input) {
      if (keysToRemove.includes(key)) {
        delete input[key];
      } else {
        input[key] = removeKeys(input[key], keysToRemove);
      }
    }
  }
  return input;
}
