import { BouncingDotsLoader, Modal, ModalBottomSheet } from 'components/common';
import { Badge } from 'flowbite-react';
import { useMemo, useState } from 'react';
import { useGetAnnotatedImageOfStudentQuery } from 'store/apiSlices/parentDashboard.apiSlice';
import { ICurrentStatusInfoInCoachLabel } from 'types/dashboard/parentDashboard';

import './currentStatusInfo.css';
import { flatten } from 'lodash';
import { useWindowDimensions } from 'hooks';

const ShowCurrentStatusInfo = ({
  studentId = '',
  label,
  openModal,
  teachEvaluationId,
  setOpenModal,
  classId,
}: ICurrentStatusInfoInCoachLabel) => {
  const { isMobileScreen } = useWindowDimensions();
  const [zoomedImage, setZoomedImage] = useState<string>('');

  const evaluationIds = useMemo(
    () => label?.map((evaluation) => evaluation?.evaluationId) || [],
    [label],
  );

  const { data: annotatedImagesData, isLoading } = useGetAnnotatedImageOfStudentQuery(
    { studentId, classId },
    { skip: !(classId && studentId) },
  );

  const images = teachEvaluationId
    ? flatten(
        annotatedImagesData?.data
          ?.filter((item) => teachEvaluationId === item?.evaluationId)
          ?.map((item) => item?.images) ?? [],
      )
    : flatten(
        annotatedImagesData?.data
          ?.filter((item) => evaluationIds?.includes(item?.evaluationId))
          ?.map((item) => item?.images) ?? [],
      );

  const handleImageClick = (imageSrc?: string) => {
    setZoomedImage(imageSrc || '');
  };

  const closeZoom = () => {
    setZoomedImage('');
  };

  const RenderAnnotatedImages = () => {
    return (
      <>
        {/* Header */}
        <div className='flex items-center justify-between'>
          <div className='flex items-center text-xs font-semibold font-poppins'>
            <p className='px-3 py-1 bg-white text-neutral-500'>Submitted:</p>
            <Badge className='text-white bg-semantic-green-500'>{images?.length}</Badge>
          </div>
        </div>

        {isLoading ? (
          <div className='flex flex-col items-center justify-center w-full h-[40vh]'>
            <BouncingDotsLoader />
          </div>
        ) : (
          <div className='py-3 overflow-x-auto'>
            <div className='flex flex-wrap justify-center min-w-max gap-x-2 gap-y-4'>
              {images?.length > 0 ? (
                images.map((image) => (
                  <div
                    key={image?._id}
                    className='w-[15rem]'
                    onClick={() => handleImageClick(image?.annotated)}
                    role='button'
                    aria-label='View larger image'
                  >
                    <img
                      src={image?.annotated}
                      alt='Info'
                      className='object-cover w-full h-full rounded-xl'
                    />
                  </div>
                ))
              ) : (
                <div className='text-xs h-[40vh] flex items-center justify-center font-semibold tracking-tight font-poppins'>
                  No Image is Captured
                </div>
              )}
            </div>
          </div>
        )}

        {/* Zoomed Image Overlay */}
        {zoomedImage && (
          <div
            className='fixed inset-0 z-50 flex items-center justify-center p-4 transition-opacity duration-300 ease-out bg-black bg-opacity-75'
            style={{ animation: 'fadeIn 0.5s ease-out' }}
          >
            <button
              onClick={closeZoom}
              className='absolute text-2xl text-white cursor-pointer top-4 right-4'
            >
              &times;
            </button>
            <img
              src={zoomedImage}
              className='object-contain max-w-full max-h-full transition-transform duration-300 ease-out rounded-3xl'
              style={{ transform: 'scale(0)', animation: 'scaleIn 0.3s ease-out forwards' }}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {isMobileScreen ? (
        <ModalBottomSheet
          children={RenderAnnotatedImages()}
          showCloseBtn={true}
          heading='Checked Workbook Evaluations'
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      ) : (
        <Modal
          children={RenderAnnotatedImages()}
          showCloseBtn={true}
          heading='Checked Workbook Evaluations'
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      )}
    </>
  );
};

export default ShowCurrentStatusInfo;
