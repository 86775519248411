export const LOGIN_OTP_COUNTDOWN = 30000;

type x = {
  OTP: 'otp';
  PASSCODE: 'passcode';
};

export const LOGIN_TYPES: x = {
  OTP: 'otp',
  PASSCODE: 'passcode',
};
