import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface INetworkState {
  counter: number;
  classId?: number;
  networkLow?: boolean;
}

const initialState: INetworkState = {
  counter: 0,
  networkLow: false,
};

export const networkIssueCounter = createSlice({
  name: 'networkIssueCounter',
  initialState,
  reducers: {
    setNetworkCounter: (state, action: PayloadAction<Partial<INetworkState>>) => {
      const { classId, networkLow } = action.payload;
      if (classId !== state.classId) {
        state.counter = 0;
        state.classId = classId;
        state.networkLow = false;
      } else if (networkLow) {
        state.counter += 1;
      }
      if (networkLow !== undefined) state.networkLow = networkLow;
    },
  },
  selectors: {
    selectNetworkLowCounter: (state) => state?.counter,
    selectNetworkClassId: (state) => state?.classId,
    selectNetworkLow: (state) => state?.networkLow,
  },
});

export const { setNetworkCounter } = networkIssueCounter.actions;

export const { selectNetworkLowCounter, selectNetworkClassId, selectNetworkLow } =
  networkIssueCounter.selectors;
