import { CONFIG } from 'configs';
import { Accumulator, currentStates } from 'types/utils/inClass/inClassFunctions';

declare global {
  interface HTMLScriptElement {
    readyState?: 'loaded' | 'complete';
    onreadystatechange?: () => void | undefined;
  }
}

export const randomPictureName = () => {
  const d = new Date();
  const time = d.getTime();
  return `${time}.jpeg`;
};

export const dataURLtoFile = (dataurl: string, filename: string): File => {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]),
    n = bstr.length;
  const u8arr = new Uint8Array(n);

  for (let i = n; i--; ) {
    u8arr[i] = bstr.charCodeAt(i);
  }

  return new File([u8arr], filename, { type: mime });
};

async function getCurrentState(classId: string) {
  try {
    const response = await fetch(
      `${CONFIG.API_URL}/in-class/engagement/current-state?classId=${classId}`,
      {
        credentials: 'include',
      },
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    throw error;
  }
}

export const getStatesOfAllStudents = (currentStates: currentStates): Accumulator => {
  const result = currentStates.reduce<Accumulator>(
    (acc, student) => {
      if (!student.currentState) {
        acc.teach.push(student.studentId.toString());
        return acc;
      }
      acc[student.currentState]?.push(student.studentId.toString());
      return acc;
    },
    {
      coach_basic: [],
      coach_intermediate: [],
      coach_advanced: [],
      teach: [],
    },
  );
  return result;
};

export default getCurrentState;
