import { Badge } from 'flowbite-react';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useGetExamScheduleQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { CompassLight, ExamPen, MathsBook, SectionHeadingLogo, SubjectIcon } from 'assets/svg';
import { Avatar, Card, Loader } from 'components/common';
import { BookDetails, ILearningMaterial } from 'types/democlass';

import { DUMMY_BOOK_INFORMATION, RomanNumber, SUBJECTS } from 'configs';

export default function LearningMaterials({ learningMaterial }: ILearningMaterial) {
  const [Books, setBooks] = useState<BookDetails[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /* Here i am taking schoolDetails from studentDetails coz this data is coming from getClassById api*/
  const schoolDetails =
    learningMaterial?.schoolDetails || learningMaterial?.studentDetails?.schoolDetails;

  /* API -> get the exam schedules */
  const { data: examScheduleData } = useGetExamScheduleQuery(
    {
      schoolId: schoolDetails?.id ?? 0,
      gradeId: learningMaterial?.studentDetails?.gradeSanityId ?? '',
      boardId: learningMaterial?.boardSanityId ?? '',
    },
    {
      skip: !learningMaterial,
    },
  );

  useEffect(() => {
    if (learningMaterial) {
      setIsLoading(true);
      setBooks(learningMaterial?.bookDetails || DUMMY_BOOK_INFORMATION);
      setIsLoading(false);
    }
  }, [learningMaterial]);

  const grade = RomanNumber.find(
    (r) => r.class === learningMaterial?.studentDetails?.gradeName,
  )?.romanValue;
  return (
    <div className='flex flex-col gap-4 mb-10'>
      {/* Learning Material Heading along with subject selection */}
      <div className='justify-between px-2 pt-5 lg:py-10 md:flex md:px-0 md:pr-2'>
        <h1 className='items-center text-xl font-bold tracking-wider uppercase md:flex font-sora text-start md:text-lg lg:text-2xl xl:leading-6'>
          <img src={SectionHeadingLogo} alt='uprio section logo' className='my-4 mr-5 md:my-0' />
          School Exam Schedule & Books Followed
        </h1>
      </div>
      {/* Exam Schedule */}
      <div className='flex justify-start w-full gap-2 overflow-x-auto md:max-w-screen flex-nowrap'>
        {examScheduleData?.data?.examSchedule && examScheduleData.data.examSchedule.length > 0 ? (
          examScheduleData.data.examSchedule.map((exam) => (
            <div key={exam?._id} className='min-w-[17rem] lg:w-full'>
              <Card className='bg-white p-4 rounded-[20px]'>
                <div className='flex flex-col justify-between gap-4'>
                  <div className='flex items-center gap-3 text-xs capitalize text-neutral-900 lg:text-base body-semibold'>
                    <span className='p-2 rounded-[5.6px] bg-complementary-colors-25'>
                      <img src={ExamPen} alt='Exam Icon' />
                    </span>
                    {exam?.examName}
                  </div>
                  <div className='flex items-center justify-between text-sm leading-3 text-neutral-500 body-medium'>
                    <span>{moment(exam?.examDate).format('DD MMM YYYY')}</span>
                    <Badge
                      className={`rounded-lg flex py-[7px] px-[8px]  ${moment(exam?.examDate).isAfter(new Date()) ? 'text-white bg-semantic-green-500' : 'text-white bg-neutral-300'}`}
                    >
                      <span className='capitalize body-semibold lg:text-xs'>
                        {moment(exam?.examDate).isAfter(new Date()) ? 'Expected' : 'Exam Over'}
                      </span>
                    </Badge>
                  </div>
                </div>
              </Card>
            </div>
          ))
        ) : (
          <p className='text-center text-neutral-800'>No exams scheduled</p>
        )}
      </div>

      <div className='grid grid-rows-1'>
        <div className='grid flex-wrap grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-x-2'>
          {/* School section */}
          <div className='bg-neutral-800 rounded-[20px] flex flex-row justify-between items-center sm:items-stretch  lg:flex-col lg:h-[12rem]'>
            {/* Profile Image Section */}
            <div className='flex items-center flex-1 pl-4 sm:flex-none gap-x-2 lg:p-4 '>
              <Avatar
                image={learningMaterial?.studentDetails?.profileImage}
                firstName={learningMaterial?.studentDetails?.firstName.charAt(0)}
                lastName={learningMaterial?.studentDetails?.lastName.charAt(0)}
                className='object-cover aspect-square w-10 h-10 border-2 rounded-full lg:w-16 lg:h-16 md:w-20 md:h-20 border-complementary-colors-25'
                initialsClassName='flex flex-col uppercase items-center justify-center object-contain w-12 h-12 text-lg border-2 rounded-full bg-primary-500 md:w-14 md:h-14 border-complementary-colors-25'
              />
              {/* Profile section with name along with grade and location */}
              <div className='flex-1'>
                <h2 className='text-base text-white body-semibold'>
                  {learningMaterial?.studentDetails?.firstName +
                    ' ' +
                    learningMaterial?.studentDetails?.lastName || ''}
                </h2>
                <span className='flex items-center gap-2 my-1 md:my-0'>
                  <p className='text-neutral-300 body-medium text-[10px] sm:text-xs'>
                    {`Class ${grade}`}
                  </p>
                  <p className='w-10 truncate text-neutral-300 body-medium lg:text-xs '>
                    {schoolDetails?.name || ''}
                  </p>
                </span>
              </div>
            </div>
            {/* School Details */}
            <div className='relative px-4 my-3'>
              <img
                src={schoolDetails?.headerImage || ''}
                alt='image'
                className='h-20 rounded-lg w-36 lg:w-full lg:h-20 object-fit'
              />
              <div className='absolute inset-0 flex flex-col items-center justify-center text-center text-white'>
                <h2 className='text-white body-semibold text-[8px] sm:text-xs text-wrap w-24 md:w-36'>
                  {schoolDetails?.name || ''}
                </h2>
              </div>
            </div>
          </div>

          {/* Learning Material Section */}
          <div className='gap-4 bg-white lg:h-[12rem] md:col-span-2 rounded-[20px] lg:col-span-4 my-3 lg:my-0'>
            <h6 className='p-4 pb-0 text-base tracking-widest cap-semibold lg:text-sm text-neutral-500'>
              SCHOOL BOOK INFORMATION
            </h6>
            {/* List of Books */}
            <div className='p-4 pt-2'>
              {Books?.length === 0 && (
                <div className='flex flex-col items-center justify-center py-5 lg:py-10'>
                  <p className='body-medium text-neutral-500'>No books found we will update soon</p>
                </div>
              )}
              {isLoading ? (
                <div className='flex flex-col items-center justify-center py-5 lg:py-10'>
                  <Loader size={'lg'} color={'gray'} />
                </div>
              ) : (
                <>
                  {Books?.length > 0 && (
                    <div className='flex flex-wrap gap-2 overflow-x-auto md:max-w-screen md:flex-nowrap xl:gap-4'>
                      {/* List of Books */}
                      {Books?.map((book) => {
                        return (
                          <Card
                            key={book?.id}
                            className='bg-neutral-50 w-full sm:w-[20rem] h-32 flex items-center rounded-lg'
                          >
                            <img
                              src={book?.coverImage || MathsBook}
                              alt='book'
                              className='w-24 h-32 p-2 object-fit rounded-xl'
                            />
                            <div className='flex flex-col justify-between p-2'>
                              <div>
                                <Badge className='flex items-center p-1 rounded-lg w-28 bg-primary-150'>
                                  <span className='text-sm cap-semibold flex items-center gap-2 tracking-widest text-complementary-colors-50 md:text-base lg:text-xs font-semibold leading-[19.3px]'>
                                    <img
                                      src={
                                        book?.subjectName === SUBJECTS.MATHS
                                          ? CompassLight
                                          : book?.subjectName === SUBJECTS.SCIENCE
                                            ? SubjectIcon
                                            : CompassLight
                                      }
                                      alt='compass'
                                      className='p-[2px] rounded-md bg-primary-500'
                                    />
                                    {book?.subjectName}
                                  </span>
                                </Badge>
                              </div>

                              <p className='mt-1 text-base lg:text-base text-wrap body-semibold'>
                                {_.startCase(book?.name)}
                              </p>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
