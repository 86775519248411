import { FaRegHandPointRight } from 'react-icons/fa6';

import { useGetUserPasscodeQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import Cookie from 'js-cookie';
import { DOMAIN_URL, CONFIG } from 'configs';

function Instructions() {
  const { data: profileData } = useGetUserDataQuery(undefined, { refetchOnMountOrArgChange: true });

  //const token = localStorage.getItem('loginToken');
  const token = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);
  const { data: passcodeData } = useGetUserPasscodeQuery(undefined, { skip: !token });

  const webUrl = DOMAIN_URL;

  return (
    <div className='flex flex-col justify-start align-center font-poppins body-regular mb-8'>
      <div>
        <h1 className='font-sora cap-semibold text-center'>Instructions to be followed</h1>
        <br />
        <div className='flex gap-5'>
          <span>{<FaRegHandPointRight style={{ marginTop: '10px' }} />}</span>{' '}
          <p className='py-2'>
            {' '}
            On your mobile, open any browser and go to{' '}
            <strong>
              <em>{webUrl.substring(8)}</em>
            </strong>
          </p>
        </div>
        <div className='flex gap-5'>
          <span>{<FaRegHandPointRight style={{ marginTop: '10px' }} />}</span>{' '}
          <p className='py-2'>
            {' '}
            Login: with,{' '}
            <ul style={{ listStyleType: 'square' }} className='ml-8'>
              <li>
                <strong>Mobile: {profileData?.data?.mobileNumber}</strong> ,{' '}
              </li>
              <li>
                <strong>Passcode: {passcodeData?.data?.passcode}</strong>
              </li>
            </ul>
          </p>
        </div>
        <div className='flex gap-5'>
          <span>{<FaRegHandPointRight style={{ marginTop: '10px' }} />}</span>{' '}
          <p className='py-2'>
            {' '}
            Select <strong>Student account</strong> from the list
          </p>
        </div>
        <div className='flex gap-5'>
          {' '}
          <span>{<FaRegHandPointRight style={{ marginTop: '10px' }} />}</span>{' '}
          <p className='py-2'>
            Go to <strong>Practice</strong> tab and submit your answers
          </p>
        </div>
      </div>
    </div>
  );
}

export default Instructions;
