import { useCallback, useEffect, useMemo, useState } from 'react';

import { Modal, ModalBottomSheet, OnboardingNav, Stepper } from 'components/common/index';
import { reset } from 'store/slice/studentRegister.slice';

import {
  DemoClass,
  HeroSection,
  MobileVerification,
  RegisterSuccessfully,
  StudentDetails,
} from 'components/screens';
import { useAppDispatch, useAppSelector } from 'hooks/store';

import { STEPPER_DATA, STEPPER_SWITCH } from 'configs';
import { useLocalStorage, useWindowDimensions } from 'hooks';

export default function Register() {
  const [autoSetPasscode, setAutoSetPasscode] = useState<string>('');
  const dispatch = useAppDispatch();
  const { isMobileScreen } = useWindowDimensions();
  const { removeFromLocalStorage } = useLocalStorage();
  const mobileVerifiedState = useAppSelector(
    (state) => state.studentRegister?.requestOTP?.mobileNumberVerifiedState,
  );

  const studentRegistrationState = useAppSelector(
    (state) => state.studentRegister?.registrationResponse?.studentRegistrationState,
  );

  const demoClassState = useAppSelector(
    (state) => state.studentRegister?.demoClassResponse?.demoClassState,
  );

  const passcodeState = useAppSelector(
    (state) => state.studentRegister?.passcodeResponse?.passcodeState,
  );

  const [isMobileStepsValidated, setIsMobileStepsValidated] = useState<boolean>(false);

  const [stepperCount, setStepperCount] = useState<number>(STEPPER_SWITCH.PERSONAL_DETAILS);
  const [initialRequestOTP, setInitialRequestOTP] = useState<boolean>(false);

  const [showDemoClassModal, setShowDemoClassModal] = useState<boolean>(false);

  /* initially clearing the local storage and redux store as well */
  useEffect(() => {
    const isFirstVisit = localStorage.getItem('isFirstVisit');

    if (!isFirstVisit) {
      dispatch(reset());
      localStorage.setItem('isFirstVisit', 'false');
    }
  }, [removeFromLocalStorage, dispatch]);

  useEffect(() => {
    // Logic to handle step transitions and conditionally show the success modal
    if (studentRegistrationState && demoClassState && passcodeState) {
      setShowDemoClassModal(true);
    } else if (studentRegistrationState && demoClassState) {
      setShowDemoClassModal(true);
    } else if (studentRegistrationState) {
      setStepperCount(STEPPER_SWITCH.DEMO_CLASS);
    } else {
      setStepperCount(STEPPER_SWITCH.PERSONAL_DETAILS);
    }
  }, [studentRegistrationState, demoClassState, passcodeState]);

  const handleIsMobileStepsValidated = useCallback((isMobileStepsValidated: boolean) => {
    if (isMobileStepsValidated) {
      setInitialRequestOTP(false);
      setIsMobileStepsValidated(true);
    }
  }, []);

  const stepperSwitch = useMemo(() => {
    switch (stepperCount) {
      case STEPPER_SWITCH.PERSONAL_DETAILS:
        return <StudentDetails setAutoSetPasscode={setAutoSetPasscode} />;
      case STEPPER_SWITCH.DEMO_CLASS:
        return <DemoClass />;
      default:
        return null;
    }
  }, [stepperCount]);

  return (
    <div className='flex flex-col flex-1 min-h-screen bg-white'>
      <OnboardingNav />
      <section className={`flex-1 h-full grid grid-cols-1 lg:grid-cols-2 bg-white`}>
        {(!mobileVerifiedState && isMobileScreen) || !isMobileScreen ? (
          <div className={`order-first lg:order-last block rounded-b-[3rem] lg:top-0`}>
            <HeroSection />
          </div>
        ) : null}

        <div className='relative flex justify-center flex-1 h-full p-1 bg-white'>
          <div className='w-full flex flex-col justify-start mx-2 md:w-[70%] lg:w-[90%]'>
            {initialRequestOTP && !isMobileStepsValidated && (
              <hr className='block w-full h-1 text-black md:hidden' />
            )}
            {isMobileStepsValidated ? (
              <>
                <Stepper activeStep={stepperCount} stepperData={STEPPER_DATA} />
                <div className='flex justify-start gap-2 py-8 md:items-center md:justify-center'>
                  <h1 className='px-3 text-xl lg:px-0 lg:text-2xl xl:text-3xl md:capitalize display-bold whitespace-nowrap text-neutral-850'>
                    {STEPPER_DATA[stepperCount]?.name}
                  </h1>
                </div>

                <div className='flex-1 h-full'>{stepperSwitch}</div>
                {showDemoClassModal ? (
                  isMobileScreen ? (
                    <>
                      <ModalBottomSheet
                        children={<RegisterSuccessfully autoSetPasscode={autoSetPasscode} />}
                        openModal={isMobileScreen ? showDemoClassModal : false}
                        setOpenModal={setShowDemoClassModal}
                        showCloseBtn={false}
                      />
                    </>
                  ) : (
                    <Modal
                      children={<RegisterSuccessfully autoSetPasscode={autoSetPasscode} />}
                      openModal={!isMobileScreen ? showDemoClassModal : false}
                      setOpenModal={setShowDemoClassModal}
                      showCloseBtn={false}
                    />
                  )
                ) : null}
              </>
            ) : (
              <>
                <MobileVerification isMobileStepsValidated={handleIsMobileStepsValidated} />
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
