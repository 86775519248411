import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import toast from 'react-hot-toast';
import { ErrorResponse } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useInitialForgetPasscodeMutation } from 'store/apiSlices/forgetPasscode.apiSlice';
import {
  useLazyPassCodeStatusQuery,
  useLoginOTPVerifyMutation,
  useVerifyPassCodeMutation,
} from 'store/apiSlices/login.apiSlice';

import { Button, Input, OtpInput } from 'components/common';

import { BackIconBlue, ExclamationLight, TickRoundedLight } from 'assets/svg';
import {
  APP_DEFAULT_MESSAGES,
  APP_REGEX,
  COMMON_ERROR,
  LOGIN_OTP_COUNTDOWN,
  LOGIN_TYPES,
  OTP_VERIFICATION_SUCCESS,
  ROUTES,
  SENT_VERIFICATION_TEXT,
} from 'configs';
import { useLocalStorage } from 'hooks';

interface IPasscodeOrOTP {
  buttonType: 'passcode' | 'otp';
}

export default function PasscodeOrOTP({ buttonType }: IPasscodeOrOTP) {
  const navigate = useNavigate();
  const location = useLocation();
  const passcodeInputRef = useRef<HTMLInputElement>(null);

  // Local Storage
  const { getFromLocalStorage } = useLocalStorage();
  const studentGuardiansId = getFromLocalStorage('studentGuardiansId');
  const mobileNumber = getFromLocalStorage('mobileNumber');

  // API calls
  const [trigger] = useLazyPassCodeStatusQuery();
  const [checkPassCode] = useVerifyPassCodeMutation();
  const [loginViaOtpService, { isSuccess, isLoading }] = useLoginOTPVerifyMutation();
  const [loginViaOtp] = useInitialForgetPasscodeMutation();

  // States
  const [otp, setOtp] = useState<string>('');
  const [isValidOtp, setIsValidOtp] = useState<boolean | null>(null);
  const [isCountdownFinished, setIsCountdownFinished] = useState<boolean>(false);

  //Refs
  const countdownEndTime = useRef(Date.now() + LOGIN_OTP_COUNTDOWN);

  useEffect(() => {
    if (studentGuardiansId) {
      trigger(studentGuardiansId);
    }
  }, [studentGuardiansId, trigger]);

  // Verify Login Passcode Step
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { passcode: '' },
    validationSchema: Yup.object({
      passcode: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.PASSCODE_REQUIRED),
    }),
    onSubmit: (values) => {
      checkPassCode({ studentGuardiansId: studentGuardiansId || '', passcode: values.passcode })
        .unwrap()
        .then(() => {
          navigate(ROUTES.ACCOUNTS, { state: { from: location.state?.from } });
        })
        .catch((error) => {
          toast.error((error as ErrorResponse)?.data?.message || COMMON_ERROR);
        });
    },
  });

  // Verify OTP (If Student/Guardian not set passcode during registration)
  const otpFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: '',
    },
    validationSchema: Yup.object({
      otp: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.OTP_REQUIRED),
    }),
    onSubmit: (values) => {
      loginViaOtpService({
        otp: values.otp,
        mobileNumber: mobileNumber || '',
      })
        .unwrap()
        .then(() => {
          setIsValidOtp(true);
          navigate(ROUTES.ACCOUNTS);
        })
        .catch((error) => {
          toast.error((error as ErrorResponse)?.data?.message || COMMON_ERROR);
        });
    },
  });

  // Resend OTP
  const handleResendOtp = () => {
    if (mobileNumber) {
      loginViaOtp({ mobileNumber: mobileNumber })
        .unwrap()
        .then((payload) => {
          toast.success(payload?.message || OTP_VERIFICATION_SUCCESS);
        })
        .catch((error) => {
          toast.error((error as ErrorResponse)?.data?.message || COMMON_ERROR);
        });
      resetCountdown();
    }
  };

  const handleOtpChange = (otp: string) => {
    setOtp(otp);
    otpFormik.setFieldValue('otp', otp);
  };

  const resetCountdown = () => {
    countdownEndTime.current = Date.now() + LOGIN_OTP_COUNTDOWN;
    setIsCountdownFinished(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCountdownFinished(true);
    }, LOGIN_OTP_COUNTDOWN);

    return () => {
      clearTimeout(timer);
    };
  }, [isCountdownFinished]);

  /* initial focus */
  useEffect(() => {
    passcodeInputRef?.current?.focus();
  }, []);

  return (
    <div className='flex-1 h-full'>
      <form
        onSubmit={formik.handleSubmit}
        className={`${buttonType === LOGIN_TYPES.PASSCODE ? 'flex-1 h-full' : ''}`}
      >
        {buttonType === LOGIN_TYPES.PASSCODE && (
          <div className='flex flex-col justify-center flex-1 h-full md:justify-around'>
            <div>
              <div
                onClick={() => navigate(-1)}
                className='flex items-center justify-start pt-10 cursor-pointer'
              >
                <img src={BackIconBlue} alt='logo' className='object-contain w-5 h-5' />
                <h2 className='ml-2 text-base text-primary-500 body-semibold'>Back</h2>
              </div>
              <h2 className='my-8 text-2xl text-center text-neutral-850 display-bold md:text-xl lg:text-3xl'>
                Login
              </h2>
              <div>
                <Input
                  ref={passcodeInputRef}
                  placeholder='Enter a 4 digit passcode'
                  type='password'
                  id='passcode'
                  pattern='[0-9]*'
                  label='Enter passcode'
                  maxLength={APP_REGEX?.MAX_LENGTH?.PASSCODE_INPUT}
                  name='passcode'
                  className='px-2 pl-5'
                  onChange={formik.handleChange}
                  value={formik.values.passcode}
                  onBlur={formik.handleBlur}
                />
                <h6
                  className='mt-3 leading-5 underline cursor-pointer text-end body-semibold text-primary-800 hover:text-primary-800 sm:text-sm'
                  onClick={() => navigate(ROUTES.FORGET_PASSCODE)}
                >
                  Forgot Passcode?
                </h6>
              </div>
            </div>
            <div className='my-6'>
              <Button
                type='submit'
                className={`w-full bg-primary-500 rounded-[14px] focus:ring-0 ring-none outline-none  text-white enabled:hover:bg-primary-500 enabled:hover:text-white  `}
                text='Login'
                disabled={!(formik.isValid && formik.dirty)}
              />
            </div>
          </div>
        )}
      </form>
      <form
        onSubmit={otpFormik.handleSubmit}
        className={`${buttonType === LOGIN_TYPES.OTP ? 'flex-1 h-full' : ''}`}
      >
        {buttonType === LOGIN_TYPES.OTP && (
          <div className='flex flex-col justify-center flex-1 h-full md:justify-around'>
            {/* go back */}
            <div
              onClick={() => navigate(-1)}
              className='items-center justify-start hidden pt-10 cursor-pointer md:flex'
            >
              <img src={BackIconBlue} alt='logo' className='object-contain w-5 h-5' />
              <h2 className='ml-2 text-base text-primary-500 body-semibold'>Back</h2>
            </div>
            <div className='flex flex-col md:text-center md:justify-center md:items-center'>
              {/* go back */}
              <div
                onClick={() => navigate(-1)}
                className='flex items-center justify-start pt-10 cursor-pointer md:hidden'
              >
                <img src={BackIconBlue} alt='logo' className='object-contain w-5 h-5' />
                <h2 className='ml-2 text-base text-primary-500 body-semibold'>Back</h2>
              </div>
              <h2 className='my-2 hidden md:block text-2xl text-neutral-850 display-bold md:text-xl xl:text-[32px]'>
                OTP Verification
              </h2>
              <h3 className='w-full my-4 leading-6 text-neutral-800 body-medium lg:text-sm xl:text-base'>
                {SENT_VERIFICATION_TEXT}{' '}
                <span className='font-bold leading-5'>+91 {mobileNumber}</span>
              </h3>
              <div className='mt-[32px] text-sm sm:mt-6 md:text-sm'>
                <p className='text-xs tracking-widest text-neutral-500 text-start cap-semibold lg:text-sm'>
                  Verify Via OTP
                </p>
                <div className='mt-3'>
                  <div className='flex flex-col gap-3 md:items-center'>
                    <OtpInput
                      handleChange={handleOtpChange}
                      value={otp}
                      isValid={otpFormik.errors.otp ? false : isValidOtp}
                      fields={4}
                    />
                    {isSuccess ||
                      (otpFormik?.errors?.otp && (
                        <span
                          className={`rounded-lg p-2 ${isValidOtp || isSuccess ? 'bg-semantic-green-500' : 'bg-semantic-red-500'}`}
                        >
                          <img
                            src={isValidOtp || isSuccess ? TickRoundedLight : ExclamationLight}
                            alt='tick'
                            className='object-contain w-4 h-4'
                          />
                        </span>
                      ))}
                  </div>

                  {otpFormik.errors.otp ? (
                    <p className='mt-2 leading-5 text-semantic-red-500 sm:text-sm body-regular'>
                      {otpFormik.errors.otp}
                    </p>
                  ) : (
                    <p className='text-xs body-regular text-neutral-600 lg:text-sm'>
                      Didn’t receive the OTP?{' '}
                      {isCountdownFinished ? (
                        <span
                          onClick={handleResendOtp}
                          className='text-sm leading-5 underline cursor-pointer body-semibold text-primary-500'
                        >
                          Resend OTP
                        </span>
                      ) : (
                        <>
                          <span className='leading-5 text-primary-800 body-semibold lg:text-xs'>
                            Resend OTP In{' '}
                            <Countdown
                              date={countdownEndTime?.current}
                              precision={0}
                              renderer={({ minutes, seconds, completed }) => {
                                if (completed) {
                                  setIsCountdownFinished(true);
                                  return null;
                                } else {
                                  return (
                                    <span>
                                      {String(minutes).padStart(2, '0')}:
                                      {String(seconds).padStart(2, '0')}
                                    </span>
                                  );
                                }
                              }}
                            />
                          </span>
                        </>
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className='my-6'>
              <Button
                type='submit'
                isLoading={isLoading}
                className={`w-full bg-primary-500 focus:ring-0 ring-none outline-none  text-white enabled:hover:bg-primary-500 enabled:hover:text-white  `}
                text='Verify & Login'
                disabled={otp.length !== 4}
              />
            </div>
          </div>
        )}
      </form>
    </div>
  );
}
