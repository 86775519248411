import apiSlice from 'store/apiSlices'; // Ensure correct import path

interface IRecording {
  data: {
    status: string;
  };
}

const classRecordingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startRecording: builder.mutation<IRecording, { classId: string; meetingId: string }>({
      query: ({ classId, meetingId }) => ({
        url: `/v2/classes/${classId}/recording?meetingId=${meetingId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useStartRecordingMutation } = classRecordingApiSlice;
