/* COMMON MESSAGES */
export const COMMON_ERROR = 'Something went wrong. Please try again';
export const DEMO_CLASS_BOOKING_ERROR =
  '🚫 Oops! Something went wrong. Please try again or contact our support team if the issue persists.';
export const MOBILE_NUMBER_ERROR = 'Mobile number verification failed. Please try again.';

/* TOAST MESSAGES SUCCESS */
export const MOBILE_NUMBER_SUCCESS = 'Mobile number verified successfully';
export const OTP_SENT_SUCCESS = 'OTP sent successfully';
export const OTP_VERIFICATION_SUCCESS = 'OTP verified successfully';
export const PASSCODE_SET_SUCCESS = 'Passcode set successfully';
export const PASSCODE_RESET_SUCCESS = 'Passcode reset successfully';
export const PASSCODE_VERIFICATION_SUCCESS = 'Passcode verified successfully';
export const STUDENT_REGISTRATION_SUCCESS = `🎉 Congratulations! You've successfully registered.`;
export const DEMOCLASS_BOOKING_SUCCESS = `🎉 Great! Your demo class is booked. Exciting learning journey awaits you! 🚀`;
