import { datadogRum } from '@datadog/browser-rum';
import { Dropdown, Navbar } from 'flowbite-react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import { setLoggedInStudentId } from 'store/slice/studentRegister.slice';

import {
  ALlClassesIcon,
  AllClassIconDark,
  Book,
  BookIcon,
  ClosedBook,
  ClosedWhiteBook,
  HomeDarkIcon,
  HomeLightIcon,
  InputDropdownIcon,
  Logo,
} from 'assets/svg';
import { Button } from 'components/common';
import { USER_TYPE } from 'configs/userData';
import { useAppDispatch } from 'hooks/store';
import HandleLogout from './HandleLogout';

import { PARENT_PATHS, STUDENT_BATCH, STUDENT_PATHS } from 'configs';
import { IDashboardNav, membersList } from 'types';

export default function DashboardNav({ type, parentSiblingsData, batchType }: IDashboardNav) {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();

  /* API => view profile to get the school data */
  const { data: profileData } = useGetUserDataQuery(undefined, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    //Set User for datadog RUM

    if (profileData?.data) {
      datadogRum.setUser({
        id: profileData?.data?.id?.toString(),
        name: `${profileData?.data?.firstName} ${profileData?.data?.lastName}`.trim(),
        email: profileData?.data?.email,
        userType: profileData?.data?.userType,
        mobileNumber: profileData?.data?.mobileNumber,
      });
    }
  }, [profileData?.data]);

  const userType = profileData?.data?.userType;
  const studentId = profileData?.data?.id;

  // get all students of parent and student name of default selected student.
  const { studentId: selectedStudentId = '' } = useParams();
  const studentList: { label: string; value: number }[] = [];
  const accountHolderData = parentSiblingsData?.data;
  let selectedStudentName: string = '';

  if (Array.isArray(accountHolderData)) {
    accountHolderData.forEach((item: membersList) => {
      const studentName = item.members.firstName;

      if (item.members.userType === USER_TYPE.STUDENT) {
        studentList.push({ label: studentName, value: item.members.id });
      }

      if (
        item.members.userType === USER_TYPE.STUDENT &&
        item.members.id === Number(selectedStudentId)
      ) {
        selectedStudentName = studentName;
      }
    });
  }

  const handleStudentChange = (value: string) => {
    dispatch(setLoggedInStudentId(value));
    selectedStudentName = value;
    if (location.pathname.includes('/home')) {
      navigate(
        userType === USER_TYPE.STUDENT
          ? STUDENT_PATHS.STUDENT_DASHBOARD
          : PARENT_PATHS.PARENT_DASHBOARD.replace(
              ':studentId',
              value || selectedStudentId?.toString() || studentId?.toString() || '',
            ),
      );
    } else {
      navigate(
        userType === USER_TYPE.STUDENT
          ? STUDENT_PATHS.STUDENT_CLASSES
          : PARENT_PATHS.ALLCLASSES.replace(
              ':studentId',
              value || selectedStudentId?.toString() || studentId?.toString() || '',
            ),
      );
    }
  };

  const dropDownTheme = {
    floating: {
      item: {
        base: 'flex w-full cursor-pointer items-center justify-start px-4 py-2 text-sm hover:text-white text-gray-700 hover:bg-primary-500 focus:outline-none dark:text-gray-200 dark:hover:bg-primary-500 dark:hover:text-white dark:focus:text-white font-medium rounded-md font-poppins capitalize',
      },
    },
  };

  return (
    <Navbar
      fluid
      rounded
      theme={{
        root: {
          inner: {
            base: 'mx-2 md:mx-10 flex flex-wrap items-center justify-start md:justify-between',
          },
          base: 'border-b border-secondary-100 px-0 bg-neutral-50 py-2.5',
        },
        toggle: {
          base: 'inline-flex items-center rounded-lg p-2 text-sm md:hidden',
        },
      }}
    >
      {/* {brand} */}
      <Navbar.Toggle />
      <Navbar.Brand className='justify-between flex-1 md:flex-none'>
        <span className='text-xl font-semibold text-green-800 whitespace-nowrap'>
          <img src={Logo} alt='company-logo' />
        </span>
        {/* in mobile view ww have to show in nav bar only not on toggle */}
        <div className='flex flex-row md:hidden'>
          {userType === USER_TYPE.GUARDIAN && (
            <Dropdown
              label={selectedStudentName}
              renderTrigger={() => (
                <div className='inline-flex items-center justify-center w-32 text-sm text-gray-700 capitalize bg-white border border-gray-300 rounded-3xl'>
                  <div className='flex items-center justify-center'>
                    <div className='px-[6px] font-poppins font-semibold text-neutral-900 text-xs py-[10px]'>
                      {selectedStudentName}
                    </div>
                    <img className='w-[10px] h-[10px]:' src={InputDropdownIcon} alt='dropdown' />
                  </div>
                </div>
              )}
              theme={dropDownTheme}
            >
              {studentList?.map((item) => (
                <Dropdown.Item onClick={() => handleStudentChange(item?.value.toString())}>
                  {item.label}
                </Dropdown.Item>
              ))}
            </Dropdown>
          )}
          <HandleLogout profileData={profileData} />
        </div>
      </Navbar.Brand>

      {/* {links } */}
      <Navbar.Collapse>
        {batchType !== STUDENT_BATCH?.DEMO && (
          <>
            <Navbar.Link
              onClick={() =>
                navigate(
                  userType === USER_TYPE.STUDENT
                    ? STUDENT_PATHS.STUDENT_DASHBOARD
                    : PARENT_PATHS.PARENT_DASHBOARD.replace(
                        ':studentId',
                        String(selectedStudentId || studentId),
                      ),
                )
              }
              activeclass='active-link'
            >
              <div className='block sm:hidden'>Home</div>
              <Button
                text={'Home'}
                icon={
                  pathname ===
                  (userType === USER_TYPE.STUDENT
                    ? STUDENT_PATHS.STUDENT_DASHBOARD
                    : PARENT_PATHS.PARENT_DASHBOARD.replace(
                        ':studentId',
                        String(selectedStudentId || studentId),
                      ))
                    ? HomeLightIcon
                    : HomeDarkIcon
                }
                preIcon={true}
                className={`hidden md:block px-5 rounded-xl body-semibold ${location?.pathname?.includes('home') ? ' bg-primary-500 text-white ' : 'text-primary-800'}`}
              />
            </Navbar.Link>
            <Navbar.Link
              onClick={() =>
                navigate(
                  userType === USER_TYPE.STUDENT
                    ? STUDENT_PATHS.STUDENT_CLASSES
                    : PARENT_PATHS.ALLCLASSES.replace(
                        ':studentId',
                        String(selectedStudentId || studentId),
                      ),
                )
              }
              activeclass='active-link'
            >
              <div className='block sm:hidden'>Classes</div>
              <Button
                text={'All Classes'}
                icon={
                  pathname ===
                  (userType === USER_TYPE.STUDENT
                    ? STUDENT_PATHS.STUDENT_CLASSES
                    : PARENT_PATHS.ALLCLASSES.replace(
                        ':studentId',
                        String(selectedStudentId || studentId),
                      ))
                    ? ALlClassesIcon
                    : AllClassIconDark
                }
                preIcon={true}
                className={`hidden md:block px-5 rounded-xl body-semibold ${location?.pathname?.includes(userType === USER_TYPE.STUDENT ? STUDENT_PATHS.STUDENT_CLASSES : PARENT_PATHS.ALLCLASSES.replace(':studentId', String(selectedStudentId || studentId))) ? ' bg-primary-500 text-white ' : 'text-primary-800'}`}
              />
            </Navbar.Link>

            <Navbar.Link
              onClick={() =>
                navigate(
                  userType === USER_TYPE.STUDENT
                    ? STUDENT_PATHS.STUDENT_PRACTICE
                    : PARENT_PATHS.PRACTICE_SHEET.replace(
                        ':studentId',
                        String(selectedStudentId || studentId),
                      ),
                )
              }
              activeclass='active-link'
            >
              <div className='block sm:hidden'>Practice</div>
              <Button
                text={'Practice'}
                icon={
                  pathname ===
                  (userType === USER_TYPE.STUDENT
                    ? STUDENT_PATHS.STUDENT_PRACTICE
                    : PARENT_PATHS.PRACTICE_SHEET.replace(
                        ':studentId',
                        String(selectedStudentId || studentId),
                      ))
                    ? Book
                    : BookIcon
                }
                preIcon={true}
                className={`hidden md:block px-5 body-semibold ${location?.pathname?.includes(userType === USER_TYPE.STUDENT ? STUDENT_PATHS.STUDENT_PRACTICE : PARENT_PATHS.PRACTICE_SHEET.replace(':studentId', String(selectedStudentId || studentId))) ? 'bg-primary-500 text-white' : 'text-primary-800'}`}
              />
            </Navbar.Link>

            {userType === USER_TYPE.STUDENT && (
              <Navbar.Link
                onClick={() =>
                  navigate(userType === USER_TYPE.STUDENT ? STUDENT_PATHS.CLASS_NOTES : '')
                }
                activeclass='active-link'
              >
                <div className='block sm:hidden'>Class Notes</div>
                <Button
                  text={'Class Notes'}
                  icon={
                    pathname ===
                    (userType === USER_TYPE.STUDENT
                      ? STUDENT_PATHS.CLASS_NOTES
                      : PARENT_PATHS.ALLCLASSES.replace(
                          ':studentId',
                          String(selectedStudentId || studentId),
                        ))
                      ? ClosedWhiteBook
                      : ClosedBook
                  }
                  preIcon={true}
                  className={`hidden md:block px-5 body-semibold ${location?.pathname?.includes(userType === USER_TYPE.STUDENT ? STUDENT_PATHS.CLASS_NOTES : '') ? 'bg-primary-500 text-white' : 'text-primary-800'}`}
                />
              </Navbar.Link>
            )}
          </>
        )}
      </Navbar.Collapse>

      {/* {icons} */}
      <div className='navbar-default'>
        <ul className='inline-flex items-center list-none gap-x-2'>
          {/* showing dropdown to select switch student */}
          {type === USER_TYPE.GUARDIAN && (
            <div className='relative hidden md:block'>
              <Dropdown
                label={selectedStudentName}
                renderTrigger={() => (
                  <div className='inline-flex items-center justify-center w-32 text-sm text-gray-700 capitalize bg-white border border-gray-300 cursor-pointer rounded-3xl'>
                    <div className='flex items-center justify-center'>
                      <div className='hidden md:block px-[6px] font-poppins font-semibold text-neutral-900 text-xs py-[10px]'>
                        {selectedStudentName}
                      </div>
                      <img className='w-[10px] h-[10px]:' src={InputDropdownIcon} alt='dropdown' />
                    </div>
                  </div>
                )}
                theme={dropDownTheme}
              >
                {studentList?.map((item) => (
                  <Dropdown.Item onClick={() => handleStudentChange(item?.value.toString())}>
                    {item.label}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>
          )}

          {/* handing logout */}
          <div className='hidden md:block'>
            <HandleLogout profileData={profileData} />
          </div>
        </ul>
      </div>
    </Navbar>
  );
}
