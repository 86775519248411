import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { activeElementType, LectureItem, PresentationDataResult } from 'types';
import processPresentationData from 'utils/lectureCommon';

interface IClassNotesState {
  activeElement: activeElementType;
  activeElementIndex: number;
  activeHTMLSlide: number;
  showPreviousButton: boolean;
  slidesContent: PresentationDataResult;
  currentPrevIndex: number;
}

const initialState: IClassNotesState = {
  activeElement: {
    type: '',
    slideNo: 0,
    contentUrl: '',
    presentationId: '',
    thumbnails: [],
    elementDetails: {
      elementId: '',
      name: '',
      referenceId: '',
    },
  },
  activeElementIndex: 0,
  activeHTMLSlide: 0,
  showPreviousButton: false,
  slidesContent: {
    lastSlideNo: 0,
    slideData: [],
  },
  currentPrevIndex: 1,
};

export const classNotesState = createSlice({
  name: 'classNotesState',
  initialState,
  reducers: {
    setActiveElement: (state, action: PayloadAction<activeElementType>) => {
      state.activeElement = action.payload;
    },
    setActiveElementIndex: (state, action: PayloadAction<number>) => {
      state.activeElementIndex = action.payload;
    },
    setActiveHTMLSlide: (state, action: PayloadAction<number>) => {
      state.activeHTMLSlide = action.payload;
    },
    setShowPreviousButton: (state, action: PayloadAction<boolean>) => {
      state.showPreviousButton = action.payload;
    },
    setSlidesContent: (state, action: PayloadAction<LectureItem[]>) => {
      const data = processPresentationData(action.payload);
      state.slidesContent = data;
    },
    setCurrentPrevIndex: (state, action: PayloadAction<number>) => {
      state.currentPrevIndex = action.payload;
    },
  },
  selectors: {
    selectActiveElement: (state) => state?.activeElement,
    selectActiveElementIndex: (state) => state?.activeElementIndex,
    selectActiveHTMLSlide: (state) => state?.activeHTMLSlide,
    selectShowPreviousButton: (state) => state?.showPreviousButton,
    selectSetSlidesContent: (state) => state?.slidesContent,
    selectCurrentPrevIndex: (state) => state?.currentPrevIndex,
  },
});

export const {
  setActiveElement,
  setActiveElementIndex,
  setActiveHTMLSlide,
  setShowPreviousButton,
  setSlidesContent,
  setCurrentPrevIndex,
} = classNotesState.actions;

export const {
  selectActiveElement,
  selectActiveElementIndex,
  selectActiveHTMLSlide,
  selectShowPreviousButton,
  selectSetSlidesContent,
  selectCurrentPrevIndex,
} = classNotesState.selectors;
