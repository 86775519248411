import { Carousel as FlowbiteCarousel, CarouselProps } from 'flowbite-react';

interface ICarousalProps extends CarouselProps {
  wrapperClass?: string;
}

const Carousel = ({ children, wrapperClass, ...props }: ICarousalProps) => {
  return (
    <div className={wrapperClass ? wrapperClass : 'h-56 sm:h-64 xl:h-80 2xl:h-96'}>
      <FlowbiteCarousel {...props}>{children}</FlowbiteCarousel>
    </div>
  );
};

export default Carousel;
