import { useEffect, useState } from 'react';

import { useLazyListOfClassesQuery } from 'store/apiSlices/classes.apiSlice';
import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';

interface PaginationInfo {
  studentId: number | null;
  pageNumber: number;
  pageSize: number;
}

const ClassList = () => {
  // State to hold the student ID
  const [studentId, setStudentId] = useState<number | null>(null);

  // State to hold the pagination info
  const [scheduledClassesPaginationInfo, setScheduledClassesPaginationInfo] =
    useState<PaginationInfo>({
      studentId: studentId,
      pageNumber: 1,
      pageSize: 6,
    });

  // Use the get user data query to get the user data
  const { data: userData, isSuccess } = useGetUserDataQuery();

  // When the query is successful, set the student ID and update the pagination info
  useEffect(() => {
    if (isSuccess && userData) {
      const studentId = userData?.data?.id;
      setStudentId(studentId);
      setScheduledClassesPaginationInfo((prevState) => ({
        ...prevState,
        studentId: userData?.data?.id,
      }));
    }
  }, [isSuccess, userData]);

  // Function to prepare the query params for the API call
  const prepareQueryParams = (data: object = {}) => {
    let queryParams: string = '';
    if (Object.keys(data).length > 0) {
      queryParams = new URLSearchParams(data as Record<string, string>).toString();
      if (queryParams) {
        queryParams = '?' + queryParams;
      }
    }
    return queryParams;
  };

  // Prepare the pagination info
  const paginationInfo = prepareQueryParams(scheduledClassesPaginationInfo);

  // Use the lazy list of classes query to get the class data
  const [trigger, result] = useLazyListOfClassesQuery();

  // Trigger the query with the pagination info and login token
  useEffect(() => {
    trigger({
      paginationInfo: paginationInfo,
    });
  }, [paginationInfo, trigger]);

  const { data } = result;

  // Function to handle the join button click
  const handleJoin = (joinCode: string) => {
    const url = `/class/join/${joinCode}`;
    window.open(url, 'Join Class');
  };

  return (
    <div className='flex flex-wrap justify-evenly'>
      {data?.data.map((classItem, index) => {
        if (!classItem.classDetails) {
          return null;
        }

        const currentTime = new Date();
        const startTime = new Date(classItem.classDetails.meetingStartTime);
        const endTime = new Date(classItem.classDetails.meetingEndTime);
        const isBetween = currentTime >= startTime && currentTime <= endTime;

        return (
          <div key={index} className='px-5 m-4 overflow-hidden rounded-lg bg-gray-50 py-7'>
            <div className='text-xl font-bold'>{classItem.classDetails.name}</div>
            <p className='text-base text-gray-700'>
              Standard: {classItem.classDetails.name}
              <br />
              {classItem.classDetails.meetingStartTime} - {classItem.classDetails.meetingEndTime}
            </p>
            <button
              className={`px-4 py-2 font-bold text-white ${!isBetween && 'opacity-50'} ${isBetween && 'bg-[linear-gradient(94deg,#656565_0.53%,#363636_100%)] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]'} bg-[linear-gradient(94deg,#656565_0.53%,#363636_100%)] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] rounded-lg mt-3 `}
              disabled={!isBetween}
              onClick={() =>
                handleJoin(
                  (classItem.classDetails.studentJoinLinks &&
                    classItem.classDetails.studentJoinLinks.length > 0 &&
                    classItem.classDetails.studentJoinLinks.find(
                      (currentVal) => currentVal.studentId === studentId,
                    )?.joinCode) ||
                    'N/A',
                )
              }
            >
              Join Now
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default ClassList;
