import { useState } from 'react';

import { LoginMobile, PasscodeOrOTP } from 'components/screens';
import { ILoginVerification } from 'types/auth';
import { LOGIN_TYPES } from 'configs';

export default function LoginVerification({ isMobileVerified }: ILoginVerification) {
  const [buttonClickType, setButtonClickType] = useState<'passcode' | 'otp'>(LOGIN_TYPES.PASSCODE);
  const [isMobileValidated, setIsMobileValidated] = useState<boolean>(false);

  const handleButtonChange = (type: 'passcode' | 'otp') => {
    if (type) {
      setButtonClickType(type);
    }
  };

  const handleMobileValidatedStatus = (isMobileValidated: boolean) => {
    if (isMobileValidated) {
      setIsMobileValidated(isMobileValidated);
      isMobileVerified(true);
    }
  };

  return (
    <div className='flex-1 h-full'>
      {isMobileValidated ? (
        <PasscodeOrOTP buttonType={buttonClickType} />
      ) : (
        <>
          <LoginMobile
            buttonClickType={handleButtonChange}
            isMobileValidated={handleMobileValidatedStatus}
          />
        </>
      )}
    </div>
  );
}
