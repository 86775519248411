import classNames from 'classnames';
import { IAvatarProps } from 'types/common';

export default function Avatar({
  firstName = '',
  lastName = '',
  image,
  className,
  initialsClassName,
  initialsFontSizeClassName = '',
}: IAvatarProps) {
  return (
    <>
      {image ? (
        <img className={classNames('', className)} src={image} alt='img' />
      ) : (
        <div className={classNames('', initialsClassName)}>
          <p
            className={`font-poppins ${initialsFontSizeClassName ? initialsFontSizeClassName : 'text-[16px] sm:text-xs lg:text-lg'} tracking-widest font-semibold`}
          >{`${firstName?.charAt(0)}${lastName?.charAt(0)}`}</p>
        </div>
      )}
    </>
  );
}
