import {
  ClassList,
  ClassNotes,
  Classes,
  ForgetPasscode,
  Home,
  Login,
  ParentDashboard,
  ParentDashboardClassOver,
  Practice,
  Register,
  StudentDashBoard,
  TermsAndConditions,
} from 'pages';

import { PARENT_PATHS, ROUTES, STUDENT_PATHS } from './routePaths';

export const PROTECTED_ROUTES = [
  { path: PARENT_PATHS.PARENT_DASHBOARD, element: <ParentDashboard /> },
  { path: PARENT_PATHS.CLASS_OVER, element: <ParentDashboardClassOver /> },
  { path: PARENT_PATHS.ALLCLASSES, element: <Classes /> },
  { path: STUDENT_PATHS.STUDENT_DASHBOARD, element: <StudentDashBoard /> },
  { path: STUDENT_PATHS.STUDENT_CLASSES, element: <Classes /> },
  { path: ROUTES.LIST_OF_CLASSES, element: <ClassList /> },
  { path: STUDENT_PATHS.STUDENT_PRACTICE, element: <Practice /> },
  { path: STUDENT_PATHS.CLASS_NOTES, element: <ClassNotes /> },
  { path: PARENT_PATHS.PRACTICE_SHEET, element: <Practice /> },
];

export const OPEN_ROUTES = [
  { path: ROUTES.ROOT, element: <Home /> },
  { path: ROUTES.LOGIN, element: <Login /> },
  { path: ROUTES.REGISTER, element: <Register /> },
  { path: ROUTES.FORGET_PASSCODE, element: <ForgetPasscode /> },
  { path: ROUTES.TERMS_AND_CONDITION, element: <TermsAndConditions /> },
];
