import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';

import { selectLoggedInStudentId, selectUserType } from 'store/slice/studentRegister.slice';

import { useAppSelector } from 'hooks/store';

import { PARENT_PATHS, ROUTES, STUDENT_PATHS, USER_TYPE, CONFIG } from 'configs';
import { useEffect } from 'react';

export const ProtectedRouteWithTempToken = () => {
  const tempToken = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);
  return tempToken ? <Outlet /> : <Navigate to={ROUTES.LOGIN} />;
};

export const ProtectedRouteWithLoginToken = () => {
  const navigate = useNavigate();
  const id = useAppSelector(selectLoggedInStudentId);
  const loginToken = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);
  const userType = useAppSelector(selectUserType);

  const location = useLocation();
  useEffect(() => {
    if (!loginToken) {
      navigate(ROUTES.LOGIN, { state: { from: location.pathname } });
      return;
    }
    if (userType === USER_TYPE.STUDENT) {
      if (!window.location.pathname.startsWith('/s/')) {
        navigate(STUDENT_PATHS.STUDENT_DASHBOARD, { replace: true });
      }
    } else if (userType === USER_TYPE.GUARDIAN) {
      if (location.pathname.includes('p/home') || location.pathname.includes('/s/')) {
        const targetPath = PARENT_PATHS.PARENT_DASHBOARD.replace(':studentId', id || '');
        navigate(targetPath, { replace: true });
      }
    }
  }, [id, location.pathname, loginToken, navigate, userType]);

  return <Outlet />;
};

export const OpenRoutes = () => {
  const navigate = useNavigate();
  const userType = useAppSelector(selectUserType);
  const loginToken = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);

  if (loginToken) {
    if (userType) {
      if (userType === USER_TYPE.STUDENT) {
        navigate(STUDENT_PATHS.STUDENT_DASHBOARD, { replace: true });
      }
    } else {
      navigate(ROUTES.LOGIN, { replace: true });
    }
  }
  return <Outlet />;
};
