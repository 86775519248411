import { Badge } from 'flowbite-react';
import Countdown from 'react-countdown';

import Card from 'components/common/Card';

import { StudentBookImg, SubjectIconWhite, DateIcon, Timer } from 'assets/svg';
import { ICurrentClass } from 'types/democlass';
import moment from 'moment';

interface IScheduledProps {
  classData: ICurrentClass | undefined;
  state: string;
  remainingTime: number;
  batchTypeDetails: { name: string };
  timeLeftToClassStarts: string;
}

const ScheduledClass = ({
  classData,
  state,
  remainingTime,
  batchTypeDetails,
  timeLeftToClassStarts,
}: IScheduledProps) => {
  return (
    <>
      {remainingTime <= 15 ? (
        <Card className='w-full p-4 bg-white rounded-2xl'>
          <div className='flex flex-wrap items-center mb-10'>
            <h3 className='text-lg font-normal tracking-tight font-sora text-neutral-900 lg:text-xl'>
              {batchTypeDetails.name === 'Regular'
                ? 'Upcoming Regular Class'
                : 'Upcoming Demo Class'}
            </h3>
            <Badge className='inline px-2 leading-3 text-white rounded-xl sm:ml-2 md:px-4 bg-semantic-red-500'>
              <span className='text-sm'>{`Starting in ${timeLeftToClassStarts}`}</span>
            </Badge>
          </div>
          {state === 'NotStarted' && (
            <div className='grid grid-rows-1'>
              <div className='grid lg:grid-cols-3 xl:grid-cols-4 gap-y-5'>
                <div className='lg:col-span-1'>
                  <div className='w-full xl:w-48 2xl:w-60 h-w-48 lg:h-full bg-[#000] mr-6 rounded-2xl'>
                    <div className='h-[10rem] lg:h-full flex flex-col items-center justify-center'>
                      <p className='text-[#89BAFA] text-base font-medium'>Class starts in: </p>
                      <p className='text-[#EF4444] text-[42px] font-bold'>
                        <Countdown
                          date={moment(
                            classData?.data?.meetingStartTime || moment().toISOString(),
                          ).toDate()}
                          renderer={({ hours, minutes, seconds }) => {
                            return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className='lg:col-span-2 xl:col-span-3 lg:ml-5 xl:ml-0'>
                  <div className='flex-1'>
                    <div className='flex flex-wrap items-center'>
                      <span className='bg-[#3B8CF6] rounded-full p-3 inline-block w-8 lg:w-10 mr-2'>
                        <img className='w-4 lg:w-5' src={SubjectIconWhite} alt='' />
                      </span>
                      <span className='bg-primary-200 text-primary-600 font-poppins text-xs font-semibold mr-2 px-2.5 py-0.5 rounded'>
                        MATHS
                      </span>
                      <span className='text-xs text-white bg-primary-850 font-poppins font-semibold px-2.5 py-0.5 rounded'>
                        {batchTypeDetails.name === 'Regular' ? 'Regular' : 'Demo'}
                      </span>
                    </div>
                    <div className='flex justify-between mt-2'>
                      <div className='flex flex-col justify-between'>
                        <p className='text-[#171851] font-sans font-bold text-base lg:text-2xl sm:text-xl'>
                          {classData?.data?.lectureConfigurationName ||
                            classData?.data?.classConfigurationName ||
                            classData?.data?.name}
                        </p>
                        <div className='mt-10'>
                          <h5 className='text-[#8390A2] font-poppins font-medium mb-4 text-xs '>
                            Date & Time{' '}
                          </h5>
                          <span className='bg-[#30F2EA] rounded-2xl p-1 px-2 text-[10px] sm:text-xs lg:text-sm'>
                            <img className='inline w-5 p-1 pt-0' src={DateIcon} alt='' />
                            {moment(classData?.data?.meetingStartTime).format('DD MMM YYYY')}
                          </span>
                          <span className='bg-[#30F2EA] rounded-2xl p-1 px-2 ml-2 text-[10px] sm:text-xs lg:text-sm'>
                            <img className='inline w-5 p-1 pt-0' src={Timer} alt='' />
                            {moment(classData?.data?.meetingStartTime).format('h:mm A')} {'-'}
                            {moment(classData?.data?.meetingEndTime).format('h:mm A')}
                          </span>
                        </div>
                      </div>
                      <div className='hidden sm:block'>
                        <img className='' src={StudentBookImg} alt='' />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Card>
      ) : (
        <div className='bg-white rounded-2xl'>
          <div className='px-4 pt-4'>
            <div className='flex flex-wrap items-center justify-between text-white gap-x-2'>
              <h3 className='text-lg font-normal tracking-tight lg:text-xl text-neutral-900 font-sora'>
                {batchTypeDetails.name === 'Regular'
                  ? 'Upcoming Regular Class'
                  : 'Upcoming Demo Class'}
              </h3>
              <Badge className='inline px-2 leading-3 text-white bg-green-400 rounded-xl md:px-4'>
                <span className='text-sm'>{`Starting in ${timeLeftToClassStarts}`}</span>
              </Badge>
            </div>
            <div className='flex items-center mt-10'>
              <span className='bg-primary-200 text-primary-600 font-poppins text-xs font-semibold mr-2 px-2.5 py-0.5 rounded'>
                MATHS
              </span>
              <span className='text-xs text-white bg-primary-850 font-poppins font-semibold px-2.5 py-0.5 rounded'>
                {batchTypeDetails.name === 'Regular' ? 'Regular' : 'Demo'}
              </span>
            </div>
          </div>
          <div className='px-4 pb-4 mt-4'>
            <h4 className='text-2xl font-bold tracking-tight sm:text-2xl text-neutral-800 font-sora'>
              {classData?.data?.lectureConfigurationName ||
                classData?.data?.classConfigurationName ||
                classData?.data?.name}
            </h4>
            <div className='flex items-center justify-between'>
              <div>
                <p className='text-[#8390A2] font-poppins font-medium mb-4 text-xs '>Date & Time</p>
                <span className='bg-[#30F2EA] font-poppins rounded-2xl p-1 px-2 text-[10px] sm:text-xs lg:text-sm'>
                  <img className='inline w-5 p-1 pt-0' src={DateIcon} alt='' />
                  {moment(classData?.data?.meetingStartTime).format('DD MMM YYYY')}
                </span>
                <span className='bg-[#30F2EA] font-poppins rounded-2xl p-1 px-2 ml-2 text-[10px] sm:text-xs lg:text-sm'>
                  <img className='inline w-5 p-1 pt-0' src={Timer} alt='' />
                  {moment(classData?.data?.meetingStartTime).format('h')} {'-'}
                  {moment(classData?.data?.meetingEndTime).format('h A')}
                </span>
              </div>
              <div className='hidden sm:block'>
                <img className='' src={StudentBookImg} alt='' />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScheduledClass;
