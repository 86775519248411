import { useState } from 'react';

import Card from 'components/common/Card';
import { IUpcomingClassProps } from 'types/dashboard/parentDashboard';

import { ScheduledClass } from 'components/screens';
import TutorProfileNew from '../TutorProfile/TutorProfileNew';

import ClassTimeline from '../ClassTimeline';

const UpcomingClass = ({ classData, stateOfClass, remainingTime }: IUpcomingClassProps) => {
  const [classFlowKey] = useState({
    classId: classData?.data?.id || 0,
    classConfigId: classData?.data?.classConfigurationId || 0,
  });
  const batchTypeDetails = classData?.data?.batchTypeDetails || { name: 'Demo' };

  const convertTime = (minutes: number) => {
    if (minutes >= 60) {
      const hours = Math.ceil(minutes / 60);
      if (hours >= 24) {
        const days = Math.ceil(hours / 24);
        return days + ' days';
      }
      return hours + ' hrs';
    }
    return minutes + ' mins';
  };

  const timeLeftToClassStarts = convertTime(remainingTime);

  return (
    <Card className='w-full'>
      <div className='grid grid-rows-1'>
        <div
          className={`${remainingTime <= 15 ? 'grid lg:grid-cols-7 gap-y-5 lg:gap-x-2' : 'grid xl:grid-cols-6 gap-y-2 lg:gap-x-2 '}`}
        >
          <div className={`${remainingTime <= 15 ? 'lg:col-span-5' : 'xl:col-span-2'}`}>
            <ScheduledClass
              classData={classData}
              state={stateOfClass}
              remainingTime={remainingTime}
              batchTypeDetails={batchTypeDetails}
              timeLeftToClassStarts={timeLeftToClassStarts}
            />
          </div>
          <div className={`${remainingTime <= 15 ? 'lg:col-span-2 ' : 'xl:col-span-4 '}`}>
            <TutorProfileNew
              state={stateOfClass}
              type='guardian'
              remainingTime={remainingTime}
              classData={classData}
            />
          </div>
        </div>
      </div>

      {/* {class flow timelines} */}
      <ClassTimeline classData={classData} classFlowKey={classFlowKey} />
    </Card>
  );
};

export default UpcomingClass;
