import { useDyteSelector } from '@dytesdk/react-web-core';
import { useEffect, useState } from 'react';

import StudentSetupScreen from 'components/inClass/StudentSetupScreen';
import InClass from 'components/inClass/InClass';
import HuddleTransition from 'components/inClass/HuddleTransition';
import { BouncingDotsLoader } from 'components/common';
import { useMeeting } from 'hooks';

const Meeting = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const roomJoined = useDyteSelector((meeting) => meeting.self.roomJoined);
  const { state } = useMeeting();

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;
    if (state === 'connected-meeting') {
      setShowLoader(true);
      timer = setTimeout(() => {
        window.location.reload();
      }, 10000);
    }
    return () => {
      timer && clearTimeout(timer);
    };
  }, [state]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showLoader) {
      timer = setTimeout(() => {
        setShowLoader(false);
      }, 4000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showLoader]);

  if (state === 'connected-meeting' || showLoader) {
    return (
      <div className='flex flex-col items-center justify-center text-white bg-primary-800 w-[100vw] h-[100vh] gap-10'>
        <HuddleTransition />
        <BouncingDotsLoader />
      </div>
    );
  }
  if (!roomJoined) {
    return <StudentSetupScreen />;
  }

  return <InClass />;
};

export default Meeting;
