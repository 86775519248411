import InClassModal from 'components/inClass/Modal';
import { Button } from 'components/common';
import { useDyteMeeting } from '@dytesdk/react-web-core';

interface IEnableDeviceModal {
  enableDevice: string;
  setEnableDeviceModal: React.Dispatch<React.SetStateAction<boolean>>;
  enableDeviceModal: boolean;
}

const EnableDeviceModal = ({
  enableDevice,
  enableDeviceModal,
  setEnableDeviceModal,
}: IEnableDeviceModal) => {
  const { meeting } = useDyteMeeting();
  const handleDeviceEnable = (enableDevice: string) => {
    if (enableDevice === 'Camera') {
      meeting.self.enableVideo();
    } else {
      meeting.self.enableAudio();
    }
    setEnableDeviceModal(false);
  };
  const buttonText = `Enable ${enableDevice}`;

  return (
    <InClassModal
      openModal={enableDeviceModal}
      setOpenModal={setEnableDeviceModal}
      color='bg-primary-850'
      className='rounded-[24px]'
    >
      <div className='justify-center flex flex-col items-center'>
        <p className='text-xl text-white pb-4'>
          The tutor is asking you to enable the <span className='lowercase'>{enableDevice}!</span>
        </p>
        <Button
          onClick={() => handleDeviceEnable(enableDevice)}
          text={buttonText}
          className='w-[25%]'
        />
      </div>
    </InClassModal>
  );
};

export default EnableDeviceModal;
