export const APP_REGEX = {
  VALIDATION: {
    MOBILE_NUMBER_VALIDATION: /^(\+91[-\s])?[0]?(91)?[6789]\d{9}$/,
    PASSCODE_VALIDATION: /^(?!19|20)\d{4}$/,
  },
  PATTERN: {
    MOBILE_NUMBER_INPUT: '[6789][0-9]{9}',
    ALPHABET_INPUT: '[a-zA-Z ]*',
    ALPHA_NUMERIC_INPUT: '[a-zA-Z0-9]+',
  },
  MAX_LENGTH: {
    MOBILE_NUMBER_INPUT: 10,
    PASSCODE_INPUT: 4,
    OTP_INPUT: 4,
  },
};
