import { Label } from 'flowbite-react';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import * as Yup from 'yup';

import {
  useInitialMobileVerificationMutation,
  useParentOnboardingMutation,
} from 'store/apiSlices/register.apiSlice';
import { selectMobileNumberUpdated } from 'store/slice/studentRegister.slice';

import { ExclamationCircle } from 'assets/svg';
import { Button, Input, InputDropdown, Loader, Modal, ModalBottomSheet } from 'components/common';
import { UpdateMobileNumber } from 'components/screens';
import { USER_TYPE } from 'configs/userData';
import { useLocalStorage, useWindowDimensions } from 'hooks';
import { useAppSelector } from 'hooks/store';

import { APP_DEFAULT_MESSAGES, APP_REGEX, LOGIN_OTP_COUNTDOWN, parentRelation } from 'configs';
import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import { IisParentOnboarding } from 'types';
import toast from 'react-hot-toast';

export default function ParentOnboarding({
  isParentOnboarding,
  studentData,
  matchedId,
}: IisParentOnboarding) {
  const { isMobileScreen } = useWindowDimensions();
  const { getFromLocalStorage } = useLocalStorage();
  const inputRef = useRef<HTMLInputElement>(null);
  const mobileNumber = getFromLocalStorage('mobileNumber');
  const updateMobileNumberData = useAppSelector(selectMobileNumberUpdated);

  const [isUpdateMobileNumber, setIsUpdateMobileNumber] = useState<boolean>(false);
  const [isNewNumber, setIsNewNumber] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const [isCountdownFinished, setIsCountdownFinished] = useState<boolean>(false);
  const countdownEndTime = useRef(Date.now() + LOGIN_OTP_COUNTDOWN);

  /* API => view profile to get the school data */
  const { data: profileData } = useGetUserDataQuery(undefined, {
    skip: !isEmpty(studentData) ? true : false,
    refetchOnMountOrArgChange: true,
  });

  /* API => initial mobile number verification */
  const [initialMobileVerification, { isLoading: initialLoading }] =
    useInitialMobileVerificationMutation();

  /* API => parent onboarding  */
  const [ParentOnboarding, { isLoading }] = useParentOnboardingMutation();

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      userType: USER_TYPE?.GUARDIAN || '',
      mobileNumber:
        mobileNumber || studentData?.mobileNumber || profileData?.data?.mobileNumber || '',
      firstName: '',
      lastName: '',
      relationship: '',
      country: studentData?.country?.toString() || profileData?.data?.country?.toString() || '',
      state: studentData?.state?.toString() || profileData?.data?.state?.toString() || '',
      city: studentData?.city?.toString() || profileData?.data?.city?.toString() || '',
      otp: '',
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .required(APP_DEFAULT_MESSAGES?.VALIDATION?.MOBILE_NUMBER_REQUIRED)
        .matches(
          APP_REGEX?.VALIDATION?.MOBILE_NUMBER_VALIDATION,
          APP_DEFAULT_MESSAGES?.VALIDATION?.VALID_MOBILE_NO,
        ),
      firstName: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.FIRST_NAME_REQUIRED),
      lastName: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.LAST_NAME_REQUIRED),
      relationship: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.RELATIONSHIP_REQUIRED),
      city: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.CITY_REQUIRED),
    }),
    onSubmit: (values) => {
      const formInfo = {
        userType: values?.userType || '',
        firstName: values?.firstName || '',
        lastName: values?.lastName || '',
        country: values.country || '',
        state: values.state || '',
        city: values.city || '',
        relationship: values.relationship || '',
        studentId: matchedId || studentData?.id,
      };
      const data = isNewNumber
        ? {
            ...values,
            otp: otp || '',
            mobileNumber: values.mobileNumber || '',
          }
        : formInfo;
      const toastId = toast.loading('Loading...');

      if (
        isNewNumber ||
        values.mobileNumber !==
          (mobileNumber || studentData?.mobileNumber || profileData?.data?.mobileNumber)
      ) {
        // Exit early if OTP is not valid
        if (!otp || otp.length < 4) {
          toast.error('Please enter a valid 4-digit OTP to verify your mobile number', {
            id: toastId,
          });
          return;
        }
      }

      ParentOnboarding(data)
        .unwrap()
        .then((res) => {
          isParentOnboarding(true);
          toast.success(res?.message, { id: toastId });
        })
        .catch((err) => {
          toast.error(err?.data?.message, { id: toastId });
        });
    },
  });

  /* Initial Mobile Number Verification */
  const handleMobileVerify = () => {
    if (mobileNumber !== formik.values.mobileNumber) {
      setIsNewNumber(true);
      const toastId = toast.loading('Loading...');
      initialMobileVerification({
        mobileNumber: formik.values.mobileNumber || '',
        studentId: matchedId || studentData?.id,
      })
        .unwrap()
        .then((res) => {
          toast.success(res?.message, { id: toastId });
          setIsNewNumber(true);
        })
        .catch((err) => {
          toast.error(err.data?.message, { id: toastId });
          setIsNewNumber(false);
        });
    }
  };

  const handleResendOtp = () => {
    const toastId = toast.loading('Loading...');
    initialMobileVerification({ mobileNumber: formik.values.mobileNumber || '' })
      .then(() => {
        toast.success('OTP sent successfully', { id: toastId });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || 'Failed to send OTP', { id: toastId });
      });

    resetCountdown();
  };

  const resetCountdown = () => {
    countdownEndTime.current = Date.now() + LOGIN_OTP_COUNTDOWN;
    setIsCountdownFinished(false);
  };

  useEffect(() => {
    if (updateMobileNumberData?.ismobileNumberUpdated) {
      setIsUpdateMobileNumber(false);
    }
  }, [updateMobileNumberData]);

  useEffect(() => {
    if (inputRef) {
      inputRef.current?.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (formik.values.mobileNumber === mobileNumber || formik.values.mobileNumber.length !== 10) {
      setIsNewNumber(false);
    }
  }, [formik, mobileNumber]);

  return (
    <div className='px-3'>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <h3 className='w-full my-2 capitalize text-neutral-800 title-semibold lg:text-lg'>
            Current mobile Number:{' '}
            <span className='font-bold leading-5'>
              +91 {mobileNumber || profileData?.data?.mobileNumber}
            </span>
          </h3>
          <h5 className='w-full my-2 leading-6 text-neutral-800 body-regular lg:text-sm'>
            If you want to update your mobile number{' '}
            <span
              onClick={() => setIsUpdateMobileNumber(true)}
              className='font-bold leading-5 underline cursor-pointer text-primary-500'
            >
              click here
            </span>
          </h5>
        </div>

        <div className={`${isNewNumber && 'flex flex-row gap-2 w-full'}`}>
          <div className='flex-1 mt-3'>
            {/* Mobile Number Input Field and Verification Link */}
            <Input
              ref={inputRef}
              type='number'
              id='mobileNumber'
              name='mobileNumber'
              pattern='[0-9]*'
              label={'Enter Parent Mobile Number*'}
              className='pl-10'
              autoComplete='on'
              placeholder='932xxxxxxx'
              isMobilenumber={true}
              maxLength={APP_REGEX?.MAX_LENGTH?.MOBILE_NUMBER_INPUT}
              value={formik.values.mobileNumber || ''}
              onChange={(e) => formik.setFieldValue('mobileNumber', e.target.value)}
              isValid={formik.touched.mobileNumber && formik.errors.mobileNumber ? true : false}
              icon={
                formik.errors.mobileNumber && formik.touched.mobileNumber ? ExclamationCircle : ''
              }
            />
            {/* Verify the mobile number if they add the new one */}
            {formik.values.mobileNumber !== (mobileNumber || profileData?.data?.mobileNumber) &&
              formik.values.mobileNumber.length === 10 && (
                <h5
                  onClick={handleMobileVerify}
                  className={`pt-2 underline cursor-pointer ${isNewNumber ? 'text-start animate-none' : 'text-end'}  text-primary-500 lg:text-xs body-medium`}
                >
                  Verify your Mobile Number
                </h5>
              )}
            {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
              <p className='mt-2 leading-5 text-semantic-red-500 sm:text-sm body-regular'>
                {formik.errors.mobileNumber}
              </p>
            ) : null}
          </div>
          {/* Conditional Loader or OTP Input */}
          {isNewNumber &&
          formik?.values?.mobileNumber !== mobileNumber &&
          formik?.values?.mobileNumber.length === 10 ? (
            initialLoading ? (
              <div className='relative flex-1 mt-3'>
                <Loader size={'lg'} color={'gray'} />
              </div>
            ) : (
              <div className='flex-1 mt-3'>
                <Input
                  type='password'
                  id='otp'
                  name='otp'
                  label={'Enter the OTP*'}
                  className=''
                  placeholder='92XX'
                  maxLength={APP_REGEX?.MAX_LENGTH?.OTP_INPUT}
                  value={otp || ''}
                  onChange={(e) => setOtp(e.target.value)}
                />
                {/* OTP Resend Logic */}
                <p
                  className={`max-w-lg pt-2 ${isNewNumber ? 'text-start' : 'text-end'} body-regular text-neutral-600 lg:text-xs`}
                >
                  Didn’t receive the OTP?{' '}
                  {isCountdownFinished ? (
                    <span
                      onClick={handleResendOtp}
                      className='leading-5 underline cursor-pointer lg:text-xs body-semibold text-primary-500'
                    >
                      Resend OTP
                    </span>
                  ) : (
                    <span className='leading-5 text-primary-800 body-semibold lg:text-xs'>
                      Resend OTP In {/* Countdown Timer */}
                      <Countdown
                        date={countdownEndTime?.current}
                        precision={0}
                        renderer={({ minutes, seconds, completed }) => {
                          if (completed) {
                            setIsCountdownFinished(true);
                            return null;
                          } else {
                            return (
                              <span>
                                {String(minutes).padStart(2, '0')}:
                                {String(seconds).padStart(2, '0')}
                              </span>
                            );
                          }
                        }}
                      />
                    </span>
                  )}
                </p>
              </div>
            )
          ) : null}
        </div>

        <div className='flex flex-col gap-2 mt-2 lg:mt-4 md:flex-row'>
          <div className='flex-1'>
            <Input
              placeholder='First Name'
              type='text'
              id='firstName'
              label='First Name*'
              pattern={APP_REGEX?.PATTERN?.ALPHABET_INPUT}
              name='firstName'
              className='w-full px-2 pl-5 pr-5 leading-8'
              onChange={(e) => formik.setFieldValue('firstName', e.target.value)}
              value={formik.values?.firstName || ''}
              isValid={formik.touched.firstName && formik.errors.firstName ? true : false}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                {APP_DEFAULT_MESSAGES.VALIDATION.FIRST_NAME_REQUIRED}
              </p>
            ) : null}
          </div>
          <div className='flex-1'>
            <Input
              placeholder='Last Name'
              type='text'
              id='lastName'
              label='Last Name*'
              pattern={APP_REGEX?.PATTERN?.ALPHABET_INPUT}
              name='lastName'
              className='w-full px-2 pl-5 pr-5 leading-8'
              onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
              value={formik.values?.lastName || ''}
              onBlur={formik.handleBlur}
              isValid={formik.touched.lastName && formik.errors.lastName ? true : false}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                {APP_DEFAULT_MESSAGES.VALIDATION.LAST_NAME_REQUIRED}
              </p>
            ) : null}
          </div>
        </div>
        <div className='flex flex-col gap-2 mt-2 lg:mt-4 xl:flex-row'>
          <div className='flex-1'>
            <Label
              value='Relationship with Student*'
              className='text-sm leading-5 uppercase cap-semibold text-neutral-500'
            />
            <InputDropdown
              options={parentRelation || []}
              className='pl-5 pr-5 leading-8'
              setSelectedOption={(e) => formik.setFieldValue('relationship', e.value)}
              placeholder='Relationship with Student'
            />
            {formik.touched.relationship && formik.errors.relationship && (
              <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                {formik.errors.relationship}
              </p>
            )}
          </div>
        </div>

        <div className='mt-6'>
          <Button
            type='submit'
            isLoading={isLoading}
            className={`w-full bg-primary-500 enabled:hover:bg-primary-500 text-white rounded-lg`}
            text='Submit'
            disabled={!formik.isValid || !formik.dirty}
          />
        </div>
      </form>
      {isUpdateMobileNumber && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              setOpenModal={setIsUpdateMobileNumber}
              openModal={isUpdateMobileNumber}
              heading='Update Mobile Number'
              showCloseBtn={true}
              children={
                <div className='p-2'>
                  <UpdateMobileNumber studentData={studentData} />
                </div>
              }
            />
          ) : (
            <Modal
              heading='Update Mobile Number'
              children={<UpdateMobileNumber studentData={studentData} />}
              showCloseBtn={true}
              openModal={isUpdateMobileNumber}
              setOpenModal={setIsUpdateMobileNumber}
            />
          )}
        </div>
      )}
    </div>
  );
}
