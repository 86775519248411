import { Dot, TickWhite } from 'assets/svg';
import { Avatar } from 'components/common';
import { IRenderCoachLevel } from 'types/dashboard/parentDashboard';

const RenderCoachLevels = ({
  studentDetails,
  currentLevelDetails,
  isClassEnds,
  classStatus,
  isVisible,
}: IRenderCoachLevel) => {
  return (
    <>
      {!isClassEnds && currentLevelDetails.status === 'notStarted' ? (
        <div className='grid h-full p-1 place-items-center'>
          <img
            className='object-contain w-4 h-4 rounded-full lg:w-5 lg:h-5'
            src={Dot}
            alt='image'
          />
        </div>
      ) : classStatus && currentLevelDetails?.status === 'notStarted' ? (
        <>
          <div className='text-[5px] h-full grid place-items-center bg-primary-500 p-1 rounded-full'>
            <img
              className='object-contain w-4 h-4 rounded-full lg:w-5 lg:h-5'
              src={TickWhite}
              alt='image'
            />
          </div>
        </>
      ) : null}

      {!isClassEnds && currentLevelDetails?.status === 'inProgress' ? (
        <div className='w-full h-full'>
          <Avatar
            image={studentDetails?.profileImage}
            firstName={studentDetails?.firstName}
            lastName={studentDetails?.lastName}
            className='object-cover w-full h-full border-2 rounded-full border-primary-500 '
            initialsClassName={`w-5 h-5 lg:w-full lg:h-full rounded-full ${isVisible ? 'bg-red-500 border-red-500' : 'opacity-0'}  text-white flex items-center justify-center`}
            initialsFontSizeClassName='text-[10px] xl:text-xs'
          />
        </div>
      ) : classStatus && currentLevelDetails?.status === 'inProgress' ? (
        <div className='text-[5px] h-full grid place-items-center bg-primary-500 p-1 rounded-full'>
          <img
            className='object-contain w-4 h-4 rounded-full lg:w-5 lg:h-5'
            src={TickWhite}
            alt='image'
          />
        </div>
      ) : null}

      {currentLevelDetails?.status === 'completed' && (
        <div className='text-[5px] h-full grid place-items-center bg-primary-500 p-1 rounded-full'>
          <img
            className='object-contain w-4 h-4 rounded-full lg:w-5 lg:h-5'
            src={TickWhite}
            alt='image'
          />
        </div>
      )}
    </>
  );
};

export default RenderCoachLevels;
