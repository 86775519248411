import { useGetCurrentClassDetailsQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import Card from 'components/common/Card';

import { AllClassIconDark, HomeDarkIcon } from 'assets/svg';

import { CLASS_PROGRESS, USER_TYPE } from 'configs';
import { ITutorProfile } from 'types';
import { Avatar } from 'components/common';

export default function TutorProfile({ type, state }: ITutorProfile) {
  /* API => get current class list */
  const { data: currentClassResponse } = useGetCurrentClassDetailsQuery();
  const tutorDetails = currentClassResponse?.data?.tutorDetails[0];

  return (
    <Card
      className={`w-full rounded-xl ${state === CLASS_PROGRESS.STARTED ? 'bg-neutral-700' : 'bg-white'} ${type === USER_TYPE.STUDENT ? 'lg:h-[188px] xl:min-h-[196px] 2xl:min-h-[198px] xl:mt-5 mb-5 md:mb-0' : ''}  mt-4 `}
    >
      <div className='grid md:grid-cols-2'>
        {/* tutor profile details */}
        <div className={`flex flex-col px-4 py-3`}>
          {/* {tutors details} */}
          <div className='flex gap-5 md:block'>
            <Avatar
              image={tutorDetails?.profileImage}
              firstName={tutorDetails?.firstName}
              lastName={tutorDetails?.lastName}
              className='object-contain w-16 h-16 border-2 rounded-full md:w-20 md:h-20 border-primary-300'
              initialsClassName='flex flex-col uppercase items-center justify-center object-contain w-16 h-16 border-2 rounded-full bg-primary-500 md:w-14 md:h-14 border-complementary-colors-25'
            />

            <div className='flex flex-col md:mt-4'>
              <h6
                className={`font-poppins ${state === CLASS_PROGRESS.STARTED ? 'text-white' : 'text-secondary-800'} font-bold text-base`}
              >
                {tutorDetails?.firstName
                  ? tutorDetails?.firstName + ' ' + tutorDetails?.lastName
                  : ''}
              </h6>
              <p
                className={`font-poppins font-medium text-base ${state === CLASS_PROGRESS.STARTED ? 'text-neutral-25' : 'text-neutral-400'}`}
              >
                {tutorDetails?.subjects && Object.keys(tutorDetails?.subjects).length > 0
                  ? tutorDetails?.subjects?.find((subject) => subject?.primary === true)?.name ||
                    tutorDetails?.subjects[0]?.name
                  : 'Maths'}
              </p>
            </div>
          </div>
        </div>
        {/* tutor stats details */}
        {tutorDetails?.stats && (
          <div className='flex justify-center gap-1 m-2 md:flex-col md:justify-end md:mr-2'>
            <div
              className={` flex gap-2 justify-center px-3 md:px-0 py-3 items-center rounded-2xl ${state === CLASS_PROGRESS.STARTED ? 'bg-neutral-600' : 'bg-neutral-50'}`}
            >
              <div className='p-2 bg-white rounded-full'>
                <img src={AllClassIconDark} className='w-5 h-5' alt='classes icon' />
              </div>
              <div className='w-16'>
                <h3
                  className={`cap-semibold lg:text-sm ${state === CLASS_PROGRESS.STARTED ? 'text-neutral-100' : 'text-neutral-600'}`}
                >
                  CLASSES
                </h3>
                <h1
                  className={`display-bold lg:text-base ${state === CLASS_PROGRESS.STARTED ? 'text-neutral-100' : 'text-neutral-600'}`}
                >
                  {tutorDetails && (
                    <>
                      {tutorDetails?.stats?.noOfClassesTaken}
                      {'+'}
                    </>
                  )}
                </h1>
              </div>
            </div>
            <div
              className={`flex gap-3 justify-center px-3 md:px-0 py-3 items-center rounded-2xl ${state === CLASS_PROGRESS.STARTED ? 'bg-neutral-600' : 'bg-neutral-50'}`}
            >
              <div className='p-2 bg-white rounded-full'>
                <img src={HomeDarkIcon} className='w-5 h-5' alt='Home icon' />
              </div>
              <div className='w-16'>
                <h3
                  className={`cap-semibold lg:text-sm ${state === CLASS_PROGRESS.STARTED ? 'text-neutral-100' : 'text-neutral-600'}`}
                >
                  STUDENTS
                </h3>
                <h1
                  className={`display-bold lg:text-base ${state === CLASS_PROGRESS.STARTED ? 'text-neutral-100' : 'text-neutral-600'}`}
                >
                  {tutorDetails && (
                    <>
                      {tutorDetails?.stats?.noOfStudentsHandled}
                      {'+'}
                    </>
                  )}
                </h1>
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
