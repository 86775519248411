import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { ErrorResponse } from 'react-router';
import * as Yup from 'yup';

import { useInitialForgetPasscodeMutation } from 'store/apiSlices/forgetPasscode.apiSlice';

import { Button, Input, Toast } from 'components/common';

import { ExclamationCircle } from 'assets/svg';

import { MOBILE_NUMBER_ERROR, APP_DEFAULT_MESSAGES, APP_REGEX } from 'configs';
import { useLocalStorage, useWindowDimensions } from 'hooks';
import { IMobileVerify } from 'types';

export default function LoginMobile({ isMobileValidated }: IMobileVerify) {
  const { isMobileScreen } = useWindowDimensions();
  const inputRef = useRef<HTMLInputElement>(null);
  const { setToLocalStorage, getFromLocalStorage } = useLocalStorage();
  const mobileNumber = getFromLocalStorage('mobileNumber');

  // Otp request call
  const [loginViaOtp, { isLoading }] = useInitialForgetPasscodeMutation();

  const [toastType, setToastType] = useState<'success' | 'error'>();
  const [toastMessage, setToastMessage] = useState<string>('');
  const [showToast, setShowToast] = useState<boolean>(false);

  /* initial Login form validation for mobile number */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { mobileNumber: mobileNumber || '' },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .required(APP_DEFAULT_MESSAGES?.VALIDATION?.MOBILE_NUMBER_REQUIRED)
        .matches(
          APP_REGEX?.VALIDATION?.MOBILE_NUMBER_VALIDATION,
          APP_DEFAULT_MESSAGES?.VALIDATION?.VALID_MOBILE_NO,
        ),
    }),
    onSubmit: (values) => {
      loginViaOtp({ mobileNumber: values.mobileNumber })
        .unwrap()
        .then(() => {
          isMobileValidated(true);
        })
        .catch((error) => {
          setShowToast(true);
          setToastType('error');
          const message = (error as ErrorResponse)?.data?.message || MOBILE_NUMBER_ERROR;
          setToastMessage(message);
        });
    },
  });

  const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('mobileNumber', event.target.value);
    setToLocalStorage('mobileNumber', event.target.value);
  };

  /* initial focus */
  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className='flex-1 h-full'>
      {/* Toast */}
      {showToast && (
        <Toast
          color={toastType}
          mode='light'
          content={toastMessage}
          isCloseable={true}
          isVisible={showToast}
          displayClassName='absolute right-1 top-2'
          duration={3000}
          onVisibilityChange={setShowToast}
        />
      )}
      {/* Mobile Number */}
      <div className='flex flex-col justify-between flex-1 h-full md:justify-around'>
        <div>
          <h1
            className={`md:mb-8 text-xl sm:text-lg md:text-xl lg:text-3xl uppercase md:capitalize text-center display-bold whitespace-nowrap text-neutral-850 mt-4 md:mt-7`}
          >
            {' '}
            Forgot Passcode
          </h1>
          <Input
            ref={inputRef}
            type='number'
            id='mobileNumber'
            name='mobileNumber'
            placeholder='932xxxxxxx'
            label={isMobileScreen ? '' : 'Mobile Number'}
            pattern='[0-9]*'
            autoComplete='on'
            className='w-full py-0 pl-10 pr-10 leading-8'
            isMobilenumber={true}
            value={formik.values.mobileNumber || ''}
            onChange={handleMobileChange}
            maxLength={APP_REGEX?.MAX_LENGTH?.MOBILE_NUMBER_INPUT}
            onBlur={formik.handleBlur}
            isValid={formik.touched.mobileNumber && formik.errors.mobileNumber ? true : false}
            icon={
              formik.errors.mobileNumber && formik.touched.mobileNumber ? ExclamationCircle : ''
            }
          />
          {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
            <p className='mt-2 leading-5 text-semantic-red-500 sm:text-sm body-regular'>
              {formik.errors.mobileNumber}
            </p>
          ) : null}
        </div>
        <div className='my-6'>
          <Button
            type='submit'
            isLoading={isLoading}
            text='Proceed'
            className={`w-full ${formik.values.mobileNumber.length !== 10 ? 'bg-primary-150' : 'bg-primary-500'} focus:ring-0 ring-none outline-none  text-white enabled:hover:bg-primary-500 enabled:hover:text-white mb-2`}
            disabled={formik.values.mobileNumber.length !== 10}
          />
        </div>
      </div>
    </form>
  );
}
