import { useState, useEffect } from 'react';

interface WindowDimensions {
  width: number;
  height: number;
  isMobileScreen: boolean;
  screenResolution: string;
}

export default function useWindowDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobileScreen: window.innerWidth < 767,
    screenResolution: calculateScreenResolution(window.innerHeight),
  });

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 767;
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobileScreen: isMobile,
        screenResolution: calculateScreenResolution(window.innerHeight),
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to calculate screen resolution based on height
  function calculateScreenResolution(height: number): string {
    if (height < 480) {
      return 'SD';
    } else if (height <= 720) {
      return 'HD';
    } else if (height <= 1080) {
      return 'FHD';
    } else {
      return 'QHD';
    }
  }

  return windowDimensions;
}
