export const APP_DEFAULT_MESSAGES = {
  success: {
    OTP_SENT_SUCCESSFULLY: 'OTP sent successfully. Please verify your OTP',
    REGISTRATION_SUCCESSFUL: 'Congratulations, Your registration has been completed successfully',
    PASSCODE_RESET_SUCCESSFUL: 'Congratulations, Your passcode reset successfully',
    PROFILE_UPDATED_SUCCESSFULLY: 'Congratulations, Your profile updated successfully',
    GUARDIAN_ADDED_SUCCESSFULLY: 'Parent added successfully',
    SIBLING_ADDED_SUCCESSFULLY: 'Sibling added successfully',
    DEMO_CLASS_SCHEDULED_SUCCESSFULLY: 'Congratulations, Your demo class scheduled successfully',
    INVITATION_SENT_SUCCESSFULLY: 'Invitation sent successfully',
    ADDED_SUCCESSFULLY: 'Added successfully',
    UPDATED_SUCCESSFULLY: 'Updated successfully',
    DELETED_SUCCESSFULLY: 'Deleted successfully',
    PASSCODE_SENT_SUCCESSFULLY: 'Congratulations, Your passcode updated successfully',
  },
  INFO: {},
  WARNING: {},
  ERROR: {
    CITY_SCHOOL_REQUIRED: 'The `City` and `Search your school` field must be required`',
    NO_SCHOOL_FOUND: "Sorry! We haven't found schools with your search keywords. Please try again",
    SOMETHING_WENT_WRONG: 'Something went wrong. Please try again',
  },
  VALIDATION: {
    FIRST_NAME_REQUIRED: 'First Name is required',
    LAST_NAME_REQUIRED: 'Last Name is required',
    STUDENT_NAME_REQUIRED: "Student's name is required",
    GUARDIAN_NAME_REQUIRED: "Parent's name is required",
    EMAIL_REQUIRED: 'Email address is required',
    VALID_EMAIL: 'Please enter a valid email address',
    MOBILE_NUMBER_REQUIRED: 'Mobile Number is required',
    VALID_MOBILE_NO: 'Please enter a valid Mobile Number',
    OTP_REQUIRED: 'OTP is required',
    VALID_OTP: 'Please enter a valid OTP',
    GENDER_REQUIRED: 'Gender is required',
    RELATIONSHIP_REQUIRED: 'Relationship is required',
    SCHOOL_REQUIRED: 'School is required',
    PASSCODE_REQUIRED: 'Passcode is required',
    VALID_PASSCODE: 'Passcode must be 4 digits only',
    CONFIRM_PASSCODE_REQUIRED: 'Confirm passcode is required',
    COUNTRY_REQUIRED: 'Country is required',
    STATE_REQUIRED: 'State is required',
    CITY_REQUIRED: 'City is required',
    ZIP_REQUIRED: 'Zip Code is required',
    GRADE_REQUIRED: 'Grade is required',
    BOARD_REQUIRED: 'Board is required',
    ENTER_YOUR_SCHOOL: 'Please enter the name of your school',
    MATCH_CONFIRM_PASSCODE: 'Passcode and Confirm Passcode must be matched',
    DEMO_CLASS_DATA_REQUIRED: 'Preference date is required',
    DEMO_CLASS_TIME_REQUIRED: 'Slot timings is required',
    DEMO_CLASS_TOPIC_REQUIRED: 'Topic is required',
    DEMO_CLASS_SUBJECT_REQUIRED: 'Subject is required',
    MOBILE_NUMBER_SAME: 'Please enter a different Mobile Number',
    PASSCODE_LENGTH: 'Passcode must be exactly 4 characters long',
  },
};
