export const EVALUATIONS = {
  NOTEBOOK: 'notebook_evaluations',
  PRACTICE: 'practice_evaluations',
};

export const CONTENT_TYPE = {
  ELEMENT: 'elements',
  COACH_MODULE: 'coach_module',
  EVALUATION: 'evaluation',
};

export const GUIDED_TYPE = {
  MANUAL: 'manual',
  AUTOMATIC: 'automatic',
};

export const ATTEMPT_QUESTIONS = 'Attempt questions now';
export const STAY_ON_GUIDED_SOLUTION = 'Stay on Guided Examples';
export const NO_ANSWER_SEEN = `You haven't viewed any solutions yet. Are you sure you want to Attempt questions?`;
