import { Progress, ProgressProps } from 'flowbite-react';

interface IProgressBar extends ProgressProps {
  type?: string;
  radius?: number;
  strokeWidth?: number;
  strokeColor?: string;
}

export default function ProgressBar({
  type = 'linear',
  radius = 16,
  strokeWidth = 2,
  strokeColor = 'currentColor',
  progress = 0,
  ...props
}: IProgressBar) {
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <>
      {type === 'rounded' ? (
        <svg height={radius * 2} width={radius * 2}>
          <circle
            stroke='#C47E09'
            fill='transparent'
            strokeWidth={strokeWidth}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            stroke={strokeColor}
            fill='transparent'
            strokeWidth={strokeWidth}
            strokeDasharray={`${circumference} ${circumference}`}
            style={{ strokeDashoffset, transform: `rotate(-90deg)`, transformOrigin: '50% 50%' }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
      ) : (
        <Progress progress={0} {...props} />
      )}
    </>
  );
}
