import { Popover } from 'flowbite-react';
import { startCase } from 'lodash';
import React, { useEffect, useState } from 'react';

import {
  IMathProps,
  classFlowStateSteps,
  teachingPhaseState,
} from 'types/dashboard/parentDashboard';

import {
  Dot,
  DownLabelArrow,
  InfoIconBlue,
  RightLabelArrow,
  TickWhite,
  UpLabelArrow,
} from 'assets/svg';
import ShowCurrentStatusInfo from './ShowCurrentStatusInfo';
import { Avatar } from 'components/common';

const TeachingPhase = ({
  studentDetails,
  steps,
  coachDetails,
  isVisible,
  isCoachFirstNodeCompleted,
  classId,
}: IMathProps) => {
  const [stepState, setStepState] = useState<teachingPhaseState>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [evaluationId, setEvaluationId] = useState<string>('');

  useEffect(() => {
    let updatedSteps = [...steps]; // Clone the steps array to avoid direct mutation
    if (coachDetails && coachDetails.length > 0) {
      // Mark all steps as completed if coach details are present
      updatedSteps = updatedSteps.map((step) => ({
        ...step,
        status: 'completed',
      }));
    } else if (isCoachFirstNodeCompleted) {
      // Mark all steps as completed if the first node completed by the coach is true
      updatedSteps = updatedSteps.map((step) => ({
        ...step,
        status: 'completed',
      }));
    } else {
      const lastCompletedIndex = steps.reduce(
        (acc, step, index) => (step.status === 'completed' ? index : acc),
        -1,
      );

      const currentInProgressIndex = steps?.reduce(
        (acc, step, index) => (step.status === 'inProgress' ? index : acc),
        -1,
      );

      updatedSteps = steps.map((step, index) => {
        if (index <= lastCompletedIndex || index < currentInProgressIndex) {
          return { ...step, status: 'completed' };
        } else if (index === currentInProgressIndex) {
          return { ...step, status: 'inProgress' };
        }
        return step;
      });
    }

    const createSteps = updatedSteps.map((step) => ({
      id: step.sequence,
      sequence: step.sequence,
      description: step.name,
      completed: step.status === 'completed',
      inProgress: step.status === 'inProgress',
      notStarted: step.status === 'notStarted',
      type: step?.type,
      evaluationId: step?.evaluationId || '',
    }));

    setStepState(createSteps);
  }, [steps, coachDetails, isCoachFirstNodeCompleted]);

  const measureWidthOfDesciption = (description: string) => {
    const element = document.createElement('span');
    element.textContent = description;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    document.body.appendChild(element);

    const width = element.getBoundingClientRect().width;

    //after calculation removing the element from body
    document.body.removeChild(element);

    // we need to check if desciption width is not greater than 5 rem then, no need to show popover
    return width;
  };

  return (
    <>
      {/* {desktop view} */}
      <div className='self-center hidden w-full xl:flex xl:flex-1 xl:items-center'>
        <div className='flex items-center flex-1 ml-2'>
          {stepState?.map(
            (
              {
                id,
                sequence,
                description,
                completed,
                inProgress,
                notStarted,
                type,
                evaluationId,
              }: classFlowStateSteps,
              idx,
            ) => {
              const width = measureWidthOfDesciption(description);
              return (
                <React.Fragment key={id}>
                  <div
                    key={id}
                    className='relative flex flex-col items-center justify-start flex-1'
                  >
                    {/* Class flow teach phase upper label data */}
                    <div
                      className={`${sequence % 2 !== 0 ? 'opacity-100' : 'opacity-0'} w-full flex flex-col justify-center items-start`}
                    >
                      {width > 80 ? (
                        <Popover
                          trigger='hover'
                          placement='top-end'
                          content={
                            <div className='bg-white flex items-center gap-2 text-primary-500 text-xs font-[600] font-poppins px-3 py-1 rounded-md'>
                              {startCase(description)}
                              {type === 'evaluation' &&
                                evaluationId &&
                                description !== 'poll_evaluations' &&
                                (completed || inProgress) && (
                                  <img
                                    className='object-contain w-4 h-4 cursor-pointer'
                                    src={InfoIconBlue}
                                    width={12}
                                    alt='Info'
                                    onClick={() => {
                                      setOpenModal(true);
                                      setEvaluationId(evaluationId);
                                    }}
                                  />
                                )}
                            </div>
                          }
                        >
                          <div className='bg-white text-primary-500 text-xs font-semibold font-poppins px-3 py-1 rounded-md max-w-[5rem] overflow-hidden whitespace-nowrap overflow-ellipsis'>
                            {startCase(description)}
                          </div>
                        </Popover>
                      ) : (
                        <div className='bg-white text-primary-500 text-xs font-[600] font-poppins px-3 py-1 rounded-md'>
                          {startCase(description)}
                        </div>
                      )}
                      <img className={`ml-2 object-contain`} src={UpLabelArrow} alt='image' />
                    </div>
                    {/* Class flow teach phase middle data nodes */}
                    <div className='relative flex items-center w-full'>
                      <div
                        className={`rounded-full ${completed ? 'bg-primary-500 border-primary-500' : 'bg-neutral-500 border-neutral-500'}}`}
                      >
                        {inProgress && (
                          <div className='w-full h-full'>
                            <Avatar
                              firstName={studentDetails?.firstName}
                              lastName={studentDetails?.lastName}
                              image={studentDetails?.profileImage}
                              className='object-cover w-full h-full rounded-full animate-pulse'
                              initialsClassName={`h-full p-2 rounded-full ${isVisible ? 'bg-red-500 border-red-500' : 'opacity-0'} text-white flex items-center justify-center`}
                              initialsFontSizeClassName='text-[10px] xl:text-xs'
                            />
                          </div>
                        )}
                        {completed && (
                          <div className='flex items-center h-full p-1 px-2'>
                            <img
                              className='object-contain w-5 h-5 rounded-full'
                              src={TickWhite}
                              alt='image'
                            />
                          </div>
                        )}
                        {notStarted && (
                          <div className='flex items-center h-full p-1 px-2'>
                            <img
                              className='w-5 h-5 rounded-full object-fit'
                              src={Dot}
                              alt='image'
                            />
                          </div>
                        )}
                      </div>
                      <div className='w-full'>
                        <div
                          className={`h-[8px] ${completed ? 'bg-primary-500' : 'bg-neutral-200'} ${stepState?.length - 1 === idx && 'rounded-tr-lg'}`}
                        ></div>
                      </div>
                    </div>
                    {/* Class flow teach phase lower label data */}
                    <div
                      className={`${sequence % 2 === 0 ? 'opacity-100' : 'opacity-0'}  w-full flex flex-col justify-center items-start`}
                    >
                      <img className={`ml-2 object-contain`} src={DownLabelArrow} alt='image' />
                      <Popover
                        trigger='hover'
                        placement='bottom-start'
                        content={
                          <div className='bg-white flex items-center gap-2 text-primary-500 text-xs font-[600] font-poppins px-3 py-1 rounded-md'>
                            {startCase(description)}
                            {type === 'evaluation' &&
                              evaluationId &&
                              description !== 'poll_evaluations' &&
                              (completed || inProgress) && (
                                <img
                                  className='object-contain w-4 h-4 cursor-pointer'
                                  src={InfoIconBlue}
                                  width={12}
                                  alt='Info'
                                  onClick={() => {
                                    setOpenModal(true);
                                    setEvaluationId(evaluationId);
                                  }}
                                />
                              )}
                          </div>
                        }
                      >
                        <div className='bg-white text-primary-500 text-xs font-[600] font-poppins px-3 py-1 rounded-md max-w-[5rem] overflow-hidden whitespace-nowrap overflow-ellipsis'>
                          {startCase(description)}
                        </div>
                      </Popover>
                    </div>
                  </div>
                </React.Fragment>
              );
            },
          )}
        </div>
      </div>

      {/* {mobile view} */}
      <div className='relative flex h-full xl:hidden gap-x-1'>
        <div className='flex flex-col bg-[#09D6CE42] w-[10rem] rounded-xl pt-14'>
          {stepState.map(
            ({
              id,
              description,
              completed,
              inProgress,
              notStarted,
              type,
              evaluationId,
            }: classFlowStateSteps) => {
              const width = measureWidthOfDesciption(description);
              return (
                <div key={id} className='relative'>
                  {/* class flow teach phase nodes */}
                  <div className='flex flex-col items-center'>
                    <div
                      className={`rounded-full ${completed ? 'bg-primary-500 border-primary-500' : 'bg-neutral-500 border-neutral-500'} ${inProgress ? 'w-[30px] h-[30px]' : 'w-[20px] h-[20px]'}`}
                    >
                      {inProgress && (
                        <div className=''>
                          <Avatar
                            firstName={studentDetails?.firstName}
                            lastName={studentDetails?.lastName}
                            image={studentDetails?.profileImage}
                            className='object-contain border-2 rounded-full border-primary-500 animate-pulse'
                            initialsClassName={`w-[30px] h-[30px] rounded-full ${isVisible ? 'bg-red-500 border-red-500' : 'opacity-0'}  text-white flex items-center justify-center`}
                            initialsFontSizeClassName='text-[10px]'
                          />
                        </div>
                      )}
                      {completed && (
                        <div className='text-[5px] h-full grid place-items-center'>
                          <img
                            className='object-contain w-4 h-4 border-2 rounded-full border-primary-500'
                            src={TickWhite}
                            alt='image'
                          />
                        </div>
                      )}
                      {notStarted && (
                        <div className='text-[5px] h-full grid place-items-center'>
                          <img className='object-contain rounded-full' src={Dot} alt='image' />
                        </div>
                      )}
                    </div>
                    <div className='h-[3rem]'>
                      <div
                        className={`h-full w-[6px] ${completed ? 'bg-primary-500' : 'bg-neutral-200'}`}
                      ></div>
                    </div>
                  </div>
                  {/* Class flow teach phase description */}
                  <div className={`flex absolute left-[90px] top-[0px]`}>
                    <img className='object-contain' src={RightLabelArrow} alt='image' />
                    {width > 140 ? (
                      <Popover
                        trigger='hover'
                        placement='top-end'
                        content={
                          <div className='bg-neutral-50 flex items-center gap-1 text-primary-500 font-semibold text-[10px] font-poppins px-3 py-1 rounded-md'>
                            {startCase(description)}
                            {type === 'evaluation' &&
                              evaluationId &&
                              description !== 'poll_evaluations' &&
                              (completed || inProgress) && (
                                <img
                                  className='object-contain w-4 h-4 cursor-pointer'
                                  src={InfoIconBlue}
                                  width={12}
                                  alt='Info'
                                  onClick={() => {
                                    setOpenModal(true);
                                    setEvaluationId(evaluationId);
                                  }}
                                />
                              )}
                          </div>
                        }
                      >
                        <div className='bg-neutral-50 flex items-center gap-2 text-primary-500 font-semibold text-[10px] font-poppins px-3 w-[calc(100vw-10rem)] min-w-[6.5rem] p-1 rounded-md truncate'>
                          {startCase(description)}
                          {type === 'evaluation' &&
                            evaluationId &&
                            description !== 'poll_evaluations' &&
                            (completed || inProgress) && (
                              <img
                                className='object-contain w-4 h-4 cursor-pointer'
                                src={InfoIconBlue}
                                width={12}
                                alt='Info'
                                onClick={() => {
                                  setOpenModal(true);
                                  setEvaluationId(evaluationId);
                                }}
                              />
                            )}
                        </div>
                      </Popover>
                    ) : (
                      <div className='bg-neutral-50 text-primary-500 font-semibold text-[10px]  w-[calc(100vw-10rem)] font-poppins px-3 py-1 rounded-md min-w-[6.5rem]'>
                        {startCase(description)}
                      </div>
                    )}
                  </div>
                </div>
              );
            },
          )}
        </div>
        <div className='flex-1'>
          <div className=''>
            {/* Teach Phase Heading */}
            <p className='text-neutral-900 font-semibold font-poppins text-[10px] xl:text-xs'>
              {`Teach Phase`}
            </p>
          </div>
        </div>
      </div>

      {/* Modal for displaying class flow  */}
      {openModal && evaluationId && (
        <ShowCurrentStatusInfo
          studentId={studentDetails?.id?.toString()}
          teachEvaluationId={evaluationId}
          openModal={openModal}
          setOpenModal={setOpenModal}
          classId={classId}
        />
      )}
    </>
  );
};

export default TeachingPhase;
