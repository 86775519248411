import { Dropdown } from 'flowbite-react';
import classNames from 'classnames';

import { ActiveMic, ActiveVideo, ArrowIcon, Speaker, TickWhite } from 'assets/svg';
import { IMediaSelectDropdownProps } from 'types';
import { useMediaDevices } from 'hooks/inClass/useMediaDevices';

const MediaSelectDropdown = ({ mediaDevice, className }: IMediaSelectDropdownProps) => {
  // Getting meeting from useDyteMeeting
  const { audioDevices, videoDevices, speakerDevices, currentSelectedDevice, handleMediaSelect } =
    useMediaDevices();

  return (
    <Dropdown
      inline
      placement='top'
      renderTrigger={() => <img src={ArrowIcon} />}
      label={undefined}
      className={classNames(' bg-primary-800 shadow-2xl ', className)}
      theme={{
        arrowIcon: 'h-4 w-4',
        content: 'focus:outline-none',
        floating: {
          item: {
            base: 'flex w-full cursor-pointer items-center justify-start px-4 py-2 hover:bg-primary-700 focus:outline-none',
          },
          style: {
            dark: 'bg-gray-850 text-white ',
            light: 'bg-white text-gray-900',
            auto: ' bg-white text-gray-900',
          },
          divider: 'h-px bg-primary-700',
          base: 'z-50 w-fit divide-y divide-gray-100 rounded shadow focus:outline-none',
        },
      }}
    >
      {mediaDevice === 'video' ? (
        <>
          <Dropdown.Header className='flex items-center gap-2 py-3 bg-primary-750'>
            <img
              src={ActiveVideo}
              alt='video'
              className='w-5 h-5 p-1 bg-white rounded-full 2xl:w-6 2xl:h-6'
            />
            <span className='block text-base font-semibold text-white'>Select a Video Device</span>
          </Dropdown.Header>
          {videoDevices.length > 0 ? (
            videoDevices.map((device) => (
              <Dropdown.Item key={device.deviceId} onClick={() => handleMediaSelect(device)}>
                <div className='flex items-center gap-2'>
                  {currentSelectedDevice.video &&
                    device?.deviceId === currentSelectedDevice.video && (
                      <img src={TickWhite} alt='tick' className='w-4 h-4 2xl:w-5 2xl:h-5' />
                    )}
                  <p
                    className={`text-sm ${currentSelectedDevice.video && device?.deviceId === currentSelectedDevice.video ? 'font-bold text-semantic-green-400' : 'pl-7 text-white'}`}
                  >
                    {device?.label}
                  </p>
                </div>
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item>
              <p className='text-[14px] text-white'>No video devices found</p>
            </Dropdown.Item>
          )}
        </>
      ) : mediaDevice === 'audio' ? (
        <div>
          <Dropdown.Header className='flex items-center gap-2 py-3 bg-primary-750'>
            <img
              src={ActiveMic}
              alt='mic'
              className='w-5 h-5 p-1 bg-white rounded-full 2xl:w-6 2xl:h-6'
            />
            <span className='block text-base font-semibold text-white'>Select an Audio Device</span>
          </Dropdown.Header>
          {audioDevices.length > 0 ? (
            audioDevices.map((device) => (
              <Dropdown.Item key={device.deviceId} onClick={() => handleMediaSelect(device)}>
                <div className='flex items-center gap-2'>
                  {currentSelectedDevice.audio &&
                    device?.deviceId === currentSelectedDevice.audio && (
                      <img src={TickWhite} alt='tick' className='w-4 h-4 2xl:w-5 2xl:h-5' />
                    )}
                  <p
                    className={`text-sm ${currentSelectedDevice.audio && device?.deviceId === currentSelectedDevice.audio ? 'font-bold text-semantic-green-400' : 'pl-7 text-white'}`}
                  >
                    {device?.label}
                  </p>
                </div>
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item>
              <p className='text-[14px] text-white'>No audio devices found</p>
            </Dropdown.Item>
          )}
          <Dropdown.Header className='flex items-center gap-2 py-3 bg-primary-750'>
            <img
              src={Speaker}
              alt='speaker'
              className='w-5 h-5 p-1 bg-white rounded-full 2xl:w-6 2xl:h-6'
            />
            <span className='block text-base font-semibold text-white'>
              Select a Speaker Device
            </span>
          </Dropdown.Header>
          {speakerDevices.length > 0 ? (
            speakerDevices.map((device) => (
              <Dropdown.Item key={device.deviceId} onClick={() => handleMediaSelect(device)}>
                <div className='flex items-center gap-2'>
                  {currentSelectedDevice.speaker &&
                    device?.deviceId === currentSelectedDevice.speaker && (
                      <img src={TickWhite} alt='tick' className='w-4 h-4 2xl:w-5 2xl:h-5' />
                    )}
                  <p
                    className={`text-sm ${currentSelectedDevice.speaker && device?.deviceId === currentSelectedDevice.speaker ? 'font-bold text-semantic-green-400' : 'pl-7 text-white'}`}
                  >
                    {device?.label}
                  </p>
                </div>
              </Dropdown.Item>
            ))
          ) : (
            <Dropdown.Item>
              <p className='text-[14px] text-white'>No speaker devices found</p>
            </Dropdown.Item>
          )}
        </div>
      ) : (
        ''
      )}
    </Dropdown>
  );
};

export default MediaSelectDropdown;
