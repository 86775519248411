import { Source } from 'configs/morphcast';
import { MORPHCAST } from '../config';

// Singleton
let aiSdkInstance;
let source;

async function downloadAiSdk() {
  if (globalThis.CY) {
    throw new Error('AI-SDK has already been downloaded.');
  }
}

async function initAiSdk() {
  if (aiSdkInstance) {
    throw new Error('An instance of the AI-SDK is already running.');
  }
  source = new Source();
  const config = {
    initialWaitMs: parseInt(MORPHCAST?.INITIAL_WAIT) || 1000,
    periodMs: parseInt(MORPHCAST?.ATTENTION_INTERVAL) || 1000,
  };
  const minFaceSizeAt640 = 25;
  aiSdkInstance = await globalThis.CY.loader()
    .licenseKey(MORPHCAST?.API_KEY)
    .source(source)
    .maxInputFrameSize(parseInt(MORPHCAST?.MAX_INPUT_FRAME_SIZE) || 1024)
    .addModule(globalThis.CY.modules().FACE_POSE.name, {})
    .addModule(globalThis.CY.modules().FACE_EMOTION.name, {
      enableBalancer: false,
      smoothness: 0.5,
    })
    .addModule(globalThis.CY.modules().FACE_DETECTOR.name, {
      maxInputFrameSize: parseInt(MORPHCAST?.MAX_INPUT_FRAME_SIZE) || 1024,
      minFaceSizeAt640,
    })
    .addModule(globalThis.CY.modules().FACE_AROUSAL_VALENCE.name, {
      smoothness: 0.7,
    })
    .addModule(globalThis.CY.modules().FACE_ATTENTION.name, {})
    .addModule(globalThis.CY.modules().DATA_AGGREGATOR.name, config)
    .load();
}

/**
 * Loads the MorphCast SDK, only the first time, then returns the controls for the start / stop
 *
 * @returns {Promise<{getModule: *, stop: *, CY: *, start: *, source: *}>}
 */
const getAiSdkControls = async () => {
  if (globalThis.CY === undefined) {
    await downloadAiSdk();
  }
  if (aiSdkInstance === undefined) {
    await initAiSdk();
  }

  const { start, stop, getModule } = aiSdkInstance;
  return { start, stop, getModule, source, CY: globalThis.CY };
};

export default getAiSdkControls;
