import { SocketContext } from 'contexts';
import { useEffect, useState } from 'react';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { Socket } from 'socket.io-client';
import { connectToSocket } from 'utils/socket';

type Props = {
  children: React.ReactNode;
  userType: 'student' | 'tutor' | 'guardian';
  userId: string;
  classId: string;
};

const SocketProvider = ({ children, userType, userId, classId }: Props) => {
  const [socket, setSocket] = useState<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null);

  useEffect(() => {
    if (!socket) {
      const socket = connectToSocket({ userId, userType, classId });
      setSocket(socket);
    }
    return () => {
      if (socket) {
        socket?.removeAllListeners();
        socket?.close();
      }
    };
  }, [userType, classId, userId, socket]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
