import { MediaPermissionIcon } from 'assets/svg';

export default function MediaPermission() {
  return (
    <div className='flex w-full'>
      {/* Image for the permission */}
      <div className='w-1/2'>
        <img src={MediaPermissionIcon} alt='permission' className='object-contain w-full h-full' />
      </div>

      {/* Text */}
      <div className='flex flex-col w-1/2 mt-8'>
        <h1 className='mb-4 text-xl display-bold lg:text-2xl'>
          Your camera access for class is not enabled
        </h1>
        <div className='gap-2 text-lg text-gray-700 list-disc body-medium'>
          <div className='flex items-start gap-2 mb-2 list-none'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='w-6 h-6 text-gray-500 fill-current'
              viewBox='0 -960 960 960'
            >
              <path d='M700-130q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm-.235-60Q733-190 756.5-213.265q23.5-23.264 23.5-56.5Q780-303 756.735-326.5q-23.264-23.5-56.5-23.5Q667-350 643.5-326.735q-23.5 23.264-23.5 56.5Q620-237 643.265-213.5q23.264 23.5 56.5 23.5ZM120-240v-60h360v60H120Zm140-310q-58 0-99-41t-41-99q0-58 41-99t99-41q58 0 99 41t41 99q0 58-41 99t-99 41Zm-.235-60Q293-610 316.5-633.265q23.5-23.264 23.5-56.5Q340-723 316.735-746.5q-23.264-23.5-56.5-23.5Q227-770 203.5-746.735q-23.5 23.264-23.5 56.5Q180-657 203.265-633.5q23.264 23.5 56.5 23.5ZM480-660v-60h360v60H480Z' />
            </svg>
          </div>
          <li className='mb-2 list-none text-start'>
            Click the page info icon located in your browser's address bar
          </li>
          <li className='mb-2 list-none'>Enable your camera, microphone and refresh the page</li>
        </div>
      </div>
    </div>
  );
}
