import { SocketContext } from 'contexts';
import { useContext, useEffect } from 'react';

export default function useSocketSubscribe<T>(eventName: string, eventHandler: (data: T) => void) {
  const socket = useContext(SocketContext);

  useEffect(() => {
    socket?.on(eventName, eventHandler);

    return () => {
      socket?.off(eventName, eventHandler);
    };
  }, [eventHandler, eventName, socket]);
}
