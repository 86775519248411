import { useEffect, useState } from 'react';

import { OnboardingNav } from 'components/common/Navbar';
import { reset } from 'store/slice/studentRegister.slice';

import { HeroSection, LoginVerification } from 'components/screens';
import { useAppDispatch } from 'hooks/store';

import { useLocalStorage, useWindowDimensions } from 'hooks';

export default function Login() {
  const { removeFromLocalStorage } = useLocalStorage();
  const { isMobileScreen } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const [IsMobileVerified, setIsMobileVerified] = useState<boolean>(false);

  useEffect(() => {
    removeFromLocalStorage('demoClassBooked');
    removeFromLocalStorage('tempAuthToken');
    removeFromLocalStorage('tempToken');
    removeFromLocalStorage('loginToken');
    /* reset the persisted state */
    dispatch(reset());
  }, [removeFromLocalStorage, dispatch]);
  return (
    <div className='flex flex-col flex-1 h-full min-h-screen'>
      <OnboardingNav />
      <section className='relative grid flex-1 h-full grid-cols-1 pt-5 bg-white lg:grid-cols-2 lg:mx-0 sm:pt-0'>
        {/* Hero Section */}
        {(!IsMobileVerified && isMobileScreen) || !isMobileScreen ? (
          <div
            className={`order-first lg:order-last block rounded-b-[3rem] lg:sticky lg:top-0 lg:h-full`}
          >
            <HeroSection />
          </div>
        ) : null}

        <div className='flex justify-center h-full p-1 bg-white'>
          <div className='w-full h-full flex flex-col justify-start mx-2 md:w-[70%] lg:w-[90%]'>
            {/* Header Section */}
            <div className='flex-1 px-2'>
              <LoginVerification
                isMobileVerified={(IsMobileVerified) => setIsMobileVerified(IsMobileVerified)}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
