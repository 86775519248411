import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { Bell } from 'assets/svg';
import { IClassStartedProps } from 'types';

const ClassStarted = ({ startTime }: IClassStartedProps) => {
  const [timeDifference, setTimeDifference] = useState<string>('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const diffInMilliseconds = now.getTime() - startTime.getTime();
      const diffInMinutes = Math.floor(diffInMilliseconds / 60000);
      const hours = Math.floor(diffInMinutes / 60);
      const minutes = diffInMinutes % 60;
      if (hours > 0) {
        setTimeDifference(`${hours} hrs ${minutes} min`);
      } else {
        setTimeDifference(`${minutes} min`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <motion.div
      className='px-4 py-2 text-xs 2xl:text-sm text-center text-white bg-[#2A6041] rounded-full flex gap-2 items-center justify-center'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3, delay: 1 }}
    >
      <img src={Bell} className='size-3 2xl:size-5' alt='alert' />
      Class Started {timeDifference} ago
    </motion.div>
  );
};

export default ClassStarted;
