import { BouncingDotsLoader, Footer, Header, SocketProvider } from 'components/common';
import {
  ClassOver,
  DashboardNav,
  LearningMaterials,
  // PotentialCheck,
  OngoingClass,
  UpcomingClass,
} from 'components/screens';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import {
  useGetCurrentClassDetailsOfStudentQuery,
  useGetSiblingParentListDetailsQuery,
} from 'store/apiSlices/parentDashboard.apiSlice';

import { UprioHeaderLogo } from 'assets/svg';
import { ICurrentClassDetails } from 'types/democlass';

import { CLASS_PROGRESS, CLASS_STATUS, USER_TYPE } from 'configs';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

export default function DashboardParent() {
  const [stateOfClass, setStateOfClass] = useState<string>('');
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [isClassEnds, setIsClassEnds] = useState<boolean>(false);
  const [bufferTime, setBufferTime] = useState<number>(0);
  // const [isAPILoaded, setIsAPILoaded] = useState<boolean>(true);

  const { studentId = '' } = useParams();

  // parent information
  const { currentData: userData, isLoading: isUserDataIsLoading } = useGetUserDataQuery();

  // Get Parent Siblings Info
  const { data: parentSiblingsData, isLoading: isParentSibilingIsloading } =
    useGetSiblingParentListDetailsQuery(undefined);
  const studentDetails = parentSiblingsData?.data?.find(
    (child) => child?.members?.id === Number(studentId),
  );
  const studentName = studentDetails?.members?.firstName || '';

  // get current class of student selected
  const { data: currentClassData, isLoading: isCurrentClassDataIsLoading } =
    useGetCurrentClassDetailsOfStudentQuery({
      studentId: studentId && studentId,
    });
  const classId = currentClassData?.data?.id?.toString();

  useEffect(() => {
    const calculateTimeLeft = () => {
      const serverTime = moment();
      const classStartDateTime = moment(currentClassData?.data?.meetingStartTime);
      const classEndDateTime = moment(currentClassData?.data?.meetingEndTime);

      const classEndBufferedTime = classEndDateTime.clone().add(bufferTime, 'minutes');

      const secondsDifferenceInClassStarts = classStartDateTime.diff(serverTime, 'seconds');
      const secondsDifferenceInClassEnds = classEndBufferedTime.diff(serverTime, 'seconds');

      let newState = '';
      let newRemainingTime = 0;
      if (secondsDifferenceInClassStarts > 0 && secondsDifferenceInClassStarts <= 15 * 60) {
        newState = CLASS_PROGRESS.NOT_STARTED;
        newRemainingTime = Math.ceil(secondsDifferenceInClassStarts / 60);
      } else if (secondsDifferenceInClassStarts <= 0 && secondsDifferenceInClassEnds > 0) {
        newState = CLASS_PROGRESS.STARTED;
        newRemainingTime = Math.ceil(secondsDifferenceInClassEnds / 60);
      } else if (secondsDifferenceInClassEnds <= 0) {
        newState = CLASS_PROGRESS.END;
        newRemainingTime = 0;
      } else if (secondsDifferenceInClassStarts > 15 * 60) {
        newState = CLASS_PROGRESS.NOT_STARTED;
        newRemainingTime = Math.ceil(secondsDifferenceInClassStarts / 60);
      }
      setStateOfClass(newState);
      setRemainingTime(newRemainingTime);
    };
    calculateTimeLeft();
    let intervalId: NodeJS.Timeout;
    if (currentClassData?.data?.classStatus === CLASS_STATUS.ENDED) {
      setStateOfClass(CLASS_PROGRESS.END);
    } else if (currentClassData) {
      intervalId = setInterval(calculateTimeLeft, 1000);
    }

    return () => clearInterval(intervalId);
  }, [bufferTime, currentClassData]);

  const isLoading = isCurrentClassDataIsLoading || isParentSibilingIsloading || isUserDataIsLoading;

  return (
    <div className='flex flex-col flex-grow min-h-screen bg-neutral-25'>
      <DashboardNav type={USER_TYPE?.GUARDIAN} parentSiblingsData={parentSiblingsData} />
      {isLoading ? (
        <>
          <div className='flex items-center justify-center flex-1 mx-2 xl:mx-6'>
            <BouncingDotsLoader />
          </div>
        </>
      ) : (
        <div className='bg-neutral-50'>
          <Header
            parentName={`${userData?.data?.firstName} ${userData?.data?.lastName}`}
            studentName={studentName}
            symbol={'👋'}
          />
          <div className='mx-2 xl:mx-10 mb-[4rem]'>
            {classId && (
              <>
                <div className='flex flex-col items-start py-5 gap-y-2 xl:justify-start xl:flex-row xl:gap-x-2'>
                  <img className='h-6 w-23 xl:h-8 xl:w-28' src={UprioHeaderLogo} alt='heading' />
                  <span className='text-xl font-bold tracking-widest text-start xl:text-2xl font-sora text-neutral-800'>
                    CLASS DETAILS & SUMMARY
                  </span>
                </div>
                {/* {as per state of class we can conditionally render ui} */}
                {stateOfClass === CLASS_PROGRESS.NOT_STARTED && (
                  <UpcomingClass
                    classData={currentClassData}
                    stateOfClass={stateOfClass}
                    remainingTime={remainingTime}
                  />
                )}
                {/* if class status coming in class flow api is ended then we have to show class over screen otherwise give 60 mins of buffer */}
                {stateOfClass === CLASS_PROGRESS.STARTED && !isClassEnds && (
                  <SocketProvider userId={studentId} userType='guardian' classId={classId}>
                    <OngoingClass
                      classData={currentClassData}
                      stateOfClass={stateOfClass}
                      remainingTime={remainingTime}
                      isClassEnds={isClassEnds}
                      setIsClassEnds={setIsClassEnds}
                      setBufferTime={setBufferTime}
                    />
                  </SocketProvider>
                )}
                {stateOfClass === CLASS_PROGRESS.END && (
                  <ClassOver
                    classDetails={currentClassData}
                    classId={classId}
                    studentId={studentId}
                    userType={USER_TYPE.GUARDIAN}
                  />
                )}
              </>
            )}

            <div className=''>
              <LearningMaterials
                learningMaterial={currentClassData?.data as ICurrentClassDetails}
              />
            </div>
            {/* <PotentialCheck /> */}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
