import { Radio as FlowbiteRadio, Label, RadioProps } from 'flowbite-react';
import classNames from 'classnames';

interface IRadioProps extends RadioProps {
  label?: string;
  id: string;
  isTimeSlotRadio?: boolean;
  lableClassName?: string;
  children?: React.ReactNode;
}

export default function Radio({
  label,
  id,
  className,
  lableClassName,
  isTimeSlotRadio,
  checked = false,
  children,
  ...props
}: IRadioProps) {
  return (
    <div>
      <div
        className={classNames(
          'flex items-center p-2',
          isTimeSlotRadio
            ? `border-1 ring-1 rounded-xl lg:rounded-full ${checked ? 'border-black ring-black bg-[#393939]' : 'border-gray-400 ring-gray-400'}`
            : '',
        )}
      >
        <FlowbiteRadio
          id={id}
          checked={checked}
          className={classNames(
            'w-5 h-5 text-neutral-800 bg-white border-2 focus:ring-0',
            checked ? 'border-black ring-0' : 'border-gray-400 ring-gray-400',
            className,
          )}
          {...props}
        />
        {children ? (
          children
        ) : (
          <Label
            htmlFor={id}
            className={classNames(
              `mx-3 body-medium lg:text-base text-neutral-800 ${isTimeSlotRadio ? 'text-[10px] sm:text-xs' : 'text-base'}  xs:whitespace-nowrap`,
              lableClassName,
            )}
          >
            {label}
          </Label>
        )}
      </div>
    </div>
  );
}
