import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { ErrorResponse, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { useRequestOTPMutation } from 'store/apiSlices/register.apiSlice';
import {
  selectMobileNumber,
  setMobileRequest,
  setRequestOTPResponseData,
} from 'store/slice/studentRegister.slice';

import { ExclamationCircle } from 'assets/svg';
import { Button, Input } from 'components/common';
import { useAppDispatch, useAppSelector } from 'hooks/store';

import {
  APP_DEFAULT_MESSAGES,
  APP_REGEX,
  COMMON_ERROR,
  REGISTER_HEADER,
  ROUTES,
  TOKENS_TO_REMOVE,
} from 'configs';
import { useLocalStorage, useWindowDimensions } from 'hooks';

export default function Mobile() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setToLocalStorage, removeFromLocalStorage } = useLocalStorage();
  const mobileNumber = useAppSelector(selectMobileNumber);
  const { isMobileScreen } = useWindowDimensions();
  const [requestOTP, { isLoading }] = useRequestOTPMutation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [, setIsMobileValid] = useState<boolean>(true);

  /* initial form validation for mobile number */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { mobileNumber: mobileNumber || '' },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .required(APP_DEFAULT_MESSAGES?.VALIDATION?.MOBILE_NUMBER_REQUIRED)
        .matches(
          APP_REGEX?.VALIDATION?.MOBILE_NUMBER_VALIDATION,
          APP_DEFAULT_MESSAGES?.VALIDATION?.VALID_MOBILE_NO,
        ),
    }),
    onSubmit: (values) => {
      /* request otp using Rtk Query api slices */
      requestOTP({ mobileNumber: values.mobileNumber })
        .unwrap()
        .then((payload) => {
          dispatch(setRequestOTPResponseData(payload));
          setToLocalStorage('mobileNumber', values?.mobileNumber);
          dispatch(
            setMobileRequest({
              mobileNumber: values?.mobileNumber,
              mobileNumberVerifiedState: true,
            }),
          );
          // Removing already existing token for the registration flow
          TOKENS_TO_REMOVE.forEach(removeFromLocalStorage);
        })
        .catch((error) => {
          setIsMobileValid(false);
          const errorMessage = (error as ErrorResponse)?.data?.message;
          toast.error(errorMessage || COMMON_ERROR);
        });
    },
  });

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className='flex-1 h-full my-5'>
      <div className='flex flex-col justify-between flex-1 h-full md:justify-around'>
        {/* Mobile Number */}
        <div className=''>
          {/* Header Section */}
          <h1
            className={`md:mb-8 text-xl sm:text-lg md:text-xl lg:text-3xl uppercase md:capitalize text-center display-bold whitespace-nowrap text-neutral-850 mt-4 md:mt-7`}
          >
            {REGISTER_HEADER}
          </h1>
          <Input
            ref={inputRef}
            type='text'
            id='mobileNumber'
            name='mobileNumber'
            pattern='[0-9]*'
            label={isMobileScreen ? '' : 'Mobile Number'}
            className='pl-14'
            autoComplete='on'
            placeholder='932xxxxxxx'
            isMobilenumber={true}
            maxLength={APP_REGEX?.MAX_LENGTH?.MOBILE_NUMBER_INPUT}
            value={formik.values.mobileNumber || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isValid={formik.touched.mobileNumber && formik.errors.mobileNumber ? true : false}
            icon={
              formik.errors.mobileNumber && formik.touched.mobileNumber ? ExclamationCircle : ''
            }
          />

          {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
            <p className='mt-2 leading-5 text-semantic-red-500 sm:text-sm body-regular'>
              {formik.errors.mobileNumber}
            </p>
          ) : null}
        </div>

        <div>
          <div>
            <Button
              type='submit'
              isLoading={isLoading}
              text={'Proceed with OTP'}
              disabled={formik.values.mobileNumber.length !== 10}
              className={`w-full bg-primary-500 focus:ring-0 ring-none outline-none  text-white enabled:hover:bg-primary-500 enabled:hover:text-white  `}
            />
            <div>
              {/* //TODO: INTEGRATE THE WHATSAPP FLOW */}
              {/* <p className='my-6 text-xs leading-5 text-center body-semibold lg:text-base text-primary-800'>
                or
              </p>
              <h6
                className={`flex gap-2 cursor-pointer items-center justify-center whitespace-nowrap text-sm font-sora font-bold lg:text-lg  text-primary-800`}
              >
                <img src={WhatsApp} alt='whatsapp' className='w-4 h-4' />
                Book a Demo Class Via WhatsApp
              </h6>
               */}
              <h6 className={`text-neutral-500 body-medium text-sm lg:text-base text-center mt-6`}>
                Already have an account?{' '}
                <span
                  className='leading-5 underline cursor-pointer text-primary-500 lg:text-sm body-semibold'
                  onClick={() => navigate(ROUTES.LOGIN)}
                >
                  Login
                </span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
