import { CompassLight, rightArrow, ScienceIcon, SubjectIconWhite } from 'assets/svg';
import { SUBJECTS } from 'configs';
import { Card } from 'components/common';
import moment from 'moment';
import React from 'react';
import { Common } from 'configs/common';

interface TopicCardProps {
  time: { startTime: string; endTime: string };
  lectureName: string;
  onClick: (classId: number) => void;
  selectedClassId: number;
  cardClassId: number;
  subjectId?: number;
  subjectName?: string;
}

const ClassNotesCard: React.FC<TopicCardProps> = ({
  time,
  lectureName,
  onClick,
  selectedClassId,
  cardClassId,
  subjectId,
  subjectName,
}) => {
  const subName = subjectName
    ? subjectName
    : subjectId
      ? Common.SUBJECT_NAMES[subjectId as keyof typeof Common.SUBJECT_NAMES]
      : '';
  const isSelected = cardClassId === selectedClassId;

  return (
    <div onClick={() => onClick(cardClassId)}>
      <Card
        className={`flex-1 rounded-[20px] bg-white m-2 p-4 font-poppins font-medium shadow-md 
        ${isSelected ? 'border-2 border-primary-500' : 'hover:bg-gray-700/20'} 
        hover:cursor-pointer`}
      >
        <div>
          <span className='text-sm md:text-base'>
            {moment(time.startTime).format('ddd, MMM D, YYYY')}
          </span>
          <div className='my-2'>
            <h5 className='text-lg font-semibold md:text-xl'>{lectureName}</h5>
          </div>
          <div className='flex flex-col justify-between md:flex-row'>
            <div className='flex items-center gap-2 mb-2 md:mb-0'>
              <span className='flex items-center gap-2 text-xs font-semibold tracking-widest md:text-sm cap-semibold text-primary-200 lg:text-complementary-colors-50'>
                {subName && (
                  <img
                    src={
                      subName === SUBJECTS.MATHS
                        ? CompassLight
                        : subName === SUBJECTS.SCIENCE
                          ? ScienceIcon
                          : SubjectIconWhite
                    }
                    alt='subject icon'
                    className='w-4 p-1 rounded-md md:w-5 bg-primary-500'
                  />
                )}
                {subName || ''}
              </span>
            </div>
            <div className='flex justify-end'>
              <img src={rightArrow} alt={'right-arrow'} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ClassNotesCard;
