import { useEffect, useState } from 'react';

const useExternalScript = (url) => {
  const [state, setState] = useState(url ? 'loading' : 'idle');

  useEffect(() => {
    let script;

    if (!url) {
      setState('idle');
      return;
    }

    script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (event) => {
      setState(event.type === 'load' ? 'success' : 'error');
    };

    if (!script) {
      script = document.createElement('script');
      script.type = 'application/javascript';
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
    }

    script.addEventListener('load', handleScript);
    script.addEventListener('error', handleScript);

    return () => {
      script.removeEventListener('load', handleScript);
      script.removeEventListener('error', handleScript);
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [url]);

  return state;
};

export default useExternalScript;
