import { createContext } from 'react';

export interface NotificationContextType {
  notification: {
    visible: boolean;
    message: string;
    type: 'success' | 'error' | 'info';
  };
  setNotification: (notification: NotificationContextType['notification']) => void;
}

const initialNotificationState: NotificationContextType = {
  notification: {
    visible: false,
    message: '',
    type: 'info',
  },
  setNotification: () => {},
};

export const NotificationContext = createContext<NotificationContextType>(initialNotificationState);
