import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StateKeys } from 'types';
import { Accumulator } from 'types/utils/inClass/inClassFunctions';

interface IInClass {
  myCurrentState: StateKeys;
  studentsCurrentStates: Accumulator;
}
const initialState: IInClass = {
  myCurrentState: 'teach',
  studentsCurrentStates: {
    coach_basic: [],
    coach_intermediate: [],
    coach_advanced: [],
    teach: [],
  },
};

export const inClassConfigSlice = createSlice({
  name: 'inClassConfig',
  initialState,
  reducers: {
    setMyCurrentState: (state, action: PayloadAction<StateKeys>) => {
      state.myCurrentState = action.payload;
    },
    setStudentsCurrentStates: (state, action) => {
      state.studentsCurrentStates = action.payload;
    },
  },
  selectors: {
    selectMyCurrentState: (state) => state?.myCurrentState,
    selectStudentsCurrentStates: (state) => state?.studentsCurrentStates,
  },
});

export const { setMyCurrentState, setStudentsCurrentStates } = inClassConfigSlice.actions;

export const { selectMyCurrentState, selectStudentsCurrentStates } = inClassConfigSlice.selectors;
