import { isEmpty } from 'lodash';
// import QRCode from 'qrcode.react';
import { useEffect } from 'react';

// import { PracticeEvalContent } from 'components/screens';
// AI_EVALUATION,
// questionString,
// import {  LOG_TYPE } from 'configs';
// import { useLogger } from 'hooks';
import { IAiEvaluationReport } from 'types';

import './index.css';

import Instructions from './Instructions';
import { useGetPracticeNoteBookCaptureSubmissionQuery } from 'store/apiSlices/studentDashboard.apiSlice';

export default function AIReportModal({ paramData, setIsOpenModal }: IAiEvaluationReport) {
  const { evaluationId, questionId, studentId, practiceSheetId } = paramData;
  // const { logInfo } = useLogger();

  /* //TODO: HAVE TO IMPLEMENT THE AI RESPONSE FEATURES IN THE UI */
  //TODO: FOR THE IMPLEMENTATION HAVE TO UNCOMMENT THE CODE TO DISPLAY THE AI RESPONSE
  // const [isScanned, setIsScanned] = useState<boolean>(false);
  // const [showReport, setShowReport] = useState<boolean>(false);
  // const [prevUpdatedAt, setPrevUpdatedAt] = useState<string>('');

  /* API => view profile to get the school data */
  // const { data: profileData } = useGetUserDataQuery(undefined, { refetchOnMountOrArgChange: true });

  const { data: evaluationReportData } = useGetPracticeNoteBookCaptureSubmissionQuery(
    {
      studentId: studentId,
      evaluationId: evaluationId,
      questionId: questionId,
      practiceSheetId: practiceSheetId,
    },
    {
      skip: !(studentId || evaluationId || questionId || practiceSheetId),
      pollingInterval: 5000,
    },
  );

  useEffect(() => {
    if (!isEmpty(evaluationReportData?.data?.evaluationAnswers)) {
      setIsOpenModal(false);
    }
  }, [evaluationReportData, setIsOpenModal]);

  // const aiReportData = evaluationReportData?.data?.aiResponse;

  // useEffect(() => {
  //   const updatedAt = evaluationReportData?.data?.updatedAt;

  //   if (updatedAt && !prevUpdatedAt) {
  //     setPrevUpdatedAt(updatedAt);
  //   }

  //   if (updatedAt && updatedAt !== prevUpdatedAt) {
  //     setIsScanned(true);
  //     setShowReport(true);
  //     setPrevUpdatedAt(updatedAt);
  //   }
  // }, [aiReportData, evaluationReportData?.data?.updatedAt, prevUpdatedAt]);

  // const generateQRCode = () => {
  //   if (evaluationId && questionId && practiceSheetId && studentId) {
  //     const encodedToken = btoa(JSON.stringify(localStorage?.getItem('loginToken')));
  //     logInfo(
  //       `Generating QR-Code for the studentId -> ${studentId}, evaluationId -> ${evaluationId}, practiceSheetId -> ${practiceSheetId}, questionId -> ${questionId}`,
  //       {
  //         evaluationId,
  //         questionId,
  //         practiceSheetId,
  //         studentId,
  //         encodedToken,
  //       },
  //       LOG_TYPE.INFO,
  //     );
  //     return `${window.location.origin}/practice-evaluations?evaluationId=${evaluationId}&practiceSheetId=${practiceSheetId}&questionId=${questionId}&studentId=${studentId}&token=${encodedToken}&showQuestion=false`;
  //   }
  //   return '';
  // };

  // const handleReSubmit = () => {
  //   setShowReport(false);
  //   setIsScanned(false);
  // };

  return (
    <div className='flex items-center justify-center px-2'>
      {/* {isLoading ? (
        <div className='flex items-center justify-center w-full h-64 card'>
          <div className='flex flex-col items-center justify-center w-full h-full text-white'>
            <BouncingDotsLoader />
          </div>
        </div>
      ) : ( */}
      {/* <> */}
      {/* {!isScanned ? ( */}
      {/* className='flex flex-col items-center scrollbar-mobile' */}
      {/* <div> */}
      {/* <h1 className='pb-2 lg:text-lg xl:text-2xl text-neutral-800 display-bold '>
            {AI_EVALUATION.HEADER}
          </h1> */}
      {/* <span className='pb-2 lg:text-base xl:text-xl text-neutral-800 font-sora display-semibold '>
            {AI_EVALUATION.SCAN_QRCODE_HEADER}
          </span> */}
      {/* <h2 className='text-black capitalize body-semibold font-poppins lg:text-lg'>
            <span className='font-bold lg:text-lg'>Question: </span>
          </h2> */}
      {/* <div className='flex flex-col items-start justify-between pb-3 my-2 text-lg font-medium break-words md:flex-row md:items-center lg:text-xl'>
            <PracticeEvalContent data={questionString} />
          </div> */}
      {/* <div className='relative p-4 bg-white'>
            {/* <QRCode value={generateQRCode()} /> */}
      {/* Corner Borders
            <div className='absolute top-0 left-0 w-6 h-6 -mt-2 -ml-2 border-t-4 border-l-4 border-semantic-green-500'></div>
            <div className='absolute top-0 right-0 w-6 h-6 -mt-2 -mr-2 border-t-4 border-r-4 border-semantic-green-500'></div>
            <div className='absolute bottom-0 left-0 w-6 h-6 -mb-2 -ml-2 border-b-4 border-l-4 border-semantic-green-500'></div>
            <div className='absolute bottom-0 right-0 w-6 h-6 -mb-2 -mr-2 border-b-4 border-r-4 border-semantic-green-500'></div>
          </div>  */}

      <Instructions />
      {/* <div className='relative flex flex-col items-center mt-1'> */}
      {/* <p className='pb-5 mt-20 text-neutral-600 body-semibold'>
              {AI_EVALUATION.SCAN_QRCODE_FOOTER}{' '}
            </p> */}
      {/* </div> */}
      {/* </div> */}
      {/* ) : (
            showReport &&
            aiReportData && (
              <div className='px-6 py-5 rounded-2xl'>
                <h1 className='pb-2 lg:text-lg xl:text-2xl text-primary-500 display-bold '>
                  Submitted Evaluation Report:
                </h1>
                <h2 className='pt-2 text-black capitalize body-semibold font-poppins lg:text-lg'>
                  <span className='font-bold lg:text-lg'>Question: </span>
                  <span className='text-lg body-medium font-sora'>
                    <RenderEvalContent data={questionString} />
                  </span>
                </h2>
                {aiReportData?.result === 'irrelevant_image' ? (
                  <div className='p-10 my-5 text-center body-semibold text-neutral-800 bg-primary-100 rounded-2xl'>
                    {AI_EVALUATION.IRRELEVANT_IMAGE}
                  </div>
                ) : (
                  <>
                    <div className='my-2'>
                      <h2 className='flex items-center gap-1 capitalize body-semibold font-poppins'>
                        <span className='font-bold text-black lg:text-lg'>Result: </span>
                        <Badge
                          className={`capitalize bg-${
                            aiReportData?.result === 'correct' 
                              ? 'semantic-green'
                              : aiReportData?.result === 'incorrect'
                                ? 'semantic-red'
                                : 'semantic-orange'
                          }-500 py-[2px] px-2 rounded-lg text-xs md:text-sm font-semibold font-poppins text-white`}
                        >
                          {aiReportData?.result}
                        </Badge>
                      </h2>
                      {aiReportData?.ai_reason_for_result && (
                        <h2 className='mt-[3px] font-semibold capitalize body-semibold font-poppins'>
                          <span className='font-bold text-black lg:text-lg'>Reason: </span>{' '}
                          <span>
                            {aiReportData?.ai_reason_for_result?.replace(
                              'The student',
                              profileData?.data?.firstName + ' ' + profileData?.data?.lastName,
                            )}
                          </span>
                        </h2>
                      )}
                    </div>
                    <h2 className='mb-2 text-black capitalize body-semibold font-poppins lg:text-lg'>
                      <span className='font-bold lg:text-lg'>Steps: </span>
                    </h2>
                    {Array.isArray(aiReportData?.steps) && aiReportData?.steps.length > 0 ? (
                      <>
                        <motion.div
                          className='grid grid-cols-1 gap-4 mb-4 md:grid-cols-2 md:h-full md:overflow-hidden scrollbar-mobile'
                          initial='hidden'
                          animate='visible'
                          variants={CONSTRAINTS_VARIANTS}
                        >
                          {aiReportData?.steps?.map((step, idx) => (
                            <motion.div
                              key={idx}
                              className={`p-4 pt-5 relative rounded-lg ${
                                step?.status === 'correct'
                                  ? 'bg-semantic-green-100 text-semantic-green-800'
                                  : step?.status === 'incorrect'
                                    ? 'bg-semantic-red-100 text-semantic-red-800'
                                    : 'bg-semantic-orange-200 text-semantic-orange-800'
                              } items-center`}
                              variants={ITEM_VARIANTS}
                            >
                              <span className='body-semibold'>
                                {idx + 1}. {step?.name}
                                {step?.status === 'correct' ? '.' : ':'}
                              </span>
                              <Badge
                                className={`absolute top-0 right-0 capitalize bg-${
                                  step?.status === 'correct'
                                    ? 'semantic-green'
                                    : step?.status === 'incorrect'
                                      ? 'semantic-red'
                                      : 'semantic-orange'
                                }-500 py-[2px] px-2 rounded-l-lg text-xs md:text-sm font-semibold font-poppins text-white`}
                              >
                                {step?.status}
                              </Badge>
                              {step?.what_was_the_mistake_in_the_step && (
                                <p className='mt-2 text-black body-semibold'>
                                  {step?.what_was_the_mistake_in_the_step}
                                </p>
                              )}
                            </motion.div>
                          ))}
                        </motion.div>
                      </>
                    ) : typeof aiReportData?.steps === 'string' ? (
                      <h2 className='py-5 mb-2 text-center text-black capitalize bg-primary-100 rounded-xl body-semibold font-poppins lg:text-lg'>
                        {aiReportData?.steps}
                      </h2>
                    ) : (
                      <div className='p-10 font-semibold text-center text-white bg-primary-700 rounded-2xl'>
                        🌟 No steps available at the moment. Stay tuned – more content is on the way
                        soon! 🚀🙌
                      </div>
                    )}
                  </>
                )}

                <div className='flex items-center justify-end mt-4'>
                  {aiReportData?.result === 'correct' ? (
                    <Button
                      text={'Done'}
                      onClick={() => setIsOpenModal(false)}
                      className='px-6 text-white transition-transform transform bg-green-500 rounded-lg shadow-lg hover:scale-105 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400'
                    />
                  ) : (
                    <div className='flex gap-2'>
                      <Button
                        text={'Submit Again'}
                        onClick={handleReSubmit}
                        className='px-6 text-white transition-transform transform rounded-lg shadow-lg bg-primary-500 hover:scale-105 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-400'
                      />
                      <Button
                        text={'Done'}
                        onClick={() => setIsOpenModal(false)}
                        className='px-6 text-white transition-transform transform bg-green-500 rounded-lg shadow-lg hover:scale-105 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400'
                      />
                    </div>
                  )}
                </div>
              </div>
            )
          )} */}
      {/* </> */}
      {/* )} */}
    </div>
  );
}
