import { motion, AnimatePresence } from 'framer-motion';
import { ReactNode } from 'react';
import { CloseX } from 'assets/svg';
import classNames from 'classnames';

interface IModalBottomSheetProps {
  children: ReactNode;
  heading?: string;
  className?: string;
  openModal: boolean;
  showCloseBtn?: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const modalVariants = {
  hidden: { y: '100%' },
  visible: {
    y: '0%',
    transition: { type: 'spring', stiffness: 300, damping: 30 },
  },
};

export default function ModalBottomSheet({
  children,
  openModal,
  heading,
  className,
  showCloseBtn = true,
  setOpenModal,
}: IModalBottomSheetProps) {
  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <AnimatePresence>
      {openModal && (
        <motion.div
          className='fixed inset-0 z-50 flex items-end justify-center bg-black bg-opacity-50'
          initial='hidden'
          animate='visible'
          exit='hidden'
        >
          <motion.div
            className='w-full p-2 pb-0 overflow-hidden bg-white rounded-t-lg max-h-half'
            variants={modalVariants}
          >
            <div
              className={classNames(
                `flex p-3 items-center ${showCloseBtn ? 'justify-between' : 'justify-center'}`,
                className,
              )}
            >
              <h1 className='text-lg font-semibold'>{heading}</h1>
              {showCloseBtn && (
                <img
                  src={CloseX}
                  alt='close'
                  className='p-2 rounded-md cursor-pointer hover:bg-neutral-25'
                  onClick={closeModal}
                />
              )}
            </div>
            <div className='overflow-y-auto'>{children}</div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
