import { Modal as FlowbiteModal } from 'flowbite-react';
import { useCallback } from 'react';
import classNames from 'classnames';

import { Close } from 'assets/svg';
import { IModalProps } from 'types';
import { useDyteMeeting } from '@dytesdk/react-web-core';

const InClassModal = ({
  children,
  heading,
  showCloseBtn = false,
  openModal,
  setOpenModal,
  className,
  color,
  ...props
}: IModalProps) => {
  const { meeting } = useDyteMeeting();

  const handleClose = useCallback(() => {
    setOpenModal(false);
    meeting.leave();
  }, [meeting, setOpenModal]);

  return (
    <FlowbiteModal
      show={openModal}
      position={'center'}
      onClose={handleClose}
      {...props}
      theme={{
        root: {
          show: {
            on: 'flex bg-gray-900 bg-opacity-50 backdrop-blur-lg dark:bg-opacity-80',
          },
        },
        content: {
          base: 'relative h-full w-full p-4 md:h-auto flex flex-col justify-center items-center focus:outline-none',
          inner: 'relative flex max-h-[90dvh] flex-col rounded-[24px] bg-primary-850 w-full',
        },
      }}
    >
      <FlowbiteModal.Body className={`${color} rounded-[24px]`}>
        {heading && (
          <div
            className={classNames(
              `flex pb-3 items-center ${showCloseBtn ? 'justify-between' : 'justify-center'}`,
              className,
            )}
          >
            <div className='font-semibold text-md sm:text-lg lg:text-xl'>{heading}</div>
            {showCloseBtn && (
              <button className='p-3 rounded-md hover:bg-gray-200' onClick={handleClose}>
                <img src={Close} alt='Close button' />
              </button>
            )}
          </div>
        )}
        {children}
      </FlowbiteModal.Body>
    </FlowbiteModal>
  );
};

export default InClassModal;
