import {
  CarousalOneBottomOne,
  CarousalOneBottomTwo,
  // CarousalOneTop,
  CarousalTwoBottomOne,
  CarousalTwoBottomTwo,
  // CarousalTwoTop,
} from 'assets/images';

export const REGISTER_HEADER = 'Book Demo Class';
export const MOBILE_VERIFICATION_TEXT = 'Mobile Number Verification';
export const LOGIN_VERIFICATION_TEXT = 'Verify your mobile number';
export const SENT_VERIFICATION_TEXT = 'We’ve sent a verification code to';
export const FORGOT_PASSCODE_VERIFICATION_TEXT =
  'A verification code has been sent to the registered number ending in';
export const STUDENT_DETAILS_TEXT = 'Student & School Details';
export const SCHOOL_NOT_LISTED = `your school is not listed? Don't worry, we are expanding our network to more schools soon. 🚀`;

export const OTP_COUNTDOWN = 30000;

/* Don't change this is the stepper and header */
export const STEPPER_DATA = [
  { name: 'Personal details', id: 1 },
  { name: 'Demo class', id: 2 },
  { name: 'Set passcode', id: 3 },
];

type IStepperSwitch = {
  PERSONAL_DETAILS: number;
  DEMO_CLASS: number;
  SET_PASSCODE: number;
};

/* stepperSwitch data */
export const STEPPER_SWITCH: IStepperSwitch = {
  PERSONAL_DETAILS: 0,
  DEMO_CLASS: 1,
  SET_PASSCODE: 2,
};

/* herosection data */
export const SLIDES = [
  {
    id: 1,
    top: CarousalOneBottomOne, // CarousalOneTop
    right: [
      {
        id: 1,
        img: CarousalOneBottomOne,
      },
      {
        id: 2,
        img: CarousalOneBottomTwo,
      },
    ],
    content: 'Your own personal coach for your own guided learning.',
  },
  {
    id: 2,
    top: CarousalOneBottomTwo, //CarousalTwoTop
    right: [
      {
        id: 1,
        img: CarousalTwoBottomOne,
      },
      {
        id: 2,
        img: CarousalTwoBottomTwo,
      },
    ],
    content:
      'UPRIO mirrors your school curriculum and schedule - so that you are perfectly in sync',
  },
];

export const TOKENS_TO_REMOVE = ['tempToken', 'loginToken', 'tempAuthToken'];
