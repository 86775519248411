import { IGoogle } from 'types/common';

/* mobile initial request for otp */
export interface requestOTP {
  mobileNumber: string;
  mobileNumberVerifiedState?: boolean;
}

export interface responseOTP {
  data: {
    tempUser?: boolean;
  };
  message: string;
  status?: number;
}

/* verifying the otp sent to mobile */
export interface verifyOTP {
  otp: string;
  tempUser: boolean;
  mobileNumber: string;
}

export interface responseVerifyOTP {
  data: {
    tempId: number;
    tempToken: string;
  };
  message: string;
  otpVerifiedState: boolean;
}

/* interface for login via otp  */
export interface ILoginViaOtpResponse {
  data: {
    memberId: string;
  };
  message: string;
}

export interface ILoginOTPVerifyResponse {
  data: {
    tempToken: string;
  };
  message: string;
}

export interface ILoginOTPVerifyRequest {
  mobileNumber: string;
  otp: string;
}

/* interface for the student registration */
export interface IStudentRegistration {
  data: {
    boardId: string;
    city: string;
    country: string;
    firstName: string;
    gradeId: string;
    isSchoolCreated: boolean;
    lastName: string;
    school: object | string;
    state: string;
  };
  gradeName?: string;
}

export interface responseStudentRegistration {
  data: {
    memberId: number;
    tempToken: string;
  };
  message: string;
  studentRegistrationState?: boolean;
}

/* interface for the demo class config */
export interface DemoClassConfig {
  key?: string;
  value: IDemoClassValues[];
  createdAt?: string;
  updatedAt?: string;
  id: number;
}

export interface IDemoClassValues {
  startTime: string;
  endTime: string;
  startAmPm: string;
  endAmPm: string;
}

export interface IDemoClassConfig {
  message: string;
  data: DemoClassConfig[];
}

export interface demoClassResponse {
  data: object;
  message: string;
}

/* interface for the passcode response */
export interface IPasscodeResponse {
  message: string;
  data: {
    tempToken: string;
  };
  passcodeState?: boolean;
}

export type PasscodeTypes = {
  FORGET_PASSCODE: 'forgetPasscode';
  REGISTER_SET_PASSCODE: 'registerSetPasscode';
};

export const PASSCODE_TYPES: PasscodeTypes = {
  FORGET_PASSCODE: 'forgetPasscode',
  REGISTER_SET_PASSCODE: 'registerSetPasscode',
};

export interface ISetPasscode {
  type: 'forgetPasscode' | 'registerSetPasscode';
}

/* interface for the Add School */
export interface IAddSchoolProps {
  city: ISelectedOption;
  onGoogleSearchData: (data: IGoogle) => void;
  setIsOpenModal: (value: boolean) => void;
}

export interface ISelectedOption {
  cityName: string;
  stateName: string;
  countryName: string;
}

export interface ILoginVerification {
  isMobileVerified: (isMobileValidated: boolean) => void;
}

/* Mobile Verification page */
export interface IMobileStepsVerificationProps {
  isMobileStepsValidated: (isMobileStepsValidated: boolean) => void;
}

/* Update Mobile Number Types*/
export interface IUpdateMobileNumber {
  mobileNumber: string;
  ismobileNumberUpdated: boolean;
}

/* Interface for parent onboarding */
export interface IParentOnboarding {
  userType: string;
  firstName: string;
  lastName: string;
  country: string;
  state: string;
  city: string;
  relationship: string;
  mobileNumber?: string;
  otp?: string;
  studentId?: number | undefined;
}

export interface IcurrentState {
  currentState: string;
}
