import { Button, Input } from 'components/common';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { ErrorResponse } from 'react-router';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useInitialLoginMutation, useRequestOtpMutation } from 'store/apiSlices/login.apiSlice';

import { ExclamationCircle } from 'assets/svg';

import { APP_DEFAULT_MESSAGES, APP_REGEX, LOGIN_TYPES, MOBILE_NUMBER_ERROR, ROUTES } from 'configs';
import { useLocalStorage, useWindowDimensions } from 'hooks';
import toast from 'react-hot-toast';

interface ILoginMobile {
  buttonClickType: (type: 'passcode' | 'otp') => void;
  isMobileValidated: (isMobileValidated: boolean) => void;
}

export default function LoginMobile({ buttonClickType, isMobileValidated }: ILoginMobile) {
  const { isMobileScreen } = useWindowDimensions();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const { setToLocalStorage, getFromLocalStorage } = useLocalStorage();
  const mobileNumber = getFromLocalStorage('mobileNumber');
  // CheckPhone number
  const [checkPhoneNumber, { isLoading }] = useInitialLoginMutation();
  // Otp request call
  const [loginViaOtp, { isLoading: IsInitialLoading }] = useRequestOtpMutation();

  const [buttonState, setButtonState] = useState<'passcode' | 'otp'>();

  /* initial Login form validation for mobile number */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { mobileNumber: mobileNumber || '' },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .required(APP_DEFAULT_MESSAGES?.VALIDATION?.MOBILE_NUMBER_REQUIRED)
        .matches(
          APP_REGEX?.VALIDATION?.MOBILE_NUMBER_VALIDATION,
          APP_DEFAULT_MESSAGES?.VALIDATION?.VALID_MOBILE_NO,
        ),
    }),
    onSubmit: (values) => {
      checkPhoneNumber({ mobileNumber: values.mobileNumber })
        .unwrap()
        .then((payload) => {
          setToLocalStorage(
            'studentGuardiansId',
            payload?.data?.studentGuardiansId?.toString() || '',
          );
          isMobileValidated(true);
        })
        .catch((error) => {
          toast.error((error as ErrorResponse)?.data?.message || MOBILE_NUMBER_ERROR);
        });
      if (buttonState === 'otp') {
        loginViaOtp({ mobileNumber: values.mobileNumber }).unwrap();
      }
    },
  });

  const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('mobileNumber', event.target.value);
    setToLocalStorage('mobileNumber', event.target.value);
  };
  /* initial focus */
  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className='flex-1 h-full'>
      <div className='flex flex-col justify-between flex-1 h-full md:justify-around'>
        {/* Mobile Number */}
        <div>
          <h1
            className={`md:mb-8 text-xl sm:text-lg md:text-xl lg:text-3xl uppercase md:capitalize text-center display-bold whitespace-nowrap text-neutral-850 md:mt-7`}
          >
            {'LOGIN'}
          </h1>
          <Input
            ref={inputRef}
            type='number'
            id='mobileNumber'
            name='mobileNumber'
            placeholder='932xxxxxxx'
            label={isMobileScreen ? '' : 'Mobile Number'}
            pattern='[0-9]*'
            autoComplete='on'
            className='w-full py-0 pl-10 pr-10 leading-8'
            isMobilenumber={true}
            value={formik.values.mobileNumber || mobileNumber || ''}
            onChange={handleMobileChange}
            maxLength={APP_REGEX?.MAX_LENGTH?.MOBILE_NUMBER_INPUT}
            onBlur={formik.handleBlur}
            isValid={formik.touched.mobileNumber && formik.errors.mobileNumber ? true : false}
            icon={
              formik.errors.mobileNumber && formik.touched.mobileNumber ? ExclamationCircle : ''
            }
          />
          {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
            <p className='mt-2 leading-5 text-semantic-red-500 sm:text-sm body-regular'>
              {formik.errors.mobileNumber}
            </p>
          ) : null}
        </div>

        <div className='my-4'>
          {/* Login with Passcode */}
          <Button
            type='submit'
            text={'Login with Passcode'}
            isLoading={buttonState === LOGIN_TYPES.PASSCODE ? isLoading : false}
            onClick={() => {
              buttonClickType(LOGIN_TYPES.PASSCODE);
              setButtonState(LOGIN_TYPES.PASSCODE);
            }}
            disabled={
              mobileNumber
                ? false
                : formik.errors.mobileNumber
                  ? true
                  : false || Number(formik.values.mobileNumber) !== 10
                    ? true
                    : false
            }
            className={`w-full bg-primary-500 enabled:hover:bg-primary-500 enabled:hover:text-white focus:ring-0 ring-none outline-none  text-white rounded-[14px]`}
          />
          <div className='my-4 text-center sm:text-base body-semibold text-primary-800'>or</div>
          {/* Get OTP */}
          <Button
            type='submit'
            isLoading={IsInitialLoading}
            text='Login Using OTP'
            disabled={
              mobileNumber
                ? false
                : formik.errors.mobileNumber
                  ? true
                  : false || Number(formik.values.mobileNumber) !== 10
                    ? true
                    : false
            }
            onClick={() => {
              buttonClickType(LOGIN_TYPES.OTP);
              setButtonState(LOGIN_TYPES.OTP);
            }}
            className={`w-full bg-white focus:ring-2 display-bold md:text-lg ring-2 focus:ring-primary-500 active:ring-primary-500 ring-primary-500 text-primary-500 enabled:hover:bg-white enabled:hover:text-primary-500  rounded-[14px]`}
          />
          <p className={`text-neutral-500 body-medium md:text-base text-center mt-5`}>
            Not Registered Yet?{' '}
            <span
              className='leading-4 underline cursor-pointer text-primary-500 sm:text-base 2xl:text-lg body-semibold'
              onClick={() => navigate(ROUTES.REGISTER)}
            >
              Register Now
            </span>
          </p>
        </div>
      </div>
    </form>
  );
}
