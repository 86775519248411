import { useDyteMeeting } from '@dytesdk/react-web-core';
import { BroadcastMessagePayload } from '@dytesdk/web-core';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DytePluginMain, DyteScreenshareView } from '@dytesdk/react-ui-kit';
import { Evaluation } from 'components/inClass';
import InClassChat from 'components/inClass/InClassChat';
import InClassControls from 'components/inClass/InClassControls';
import InClassHeader from 'components/inClass/InClassHeader';
import EnableDeviceModal from 'components/inClass/ModalInClass/EnableDeviceModal';
import ParticipantTile from 'components/inClass/ParticipantTile';
import { CONFIG, DOMAIN_ENV, PRESET_TYPE, WHITEBOARD_PLUGIN_ID } from 'configs';
import { SocketContext } from 'contexts';
import {
  useEvalRender,
  useInclassLogger,
  useMeeting,
  useMorphcast,
  useNetworkStatus,
  useWindowDimensions,
} from 'hooks';
import { useSocketHandlers } from 'hooks/inClass/useSocketHandler';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useUpdateSessionMutation } from 'store/apiSlices/inClass/classConfig.apiSlice';
import { useStartRecordingMutation } from 'store/apiSlices/inClass/classRecording.apiSlice';
import { useGetCurrentStatusOfStudentQuery } from 'store/apiSlices/inClass/inClassCommon.apiSlice';
import { selectHtmlLink } from 'store/slice/content.slice';
import {
  selectMyCurrentState,
  selectStudentsCurrentStates,
  setStudentsCurrentStates,
} from 'store/slice/inClassConfig.slice';
import { IEvalStatus, IPluginStatus } from 'types';
import { getClassNames, getStatesOfAllStudents } from 'utils';
import Content from '../Content';
import EndMeetingModal from '../ModalInClass/EndMeetingModal';
import NotebookCapture from '../Notebook/NotebookCapture';
import Oral from '../Oral';
import Polls from '../Polls';
import './style.css';

const InClass = () => {
  const dispatch = useAppDispatch();
  const myCurrentState = useAppSelector(selectMyCurrentState) || 'teach';
  const getStudentsCurrentState = useAppSelector(selectStudentsCurrentStates);
  const filteredStudentIds = getStudentsCurrentState?.[myCurrentState];

  // State variables
  const [chatToggle, setChatToggle] = useState<boolean>(false);
  const [render, setRender] = useState({
    participant: false,
    plugin: false,
    polls: false,
    notebook: false,
    written: false,
    oral: false,
    slides: false,
  });
  const [activeMorphcast, setActiveMorphcast] = useState<boolean>(false);
  const [pluginData, setPluginData] = useState<{ pluginId: string; meetingId?: string }>({
    pluginId: '',
    meetingId: '',
  });

  const [enableDeviceModal, setEnableDeviceModal] = useState<boolean>(false);
  const [enableDevice, setEnableDevice] = useState<string>('');

  // Modal State
  const [leaveMeetingModal, setLeaveMeetingModal] = useState<boolean>(false);
  const [updateSession] = useUpdateSessionMutation();
  const [pluginStatus, setPluginStatus] = useState<IPluginStatus>({
    slide: { active: false, show: false },
    whiteboard: { active: false, show: false },
  });
  const [evalStatus, setEvalStatus] = useState<IEvalStatus>({
    coach_advanced: false,
    coach_basic: false,
    coach_intermediate: false,
    teach: false,
  });
  // Hooks
  const { meeting } = useDyteMeeting();
  const { screenResolution } = useWindowDimensions();
  const { activateMorphcast } = useNetworkStatus();
  const socket = useContext(SocketContext);
  const {
    students,
    studentsWithOutSelf,
    tutors,
    studentsCount,
    self,
    screenShareHost,
    screenShareEnabled,
    activePlugins,
    classState,
    classId,
    studentId,
    participants,
    studentName,
    loginTempToken,
    classStateForTutor,
  } = useMeeting();

  // Datadog
  const { datadogLog } = useInclassLogger();
  const getHtmlLink = useAppSelector(selectHtmlLink);

  const filteredParticipantsOutSelf = useMemo(() => {
    if (myCurrentState !== 'teach') {
      return studentsWithOutSelf.filter((participant) => {
        return (
          participant.customParticipantId !== undefined &&
          filteredStudentIds?.includes(participant.customParticipantId)
        );
      });
    }
    return studentsWithOutSelf;
  }, [studentsWithOutSelf, myCurrentState, filteredStudentIds]);

  const filteredParticipants = useMemo(() => {
    if (myCurrentState !== 'teach') {
      return students.filter((participant) => {
        return (
          participant.customParticipantId !== undefined &&
          filteredStudentIds?.includes(participant.customParticipantId)
        );
      });
    }
    return students;
  }, [students, myCurrentState, filteredStudentIds]);

  const { handRaised, noteBookCaptureData, evalTrigger, tutorActiveRoom } = useSocketHandlers(
    setRender,
    setActiveMorphcast,
    studentName,
    setPluginStatus,
    setEvalStatus,
  );

  // screenShare only activating if the tutor is in same room
  const screenShareForMyRoom = useMemo(() => {
    if (screenShareEnabled && tutorActiveRoom === myCurrentState) {
      return true;
    }
    return false;
  }, [myCurrentState, screenShareEnabled, tutorActiveRoom]);

  useEvalRender({ setRender, evalTrigger });
  useMorphcast({ activeState: activeMorphcast });

  const whiteBoardPlugin = meeting?.plugins?.all?.get(WHITEBOARD_PLUGIN_ID);

  // function to get class names
  const { participantsWidth, mainTutor, mainStudents, secondaryStudent, secondaryTutor } =
    getClassNames({
      tutorsLength: tutors.length,
      chatToggle: chatToggle,
      screenResolution: screenResolution as 'HD' | 'FHD' | 'QHD' | 'SD',
      studentsWithoutSelfLength: studentsWithOutSelf.length,
      studentsCount: studentsCount,
      activePluginsLength: activePlugins.length,
      screenShareEnabled: screenShareForMyRoom,
      getHtmlLink: getHtmlLink,
      isWrittenEvalOpen: render?.written,
      isPollEvalOpen: render?.polls,
      isOralEvalOpen: render?.oral,
    });

  // API Call -> Get the currentStatus
  const { data: currentStatus } = useGetCurrentStatusOfStudentQuery({
    classId: String(classId),
    tempToken: loginTempToken || '',
  });

  const selectedStudentStatus = currentStatus?.data.find((data) => data.studentId === studentId);

  useEffect(() => {
    if (currentStatus) {
      const states = getStatesOfAllStudents(currentStatus?.data);
      dispatch(setStudentsCurrentStates(states));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus, selectedStudentStatus?.currentState]);

  const roomState = myCurrentState || selectedStudentStatus?.currentState; //Here I am getting the currentState from store if not present taking from api

  useEffect(() => {
    meeting?.participants?.broadcastMessage(
      'getCurrentState',
      {},
      { presetNames: ['group_call_host'] },
    );
  }, [meeting?.participants]);

  // API call for updating sessionId
  useEffect(() => {
    const body = {
      classId: Number(classId),
      sessionId: meeting?.meta?.sessionId,
    };
    updateSession({ body });
    datadogLog(
      `Updated sessionId for student ${studentName} (sessionId: ${meeting?.meta?.sessionId})`,
      { sessionId: meeting?.meta?.sessionId },
    );
  }, [classId, datadogLog, loginTempToken, meeting?.meta?.sessionId, studentName, updateSession]);

  // API Call -> Start Recording
  const [startRecording] = useStartRecordingMutation();

  useEffect(() => {
    meeting?.participants?.broadcastMessage(
      'getCurrentState',
      {},
      { presetNames: ['group_call_host'] },
    );
  }, [meeting?.participants]);

  const handleRecording = useCallback(async () => {
    const studentIds = CONFIG.RECORDING_DISABLED_FOR_STUDENTS.split(',');
    if (
      DOMAIN_ENV === 'production' &&
      meeting.recording.recordingState === 'IDLE' &&
      classId &&
      meeting?.meta?.meetingId &&
      !studentIds.includes(self.customParticipantId?.split('-').pop())
    ) {
      try {
        await startRecording({
          classId: String(classId),
          meetingId: meeting?.meta?.meetingId,
        });
      } catch (error) {
        console.error('Error starting recording:', error);
      }
    }
  }, [
    classId,
    meeting?.meta?.meetingId,
    meeting.recording.recordingState,
    startRecording,
    self.customParticipantId,
  ]);

  useEffect(() => {
    handleRecording();
  }, [handleRecording]);

  useEffect(() => {
    const currentState = roomState || CONFIG.ROOMS.TEACH;
    const rooms = [
      CONFIG.ROOMS.COACH_BASIC,
      CONFIG.ROOMS.COACH_INTERMEDIATE,
      CONFIG.ROOMS.COACH_ADVANCE,
    ];
    if (rooms.includes(currentState)) {
      setActiveMorphcast(false);
    } else {
      setActiveMorphcast(activateMorphcast);
    }
  }, [activateMorphcast, roomState]);

  useEffect(() => {
    if (activePlugins.length > 0 && !pluginData.pluginId) {
      setPluginData((prev) => ({ ...prev, pluginId: activePlugins[0].id }));
    }
  }, [activePlugins, pluginData]);

  useEffect(() => {
    socket?.emit('refreshHuddleState', { studentId: studentId, classId: classId });
  }, [socket, self.roomState, studentId, classId]);

  useEffect(() => {
    try {
      if (self?.roomState === 'joined' && classState.type === 'coach') {
        self?.enableAudio();
      }
    } catch (error) {
      console.log('unable to start audio');
    }
  }, [self, classState]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      !screenShareForMyRoom &&
      !render.plugin &&
      !render.polls &&
      !render.notebook &&
      !render.written &&
      !render.oral &&
      !render.slides
    ) {
      timeoutId = setTimeout(() => {
        setRender((prev) => ({ ...prev, participant: true }));
      }, 1000);
    } else {
      timeoutId = setTimeout(() => {
        setRender((prev) => ({ ...prev, participant: false }));
      }, 0);
    }
    return () => clearTimeout(timeoutId);
  }, [
    render.polls,
    render.notebook,
    render.written,
    screenShareForMyRoom,
    render.plugin,
    render.oral,
    render.slides,
  ]);

  // Adding delay for rendering plugins
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      activePlugins.length > 0 &&
      pluginStatus?.whiteboard?.show &&
      pluginStatus?.whiteboard?.active
    ) {
      timeoutId = setTimeout(() => {
        setRender((prev) => ({ ...prev, plugin: true }));
      }, 700);
    } else {
      setRender((prev) => ({ ...prev, plugin: false }));
    }
    return () => clearTimeout(timeoutId);
  }, [activePlugins, classState, pluginStatus]);

  useEffect(() => {
    const handleBroadcastedMessage = (payload: {
      type: string;
      payload: BroadcastMessagePayload;
    }) => {
      const { type, payload: messagePayload } = payload;
      const id = String(messagePayload?.participant_id)?.split('-')?.pop();
      const selfId = String(meeting?.self?.customParticipantId)?.split('-')?.pop();

      if (Number(id) === Number(selfId)) {
        switch (type) {
          case 'onVideo': {
            setEnableDeviceModal(true);
            setEnableDevice('Camera');
            break;
          }
          case 'onMic': {
            setEnableDeviceModal(true);
            setEnableDevice('Mic');
            break;
          }
          default:
            break;
        }
      }
    };
    meeting.participants.on('broadcastedMessage', handleBroadcastedMessage);
    return () => {
      meeting.participants.removeListener('broadcastedMessage', handleBroadcastedMessage);
    };
  }, [meeting]);

  useEffect(() => {
    meeting.participants.broadcastMessage('studentClassId', {
      classId: String(classId),
      studentName: studentName,
    });
  }, [classId, meeting.participants, studentName]);

  useEffect(() => {
    whiteBoardPlugin?.on('ready', () => {
      const tutor = participants?.toArray()?.find((p) => p.presetName === PRESET_TYPE.TUTOR);
      if (!tutor) return;
      whiteBoardPlugin?.sendData({
        eventName: 'config',
        data: {
          eventName: 'config',
          follow: tutor?.id,
          autoScale: true,
        },
      });
    });
    return () => {
      whiteBoardPlugin?.removeAllListeners('ready');
    };
  }, [whiteBoardPlugin, participants]);

  useEffect(() => {
    participants.on('participantJoined', (participant) => {
      if (participant.presetName !== PRESET_TYPE.TUTOR) return;
      whiteBoardPlugin?.sendData({
        eventName: 'config',
        data: {
          eventName: 'config',
          follow: participant.id,
          // autoScale: true,
        },
      });
      setTimeout(() => {
        meeting.participants.broadcastMessage('studentClassId', {
          classId: String(classId),
          studentName: studentName,
        });
      }, 3000);
    });
    return () => {
      participants.removeAllListeners('participantJoined');
    };
  }, [whiteBoardPlugin, participants, meeting.participants, classId, studentName]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState !== 'visible') {
        socket?.emit('tabNotActive', studentName);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [socket, studentName]);

  return (
    <>
      <div
        className='flex min-h-screen py-3 overflow-hidden select-none bg-primary-900 scrollbar'
        id='mainClass'
      >
        <div className='flex flex-col justify-between w-full mx-6 scrollbar'>
          {/* Header info */}
          <InClassHeader tutorActiveRoom={tutorActiveRoom} />
          {/* Main video and plugins */}
          <div className='flex justify-center h-full gap-3'>
            {/* Plugin view */}
            {(render.plugin ||
              render.polls ||
              render.notebook ||
              render.written ||
              render.oral ||
              render.slides ||
              (screenShareForMyRoom && screenShareHost && myCurrentState === tutorActiveRoom)) && (
              <div
                className={`flex relative items-center justify-center ${screenResolution === 'FHD' ? 'h-[79vh]' : 'h-[72.5vh]'} gap-2 my-2 w-full`}
                key='plugin'
              >
                {screenShareForMyRoom && screenShareHost && (
                  <div className={`absolute top-0 left-0 size-full`}>
                    <div className={`items-center justify-center w-full h-full aspect-video`}>
                      <DyteScreenshareView participant={screenShareHost} meeting={meeting} />
                    </div>
                  </div>
                )}
                {!screenShareForMyRoom &&
                  activePlugins?.map((plugin) => {
                    return (
                      <div
                        className={`absolute top-0 left-0 size-full ${pluginStatus?.whiteboard?.show ? 'opacity-100 z-10' : 'opacity-0 z-0'}`}
                        key={plugin.id}
                      >
                        <DytePluginMain key={plugin.id} meeting={meeting} plugin={plugin} />
                      </div>
                    );
                  })}
                {render.slides && !screenShareForMyRoom && (
                  <div
                    className={`absolute top-0 left-0 bg-primary-800 rounded-[5px] border border-gray-500 size-full ${pluginStatus?.slide?.show && 'opacity-100 z-10'}`}
                  >
                    <Content />
                  </div>
                )}
                {!screenShareForMyRoom &&
                  evalStatus[myCurrentState] &&
                  pluginStatus?.slide?.show && (
                    <div
                      className={`absolute top-0 left-0 bg-primary-800 rounded-[5px] border border-gray-500 size-full z-20`}
                    >
                      <div className='flex items-center justify-center text-4xl text-white size-full'>
                        Evaluation
                      </div>
                    </div>
                  )}
                {render.polls && (
                  <div className='absolute top-0 left-0 z-20 bg-primary-800 rounded-[5px] border border-gray-500 size-full'>
                    <Polls
                      polls={render.polls}
                      loading={evalTrigger.loading}
                      submit={evalTrigger.submit}
                    />
                  </div>
                )}
                {render.written && (
                  <div className='absolute top-0 left-0 z-20 bg-primary-800 rounded-[5px] border border-gray-500 size-full'>
                    <Evaluation written={render.written} loading={evalTrigger.loading} />
                  </div>
                )}
                {render.notebook && (
                  <div className='absolute top-0 left-0 z-30 bg-primary-900 size-full'>
                    <NotebookCapture
                      setRender={setRender}
                      data={noteBookCaptureData}
                      setActiveMorphcast={setActiveMorphcast}
                    />
                  </div>
                )}
                {render.oral && (
                  <div className='absolute top-0 left-0 z-20 bg-primary-800 rounded-[5px] border border-gray-500 size-full'>
                    <Oral />
                  </div>
                )}
              </div>
            )}

            {/* Participants */}
            {render.participant ? (
              <div className={`flex items-center gap-2  ${participantsWidth}`}>
                <div className='relative flex flex-wrap justify-center gap-2 w-fit '>
                  {tutors?.length > 0 ? (
                    tutors.map((tutor) => {
                      return (
                        <>
                          {roomState && roomState !== tutorActiveRoom && (
                            <div className='absolute z-10 flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 border-2 top-1/2 left-1/2 bg-primary-850/50 rounded-2xl'>
                              <p className='text-base font-bold text-center text-white xl:text-lg 2xl:text-xl'>
                                The tutor will be joining your room soon. You can click on "Raise
                                Hand" if you have any doubts
                              </p>
                            </div>
                          )}
                          <div
                            key={tutor?.id}
                            className={`relative flex flex-col flex-wrap ${mainTutor}`}
                          >
                            <ParticipantTile participant={tutor} handRaised={handRaised} />
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div
                      className='w-[23vw] h-[70vh] bg-primary-850 flex justify-center items-center border-2 border-[#2a6041] rounded-2xl'
                      key='notPresent'
                    >
                      <div className='relative z-10 flex items-center w-full h-full'>
                        {classStateForTutor ? (
                          <>
                            {!chatToggle && (
                              <div className='absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
                                <p className='text-base font-bold text-center text-white xl:text-lg 2xl:text-xl'>
                                  The tutor will be joining your room soon. You can click on "Raise
                                  Hand" if you have any doubts
                                </p>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div className='flex flex-col items-center w-full gap-2 text-white'>
                              <p>Stay tuned.</p>
                              <p>Your tutor will be joining soon</p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className='flex flex-wrap justify-center w-full gap-2'>
                  {filteredParticipants?.length > 0 &&
                    filteredParticipants?.map((student) => {
                      return student ? (
                        <div key={student?.userId} className={`relative ${mainStudents}`}>
                          <ParticipantTile participant={student} handRaised={handRaised} />
                        </div>
                      ) : null;
                    })}
                </div>
              </div>
            ) : null}
          </div>

          {/* Control buttons */}
          <InClassControls
            setChatToggle={setChatToggle}
            chatToggle={chatToggle}
            setLeaveMeetingModal={setLeaveMeetingModal}
            studentHandsRaised={handRaised}
          />
        </div>
        {/* Participants view when screen share and plugin is enabled || Chat view when screen share and plugin is disabled */}
        {!render.notebook && (
          <div
            className={`flex flex-col justify-center gap-2 items-center ${chatToggle ? ' w-[21vw]' : ''}`}
          >
            {(screenShareForMyRoom && myCurrentState === tutorActiveRoom) ||
            render.plugin ||
            render.polls ||
            render.written ||
            render.slides ||
            render.oral ? (
              <div
                key='participants'
                className='flex flex-col items-center justify-center w-[20vw] mr-2'
              >
                <div className='flex gap-x-2 w-[20vw] justify-center items-center'>
                  {filteredParticipantsOutSelf?.length > 0 &&
                    filteredParticipantsOutSelf?.map((student) => {
                      return (
                        <div
                          key={`${student?.id}-${student?.userId}`}
                          className={`relative overflow-hidden ${chatToggle ? 'h-[10vh]' : 'h-[15vh]'}`}
                        >
                          <ParticipantTile
                            participant={student}
                            studentTile
                            handRaised={handRaised}
                          />
                        </div>
                      );
                    })}
                </div>
                <div
                  className={`flex ${chatToggle ? 'flex-wrap' : 'flex-col'} justify-center items-center gap-2`}
                >
                  <div className='mt-2'>
                    {self && (
                      <div key={self?.userId} className={`relative ${secondaryStudent} `}>
                        <ParticipantTile participant={self} handRaised={handRaised} />
                      </div>
                    )}
                  </div>
                  <div className={`relative ${tutors.length >= 2 ? 'flex flex-wrap gap-1' : ''}`}>
                    {(screenShareForMyRoom ||
                      activePlugins.length ||
                      render.plugin ||
                      render.polls ||
                      render.written ||
                      render.slides ||
                      render.oral) && (
                      <>
                        {tutors?.length > 0 ? (
                          tutors.map((tutor) => {
                            return (
                              <>
                                {roomState && roomState !== tutorActiveRoom && (
                                  <div className='absolute left-0 z-10 flex items-center w-full h-full -translate-y-1/2 border-2 justify-center-translate-x-1/2 top-1/2 bg-primary-850/50 rounded-2xl'>
                                    <p
                                      className={`text-base font-bold text-center text-white  ${chatToggle ? 'text-xs' : 'xl:text-lg 2xl:text-xl'}`}
                                    >
                                      The tutor will be joining your room soon. You can click on
                                      "Raise Hand" if you have any doubts
                                    </p>
                                  </div>
                                )}
                                <div
                                  key={`${tutor?.id}-${tutor?.userId}`}
                                  className={`relative ${secondaryTutor}`}
                                >
                                  <ParticipantTile participant={tutor} handRaised={handRaised} />
                                </div>
                              </>
                            );
                          })
                        ) : (
                          <div
                            className={`${secondaryTutor} bg-primary-850 flex justify-center items-center border-2 border-[#2a6041] rounded-2xl`}
                            key='notPresent'
                          >
                            <div className='relative z-10 w-full'>
                              {classStateForTutor ? (
                                <>
                                  {!chatToggle && (
                                    <div className='absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
                                      <p className='text-base font-bold text-center text-white xl:text-lg 2xl:text-xl'>
                                        The tutor will be joining your room soon. You can click on
                                        "Raise Hand" if you have any doubts
                                      </p>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className='flex flex-col items-center gap-2 text-white'>
                                    <p>Stay tuned.</p>
                                    <p>Your tutor will be joining soon</p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {/* Display chat when screen share and plugin is disabled */}
            {chatToggle ? (
              <div key='chat' className='flex flex-wrap items-center justify-center mr-2 w-fit'>
                <InClassChat
                  setChatToggle={setChatToggle}
                  chatToggle={chatToggle}
                  isWrittenEvalOpen={render.written}
                  isPollEvalOpen={render.polls}
                  isOralEvalOpen={render.polls}
                />
              </div>
            ) : null}
          </div>
        )}

        <EndMeetingModal
          leaveMeetingModal={leaveMeetingModal}
          setLeaveMeetingModal={setLeaveMeetingModal}
          setActiveMorphcast={setActiveMorphcast}
        />

        <EnableDeviceModal
          setEnableDeviceModal={setEnableDeviceModal}
          enableDeviceModal={enableDeviceModal}
          enableDevice={enableDevice}
        />
      </div>
    </>
  );
};

export default InClass;
