import { ClassJoinTokenResponse, ListOfClassesResponse } from 'types/classApiSlice';
import apiSlice from '.';
import { ICurrentClass } from 'types/democlass';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listOfClasses: builder.query<ListOfClassesResponse, { paginationInfo: string }>({
      query: ({ paginationInfo }) => ({
        url: `/classes/list${paginationInfo}`,
      }),
    }),
    getClassJoinToken: builder.query<ClassJoinTokenResponse, { authToken: string }>({
      query: ({ authToken }) => ({
        url: `/public/join-class/${authToken}`,
      }),
    }),
    getCurrentClassDetailsById: builder.query<ICurrentClass, { classId: number }>({
      query: ({ classId }) => ({
        url: `/classes/${classId}`,
      }),
    }),
  }),
});

export const {
  useLazyListOfClassesQuery,
  useGetClassJoinTokenQuery,
  useGetCurrentClassDetailsByIdQuery,
} = extendedApiSlice;
