import apiSlice from 'store/apiSlices';
import { studentsStatus } from 'types';
import { IJoiningExperince } from 'types/inClass/StudentSetupScreen';

interface ICurrentStatusOfStudentInClass {
  message: string;
  data: studentsStatus[];
}

const inClassCommonApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentStatusOfStudent: builder.query<
      ICurrentStatusOfStudentInClass,
      { classId: string; tempToken: string }
    >({
      query: ({ classId }) => ({
        url: `/in-class/engagement/current-state?classId=${classId}`,
      }),
    }),
    sendParentNetworkAlert: builder.mutation<void, { classId: number }>({
      query: ({ classId }) => ({
        url: `/v2/parents/network-issue-alert`,
        method: 'POST',
        body: { classId },
      }),
    }),

    // Joining Experince API
    addJoiningExperince: builder.mutation<
      void,
      { classId: string; JoiningExperinceBody: IJoiningExperince }
    >({
      query: ({ classId, JoiningExperinceBody }) => ({
        url: `/v2/joining-experiences/${classId}`,
        method: 'POST',
        body: JoiningExperinceBody,
      }),
    }),
  }),
});

export const {
  useGetCurrentStatusOfStudentQuery,
  useSendParentNetworkAlertMutation,
  useAddJoiningExperinceMutation,
} = inClassCommonApiSlice;
