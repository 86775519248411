import { datadogRum } from '@datadog/browser-rum';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { useAppDispatch } from 'hooks/store';
import { ROUTES } from 'configs';
import { Logo } from 'assets/svg';
import { reset } from 'store/slice/studentRegister.slice';

export interface IOnboardingNavProps {
  className?: string;
}

const OnboardingNav = ({ className }: IOnboardingNavProps) => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    dispatch(reset());
    datadogRum.clearUser();
    navigate(ROUTES.LOGIN);
  };

  return (
    <div
      className={classNames(
        'bg-neutral-100 p-6 md:px-10 w-full sticky top-0 z-10 flex justify-between',
        className,
      )}
    >
      <img src={Logo} className='h-auto max-w-full' alt='logo' />
      {pathname === ROUTES.ACCOUNTS && (
        <div
          className='px-3 py-2 text-base font-semibold tracking-wide transition-transform transform bg-white rounded-lg cursor-pointer font-sora hover:bg-primary-500 text-neutral-600 hover:text-white hover:scale-105 hover:duration-150 hover:ease-in-out'
          onClick={() => handleLogout()}
        >
          Logout
        </div>
      )}
    </div>
  );
};

export default OnboardingNav;
