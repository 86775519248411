import { Spinner } from 'flowbite-react';
import { ChangeEvent, ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';

import { InputDropdownIcon, InputDropdownIconUp } from 'assets/svg';

import { Input } from 'components/common/Input/index';
import './index.css';

export interface DropdownOption {
  value: string | number;
  label: string;
}

interface InputDropdownProps {
  options: DropdownOption[];
  setSelectedOption: (option: DropdownOption) => void;
  placeholder: string;
  handleOnBlur?: () => void;
  className?: string;
  setEnteredValue?: (value: string) => void;
  isLoading?: boolean;
  defaultOption?: DropdownOption;
  disabled?: boolean;
  label?: string;
}

const InputDropdown = forwardRef(
  (props: InputDropdownProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      options,
      setSelectedOption,
      setEnteredValue,
      disabled,
      placeholder,
      isLoading,
      defaultOption,
      handleOnBlur,
      label,
    } = props;
    const [inputValue, setInputValue] = useState<string>('');
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    /* Filter options based on input */
    const filteredOptions = options
      .filter((option) => option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase()))
      .sort((a, b) => a?.label?.localeCompare(b?.label));

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    };

    useEffect(() => {
      if (inputValue) {
        setEnteredValue?.(inputValue);
      }
    }, [inputValue, setEnteredValue]);

    /* Handle option click  */
    const handleOptionClick = (option: DropdownOption) => {
      setSelectedOption(option);
      setInputValue(option.label);
      setDropdownVisible(false);
    };

    const handleFocus = () => setDropdownVisible(true);

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setDropdownVisible(false);
      }
    };

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setDropdownVisible(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    return (
      <div className='relative' ref={dropdownRef}>
        <div className='items-center rounded-lg'>
          <Input
            type='text'
            placeholder={placeholder}
            value={inputValue}
            label={label}
            onChange={handleInputChange}
            onFocus={handleFocus}
            className={props.className}
            ref={ref}
            onBlur={handleOnBlur}
            icon={dropdownVisible ? InputDropdownIconUp : InputDropdownIcon}
            disabled={disabled}
          />
        </div>
        {dropdownVisible && (
          <ul
            className={`absolute z-10 w-full overflow-y-auto bg-primary-100 ${inputValue.length > 3 ? 'border text-neutral-800 border-gray-300' : ''} rounded-b-lg shadow-lg max-h-52 custom-scrollbar input-dropdown-custom-scrollbar`}
          >
            {isLoading ? (
              <div className='flex items-center justify-center w-full p-2'>
                <Spinner color={'gray'} size={'lg'} />
              </div>
            ) : (
              <>
                {filteredOptions?.map((option, index) => (
                  <li
                    key={option.value}
                    onClick={() => handleOptionClick(option)}
                    className={`pt-3 ${index === filteredOptions.length - 1 ? 'pb-3' : ''} px-5 cursor-pointer text-neutral-800 body-medium lg:text-base hover:text-neutral-800 hover:bg-primary-400`}
                  >
                    {option.label}
                    {index < filteredOptions.length - 1 && (
                      <hr className='mt-3 bg-neutral-150 h-[1px] border-none' />
                    )}
                  </li>
                ))}
                {/* Added as the default option */}
                {defaultOption && inputValue.length > 3 && (
                  <li
                    onClick={() =>
                      handleOptionClick({
                        value: defaultOption?.value,
                        label: defaultOption?.label,
                      })
                    }
                    className='px-5 pt-3 pb-3 cursor-pointer body-medium lg:text-base text-neutral-800 hover:text-neutral-800 hover:bg-primary-400'
                  >
                    {defaultOption?.label}
                  </li>
                )}
              </>
            )}
          </ul>
        )}
      </div>
    );
  },
);

export default InputDropdown;
