import { CONFIG } from 'configs';
import { Element, EvaluationType, ITeach } from 'types/dashboard/parentDashboard';

const getEvaluationData = (evaluation?: EvaluationType): EvaluationType => ({
  correctAnswers: evaluation?.correctAnswers || 0,
  totalQuestions: evaluation?.totalQuestions || 0,
});

export const useGetStudentsPerformance = (
  elementWiseClassProgressDetails: Element[],
  classFlowTeachphaseDetails: ITeach[],
) => {
  const studentPerformance = classFlowTeachphaseDetails?.map((teach) => {
    let elementWiseDetails;

    if (teach.type === CONFIG.ROOMS.TEACH) {
      elementWiseDetails = elementWiseClassProgressDetails?.find(
        (obj) => obj?.elementId === teach?.elementId,
      );
    } else if (teach.type === 'evaluation') {
      elementWiseDetails = elementWiseClassProgressDetails?.find((obj) =>
        obj?.evaluations?.some((evaluation) => evaluation?.evaluationId === teach?.evaluationId),
      );
    }

    if (!elementWiseDetails) {
      return {
        elementId: teach?.elementId || '',
        elementName: teach?.name || '',
        performanceScore: 0,
        evaluationType: {
          pollsEvaluation: getEvaluationData(),
          oralEvaluation: getEvaluationData(),
          workbookEvaluation: getEvaluationData(),
        },
      };
    }

    const elementWiseOralEvaluation = elementWiseDetails?.evaluationTypes?.oral_evaluations;
    const elementWiseWorkbookEvaluation = elementWiseDetails?.evaluationTypes?.notebook_evaluations;
    const elementWisePollEvaluation = elementWiseDetails?.evaluationTypes?.poll_evaluations;

    return {
      elementId: teach?.elementId || '',
      elementName: teach?.name || '',
      performanceScore: elementWiseDetails?.performanceScore || 0,
      evaluationType: {
        pollsEvaluation: getEvaluationData(elementWisePollEvaluation),
        oralEvaluation: getEvaluationData(elementWiseOralEvaluation),
        workbookEvaluation: getEvaluationData(elementWiseWorkbookEvaluation),
      },
    };
  });

  return studentPerformance;
};
