import { io } from 'socket.io-client';

type Params = {
  userId: string;
  userType: 'student' | 'tutor' | 'guardian';
  classId: string;
};

export const connectToSocket = ({ userId, userType, classId }: Params) => {
  const socket = io(import.meta.env.VITE_BASE_SOCKET_URL, {
    query: {
      id: userId,
      userType,
      classId,
    },
    transports: ['websocket'],
  });
  return socket;
};
