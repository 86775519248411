import {
  IAiReport,
  IClassNotesAnalytics,
  IExamSchedules,
  IGetUserPassCode,
  IListOfClassesResponse,
  IPracticeQuestionsResponse,
  IPracticeSheet,
  ISiblingGuardianResponse,
  ITutorResponse,
  IUpcomingClasses,
  IUpdateClassNotesAnalytics,
} from 'types';
import { ApiResponse } from 'types/common';
import { ICurrentClass } from 'types/democlass';
import apiSlice from '.';

const studentDashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    /* get the list of classes */
    getListOfClasses: builder.query<
      IListOfClassesResponse,
      { studentId: number; pageNumber: number; pageSize: number }
    >({
      query: ({ studentId, pageNumber, pageSize }) => ({
        url: `/classes/list?studentId=${studentId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
        method: 'GET',
      }),
    }),

    /* get the tutor details  */
    getTutorDetails: builder.query<ITutorResponse, { tutorId: number }>({
      query: ({ tutorId }) => ({
        url: `/tutors?tutorId=${tutorId}`,
        method: 'GET',
      }),
    }),

    /* get the current class details */
    getCurrentClassDetails: builder.query<ICurrentClass, void>({
      query: () => ({
        url: '/classes/current',
      }),
    }),

    /* get the class details */
    getClassDetails: builder.query<
      IUpcomingClasses,
      { filter: object; pageNumber?: number; pageSize?: number; sort?: object }
    >({
      query: ({ filter = {}, pageNumber = 1, pageSize = 10, sort }) => ({
        url: '/v2/classes',
        params: {
          filter,
          page: pageNumber,
          pagesize: pageSize,
          sort,
        },
      }),
    }),

    /* get the student parent details */
    getStudentParentDetails: builder.query<ISiblingGuardianResponse, void>({
      query: () => ({
        url: '/auth/student-guardians/sibling-guardians-list',
        method: 'POST',
        keepUnusedDataFor: 0,
      }),
    }),

    getStudentParentDetailsForInClass: builder.query<ISiblingGuardianResponse, void>({
      query: () => ({
        url: '/auth/student-guardians/sibling-guardians-list',
        method: 'POST',
        keepUnusedDataFor: 0,
      }),
    }),

    /* get the list of exam schedule */
    getExamSchedule: builder.query<
      IExamSchedules,
      { schoolId: number; gradeId: string; boardId: string }
    >({
      query: ({ schoolId, gradeId, boardId }) => ({
        url: `/school/exam-schedules?schoolId=${schoolId}&gradeId=${gradeId}&boardId=${boardId}`,
        method: 'GET',
      }),
    }),

    /* POST -> practice evaluation QR code notebook capture */
    addPracticeCaptures: builder.mutation<
      {
        data: {
          imageUrls: string;
          isAlreadySubmitted?: boolean;
        };
      },
      { formData: FormData }
    >({
      query: ({ formData }) => ({
        url: `/v2/note-answers/practice-captures`,
        method: 'POST',
        body: formData,
      }),
    }),

    /* Get the practice class questions data */
    getPracticeSheetQuestion: builder.query<
      IPracticeQuestionsResponse,
      { practiceSheetId: string; evaluationId: string; studentId?: string }
    >({
      query: ({ practiceSheetId, evaluationId, studentId = null }) => ({
        url: `/v2/practice-sheets/${practiceSheetId}/evaluations/${evaluationId}`,
        method: 'GET',
        params: {
          studentId: studentId ?? null,
        },
      }),
    }),

    /* Get the practice sheet for Not submitted and filtering the by the deadlines */
    getPracticeSheetDetails: builder.query<
      IPracticeSheet,
      { filter: object; pageNumber?: number; pageSize?: number; studentId?: string; sort?: object }
    >({
      query: ({
        filter = {},
        pageNumber = 1,
        pageSize = 6,
        studentId = undefined,
        sort = 'desc',
      }) => ({
        url: '/v2/practice-sheets/evaluations',
        params: {
          filter,
          page: pageNumber,
          pageSize: pageSize,
          studentId: studentId ?? undefined,
          sort,
        },
      }),
    }),

    /* Get the ai evaluation report */
    getPracticeNoteBookCaptureSubmission: builder.query<
      IAiReport,
      { studentId?: number; evaluationId: string; questionId: string; practiceSheetId: string }
    >({
      query: ({ studentId, evaluationId, questionId, practiceSheetId }) => ({
        url: `/v2/dashboard/ai-evaluation-report?`,
        params: {
          questionId,
          studentId,
          evaluationId,
          practiceSheetId,
        },
        method: 'GET',
      }),
    }),

    /* Update the practice sheet submission for a student */
    updatePracticePaperSubmission: builder.mutation<
      ApiResponse,
      { practiceSheetId: string; evaluationId: string }
    >({
      query: ({ practiceSheetId, evaluationId }) => ({
        url: `/v2/practice-sheets/${practiceSheetId}/evaluations/${evaluationId}/submit`,
        method: 'PATCH',
      }),
    }),

    getUserPasscode: builder.query<IGetUserPassCode, void>({
      query: () => ({
        url: `/auth/student-guardians/passcode`,
        method: 'GET',
      }),
    }),

    // Class Notes Analytics API's
    addClassNotesAnaltyics: builder.mutation<void, IClassNotesAnalytics>({
      query: (classNotesAanalyticsBody) => ({
        url: `/v2/class-notes/analytics`,
        method: 'POST',
        body: classNotesAanalyticsBody,
      }),
    }),

    updateClassNotesAnaltyics: builder.mutation<void, IUpdateClassNotesAnalytics>({
      query: ({ classId, elementId, slideNo, viewed }) => ({
        url: `/v2/class-notes/analytics/${classId}/${elementId}`,
        method: 'PATCH',
        body: {
          slideNo: slideNo,
          viewed: viewed,
        },
      }),
    }),
  }),
});

export const {
  useGetListOfClassesQuery,
  useGetTutorDetailsQuery,
  useGetCurrentClassDetailsQuery,
  useGetClassDetailsQuery,
  useGetStudentParentDetailsQuery,
  useGetExamScheduleQuery,
  useGetStudentParentDetailsForInClassQuery,
  useGetPracticeSheetQuestionQuery,
  useGetPracticeNoteBookCaptureSubmissionQuery,
  useAddPracticeCapturesMutation,
  useGetPracticeSheetDetailsQuery,
  useUpdatePracticePaperSubmissionMutation,
  useGetUserPasscodeQuery,
  useAddClassNotesAnaltyicsMutation,
  useUpdateClassNotesAnaltyicsMutation,
} = studentDashboardApiSlice;
