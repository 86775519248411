import { useCallback, useContext, useEffect, useState } from 'react';
import { SocketContext } from 'contexts';
import { CONFIG } from 'configs';
import useMeeting from './inClass/useMeeting';
import { useAppDispatch, useAppSelector } from './store';
import { useSendParentNetworkAlertMutation } from 'store/apiSlices/inClass/inClassCommon.apiSlice';
import { selectNetworkLowCounter, setNetworkCounter } from 'store/slice/networkIssueCounter.slice';

interface Payload {
  kind: string;
  isScreenshare: boolean;
  score: number;
  participantId: string;
}

const useNetworkStatus = () => {
  const [activateMorphcast, setActivateMorphcast] = useState<boolean>(true);
  const { classId, studentId, socketUser, meeting } = useMeeting();
  const socket = useContext(SocketContext);
  const networkLowCounter = useAppSelector(selectNetworkLowCounter);

  const dispatch = useAppDispatch();
  const [sendParentNetworkAlert] = useSendParentNetworkAlertMutation();

  const handleMediaScoreUpdate = useCallback(
    ({ score, kind }: Payload) => {
      const lowNetworkHandler = (score: number) => {
        socket?.emit('networkScore', { networkScore: score, studentId });
        if (score < CONFIG.MIN_NETWROK_SCORE) {
          setActivateMorphcast(false);
          dispatch(
            setNetworkCounter({
              classId,
              networkLow: true,
            }),
          );
          socket?.emit(
            'message',
            JSON.stringify({
              classId,
              type: socketUser,
              networkStatus: 'LOW',
            }),
          );
        } else {
          setActivateMorphcast(true);
          dispatch(
            setNetworkCounter({
              classId,
              networkLow: false,
            }),
          );
        }
      };

      if (kind === 'video' && meeting.self.videoEnabled) {
        lowNetworkHandler(score);
      } else if (kind === 'audio' && meeting.self.audioEnabled) {
        lowNetworkHandler(score);
      }
    },
    [
      meeting.self.videoEnabled,
      meeting.self.audioEnabled,
      dispatch,
      socket,
      studentId,
      classId,
      socketUser,
    ],
  );

  useEffect(() => {
    const handleUpdate = handleMediaScoreUpdate;

    meeting.self.on('mediaScoreUpdate', handleUpdate);

    return () => {
      meeting.self.off('mediaScoreUpdate', handleUpdate);
    };
  }, [meeting.self, handleMediaScoreUpdate]);

  useEffect(() => {
    const minCounter = Number(CONFIG.SEND_NETWORK_ALERT_MIN_COUNTER);
    if (networkLowCounter === minCounter && classId) {
      sendParentNetworkAlert({ classId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkLowCounter, classId]);

  return {
    activateMorphcast: activateMorphcast,
  };
};

export default useNetworkStatus;
