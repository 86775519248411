import { useEffect } from 'react';

import { IUseEvalRender } from 'types';
import { useMeeting } from 'hooks';
import { selectMultiLecture } from 'store/slice/lecture.slice';
import { useAppSelector } from 'hooks/store';
import { useAppDispatch } from 'hooks/store';
import { setEvalId } from 'store/slice/content.slice';
import { CONFIG } from 'configs';

const useEvalRender = ({ setRender, evalTrigger }: IUseEvalRender) => {
  const lectureData = useAppSelector(selectMultiLecture);
  const { classState, isHostPresent, meeting } = useMeeting();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (evalTrigger.meetingId === meeting?.meta?.meetingId) {
      if (evalTrigger.evalType === 'poll_evaluations') {
        dispatch(setEvalId(evalTrigger.evalId));
        setRender((prev) => ({ ...prev, polls: evalTrigger.status }));
      } else if (
        evalTrigger.evalType === 'notebook_evaluations' ||
        evalTrigger.evalType === 'practice_evaluations'
      ) {
        dispatch(setEvalId(evalTrigger.evalId));
        setRender((prev) => ({ ...prev, written: evalTrigger.status }));
      } else if (evalTrigger.evalType === 'oral_evaluations') {
        setRender((prev) => ({ ...prev, oral: evalTrigger.status }));
      }
    }
  }, [
    classState.level,
    dispatch,
    evalTrigger.evalId,
    evalTrigger.evalType,
    evalTrigger.meetingId,
    evalTrigger.slideNo,
    evalTrigger.status,
    meeting?.meta?.meetingId,
    setRender,
  ]);

  useEffect(() => {
    const classPhase =
      classState.type === CONFIG.ROOMS.TEACH
        ? CONFIG.ROOMS.TEACH
        : classState.level === CONFIG.ROOMS.COACH_BASIC
          ? CONFIG.ROOMS.COACH_BASIC
          : classState.level === CONFIG.ROOMS.COACH_INTERMEDIATE
            ? CONFIG.ROOMS.COACH_INTERMEDIATE
            : classState.level === CONFIG.ROOMS.COACH_ADVANCE
              ? CONFIG.ROOMS.COACH_ADVANCE
              : '';

    if (classPhase && classState.type !== CONFIG.ROOMS.TEACH && !isHostPresent) {
      const evaluationData = lectureData?.[classPhase]?.find(
        (evalData) => evalData.type === 'evaluation',
      );

      if (
        evaluationData &&
        'evaluationId' in evaluationData &&
        evaluationData.evaluationId &&
        'evaluationType' in evaluationData
      ) {
        dispatch(setEvalId(evalTrigger.evalId));
        switch (evaluationData.evaluationType) {
          case 'poll_evaluations':
            setRender((prev) => ({ ...prev, polls: true }));
            break;
          case 'notebook_evaluations':
            setRender((prev) => ({ ...prev, written: true }));
            break;
          case 'oral_evaluations':
            setRender((prev) => ({ ...prev, oral: true }));
            break;
        }
      }
    }
  }, [classState, isHostPresent, lectureData, setRender, evalTrigger.evalId, dispatch]);

  return null;
};

export default useEvalRender;
