import moment from 'moment';

import { IListOfClasses } from 'types';
import { IDemoClassBook, IQueryParams } from 'types/democlass';

/* check if class is completed */
const isClassCompleted = (classData: IListOfClasses) => {
  const meetingEndTime = classData?.classDetails?.meetingEndTime;
  const isCompleted = moment().isAfter(meetingEndTime);
  return isCompleted;
};

/* check if class is within 1 minute */
const isWithinOneMinute = (classData: IListOfClasses) => {
  const meetingStartTime = classData?.classDetails?.meetingStartTime;
  const fifteenMinutesBeforeStart = moment(meetingStartTime).subtract(15, 'minutes');
  const isWithinOneMinute = moment().isBetween(fifteenMinutesBeforeStart, meetingStartTime);
  return isWithinOneMinute;
};

/* format time range for the class eg: 6 - 7 AM / PM */
const formatTimeRange = (startTime: string, endTime: string) => {
  const start = moment(startTime);
  const end = moment(endTime);

  // Check if minutes are available
  const startTimeFormat = start.minutes() !== 0 ? 'h:mm' : 'h';
  const endTimeFormat = end.minutes() !== 0 ? 'h:mm A' : 'h A';

  const formattedStartTime = start.format(startTimeFormat);
  const formattedEndTime = end.format(endTimeFormat);

  return `${formattedStartTime} - ${formattedEndTime}`;
};

/* For Demo class booking the time format */
const demoClassTimeFormat = (
  startTime: string,
  endTime: string,
  startAmPm: string,
  endAmPm: string,
) => {
  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');

  let formattedStartTime = start.minutes() === 0 ? start.format('h') : start.format('h:mm');
  formattedStartTime += ' ' + startAmPm;

  let formattedEndTime = end.minutes() === 0 ? end.format('h') : end.format('h:mm');
  formattedEndTime += ' ' + endAmPm;

  return `${formattedStartTime} - ${formattedEndTime}`;
};

/* Registration Successful modal time format */
const registrationSuccessTimeFormat = (demoClassBookedData: IDemoClassBook) => {
  const timeSlot = JSON.parse(demoClassBookedData?.timeSlot || '{}');
  const startTime = moment(timeSlot.startTime, 'hh:mm A');
  const endTime = moment(timeSlot.endTime, 'hh:mm A');

  if (timeSlot.startAmPm === 'PM') {
    startTime.add(12, 'hours');
  }
  if (timeSlot.endAmPm === 'PM') {
    endTime.add(12, 'hours');
  }

  const format = moment(demoClassBookedData?.classDate).format('dddd, MMM Do');
  return `${format} at ${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`;
};

const getQueryParams = (queryString: string): IQueryParams => {
  return queryString
    .slice(1)
    .split('&')
    .reduce((acc: IQueryParams, pair: string) => {
      const [key, value] = pair.split('=');
      acc[decodeURIComponent(key)] = decodeURIComponent(value);
      return acc;
    }, {});
};

export {
  isClassCompleted,
  isWithinOneMinute,
  formatTimeRange,
  demoClassTimeFormat,
  registrationSuccessTimeFormat,
  getQueryParams,
};
