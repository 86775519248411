import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Countdown from 'react-countdown';

import Modal from 'components/common/Modal';
import RenderEvalContent from 'components/inClass/RenderEvalContent';
import { selectEvalId } from 'store/slice/content.slice';
import { selectMyCurrentState } from 'store/slice/inClassConfig.slice';
import { selectMultiLecture } from 'store/slice/lecture.slice';

import { Clock } from 'assets/svg';
import { useAppSelector } from 'hooks/store';

import {
  ATTEMPT_QUESTIONS,
  CONFIG,
  CONTENT_TYPE,
  GUIDED_SOLUTIONS,
  GUIDED_TYPE,
  NO_ANSWER_SEEN,
  STAY_ON_GUIDED_SOLUTION,
} from 'configs';
import { useInclassLogger, useMeeting } from 'hooks';
import { EvaluationData, IGuidedSolutions } from 'types';
import '../NotebookEval/styles.css';

export default function GuidedSolutions({ onMoveToQuestions }: IGuidedSolutions) {
  // States
  const [visibleSolutions, setVisibleSolutions] = useState<Record<string, boolean>>({});
  const [showMoveWarning, setShowMoveWarning] = useState<boolean>(false);
  const [questionsData, setQuestionsData] = useState<EvaluationData[]>([]);

  const progressionMode = useMemo(
    () => (GUIDED_SOLUTIONS?.MODE ? GUIDED_TYPE.MANUAL : GUIDED_TYPE.AUTOMATIC),
    [],
  );
  const [countdownKey, setCountdownKey] = useState<number>(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);

  // Redux selectors
  const myCurrentState = useAppSelector(selectMyCurrentState) || CONFIG.ROOMS.TEACH;
  const lectureData = useAppSelector(selectMultiLecture);
  const evalId = useAppSelector(selectEvalId);

  // Hooks
  const { studentName } = useMeeting();
  const { datadogLog } = useInclassLogger();

  // Updating countdownDate with countdownKey dependency for reset
  const countdownDate = useMemo(
    () => Date.now() + Number(GUIDED_SOLUTIONS.TIMEOUT) + countdownKey,
    [countdownKey],
  );

  // Initialize component and check completion status
  useEffect(() => {
    if (lectureData && !isInitialized) {
      const currentLecture = lectureData[myCurrentState]?.find(
        (lecture) => lecture?.type === CONTENT_TYPE.EVALUATION && lecture?.evaluationId === evalId,
      );

      if (currentLecture?.guidedSolutions) {
        setQuestionsData(currentLecture?.guidedSolutions);
        setIsInitialized(true);
      }
    }
  }, [lectureData, evalId, myCurrentState, isInitialized, onMoveToQuestions]);

  // Handle solution toggle
  const handleToggleSolution = useCallback(
    (questionId: string) => {
      setVisibleSolutions((prev) => ({
        ...prev,
        [questionId]: !prev[questionId],
      }));

      datadogLog(
        `${studentName} ${visibleSolutions[questionId] ? 'hidden' : 'viewed'} solution for question ${questionId}`,
      );
    },
    [datadogLog, studentName, visibleSolutions],
  );

  // Handle moving to questions
  const handleMoveToQuestions = useCallback(() => {
    if (isEmpty(visibleSolutions)) {
      setShowLeaveConfirmation(true);
    } else {
      setShowMoveWarning(true);
    }
  }, [visibleSolutions]);

  // Confirm move to questions
  const confirmMove = useCallback(() => {
    setShowMoveWarning(false);
    setShowLeaveConfirmation(false);
    onMoveToQuestions();
  }, [onMoveToQuestions]);

  const closeModal = () => {
    setShowMoveWarning(false);
    setShowLeaveConfirmation(false);
    setCountdownKey((prev) => prev + 1);
  };

  // Handle countdown completion
  const handleCountdownComplete = useCallback(() => {
    if (!showLeaveConfirmation && !showMoveWarning) {
      if (isEmpty(visibleSolutions)) {
        setShowLeaveConfirmation(true);
      } else {
        setShowMoveWarning(true);
      }
      setCountdownKey((prev) => prev + 1);
    }
  }, [visibleSolutions, showLeaveConfirmation, showMoveWarning]);

  const countdownRenderer = useCallback(
    ({ seconds }: { seconds: number }) => {
      if (progressionMode === GUIDED_TYPE.AUTOMATIC) {
        return (
          <div className='flex items-center mt-2 space-x-2 text-sm text-white'>
            <img src={Clock} alt='clock' className='w-5 h-5' />
            <span>{seconds}s</span>
          </div>
        );
      }
      return null;
    },
    [progressionMode],
  );

  const renderCountdown = useMemo(() => {
    if (progressionMode === GUIDED_TYPE.AUTOMATIC) {
      return (
        <Countdown
          key={countdownKey}
          date={countdownDate}
          renderer={countdownRenderer}
          onComplete={handleCountdownComplete}
          autoStart={!showMoveWarning || !showLeaveConfirmation}
        />
      );
    }
    return null;
  }, [
    progressionMode,
    countdownKey,
    countdownDate,
    countdownRenderer,
    handleCountdownComplete,
    showMoveWarning,
    showLeaveConfirmation,
  ]);

  return (
    <div className='flex flex-col h-full p-6 rounded-lg shadow-lg'>
      <div className='relative overflow-y-auto text-xl evalScrollbar grow'>
        {/* Header */}
        <div className='sticky top-0 flex flex-col items-center w-full py-4 text-xl rounded-lg shadow-lg subHeading'>
          <h2 className='text-2xl font-semibold text-white'>Guided Examples</h2>
          {progressionMode === GUIDED_TYPE.AUTOMATIC && (
            <div className='flex items-center mt-2 space-x-4'>{renderCountdown}</div>
          )}
        </div>

        {/* Questions and Solutions */}
        <div className='mt-4'>
          {questionsData?.map((question) => (
            <div
              key={question._id}
              className='p-5 mb-4 text-white border rounded-lg shadow-sm border-gray-850 bg-gray-850'
            >
              <div className='flex items-center justify-between w-full mb-3 font-medium'>
                <RenderEvalContent data={question.question_description} />
                <button
                  onClick={() => handleToggleSolution(question._id)}
                  className='px-3 py-1 text-sm transition-colors rounded-lg bg-primary-500 hover:bg-primary-600'
                >
                  {visibleSolutions[question._id] ? 'Hide Answer' : 'Show Answer'}
                </button>
              </div>

              {visibleSolutions[question._id] && (
                <div className='p-2 text-white rounded-lg shadow-lg bg-primary-850'>
                  <h3 className='text-2xl font-semibold text-white'>Solution:</h3>
                  <RenderEvalContent data={question.answer} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Move to Questions Button - Manual mode only */}
      {progressionMode === GUIDED_TYPE.MANUAL && (
        <div className='flex justify-end mt-4'>
          <button
            onClick={handleMoveToQuestions}
            className='px-4 py-2 font-semibold text-white bg-green-500 rounded-lg hover:bg-green-600'
          >
            Move to Questions
          </button>
        </div>
      )}

      {/* Confirmation Modals */}
      <Modal openModal={showMoveWarning} setOpenModal={closeModal} heading='Moving to Questions'>
        <div className='p-6'>
          <p className='mb-6 text-center body-semibold'>
            Are you sure you want to Attempt questions? You've viewed{' '}
            {Object.keys(visibleSolutions).length} / {questionsData?.length} solutions.
          </p>
          <div className='flex justify-center space-x-4'>
            <button
              onClick={closeModal}
              className='px-4 py-2 text-white rounded-lg bg-secondary-600 hover:bg-secondary-700'
            >
              {STAY_ON_GUIDED_SOLUTION}
            </button>
            <button
              onClick={confirmMove}
              className='px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600'
            >
              {ATTEMPT_QUESTIONS}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        openModal={showLeaveConfirmation}
        setOpenModal={closeModal}
        heading='No Solutions Viewed'
      >
        <div className='p-6'>
          <p className='mb-6 text-center body-semibold'>{NO_ANSWER_SEEN}</p>
          <div className='flex justify-center space-x-4'>
            <button
              onClick={closeModal}
              className='px-4 py-2 text-white rounded-lg bg-secondary-600 hover:bg-secondary-700'
            >
              {STAY_ON_GUIDED_SOLUTION}
            </button>
            <button
              onClick={confirmMove}
              className='px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600'
            >
              {ATTEMPT_QUESTIONS}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
