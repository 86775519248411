import { Badge, Label } from 'flowbite-react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { ErrorResponse } from 'react-router-dom';
import * as Yup from 'yup';

import { useGetSubjectsQuery } from 'store/apiSlices/common.apiSlice';
import {
  useBookDemoOrTrialClassMutation,
  useGetDemoClassConfigQuery,
} from 'store/apiSlices/demoClass.apiSlice';
import { selectRegisterationData } from 'store/slice/studentRegister.slice';

import { Button, Loader, Radio } from 'components/common/index';

import { Book, CompassLight } from 'assets/svg';
import { useAppSelector } from 'hooks/store';
import { IDemoClassValues } from 'types/auth';
import { IDemoClassDate } from 'types/democlass';

import { APP_DEFAULT_MESSAGES, DEMO_CLASS_BOOKING_ERROR, SUBJECTS } from 'configs';
import { demoClassTimeFormat, getDemoClassDatesByCount } from 'utils';

interface IDemoClassCheck {
  studentId: number;
  gradeName: string;
  isDemoClassBooked: (isBooked: boolean) => void;
}

export default function DemoClassCheck({
  studentId,
  gradeName,
  isDemoClassBooked,
}: IDemoClassCheck) {
  const registeredData = useAppSelector(selectRegisterationData);

  /* API -> Get Subjects , Get Demo Class Config , Book Demo Class */
  const { data: subjectsData, isLoading: isSubjectLoading } = useGetSubjectsQuery();
  const { data: configurationData, isLoading: isInitialLoading } = useGetDemoClassConfigQuery();
  const [bookDemoOrTrialClass, { isLoading }] = useBookDemoOrTrialClassMutation();

  const filterSubjectName = subjectsData?.data[0]?.name;
  const configData = configurationData?.data?.[0]?.value;

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<IDemoClassValues>();
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState<number>();
  const [selectedDate, setSelectedDate] = useState<string>('');

  // Book Demo Class
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      studentId: studentId || '',
      batchStartDate: '',
      subjectId: subjectsData?.data[0]?.id || 1,
      batchEndDate: '',
      classDate: '',
      classTime: '',
      gradeId: registeredData?.gradeId || '',
      boardId: registeredData?.boardId || '',
    },
    validationSchema: Yup.object({
      classDate: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.DEMO_CLASS_DATA_REQUIRED),
      subjectId: Yup.string().required(
        APP_DEFAULT_MESSAGES?.VALIDATION?.DEMO_CLASS_SUBJECT_REQUIRED,
      ),
      classTime: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.DEMO_CLASS_TIME_REQUIRED),
    }),
    onSubmit: (values) => {
      let combinedStartDateTime;
      let combinedEndDateTime;
      if (selectedTimeSlot) {
        const { startTime, endTime, startAmPm, endAmPm } = selectedTimeSlot;

        combinedStartDateTime = `${values.classDate} ${startTime} ${startAmPm}`;
        combinedEndDateTime = `${values.classDate} ${endTime} ${endAmPm}`;
      }
      const isoStartDateTime = moment(combinedStartDateTime, 'YYYY-MM-DD hh:mm A').toISOString();
      const isoEndDateTime = moment(combinedEndDateTime, 'YYYY-MM-DD hh:mm A').toISOString();
      const formData = {
        studentId: Number(values?.studentId) || Number(studentId),
        batchStartDate: values.classDate || '',
        subjectId: values?.subjectId || '',
        batchEndDate: values.classDate || '',
        meetingStartTime: isoStartDateTime || '',
        meetingEndTime: isoEndDateTime || '',
      };
      bookDemoOrTrialClass({ formData: formData })
        .unwrap()
        .then((payload) => {
          if (payload?.data) {
            isDemoClassBooked(true);
          }
        })
        .catch((error) => {
          toast.error((error as ErrorResponse)?.data?.message || DEMO_CLASS_BOOKING_ERROR);
        });
    },
  });

  //TODO: handle subject change in demo class
  /*   const handleSubjectChange = (id: number) => {
    setSelectedSubjectId(id);
    formik.setFieldValue('subjectId', id);
    const subjectInfo = filteredSubjects?.find((subject) => subject?.id === id)?.name;
    setDemoClassBookDetails((prev) => ({ ...prev, subjectName: subjectInfo || '' }));
  }; */

  const handleTimeSlotChange = (id: number) => {
    setSelectedTimeSlotId(id);
    if (configurationData?.data) {
      const slots = configurationData?.data || [];
      const timeSlots: IDemoClassValues[] = slots[0]?.value;
      setSelectedTimeSlot(timeSlots[id]);
      const { startTime, endTime, startAmPm, endAmPm } = timeSlots[id];
      const classTime = `{ "startTime": "${startTime}", "endTime": "${endTime}", "startAmPm": "${startAmPm}", "endAmPm": "${endAmPm}" }`;
      formik.setFieldValue('classTime', classTime);
    }
  };

  /* setting the date value to the formik state */
  const handleDemoClassDateChange = (demoCls: IDemoClassDate) => {
    setSelectedDate(demoCls?.id);
    const formattedDate = moment(demoCls?.fullDate)
      .startOf('day')
      .tz('Asia/Kolkata')
      .format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    formik.setFieldValue('classDate', moment(new Date(formattedDate)).format('Y-MM-DD'));
    formik.setFieldValue('batchStartDate', moment(new Date(formattedDate)).format('Y-MM-DD'));
    formik.setFieldValue('batchEndDate', moment(new Date(formattedDate)).format('Y-MM-DD'));
  };

  return (
    <>
      {isInitialLoading || isSubjectLoading ? (
        <Loader className='relative' />
      ) : (
        <form onSubmit={formik.handleSubmit} className='flex-1 h-full'>
          <div className='flex flex-col justify-between flex-1 h-full px-1 pb-3 mx-2 mb-5 sm:mx-10 lg:mx-0'>
            <div>
              <div className='flex flex-row w-full gap-3 mt-3'>
                <div className='flex-1'>
                  <Label
                    value='Subject*'
                    className='text-sm leading-5 uppercase cap-semibold text-neutral-500'
                  />
                  <Badge className='mt-3 rounded-lg bg-primary-100'>
                    <span className='text-sm p-1 py-2 cap-semibold flex items-center gap-2 pr-4 tracking-widest text-primary-400 md:text-base lg:text-base leading-[19.3px]'>
                      <img
                        src={CompassLight}
                        alt='compass'
                        className='p-1 rounded-md bg-primary-500'
                      />
                      {filterSubjectName || SUBJECTS.MATHS}
                    </span>
                  </Badge>
                  {/* //TODO:Future more subject we have to use this component  */}
                  {/*<div className='mt-3'>
                    {filteredSubjects?.map((sub: subjects) => (
                      <div
                        onClick={() => handleSubjectChange(sub?.id)}
                        className={`border-1 ring-neutral-150 ring-1 rounded-xl ${selectedSubjectId === sub?.id ? 'bg-primary-400' : 'bg-white'}`}
                      >
                        <Radio
                          key={sub?.value}
                          id={sub?.id.toString()}
                          onChange={() => handleSubjectChange(sub?.id)}
                          name={sub?.name}
                          label={sub?.label}
                          checked={selectedSubjectId === sub?.id || sub?.name === 'maths'}
                          className={`rounded-sm text-center hover:bg-white hover:text-white `}
                          lableClassName={`md:pl-1 body-semibold lg:text-sm  ${selectedSubjectId === sub?.id ? 'text-white' : 'text-neutral-800'}`}
                        />
                      </div>
                    ))}
                    {formik.touched.subjectId && formik.errors.subjectId && (
                      <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                        subject is required
                      </p>
                    )} 
                  </div>*/}
                </div>
                <div className='flex-1'>
                  <Label
                    value='your grade*'
                    className='text-sm leading-5 uppercase cap-semibold text-neutral-500'
                  />
                  <div className='mt-3'>
                    <Badge className='rounded-lg bg-primary-100'>
                      <span className='text-sm p-1 py-2 cap-semibold flex items-center gap-2 pr-4 tracking-widest text-primary-400 md:text-base lg:text-base leading-[19.3px]'>
                        <img src={Book} alt='compass' className='p-1 rounded-md bg-primary-500' />
                        {gradeName}
                      </span>
                    </Badge>
                  </div>
                </div>
              </div>

              {/* Select Date */}
              <div className='mt-8'>
                <Label
                  value='select Date*'
                  className='text-sm leading-5 uppercase cap-semibold text-neutral-500'
                />
                <div className='flex flex-wrap justify-start gap-3 mt-3'>
                  {getDemoClassDatesByCount(5)?.map((demoClassDate: IDemoClassDate) => (
                    <div
                      onClick={() => handleDemoClassDateChange(demoClassDate)}
                      className={`border-1 ring-neutral-150 cursor-pointer ring-1 rounded-xl ${selectedDate === demoClassDate?.id ? 'bg-primary-150' : 'bg-white'}`}
                      key={demoClassDate?.id}
                    >
                      <Radio
                        key={demoClassDate?.id}
                        id={demoClassDate?.id}
                        onChange={() => setSelectedDate(demoClassDate.id)}
                        name='board'
                        children={
                          <div className='flex flex-col items-center gap-2 px-2 text-neutral-800'>
                            <h6 className='body-semibold lg:text-xs '>{demoClassDate?.name}</h6>
                            <h2 className='cap-semibold lg:text-sm'>{demoClassDate?.value}</h2>
                          </div>
                        }
                        checked={demoClassDate?.id === selectedDate}
                        lableClassName='body-medium lg:text-base'
                        className='flex items-center text-center rounded-sm hover:bg-neutral-100 hover:text-neutral-800'
                      />
                    </div>
                  ))}
                </div>
                {formik.touched.classDate && formik.errors.classDate && (
                  <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                    {formik.errors.classDate}
                  </p>
                )}
              </div>

              {/* pick a time slot */}
              <div className='mt-8'>
                <Label
                  value='Pick a time slot*'
                  className='text-sm leading-5 uppercase cap-semibold text-neutral-500'
                />
                <div className='flex flex-wrap justify-start gap-2 mt-3'>
                  {configData &&
                    configData?.map((item, index) => (
                      <label
                        key={index}
                        htmlFor={`timeSlot-${index}`}
                        className={`border-1 ring-neutral-150 ring-1 rounded-xl cursor-pointer ${selectedTimeSlotId === index ? 'bg-primary-150' : 'bg-white'}`}
                        onClick={() => handleTimeSlotChange(index)}
                      >
                        <div key={`timeSlot-${index}`} onClick={() => handleTimeSlotChange(index)}>
                          <Radio
                            id={`timeSlot-${index}`}
                            name='timeSlot'
                            label={demoClassTimeFormat(
                              item.startTime,
                              item.endTime,
                              item.startAmPm,
                              item.endAmPm,
                            )}
                            checked={selectedTimeSlotId === index}
                            className='text-center rounded-sm hover:bg-neutral-100 hover:text-neutral-800'
                            lableClassName={`md:pl-1 font-sora font-medium lg:text-sm text-neutral-800`}
                          />
                        </div>
                      </label>
                    ))}
                  {formik.touched.classTime && formik.errors.classTime ? (
                    <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                      {formik.errors.classTime}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>

            <div className='mt-8'>
              {/* Button */}
              <Button
                type='submit'
                className={`w-full bg-primary-500 enabled:hover:bg-primary-500 text-white rounded-lg`}
                text='Continue'
                isLoading={isLoading}
                disabled={!(formik.isValid && formik.dirty)}
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
}
