import React from 'react';

interface BouncingDotsLoaderProps {
  colorClasses?: string[];
}

const BouncingDotsLoader: React.FC<BouncingDotsLoaderProps> = ({
  colorClasses = ['bg-logo-colors-engage', 'bg-logo-colors-learn', 'bg-logo-colors-progress'],
}) => {
  return (
    <div className='flex items-center justify-center'>
      <div className='flex items-center justify-center gap-3'>
        {colorClasses?.map((colorClass, index) => (
          <div
            key={index}
            className={`${colorClass} rounded-full animate-bounce w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-8 lg:h-8`}
            style={{
              animationDelay: `${index * 0.1 + 0.1}s`,
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default BouncingDotsLoader;
