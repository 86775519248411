export const TermsData = [
  {
    title: 'Introduction',
    content:
      'School Prep Pvt Ltd (referred to as "we", "us", or "our") offers educational services and products through our website https://www.theschoolprep.com. This document outlines the Terms and Conditions (Terms) and Privacy Policy governing your use of our website, application, services, and products.',
  },
  {
    title: 'Acceptance of Terms',
    content:
      'By accessing or using our services, you agree to comply with and be bound by these Terms and the accompanying Privacy Policy. If you disagree with any part of these terms, please do not use our services.',
  },
  {
    title: 'Privacy Policy',
    content:
      'User Provided Information: We collect information that you provide when you download, register, or use our services. This includes, but is not limited to, your name, age, email address, phone number, and educational interests. This information may be considered as "Personal Information" or "Sensitive Personal Information".\n\nAutomatically Collected Information: We may collect certain information automatically, including your device ID, IP address, operating system, and usage details of our services.\n\nUse of Personal Information: The information collected is used to enhance your experience, provide our services, communicate updates, and for marketing promotions.\n\nCookies and Tracking Technologies: We use cookies and other tracking technologies to personalize your experience and analyze site usage.\n\nData Security: We take reasonable steps to protect your information but cannot guarantee absolute security.\n\nData Retention: We retain your personal data as long as necessary for the purposes outlined in this policy.\n\nThird-party Links: Our services may contain links to third-party sites, for which we are not responsible.\n\nSharing and Disclosure: We do not share personal information with third parties except as necessary to provide our services, comply with the law, or for marketing and analytical purposes with your consent.',
  },
  {
    title: 'Terms and Conditions',
    content:
      "Service Use: Our services are intended for personal, non-commercial use only. Any misuse or unauthorized use may lead to suspension or termination of services.\n\nIntellectual Property: All content and services are the property of School Prep Pvt Ltd or its licensors. Unauthorized use of any content is strictly prohibited.\n\nProhibited Activities: Users must not engage in activities that are illegal, infringe upon others' rights, or cause harm to others.\n\nLimitation of Liability: We are not liable for any indirect, incidental, or punitive damages arising from your use of our services.\n\nChanges to Terms: We reserve the right to modify these Terms and the Privacy Policy at any time. Continued use of the services constitutes acceptance of the new terms.\n\nGoverning Law: These Terms are governed by India/Karnataka law, and any disputes will be subject to the exclusive jurisdiction of its courts.\n\nContact Information: For any queries or concerns regarding these Terms or Privacy Policy, please contact us at developers@theschoolprep.com.",
  },
  {
    title: 'Conclusion',
    content:
      'This document constitutes the entire agreement between School Prep Pvt Ltd and its users regarding our services. By using our services, you acknowledge that you have read, understood, and agree to be bound by these Terms and the Privacy Policy.',
  },
];
