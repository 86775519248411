import Latex from 'react-latex-next';

import { IRenderContent } from 'types';
import './practice.css';

const RenderPracticeEvalContent = ({ data }: { data: IRenderContent[] }) => {
  return (
    <div className='p-2'>
      <div className='content-container'>
        {data.map((item, index) => {
          const uniqueKey = `practice_${item?.type}_${index}`;
          switch (item?.type) {
            case 'latex':
              return (
                <div
                  key={uniqueKey}
                  className='px-2 text-lg font-bold break-words latex-container lg:text-xl'
                >
                  <div className='text-lg font-bold break-words lg:text-xl'>
                    <Latex strict>{`$${item?.content}$`}</Latex>
                  </div>
                </div>
              );
            case 'break':
              return <br key={uniqueKey} />;
            case 'text':
              return (
                <span key={uniqueKey} className='inline-block break-words'>
                  {item?.content}
                </span>
              );
            case 'image':
              return (
                <div className='flex flex=col justify-start items-center'>
                  <img
                    className='inline object-contain h-auto p-1 my-2 rounded-xl w-72'
                    key={uniqueKey}
                    src={item?.content}
                    alt='image'
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};

export default RenderPracticeEvalContent;
