import { motion } from 'framer-motion';
import { useContext } from 'react';

import { NotificationContext } from 'contexts/NotificationContext';

const NotificationDisplay = () => {
  const { notification } = useContext(NotificationContext);
  if (!notification?.visible) return null;

  const backgroundColorClass =
    notification.type === 'success'
      ? 'bg-green-700'
      : notification.type === 'error'
        ? 'bg-red-700'
        : 'bg-yellow-700';

  const variants = {
    initial: { x: '100%', opacity: 0 },
    enter: { x: 0, opacity: 1 },
    exit: { x: '100%', opacity: 0 },
  };

  return (
    <motion.div
      initial='initial'
      animate='enter'
      exit='exit'
      variants={variants}
      className={`${backgroundColorClass} text-white px-3 py-1 rounded-lg shadow-md`}
    >
      {notification.message}
    </motion.div>
  );
};

export default NotificationDisplay;
