import Carousel from 'components/common/Carousel';

import './styles.css';
// import { HeroRightArrow, HeroSectionRight } from 'assets/svg';
import { SLIDES } from 'configs';

export default function HeroSection() {
  return (
    <>
      <div className='h-full lg:h-lvh lg:fixed order-first lg:order-last rounded-b-[3rem] lg:rounded-none flex flex-col lg:flex-row'>
        <div className='flex flex-col items-center justify-center pt-3 pb-1 lg:hidden'>
          <h6 className='w-5/6 py-2 text-sm leading-4 text-center text-neutral-500 body-regular md:max-w-xs md:text-sm'>
            Your own personal coach for your own guided learning | UPRIO mirrors your school
            curriculum and schedule - so that you are perfectly in sync.
          </h6>
        </div>
        <Carousel
          theme={{
            root: {
              leftControl: 'hidden',
              rightControl: 'hidden',
            },
            indicators: {
              active: {
                off: 'bg-neutral-500',
                on: 'bg-primary-500',
              },
              wrapper: 'flex justify-center my-1 md:my-0 lg:my-4 gap-2 lg:gap-4',
              base: 'w-8 md:w-12 h-1 rounded-sm md:rounded-full',
            },
            item: {
              base: 'flex flex-col items-center justify-center',
            },
          }}
          wrapperClass='h-fit w-full my-4 lg:my-5 xl:my-8 self-center'
          slideInterval={3000}
          slide={true}
          pauseOnHover={true}
        >
          {/* Mapping through slides and rendering them */}
          {SLIDES?.map((slide) => (
            <div key={slide?.id} className='flex flex-col w-full gap-1 lg:gap-3'>
              {/* Render left image */}
              <div className='relative flex justify-center gap-1 lg:gap-2'>
                {/* Hero Section Top */}
                <img
                  src={slide?.top}
                  alt='hero image'
                  className='rounded-sm h-full object-contain'
                />
                {/* AI-Powered Insights */}
                <div
                  className={`hidden lg:block absolute h-30 lg:w-[14rem] 2xl:w-[16rem] right-5 ${slide.id === 2 && 'top-40'} p-2 xl:p-5 bg-[#008CFF] rounded-sm`}
                >
                  <h2
                    className={` ${slide.id === 2 ? 'w-[12rem]' : 'w-[11rem]'}   my-2 font-normal text-center text-white lg:block display-regular xl:my-3 2xl:w-56 lg:text-sm`}
                  >
                    {slide.content}
                  </h2>
                </div>

                {/* TODO-> commented this for now , once we get svg imgs then will those */}
                {/* Render right image display in desktop */}
                {/* <img
                  src={HeroSectionRight}
                  alt='herosection'
                  className='absolute hidden lg:block top-32 xl:top-36 2xl:top-52 -right-9 lg:h-[200px] 2xl:h-[248px] w-[100px] object-contain'
                /> */}
                {/* Render right image for the mobile view */}
                {/* <img
                  src={HeroRightArrow}
                  alt='hero image'
                  className='absolute lg:hidden h-14 w-14 sm:w-28 sm:h-28 md:w-32 md:h-32 top-12 md:top-16 right-[-30px] md:right-[-80px]  object-contain'
                /> */}
              </div>
              {/* <div className='flex gap-2 lg:gap-3 2xl:gap-6'> */}
              {/* Mapping through slides and rendering them */}
              {/* {slide?.right?.map((slide) => (
                  <img
                    key={slide?.id}
                    src={slide?.img}
                    alt='hero image'
                    className={`rounded-sm object-contain ${slide.id === 1 ? 'h-[90px] sm:h-[150px] md:h-[150px] md:w-[200px] lg:w-[250px] lg:h-auto 2xl:w-[300px] 2xl:h-[252px]' : 'w-[130px] sm:w-[200px] lg:h-auto lg:w-[250px]  2xl:h-[252px] 2xl:w-[300px]'} `}
                  />
                ))}
              </div> */}
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
}
