import React from 'react';
import {
  AccordionProps,
  Accordion as FlowbiteAccordion,
  AccordionPanelProps,
  Badge,
} from 'flowbite-react';

interface IAccordion extends AccordionProps {
  title?: string;
  icon?: string;
  badgeContent?: string;
  children: React.ReactElement<AccordionPanelProps> | React.ReactElement<AccordionPanelProps>[];
}

const Accordion = ({ title, badgeContent, children, ...props }: IAccordion) => {
  const { Panel, Title, Content } = FlowbiteAccordion;

  return (
    <FlowbiteAccordion className='w-full transition duration-300 ease-in-out border-0' {...props}>
      <Panel>
        <Title
          className='items-center px-0 py-0 mb-4 text-xl font-normal text-neutral-900 font-sora'
          theme={{
            flush: { off: '', on: '' },
            open: {
              on: '',
            },
          }}
        >
          <span className='pr-3 text-lg font-semibold tracking-tighter xl:text-xl font-sora xl:pr-0'>
            {title}
          </span>
          {badgeContent && (
            <Badge className='px-0 pr-3 mt-2 text-xs bg-white text-neutral-500 font-poppins xl:pr-0'>
              {badgeContent}
            </Badge>
          )}
        </Title>
        <Content
          theme={{
            base: 'p-0',
          }}
        >
          {children}
        </Content>
      </Panel>
    </FlowbiteAccordion>
  );
};

export default Accordion;
