import {
  IParentOnboarding,
  IPasscodeResponse,
  IStudentRegistration,
  requestOTP,
  responseOTP,
  responseStudentRegistration,
  responseVerifyOTP,
  verifyOTP,
} from 'types/auth';
import apiSlice from '.';
import { ISchools } from 'types/common';

const registerApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    /* request OTP */
    requestOTP: build.mutation<responseOTP, requestOTP>({
      query: (body) => ({
        url: '/auth/student-guardians-temp/request-otp',
        method: 'POST',
        body,
      }),
    }),
    /* verify OTP */
    verifyOTP: build.mutation<responseVerifyOTP, verifyOTP>({
      query: (body) => ({
        url: '/auth/student-guardians-temp/verify-otp',
        method: 'POST',
        body,
      }),
    }),
    /* resend OTP */
    resendOTP: build.mutation<responseVerifyOTP, requestOTP>({
      query: (body) => ({
        url: '/auth/student-guardians-temp/request-otp',
        method: 'POST',
        body,
      }),
    }),
    /* student registration */
    studentRegistration: build.mutation<responseStudentRegistration, IStudentRegistration>({
      query: ({ data }) => ({
        url: '/auth/student-guardians/registration',
        method: 'POST',
        body: data,
      }),
    }),

    /* set passcode  */
    SetPasscode: build.mutation<IPasscodeResponse, { passcode: string; userMobileNumber?: string }>(
      {
        query: ({ passcode, userMobileNumber }) => ({
          url: '/auth/student-guardians/set-pass-code',
          method: 'POST',
          body: { passcode: passcode, userMobileNumber },
        }),
      },
    ),

    /* Update the new mobile number */
    /* initial and resend verification for the new mobile number using otp */
    initialMobileVerification: build.mutation<
      { message: string },
      { mobileNumber: string; studentId?: number }
    >({
      query: (body) => ({
        url: '/auth/student-guardians/verify-new-mobile-number',
        method: 'POST',
        body,
      }),
    }),

    /* Update the new mobile number */
    UpdateMobileNumber: build.mutation<{ message: string }, { mobileNumber: string; otp: string }>({
      query: (body) => ({
        url: '/auth/student-guardians/update-mobile-number',
        method: 'PUT',
        body,
      }),
    }),

    /* Parent registration */
    ParentOnboarding: build.mutation<{ message: string }, IParentOnboarding>({
      query: (body) => ({
        url: '/auth/student-guardians/add-profile',
        method: 'POST',
        body,
      }),
    }),

    /* Get the registered schools */
    getRegisteredSchools: build.query<ISchools, void>({
      query: () => ({
        url: '/school/list',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useRequestOTPMutation,
  useVerifyOTPMutation,
  useResendOTPMutation,
  useStudentRegistrationMutation,
  useSetPasscodeMutation,
  useInitialMobileVerificationMutation,
  useUpdateMobileNumberMutation,
  useParentOnboardingMutation,
  useGetRegisteredSchoolsQuery,
} = registerApiSlice;
