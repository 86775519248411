import { ExclamationWarn } from 'assets/svg';
import { Button } from 'components/common';
import { IMediaPermission } from 'types';

export default function MediaPermission({ setIsModalOpen }: IMediaPermission) {
  return (
    <div className='flex flex-col p-4'>
      <h1 className='mb-4 text-xl display-bold lg:text-2xl'>Camera Access is not enabled</h1>
      <div className='gap-2 text-lg list-disc text-neutral-800 body-medium'>
        <div className='flex items-start gap-2 mb-4'>
          <img src={ExclamationWarn} alt='ExclamationCircle' className='object-contain w-5 h-5' />
          <div>
            <li className='mb-2 list-none text-start'>
              Click the icon in your browser's address bar to access page settings
            </li>
            <li className='mb-2 list-none'>
              Enable your camera and microphone, then refresh the page
            </li>
          </div>
        </div>
        <div className='flex justify-end mt-4'>
          <Button
            className='px-6 text-white transition-transform transform border-0 rounded-lg shadow-lg bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-400'
            onClick={() => setIsModalOpen(false)}
            text='Close'
          />
        </div>
      </div>
    </div>
  );
}
