import { useEffect } from 'react';
import { Toast as FlowbiteToast, ToastProps } from 'flowbite-react';
import classNames from 'classnames';

interface IToastsProps extends Omit<ToastProps, 'duration' | 'isVisible'> {
  className?: string;
  isCloseable: boolean;
  content: string;
  icon?: string;
  duration?: number;
  mode: 'light' | 'dark';
  color?: 'success' | 'warn' | 'error';
  isVisible: boolean;
  onVisibilityChange: (isVisible: boolean) => void;
  displayClassName?: string;
}

export default function Toast({
  className,
  icon,
  isCloseable,
  content,
  duration,
  isVisible,
  color,
  mode,
  onVisibilityChange,
  displayClassName,
  ...props
}: IToastsProps) {
  useEffect(() => {
    let timerOut: ReturnType<typeof setTimeout>;
    if (duration && isVisible) {
      timerOut = setTimeout(() => onVisibilityChange(false), duration);
    }
    /* clear timeout when unmount */
    return () => clearTimeout(timerOut);
  }, [duration, isVisible, onVisibilityChange]);

  const theme = {
    root: {
      base: 'flex w-full max-w-xs md:max-w-md items-center rounded-lg bg-white p-4 text-gray-500 shadow',
    },
  };

  const baseClassName = classNames(
    'shadow-lg py-2 gap-2 px-3 rounded-lg',
    color === 'success'
      ? 'bg-semantic-green-100 text-semantic-green-800'
      : color === 'warn'
        ? 'bg-semantic-yellow-100 text-semantic-yellow-800'
        : color === 'error'
          ? 'bg-semantic-red-100 text-semantic-red-800'
          : 'bg-secondary-100 text-secondary-800',
    className,
  );

  const toggleClassName = classNames(
    'focus:ring-0',
    color === 'success'
      ? 'bg-semantic-green-100 hover:bg-semantic-green-100 text-semantic-green-800'
      : color === 'warn'
        ? 'bg-semantic-yellow-100 hover:bg-semantic-yellow-100  text-semantic-yellow-800'
        : color === 'error'
          ? 'bg-semantic-red-100 hover:bg-semantic-red-100 text-semantic-red-800'
          : '',
  );

  return (
    isVisible && (
      <div className={`${displayClassName ? displayClassName : 'fixed z-10 top-2 right-2'}`}>
        <FlowbiteToast
          theme={theme}
          className={
            mode === 'light'
              ? baseClassName
              : `${baseClassName} border-[#122D50] border-1 text-[#FFFFFF] bg-[#1E232A]`
          }
          {...props}
        >
          {icon && (
            <div className='inline-flex items-center justify-center w-8 h-8 rounded-lg shrink-0'>
              <img src={icon} alt='icon' className='w-5 h-5' />
            </div>
          )}
          <div
            className={
              mode === 'light'
                ? 'text-xs md:text-base font-bold text-center leading-5 min-w-max'
                : 'text-sm md:text-md font-bold text-center leading-5'
            }
          >
            {content}
          </div>
          {isCloseable && <FlowbiteToast.Toggle className={toggleClassName} />}
        </FlowbiteToast>
      </div>
    )
  );
}
