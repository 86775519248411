import { useDyteMeeting } from '@dytesdk/react-web-core';

import { DyteAudioVisualizer, DyteParticipantTile, DyteAvatar } from '@dytesdk/react-ui-kit';
import { HandRaise } from 'assets/gif';
import { IParticipantTile } from 'types';

const ParticipantTile = ({ participant, studentTile, handRaised }: IParticipantTile) => {
  const { meeting } = useDyteMeeting();
  const participantId = participant?.customParticipantId;

  return (
    <>
      {!studentTile ? (
        <>
          <div className='absolute z-10 right-3 top-3'>
            <DyteAudioVisualizer
              size='sm'
              slot='start'
              participant={participant}
              className={`h-full p-3 text-white ${participant?.audioEnabled ? 'bg-[#515352]' : 'bg-semantic-red-500'} rounded-full`}
            />
          </div>
          {participantId && handRaised?.[participantId] && (
            <div className='absolute z-30 top-3 right-16'>
              <img src={HandRaise} alt='hand' className='w-12 h-12' />
            </div>
          )}
          <DyteParticipantTile
            participant={participant}
            meeting={meeting}
            className='w-full h-full'
          >
            <DyteAvatar participant={participant} size='lg' />
          </DyteParticipantTile>
          <p className='absolute bottom-0 left-0 px-5 py-1 text-sm text-white rounded-es-xl rounded-tr-xl bg-primary-950'>
            {participant.name}
          </p>
        </>
      ) : (
        <DyteParticipantTile participant={participant} meeting={meeting} className='w-full h-full'>
          {participantId && handRaised?.[participantId] && (
            <div className='absolute z-30 top-2 right-2'>
              <img src={HandRaise} alt='hand' className='w-8 h-8' />
            </div>
          )}
          <DyteAvatar participant={participant} size='lg' />
        </DyteParticipantTile>
      )}
    </>
  );
};

export default ParticipantTile;
