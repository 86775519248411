import { useState } from 'react';

import { HeroSection, PasscodeSetting } from 'components/screens';
import { OnboardingNav } from 'components/common/Navbar';
import { useWindowDimensions } from 'hooks';

export default function ForgetPasscode() {
  const { isMobileScreen } = useWindowDimensions();
  const [isOtpValidated, setIsOtpValidated] = useState<boolean>(false);

  return (
    <div className='flex flex-col flex-1 h-full min-h-screen bg-white'>
      <OnboardingNav />
      <div className='grid flex-1 h-full grid-cols-1 bg-white lg:grid-cols-2 lg:mx-0'>
        {/* Header Section */}
        {(!isOtpValidated && isMobileScreen) || !isMobileScreen ? (
          <div className='order-first block lg:order-last'>
            <HeroSection />
          </div>
        ) : null}

        <div className='relative flex justify-center h-full p-1 bg-white'>
          <div className='w-full h-full mx-2 flex flex-col justify-start md:w-[70%] lg:w-[90%] xl:w-[90%]'>
            <PasscodeSetting
              handleOtpValidationStatusChange={(isOtpValidated: boolean) =>
                setIsOtpValidated(isOtpValidated)
              }
              isMobileVerified={(isMobileVerified: boolean) => setIsOtpValidated(isMobileVerified)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
