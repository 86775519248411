export const ROUTES = {
  ROOT: '/',
  LIST_OF_CLASSES: '/list-of-classes',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGET_PASSCODE: '/forget-passcode',
  ACCOUNTS: '/accounts',
  TERMS_AND_CONDITION: '/terms-and-conditions',
  CLASSROOM_INVITE: '/class/join/:id',
  PRACTICE_CLASS_QRCODE_CAPTURE: '/practice-evaluations',
};

export const PARENT_PATHS = {
  PARENT_DASHBOARD: '/p/home/:studentId',
  ALLCLASSES: '/p/classes/:studentId',
  PRACTICE_SHEET: '/p/practice/:studentId',
  CLASS_OVER: '/p/:studentId/class/:classId',
};

export const STUDENT_PATHS = {
  STUDENT_DASHBOARD: '/s/home',
  STUDENT_CLASSES: '/s/classes',
  STUDENT_PRACTICE: '/s/practice',
  CLASS_NOTES: '/s/class-notes',
};
