import { Ref, forwardRef } from 'react';
import classNames from 'classnames';
import { Label } from 'flowbite-react';

import { InputProps } from 'types/common';

import './styles.css';

export const Input = forwardRef(
  (
    {
      className,
      icon,
      label,
      required,
      disabled,
      handleClick,
      isValid,
      type,
      isMobilenumber,
      ...props
    }: InputProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    /* keyDown for number input only to remove character */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (type !== 'number') return;
      /* when the type is number the listed characters removing */
      const CHARACTER_TO_REMOVE = ['-', '+', 'E', 'e', '.'];
      if (CHARACTER_TO_REMOVE.includes(event.key)) {
        event.preventDefault();
      }
    };

    // Prevent scrolling from changing the number
    const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
      if (type === 'number') {
        e.preventDefault();
        e.currentTarget.blur();
      }
    };

    return (
      <>
        <Label
          className={`text-sm cap-semibold text-neutral-500 ${disabled ? 'text-neutral-25' : ''}`}
        >
          {label}
          {required && (
            <span
              className={`text-neutral-500 cap-semibold leading-5 text-sm ${disabled ? 'text-neutral-25' : ''}`}
            >
              *
            </span>
          )}
        </Label>
        <div className='relative mt-3'>
          <input
            ref={ref}
            type={type}
            onWheel={handleWheel}
            className={classNames(
              'border-2 text-sm sm:text-base rounded-lg w-full py-3 px-3 text-neutral-800 body-medium leading-tight focus:border-primary-400 focus:caret-primary-400 caret-primary-400 focus:outline-none placeholder:text-neutral-200 placeholder:text-xs placeholder:md:text-sm placeholder:lg:text-base focus:placeholder:text-neutral-200 placeholder:uppercase active:placeholder:text-xs active:placeholder:md:text-sm active:placeholder:lg:text-base focus:placeholder-neutral-200 ring-neutral-800 focus:ring-primary-400 ',
              {
                'text-neutral-800': !disabled,
                'cursor-not-allowed placeholder-neutral-200 bg-white': disabled,
                'border-semantic-red-300 placeholder-neutral-800': isValid,
              },
              className,
            )}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            {...props}
          />
          {isMobilenumber && (
            <div
              className={`absolute top-0 left-0 bottom-0 flex items-center text-sm sm:text-base pl-3 pointer-events-none text-neutral-800 body-medium`}
            >
              {/* //TODO: Input field flag image */}
              {/* <img src={NationalFlag} alt='Flag' className='object-contain w-3' /> */}
              <h6>+91</h6>
            </div>
          )}
          {icon && (
            <div
              onClick={handleClick}
              className='absolute text-[#F04438] object-contain top-1/2 -translate-y-1/2 right-3 w-5'
            >
              <img src={icon} alt='Icon' />
            </div>
          )}
        </div>
      </>
    );
  },
);

export default Input;
