import { Signal_2 } from 'assets/svg';
import { useAppSelector } from 'hooks/store';
import { selectNetworkLow } from 'store/slice/networkIssueCounter.slice';

const Network = () => {
  const networkLow = useAppSelector(selectNetworkLow);
  return (
    networkLow && (
      <div className='p-2 m-2 rounded-xl bg-[#292929]'>
        <img src={Signal_2} height={30} width={30} />
      </div>
    )
  );
};

export default Network;
