import {
  IForgetPasscodeResponse,
  IVerifyOtpForgetPasscodeResponse,
} from 'types/auth/forgetPasscode';
import apiSlice from '.';

const forgetPasscodeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    initialForgetPasscode: builder.mutation<IForgetPasscodeResponse, { mobileNumber: string }>({
      query: (body) => ({
        url: '/auth/student-guardians/forgot-passcode',
        method: 'POST',
        body,
      }),
    }),
    verifyOtpForgetPasscode: builder.mutation<
      IVerifyOtpForgetPasscodeResponse,
      { otp: string; mobileNumber: string }
    >({
      query: (body) => ({
        url: '/auth/student-guardians/get-reset-passcode-token',
        method: 'POST',
        body,
      }),
    }),
    resetPasscode: builder.mutation<
      IVerifyOtpForgetPasscodeResponse,
      { token: string; passcode: string }
    >({
      query: (body) => ({
        url: '/auth/student-guardians/reset-passcode',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useInitialForgetPasscodeMutation,
  useVerifyOtpForgetPasscodeMutation,
  useResetPasscodeMutation,
} = forgetPasscodeApiSlice;
