import { PortableText, PortableTextProps } from '@portabletext/react';
import katex from 'katex';
import 'katex/dist/katex.min.css';
import { urlFor } from 'utils';

const PortableTextWithLatex = (props: PortableTextProps) => {
  return (
    <PortableText
      components={{
        types: {
          latex: ({ value }) => {
            const latexValue = value.body || '';
            const html = katex.renderToString(latexValue, {
              throwOnError: false,
              displayMode: true, // Set to false to render inline
            });
            return <span className='flex' dangerouslySetInnerHTML={{ __html: html }} />;
          },
          image: ({ value }) => {
            const imageUrl = urlFor(value).url();
            return (
              <div className='my-4'>
                <img src={imageUrl} alt={value.alt || 'Image'} className='mx-auto' />
              </div>
            );
          },
        },

        block: {
          normal: ({ children }) => <p className='my-2'>{children}</p>,
          blockquote: ({ children }) => <blockquote className='my-2'>{children}</blockquote>,
          h1: ({ children }) => <h1 className='my-2'>{children}</h1>,
          h2: ({ children }) => <h2 className='my-2'>{children}</h2>,
          h3: ({ children }) => <h3 className='my-2'>{children}</h3>,
          h4: ({ children }) => <h4 className='my-2'>{children}</h4>,
          h5: ({ children }) => <h5 className='my-2'>{children}</h5>,
          h6: ({ children }) => <h6 className='my-2'>{children}</h6>,
        },
      }}
      {...props}
    />
  );
};

export default PortableTextWithLatex;
