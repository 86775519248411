import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { useGetPracticeSheetQuestionQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { ExclamationWarn } from 'assets/svg';
import { BouncingDotsLoader, Button, Drawer, Modal, ModalBottomSheet } from 'components/common';
import { AIReportModal, PracticeSheetQuestions, PracticeSheetSubmission } from 'components/screens';

import { EMPTY_PRACTICE_QUESTION, EVALUATED_STATUS, LOG_TYPE, USER_TYPE } from 'configs';
import { useLogger, useWindowDimensions } from 'hooks';
import { IPracticePaper, IPracticePaperParams, QuestionDescription } from 'types';
import { useParams } from 'react-router-dom';

export default function PracticePapers({
  practiceSheetId,
  evaluationId,
  setShowData,
  submitted,
}: IPracticePaper) {
  const { isMobileScreen } = useWindowDimensions();
  const { logInfo } = useLogger();

  const { studentId } = useParams();

  const [question, setQuestion] = useState<QuestionDescription[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(true);
  const [showSubmissionModal, setShowSubmissionModal] = useState<boolean>(false);
  const [paramData, setParamData] = useState<IPracticePaperParams>({
    evaluationId: '',
    questionId: '',
    practiceSheetId: '',
    studentId: 0,
    questionIdx: 0,
  });

  /* API -> get the practice class question Data */
  const {
    data: practiceData,
    isLoading,
    refetch,
  } = useGetPracticeSheetQuestionQuery(
    {
      practiceSheetId,
      evaluationId,
      studentId,
    },
    {
      skip: !evaluationId || !practiceSheetId,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (!isOpenModal || showSubmissionModal) {
      refetch();
    }
  }, [isOpenModal, showSubmissionModal, refetch]);

  return (
    <>
      <Drawer
        isOpenDrawer={isOpenDrawer}
        onClose={() => {
          setIsOpenDrawer(!isOpenDrawer), setShowData(isOpenDrawer);
        }}
        heading={practiceData?.data?.[0]?.evaluationName || 'Practice Papers'}
        showCloseBtn={true}
        className={''}
        children={
          <>
            <div
              className='mb-10 overflow-y-scroll  rounded-[20px] question-container'
              style={{ maxHeight: 'calc(100vh - 80px)' }}
            >
              {isLoading ? (
                <div className='flex items-center justify-center flex-1 mt-10'>
                  <BouncingDotsLoader />
                </div>
              ) : !isEmpty(practiceData?.data) ? (
                <>
                  <div className='px-3 overflow-y-auto scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-gray-700 scrollbar-track-gray-300s'>
                    <PracticeSheetQuestions
                      type={!studentId ? USER_TYPE?.STUDENT : USER_TYPE?.GUARDIAN}
                      questions={practiceData?.data || []}
                      setIsOpenModal={setIsOpenModal}
                      setParamData={setParamData}
                      setQuestion={setQuestion}
                      submitted={submitted}
                      evaluationStatus={
                        practiceData?.data?.[0].evaluationStatus || EVALUATED_STATUS.NOT_EVALUATED
                      }
                    />
                  </div>

                  {!studentId && !submitted && (
                    <div className='sticky bottom-0 z-10 flex flex-col items-center gap-2 px-2 py-5 shadow-inner bg-primary-50 rounded-t-3xl'>
                      <div className='flex items-center p-2 rounded-lg bg-semantic-orange-200 text-neutral-900 body-semibold'>
                        <img src={ExclamationWarn} alt='info' className='w-5 h-5 mr-2' />
                        Submit your practice sheet for evaluation.
                      </div>
                      <div className='flex justify-start mb-7'>
                        <Button
                          type='button'
                          className='text-white rounded-lg bg-semantic-green-500 enabled:hover:bg-semantic-green-500'
                          text='Submit Practice Evaluation'
                          isLoading={isLoading}
                          onClick={() => {
                            const { evaluationId, practiceSheetId, studentId, questionId } =
                              paramData;
                            logInfo(
                              `Submitting practice sheet for the evaluationId -> ${evaluationId}, practiceSheetId -> ${practiceSheetId} , studentId -> ${studentId} , questionId -> ${questionId} `,
                              {
                                evaluationId,
                                practiceSheetId,
                                studentId,
                                questionId,
                              },
                              LOG_TYPE.INFO,
                            );
                            setShowSubmissionModal(true);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className='p-10 m-3 font-semibold text-center text-white bg-primary-700 rounded-2xl'>
                  {EMPTY_PRACTICE_QUESTION}
                </div>
              )}
            </div>
          </>
        }
      />

      {/* Modal for getting confirmation for the submitting the practice sheet */}
      {showSubmissionModal && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              openModal={showSubmissionModal}
              setOpenModal={setShowSubmissionModal}
              showCloseBtn={false}
              children={
                <PracticeSheetSubmission
                  totalQuestionCount={
                    practiceData?.data[0]?.totalQuestions ||
                    practiceData?.data[0]?.questions?.length ||
                    0
                  }
                  answeredCount={practiceData?.data[0]?.captureAnswerCount || 0}
                  closeSubmissionModal={setShowSubmissionModal}
                  closeDrawer={setIsOpenDrawer}
                  practiceSheetId={practiceSheetId}
                  evaluationId={evaluationId}
                />
              }
            />
          ) : (
            <Modal
              openModal={showSubmissionModal}
              setOpenModal={setShowSubmissionModal}
              size={'xl'}
              showCloseBtn={false}
              children={
                <PracticeSheetSubmission
                  totalQuestionCount={
                    practiceData?.data[0]?.totalQuestions ||
                    practiceData?.data[0]?.questions?.length ||
                    0
                  }
                  answeredCount={practiceData?.data[0]?.captureAnswerCount || 0}
                  closeSubmissionModal={setShowSubmissionModal}
                  closeDrawer={setIsOpenDrawer}
                  practiceSheetId={practiceSheetId}
                  evaluationId={evaluationId}
                />
              }
            />
          )}
        </div>
      )}

      {/* Qr code modal will appear for the student Evaluation */}
      {isOpenModal && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              setOpenModal={setIsOpenModal}
              openModal={isOpenModal}
              showCloseBtn={true}
              children={
                <div className={'customScrollBar'}>
                  <AIReportModal
                    paramData={{
                      practiceSheetId: paramData?.practiceSheetId || practiceSheetId,
                      evaluationId: paramData?.evaluationId,
                      questionId: paramData?.questionId,
                      studentId: paramData?.studentId,
                    }}
                    setIsOpenModal={setIsOpenModal}
                    questionString={question || []}
                  />
                </div>
              }
            />
          ) : (
            <Modal
              children={
                <div className={'customScrollBar'}>
                  <AIReportModal
                    paramData={{
                      evaluationId: paramData?.evaluationId,
                      practiceSheetId: paramData?.practiceSheetId || practiceSheetId,
                      questionId: paramData?.questionId,
                      studentId: paramData?.studentId,
                    }}
                    setIsOpenModal={setIsOpenModal}
                    questionString={question || []}
                  />
                </div>
              }
              showCloseBtn={true}
              openModal={isOpenModal}
              setOpenModal={setIsOpenModal}
              size={'4xl'}
            />
          )}
        </div>
      )}
    </>
  );
}
