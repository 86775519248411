import { lowerCase } from 'lodash';
import React, { useState } from 'react';

import { AllClassIconDark, HomeDarkIcon, TutorPlayButton } from 'assets/svg';
import { Avatar, Card, Modal, ModalBottomSheet } from 'components/common';
import { USER_TYPE } from 'configs/userData';

import { CLASS_PROGRESS } from 'configs';
import { useWindowDimensions } from 'hooks';
import { ITutorProfileNew } from 'types';

const TutorProfileNew = ({ type, state, remainingTime, classData }: ITutorProfileNew) => {
  const tutorDetails = classData?.data?.tutorDetails[0];

  const { isMobileScreen } = useWindowDimensions();

  const [videoModalIsOpen, setVideoModalIsOpen] = useState<boolean>(false);
  const [selectedVideoSrc, setSelectedVideoSrc] = useState<string>('');

  let tutorName = tutorDetails?.firstName
    ? tutorDetails?.firstName + ' ' + tutorDetails?.lastName
    : '';

  if (lowerCase(tutorDetails?.gender) === 'male') {
    tutorName = `Mr. ${tutorName}`;
  } else if (lowerCase(tutorDetails?.gender) === 'female') {
    tutorName = `Ms. ${tutorName}`;
  }

  const openVideoModal = (videoSrc: string) => {
    setSelectedVideoSrc(videoSrc);
    setVideoModalIsOpen(true);
  };

  return (
    <>
      <Card className={`w-full h-full rounded-2xl p-4 bg-white`}>
        <h6 className='text-lg font-normal tracking-tight lg:text-xl text-neutral-900 font-sora'>
          Tutor Profile
        </h6>

        <div
          className={`grid mt-[30px] ${remainingTime > 15 ? 'md:grid-cols-3' : 'md:grid-cols-1'}`}
        >
          {/* tutor profile details */}
          <div className='flex flex-col'>
            {/* {tutors details} */}
            <div className='flex gap-5 md:block'>
              <Avatar
                firstName={tutorDetails?.firstName}
                lastName={tutorDetails?.lastName}
                image={tutorDetails?.profileImage}
                className='object-contain w-12 h-12 border-2 rounded-full border-primary-300'
                initialsClassName='object-contain w-12 h-12 border-2 rounded-full border-primary-300 flex items-center justify-center'
              />
              <div className='md:mt-4'>
                <h6
                  className={`font-poppins ${state === CLASS_PROGRESS.STARTED ? 'text-white' : 'text-secondary-800'} font-bold text-base`}
                >
                  {tutorName}
                </h6>
                <p
                  className={`font-poppins font-medium text-base ${state === CLASS_PROGRESS.STARTED ? 'text-neutral-25' : 'text-neutral-400'}`}
                >
                  {tutorDetails?.subjects && Object.keys(tutorDetails?.subjects).length > 0
                    ? tutorDetails?.subjects?.find((subject) => subject?.primary === true)?.name ||
                      tutorDetails?.subjects[0]?.name
                    : 'Maths'}
                </p>
              </div>
            </div>
            {type === USER_TYPE.GUARDIAN && (
              <div className='flex mt-6'>
                {/* commented because api is not integrated */}
                {/* <Button
                  text={'Connect With Tutor'}
                  className='flex-1 inline-block py-1 text-xs font-semibold bg-white font-poppins text-primary-500 outline-primary-500'
                /> */}
              </div>
            )}
          </div>
          {/* tutor stats details */}
          {remainingTime > 15 && tutorDetails?.stats && (
            <div className='flex items-center justify-center w-full m-0 mt-5 gap-x-1 md:gap-y-1 lg:mt-0 md:flex-col md:m-2'>
              <div
                className={`flex-1 md:w-[10rem] flex gap-2 justify-center px-3 md:px-0 py-3 items-center rounded-2xl ${state === CLASS_PROGRESS.STARTED ? 'bg-neutral-600' : 'bg-[#C2FFFD]'}`}
              >
                <div className='p-1 bg-white rounded-full sm:p-2'>
                  <img
                    src={AllClassIconDark}
                    className='w-3 h-3 sm:w-5 sm:h-5'
                    alt='classes icon'
                  />
                </div>
                <div className='w-12'>
                  <h3
                    className={`sm:font-semibold text-[10px] sm:text-xs md:text-sm ${state === CLASS_PROGRESS.STARTED ? 'text-neutral-100' : 'text-neutral-600'}`}
                  >
                    CLASSES
                  </h3>
                  <h1
                    className={`display-bold text-[10px] sm:text-xs md:text-base ${state === CLASS_PROGRESS.STARTED ? 'text-neutral-100' : 'text-neutral-600'}`}
                  >
                    {tutorDetails && (
                      <>
                        {tutorDetails?.stats?.noOfClassesTaken}
                        {'+'}
                      </>
                    )}
                  </h1>
                </div>
              </div>
              <div
                className={`flex-1 md:w-[10rem] flex gap-3 justify-center px-3 md:px-0 py-3 items-center rounded-2xl ${state === CLASS_PROGRESS.STARTED ? 'bg-neutral-600' : 'bg-[#C2FFFD]'}`}
              >
                <div className='p-1 bg-white rounded-full sm:p-2'>
                  <img src={HomeDarkIcon} className='w-3 h-3 sm:w-5 sm:h-5' alt='Home icon' />
                </div>
                <div className='w-12'>
                  <h3
                    className={`sm:font-semibold text-[10px] sm:text-xs md:text-sm ${state === CLASS_PROGRESS.STARTED ? 'text-neutral-100' : 'text-neutral-600'}`}
                  >
                    STUDENTS
                  </h3>
                  <h1
                    className={`display-bold text-[10px] sm:text-xs md:text-base ${state === CLASS_PROGRESS.STARTED ? 'text-neutral-100' : 'text-neutral-600'}`}
                  >
                    {tutorDetails && <>{tutorDetails?.stats?.noOfStudentsHandled}</>}
                  </h1>
                </div>
              </div>
            </div>
          )}

          {/* vertical line and tutor intro videos */}
          {remainingTime > 15 && tutorDetails?.introReels && (
            <div className='flex'>
              <div className='hidden md:block w-[1px] h-full bg-neutral-300' />
              <div className='flex flex-wrap justify-start flex-1 px-0 xl:py-0 md:px-2 md:py-2 gap-x-2 gap-y-2'>
                {tutorDetails?.introReels.length > 0 &&
                  tutorDetails?.introReels.map((tutorReels: { url: string }, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <div
                          className='w-[6rem] h-[5rem] relative'
                          onClick={() => openVideoModal(tutorReels.url)}
                        >
                          <video className='object-cover w-full h-full' src={tutorReels.url} />
                          <img
                            src={TutorPlayButton}
                            alt={'play-button'}
                            className='absolute top-[15px] left-[10px]'
                          />
                          <button className=' text-white font-semibold font-poppins text-[10px] absolute top-[55px] left-[15px]'>
                            Watch Video
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </Card>

      {videoModalIsOpen && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              setOpenModal={setVideoModalIsOpen}
              openModal={videoModalIsOpen}
              heading='Parent Onboarding'
              showCloseBtn={true}
              children={
                <>
                  <video controls className='object-cover w-full h-auto' controlsList='nodownload'>
                    <source src={selectedVideoSrc} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                </>
              }
            />
          ) : (
            <Modal
              heading={`${tutorDetails?.firstName} ${tutorDetails?.lastName}`}
              children={
                <>
                  <video controls className='object-cover w-full h-auto' controlsList='nodownload'>
                    <source src={selectedVideoSrc} type='video/mp4' />
                    Your browser does not support the video tag.
                  </video>
                </>
              }
              showCloseBtn={true}
              openModal={videoModalIsOpen}
              setOpenModal={setVideoModalIsOpen}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TutorProfileNew;
