import RenderEvalContent from 'components/inClass/RenderEvalContent';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';

import { useAddPracticeCapturesMutation } from 'store/apiSlices/studentDashboard.apiSlice';
import { useGetNoteBookCaptureQuery } from 'store/apiSlices/inClass/evaluation.apiSlice';

import { Button, Modal, ModalBottomSheet } from 'components/common';
import { ImageModal, PracticePermissionModal } from 'components/screens';

import { IS_IOS_DEVICE, LOG_TYPE, STUDENT_PATHS } from 'configs';
import { useLogger, useWindowDimensions } from 'hooks';
import { ICaptureState, IEvaluationImage, IQrCodeCapture } from 'types';
import { checkMediaPermissions, dataURLtoFile, listenToMediaPermissions } from 'utils';

export default function QRCodeCapture({
  params,
  showQuestion,
  evalName,
  noteId,
  selectedQuestionData,
  questionData,
  selectedQuestionIndex,
  setShowQuestion,
  refetch,
  handleQrCodeCapture,
  setNoteId,
}: IQrCodeCapture) {
  const webcamRef = useRef<Webcam>(null);
  const { isMobileScreen } = useWindowDimensions();
  const { logInfo } = useLogger();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);

  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');
  const [captureState, setCaptureState] = useState<ICaptureState>({
    loading: false,
    timeoutId: null,
    isTimeOut: false,
  });
  const [videoPermissionDenied, setVideoPermissionDenied] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [capturedImage, setCapturedImage] = useState<IEvaluationImage[]>([]);
  const [pages, setPages] = useState<number>(1);
  const [isRecapture, setIsRecapture] = useState<boolean>(false);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollRef, selectedQuestionData]);

  /* API -> FOR CAPTURE AND STORE THE IMAGES  */
  const [addPracticeCaptures] = useAddPracticeCapturesMutation();

  /* API -> FOR GETTING THE CAPTURED NOTEBOOK DATA */
  const { data: capturedNoteBookData, refetch: getNoteBookCapture } = useGetNoteBookCaptureQuery(
    {
      practiceSheetId: params?.practiceSheetId,
      evaluationId: params?.evaluationId,
    },
    {
      skip: !params?.practiceSheetId || !params?.evaluationId,
    },
  );

  useEffect(() => {
    if (capturedNoteBookData) {
      if (isRecapture) {
        setPages(capturedNoteBookData?.data?.length || 1);
      } else {
        setPages(capturedNoteBookData?.data?.length + 1);
      }
    }
  }, [capturedNoteBookData, isRecapture]);

  const switchCamera = () => {
    setFacingMode((prevFacingMode) => (prevFacingMode === 'user' ? 'environment' : 'user'));
  };

  const refreshWebcam = useCallback(() => {
    if (webcamRef.current && webcamRef.current.video) {
      // Clear previous stream
      const video = webcamRef.current.video;
      if (video.srcObject) {
        (video.srcObject as MediaStream).getTracks().forEach((track) => track.stop());
        video.srcObject = null;
      }
      // Reinitialize the video stream
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode } })
        .then((stream) => {
          if (video) {
            video.srcObject = stream;
            video.muted = true;
            video.playsInline = true;
          }
        })
        .catch((error) => toast.error('Error accessing media devices.', error));
    }
  }, [facingMode]);

  const fetchMediaPermissions = useCallback(async () => {
    const permissions = await checkMediaPermissions();
    setVideoPermissionDenied(permissions.videoPermissionDenied);
    if (!permissions.videoPermissionDenied) {
      refreshWebcam();
      setCaptureState((prevState) => ({ ...prevState, loading: false, isTimeOut: false }));
    }
  }, [refreshWebcam]);

  const capture = useCallback(async () => {
    const permissions = await checkMediaPermissions();

    listenToMediaPermissions(({ videoPermissionDenied }) => {
      setVideoPermissionDenied(videoPermissionDenied);
    });

    if (permissions.videoPermissionDenied && IS_IOS_DEVICE.test(navigator.userAgent)) {
      toast.error('You need to enable permissions to capture the answers');
      setTimeout(() => {
        window.location.reload();
      }, 500);

      return;
    }

    setCaptureState((prevState) => ({ ...prevState, loading: true }));
    if (captureState.timeoutId) {
      clearTimeout(captureState.timeoutId);
    }

    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        try {
          const { evaluationId, questionId, practiceSheetId, studentId } = params;

          logInfo(
            `Practice sheet capture for the evaluationId -> ${evaluationId}, practiceSheetId -> ${practiceSheetId} , studentId -> ${studentId} , questionId -> ${questionId} `,
            {
              evaluationId,
              practiceSheetId,
              studentId,
              questionId,
            },
            LOG_TYPE.INFO,
          );

          const imageId = Date.now();
          const fileName = `${questionId}_${studentId}_${imageId}.png`;
          const originImageObj = dataURLtoFile(imageSrc, fileName);

          const formData = new FormData();
          formData.append('images', originImageObj);
          formData.append('evaluationId', evaluationId || '');
          formData.append('practiceSheetId', practiceSheetId || '');
          if (noteId && noteId !== 0) {
            formData.append('noteId', noteId?.toString());
          } else {
            formData.append('page', pages.toString());
          }

          const questionFilesMap = {
            [questionId || '']: [fileName],
          };
          formData.append('questionImagesMap', JSON.stringify(questionFilesMap));

          const timeout = setTimeout(() => {
            setCaptureState({ loading: false, timeoutId: null, isTimeOut: true });
            toast.error('Capture timeout. Please try again.');
          }, 15000);

          setCaptureState((prevState) => ({ ...prevState, timeoutId: timeout }));

          addPracticeCaptures({ formData })
            .unwrap()
            .then((response) => {
              clearTimeout(timeout);
              const imageUrls = response?.data?.imageUrls;
              if (Array.isArray(imageUrls)) {
                setCapturedImage(imageUrls);
                setIsOpenModal(true);
              }
              setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });
              logInfo(
                `Successfully captured the practice sheet for evaluationId: ${evaluationId}, practiceSheetId: ${practiceSheetId}, studentId: ${studentId}, questionId: ${questionId}`,
                {
                  evaluationId,
                  practiceSheetId,
                  studentId,
                  questionId,
                },
                LOG_TYPE.INFO,
              );
              getNoteBookCapture();
            })
            .catch((error) => {
              clearTimeout(timeout);
              if (error?.data?.data?.isAlreadySubmitted) {
                toast.error('Your practice paper has already been submitted.');
                setTimeout(() => {
                  navigate(STUDENT_PATHS.STUDENT_PRACTICE, {
                    replace: true,
                  });
                }, 1000);
              } else if (error.status === 'FETCH_ERROR') {
                toast.error(
                  'Network error occurred. Please check your internet connection and try again.',
                );
              } else {
                toast.error(error.data?.message);
              }
              setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });
            });
        } catch (err) {
          clearTimeout(captureState.timeoutId || undefined);
          toast.error(err?.toString() || '');
          setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });
        }
      }
    } else {
      setCaptureState({ loading: false, timeoutId: null, isTimeOut: false });
    }
  }, [
    captureState.timeoutId,
    params,
    logInfo,
    noteId,
    addPracticeCaptures,
    pages,
    getNoteBookCapture,
    navigate,
  ]);

  /* Check for the audio and video permission */
  useEffect(() => {
    if (!showQuestion) {
      fetchMediaPermissions();

      listenToMediaPermissions(({ videoPermissionDenied }) => {
        setVideoPermissionDenied(videoPermissionDenied);
      });

      if (!videoPermissionDenied) {
        setCaptureState((prevState) => ({ ...prevState, loading: false, isTimeOut: false }));
        webcamRef.current?.render;
      }
    }
  }, [refreshWebcam, showQuestion, videoPermissionDenied, fetchMediaPermissions]);

  const handleRecapture = () => {
    setIsOpenModal(false);
    setIsRecapture(true);
    setCapturedImage([]);
  };

  const handleDone = () => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.delete('noteId');
    setNoteId(0);

    navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
    setIsOpenModal(false);
    setShowQuestion(true);
    setIsRecapture(false);
    refetch();

    if (selectedQuestionIndex < questionData?.length - 1) {
      handleQrCodeCapture(questionData[selectedQuestionIndex + 1], selectedQuestionIndex + 1);
      toast.success('Redirected to the next question.');
      setTimeout(() => {
        globalThis.scrollTo({ top: 0, behavior: 'smooth' });
      }, 0);
    }
  };

  return (
    <div className='w-full p-3 md:p-6'>
      <Button
        text={'Go Back'}
        onClick={() => setShowQuestion(true)}
        className='px-6 my-3 text-white transition-transform transform rounded-lg shadow-lg bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-400'
      />
      <div className='flex flex-col items-start justify-start pb-3 my-2 text-lg font-medium break-words md:flex-row md:items-center lg:text-xl'>
        <RenderEvalContent data={selectedQuestionData || []} />
      </div>
      <div ref={scrollRef} className='relative flex flex-col w-full h-[calc(100vh-20vh)]'>
        <Webcam
          audio={false}
          ref={webcamRef}
          imageSmoothing={true}
          muted={true}
          playsInline={true}
          screenshotFormat='image/png'
          className='absolute inset-0 z-0 object-cover w-full h-[calc(100vh-20vh)] p-1 rounded-xl'
          videoConstraints={{ facingMode }}
        />
        <div className='absolute inset-0 z-20 flex flex-col items-center justify-end h-[calc(100vh-20vh)]'>
          <div className='flex items-center gap-3 mb-4'>
            <Button
              text={'Switch Camera'}
              onClick={switchCamera}
              className='px-6 text-white transition-transform transform rounded-lg shadow-lg bg-primary-500 hover:scale-105 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-400'
            />
            <div className='relative'>
              <Button
                text={captureState?.isTimeOut ? 'Retry Capture' : 'Capture'}
                onClick={capture}
                disabled={captureState?.loading || videoPermissionDenied}
                isLoading={captureState?.loading}
                className={`relative px-6 text-white bg-green-500 rounded-lg shadow-lg ${!videoPermissionDenied && !captureState?.loading && 'hover:scale-105'} hover:bg-green-600 focus:ring-green-400`}
              />
              {videoPermissionDenied && (
                <div
                  className='absolute top-[-10px] right-[-10px] z-10 cursor-pointer animate-pulse hover:animate-none'
                  onClick={() => setIsModalOpen(!isModalOpen)}
                >
                  <span className='py-[2px] px-[10px] text-sm text-white rounded-full bg-semantic-orange-500 hover:ring-8 hover:ring-sematic-orange-600'>
                    !
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isOpenModal && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              setOpenModal={setIsOpenModal}
              openModal={isOpenModal}
              showCloseBtn={true}
              heading={evalName}
              children={
                <>
                  <ImageModal
                    ImageData={capturedImage}
                    onRecapture={handleRecapture}
                    selectedQuestionIndex={selectedQuestionIndex}
                    questionData={selectedQuestionData || []}
                    totalQuestionsLength={questionData.length}
                    onDone={handleDone}
                  />
                </>
              }
            />
          ) : (
            <Modal
              showCloseBtn={true}
              openModal={isOpenModal}
              setOpenModal={setIsOpenModal}
              heading={evalName}
              size={'5xl'}
              children={
                <>
                  <ImageModal
                    ImageData={capturedImage}
                    onRecapture={handleRecapture}
                    selectedQuestionIndex={selectedQuestionIndex}
                    questionData={selectedQuestionData || []}
                    totalQuestionsLength={questionData.length}
                    onDone={handleDone}
                  />
                </>
              }
            />
          )}
        </div>
      )}

      {videoPermissionDenied && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              openModal={isModalOpen}
              setOpenModal={setIsModalOpen}
              showCloseBtn={false}
              children={<PracticePermissionModal setIsModalOpen={setIsModalOpen} />}
            />
          ) : (
            <Modal
              openModal={isModalOpen}
              setOpenModal={setIsModalOpen}
              showCloseBtn={false}
              children={<PracticePermissionModal setIsModalOpen={setIsModalOpen} />}
            />
          )}
        </div>
      )}
    </div>
  );
}
