import { Avatar } from 'components/common/index';
import { RomanNumber } from 'configs';
import { IStudentSectionProps, User } from 'types';

const ShowStudentsSection = ({
  studentsList,
  handleStudentSelect,
  selectedStudentId,
  isParentSelected,
}: IStudentSectionProps) => {
  return (
    <div className='flex flex-wrap justify-center gap-x-2 gap-y-2'>
      {studentsList &&
        studentsList.map((account: User, index) => {
          const isStudentFlow = selectedStudentId === account?.members?.id && !isParentSelected;
          const grade = RomanNumber.find((r) => r.class === account?.members?.grade)?.romanValue;
          return (
            <div
              key={account?.members?.id}
              id={`student-${index + 1}`}
              className={`${isStudentFlow ? 'bg-primary-500' : 'bg-white'} rounded-xl px-2 py-3 w-[11rem] h-[11rem] sm:w-[13rem] sm:h-[13rem] shadow-lg flex flex-col items-center justify-center gap-2 m-2 transition-transform transform hover:scale-105 hover:duration-300 cursor-pointer`}
              onClick={() => handleStudentSelect(account?.members)}
            >
              <div
                className={`bg-white rounded-full w-5 h-5 sm:w-7 sm:h-7 self-end my-2 flex justify-center items-center border-2 border-neutral-200`}
              >
                <div
                  className={`${isStudentFlow ? 'bg-primary-500' : 'bg-white'} rounded-full my-3 w-2 h-2`}
                ></div>
              </div>
              <div className='avatar'>
                <div className='rounded-full'>
                  <Avatar
                    firstName={account?.members?.firstName}
                    lastName={account?.members?.lastName}
                    image={account?.members?.profileImage}
                    className={'rounded-full h-[70px] w-[70px] object-cover aspect-square'}
                    initialsClassName={`w-10 h-10 sm:w-14 sm:h-14 ${isStudentFlow ? 'bg-white text-black' : 'bg-primary-500 text-white'} border-primary-500 rounded-full p-2 flex  justify-center items-center`}
                    initialsFontSizeClassName='text-xs sm:text-base'
                  ></Avatar>
                </div>
              </div>
              <div className='mt-4'>
                <p
                  className={`text-sm sm:text-lg font-semibold font-sora text-center ${isStudentFlow ? 'text-white' : 'text-black'}`}
                >
                  {account?.members?.firstName + ' ' + account?.members?.lastName}
                </p>
                <p
                  className={`${isStudentFlow ? 'text-neutral-200' : 'text-neutral-600'} text-xs sm:text-lg text-center font-medium font-sora pb-2`}
                >
                  {`Class ${grade}`}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ShowStudentsSection;
