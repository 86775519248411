import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';

import { useGetPracticeSheetQuestionQuery } from 'store/apiSlices/studentDashboard.apiSlice';

import { BouncingDotsLoader, OnboardingNav } from 'components/common';
import { QrCodeCapture, QrCodeQuestions } from 'components/screens';

import { IPracticeQuestion, IQrCodeFlowParams, QuestionDescription } from 'types';
import { useLogger } from 'hooks';
import { CONFIG, LOG_TYPE } from 'configs';

export default function QrCodeFlow() {
  const [params, setParams] = useState<IQrCodeFlowParams>({
    practiceSheetId: '',
    evaluationId: '',
    questionId: '',
    studentId: '',
    token: '',
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { logInfo } = useLogger();
  const [noteId, setNoteId] = useState<number>();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const encodedToken = urlParams.get('token');
    const evaluationId = urlParams.get('evaluationId');
    const practiceSheetId = urlParams.get('practiceSheetId');
    const questionId = urlParams.get('questionId');
    const studentId = urlParams.get('studentId');
    const noteId = urlParams.get('noteId');
    const showQuestionParam = urlParams.get('showQuestion');

    const newParams = {
      practiceSheetId: practiceSheetId || '',
      evaluationId: evaluationId || '',
      questionId: questionId || '',
      studentId: studentId || '',
      token: '',
    };

    let shouldNavigate = false;

    try {
      if (showQuestionParam !== null) {
        const showQuestionValue = showQuestionParam === 'true';
        localStorage.setItem('showQuestion', JSON.stringify(showQuestionValue));
        setShowQuestion(showQuestionValue);
      }

      if (noteId && Number(noteId) !== 0) {
        setNoteId(Number(noteId));
      } else {
        setNoteId(0);
        urlParams.delete('noteId');
        shouldNavigate = true;
      }

      if (encodedToken) {
        const token = JSON.parse(atob(encodedToken || ''));
        if (token) {
          Cookie.set(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`, token);
          newParams.token = token;
          urlParams.delete('token');
          urlParams.delete('showQuestion');
          shouldNavigate = true;
        }
      } else {
        newParams.token = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`) || '';
      }

      setParams(newParams);

      if (shouldNavigate) {
        navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
      }
    } catch (error) {
      logInfo(
        `Failed to decode token with the following details: evaluationId -> ${evaluationId}, practiceSheetId -> ${practiceSheetId}, studentId -> ${studentId}, questionId -> ${questionId}`,
        {
          evaluationId,
          practiceSheetId,
          studentId,
          questionId,
          error: error,
        },
        LOG_TYPE.ERROR,
      );
      console.error('Failed to decode token:', error);
    }
  }, [location.pathname, location.search, logInfo, navigate]);

  const [questionData, setQuestionData] = useState<IPracticeQuestion[]>([]);

  const [showQuestion, setShowQuestion] = useState<boolean>(() => {
    return JSON.parse(localStorage.getItem('showQuestion') || 'false');
  });
  const [evalName, setEvalName] = useState<string>('');
  const [selectedQuestionData, setSelectedQuestionData] = useState<QuestionDescription[]>();
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);
  /* API -> get the practice class question Data */
  const {
    data: practiceQuestionsData,
    isLoading,
    refetch,
  } = useGetPracticeSheetQuestionQuery(
    {
      practiceSheetId: params.practiceSheetId,
      evaluationId: params.evaluationId,
      studentId: params.studentId,
    },
    {
      skip: !params?.evaluationId || !params?.practiceSheetId,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (practiceQuestionsData) {
      const filterEvaluationData = practiceQuestionsData?.data?.find(
        (data) => data?.evaluationId === params?.evaluationId,
      );
      setEvalName(filterEvaluationData?.evaluationName || '');
      setQuestionData(filterEvaluationData?.questions || []);
      const filteredQuestion = filterEvaluationData?.questions?.find(
        (data) => data?._id === params?.questionId,
      );
      setSelectedQuestionData(filteredQuestion?.question_description || []);
    }
  }, [params?.evaluationId, params?.questionId, practiceQuestionsData]);

  useEffect(() => {
    localStorage.setItem('showQuestion', JSON.stringify(showQuestion));
  }, [showQuestion]);

  const handleQrCodeCapture = (question: IPracticeQuestion, index: number) => {
    setParams((prev) => ({
      ...prev,
      questionId: question?._id,
    }));
    setShowQuestion(false);
    setSelectedQuestionData(question?.question_description);
    setSelectedQuestionIndex(index);

    const urlParams = new URLSearchParams(location.search);
    urlParams.set('questionId', question?._id);

    // Check if noteId is valid and set it, otherwise remove it
    const validNoteId = noteId || question?.noteId;
    if (validNoteId && Number(validNoteId) !== 0) {
      urlParams.set('noteId', validNoteId.toString());
    } else {
      urlParams.delete('noteId');
    }

    navigate(`${location.pathname}?${urlParams.toString()}`, {
      replace: true,
    });
  };

  return (
    <div className='grid min-h-screen grid-rows-[auto_1fr_auto]'>
      <div className='flex items-center justify-center w-full'>
        <OnboardingNav />
      </div>
      {isLoading ? (
        <>
          <div className='relative flex items-center justify-center flex-1 h-screen mx-2 xl:mx-6'>
            <BouncingDotsLoader />
          </div>
        </>
      ) : (
        <div className='flex-1 h-full'>
          <div className='flex flex-col items-center flex-1 h-full'>
            <>
              {!showQuestion ? (
                <>
                  <QrCodeCapture
                    params={params}
                    showQuestion={showQuestion}
                    evalName={evalName}
                    questionData={questionData || []}
                    selectedQuestionIndex={selectedQuestionIndex}
                    noteId={noteId}
                    selectedQuestionData={selectedQuestionData || []}
                    handleQrCodeCapture={handleQrCodeCapture}
                    setShowQuestion={setShowQuestion}
                    refetch={refetch}
                    setNoteId={setNoteId}
                  />
                </>
              ) : (
                <>
                  <QrCodeQuestions
                    params={params}
                    evalName={evalName}
                    isLoading={isLoading}
                    questionData={questionData || []}
                    practiceQuestionsData={practiceQuestionsData}
                    handleQrCodeCapture={handleQrCodeCapture}
                    refetch={refetch}
                    setNoteId={setNoteId}
                    submissionStatus={practiceQuestionsData?.data?.[0]?.studentSubmissionStatus}
                    evaluationStatus={practiceQuestionsData?.data?.[0].evaluationStatus}
                  />
                </>
              )}
            </>
          </div>
        </div>
      )}
    </div>
  );
}
