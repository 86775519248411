import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import { SANITY_DATASET, SANITY_PROJECT_ID } from 'configs';

const checkCameraAvailability = (): Promise<boolean | null> => {
  return new Promise((resolve) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      resolve(false);
    } else {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
    }
  });
};

const isMobileOrTablet = (): boolean => {
  const userAgent: string =
    navigator.userAgent ||
    navigator.vendor ||
    (typeof window !== 'undefined' && 'opera' in window ? String(window.opera) : '');

  const mobileRegex: RegExp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const tabletRegex: RegExp = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;

  return mobileRegex.test(userAgent) || tabletRegex.test(userAgent.toLowerCase());
};

const sanityClient = createClient({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET,
  apiVersion: '2024-09-16',
  useCdn: true,
});

const builder = imageUrlBuilder(sanityClient);

const urlFor = (source: string): ImageUrlBuilder => {
  return builder.image(source);
};

export { isMobileOrTablet, checkCameraAvailability, urlFor };
