import { useState } from 'react';

import { Accordian, Button, Modal, ModalBottomSheet } from 'components/common';
import { PracticeEvalContent } from 'components/screens';

import { IPracticeQuestionData, PracticeTabsProps } from 'types';

import 'assets/styles/scrollbar.css';
import { useWindowDimensions } from 'hooks';
import { EVALUATED_STATUS, USER_TYPE } from 'configs';
import PortableTextWithLatex from 'components/common/PortableTextWithLatex';

export default function PracticeSheetQuestions({
  questions,
  setIsOpenModal,
  setParamData,
  setQuestion,
  submitted,
  type,
  evaluationStatus,
}: PracticeTabsProps) {
  const { isMobileScreen } = useWindowDimensions();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>('');

  const handleButtonClick = (question: IPracticeQuestionData['questions'][0], idx: number) => {
    localStorage.removeItem('showQuestion');
    setIsOpenModal(true);
    setParamData({
      evaluationId: question?.studentEvaluation?.evaluationId,
      practiceSheetId: question?.studentEvaluation?.practiceSheetId,
      questionId: question?._id,
      studentId: question?.studentEvaluation?.studentId,
      questionIdx: idx + 1,
    });
    setQuestion(question?.question_description || []);
  };

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  return (
    <>
      {type === USER_TYPE.GUARDIAN && !submitted && (
        <div className='py-3 text-center'>
          <p className='text-neutral-700 title-regular font-semibold font-sora'>
            Note: Ensure you switch to the student account before submitting answers.
          </p>
        </div>
      )}

      <div className='mb-10 question-container'>
        {questions[0]?.questions?.map((question, idx) => (
          <div
            key={question._id}
            className={`p-6 relative my-4 transition-all duration-300 rounded-2xl shadow-lg border-2 ${
              question?.notebookCaptured
                ? 'bg-semantic-green-200 border-semantic-green-200'
                : 'bg-white text-neutral-800 border-neutral-300 hover:bg-gray-100'
            }`}
          >
            {question?.notebookCaptured ? (
              <span className='absolute top-0 right-0 px-3 py-1 text-sm font-medium text-white bg-green-500 rounded-l-full rounded-tr-full shadow-md'>
                <span className='mr-2 animate-bounce'>🎉</span>
                Submitted
              </span>
            ) : submitted ? (
              <span className='absolute top-0 right-0 px-3 py-1 text-sm font-medium text-white bg-semantic-red-400 rounded-l-full rounded-tr-full shadow-md'>
                <span className='mr-2 animate-bounce'>❓</span>
                Not attempted
              </span>
            ) : (
              ''
            )}

            <div className='flex flex-col justify-between md:flex-row md:items-center'>
              <h5 className={`text-lg font-bold text-primary-500`}>
                Question {`#${idx + 1}`}
                {':'}
              </h5>
            </div>

            <div className='flex flex-col items-start justify-between mb-4 text-lg font-medium break-words md:flex-row md:items-center lg:text-xl'>
              <div
                className={`flex-1 ${
                  question?.notebookCaptured ? 'text-neutral-850' : 'text-neutral-800'
                }`}
              >
                <PracticeEvalContent data={question?.question_description} />
              </div>
              <div className='flex items-center mt-4 md:mt-0 md:ml-4'>
                {!question?.notebookCaptured && type === USER_TYPE?.STUDENT && !submitted && (
                  <Button
                    text='Submit Answer'
                    onClick={() => handleButtonClick(question, idx)}
                    className='px-2 text-base font-semibold text-white transition-colors duration-200 rounded-lg shadow-md bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50'
                  />

                  // ) : (
                  //   <Button
                  //     className='px-2 text-base font-semibold text-white transition-colors duration-200 rounded-lg shadow-md bg-semantic-green-500 focus:outline-none hover:bg-semantic-green-500 hover:text-white focus:ring-2 focus:ring-semantic-green-500 focus:ring-opacity-50'
                  //     // onClick={() => handleButtonClick(question, idx)}
                  //     preIcon={true}
                  //     icon={TickRoundedLight}
                  //     text='Submitted'
                  //   />
                  // )
                )}

                {question?.capturedImages?.map((image, index) => (
                  <button
                    key={index}
                    onClick={() =>
                      handleImageClick(
                        evaluationStatus === EVALUATED_STATUS.EVALUATED
                          ? image.annotated
                          : image.original,
                      )
                    }
                    className='w-24 h-24 mr-1 overflow-hidden border border-gray-300 rounded-md'
                  >
                    <img
                      src={
                        evaluationStatus === EVALUATED_STATUS.EVALUATED
                          ? image.annotated
                          : image.original
                      }
                      alt={`Capture ${index + 1}`}
                      className='object-cover w-full h-full'
                    />
                  </button>
                ))}
              </div>
            </div>

            {question.notebookCaptured &&
              submitted &&
              evaluationStatus === EVALUATED_STATUS.EVALUATED && (
                <Accordian
                  title='Show answer'
                  collapseAll={true}
                  className='p-3 border-2 rounded-xl'
                >
                  <PortableTextWithLatex value={question.answer} />
                </Accordian>
              )}
          </div>
        ))}
      </div>

      {showModal && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              setOpenModal={setShowModal}
              openModal={showModal}
              showCloseBtn={true}
              children={
                <div className='customScrollBar'>
                  <h1 className='text-xl font-semibold text-center font-sora text-neutral-800'>
                    Submitted Answer:
                  </h1>

                  <div className='flex items-center justify-center'>
                    <img
                      src={selectedImage}
                      alt='Full Size'
                      className='max-w-full max-h-screen p-2 rounded-xl'
                    />
                  </div>
                </div>
              }
            />
          ) : (
            <Modal
              children={
                <div className='customScrollBar'>
                  <h1 className='font-semibold text-center font-sora text-primary-500 lg:text-xl xl:text-2xl'>
                    Submitted Answer:
                  </h1>

                  <div className='flex items-center justify-center'>
                    <img
                      src={selectedImage}
                      alt='Full Size'
                      className='max-w-full max-h-screen p-2 rounded-xl'
                    />
                  </div>
                </div>
              }
              showCloseBtn={true}
              openModal={showModal}
              setOpenModal={setShowModal}
              size={'5xl'}
            />
          )}
        </div>
      )}
    </>
  );
}
