export const useLocalStorage = () => {
  // Function to get value from local storage
  const getFromLocalStorage = (key: string) => {
    try {
      const item = localStorage.getItem(key);
      return item;
    } catch (error) {
      console.log(`Error while getting value from local storage : ${error}`);
      return null;
    }
  };

  // Function to set value in local storage
  const setToLocalStorage = (key: string, value: string) => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.log(`Error while setting value from local storage : ${error}`);
    }
  };

  // Function to remove value from local storage
  const removeFromLocalStorage = (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.log(`Error while removing value from local storage : ${error}`);
    }
  };

  return { getFromLocalStorage, setToLocalStorage, removeFromLocalStorage };
};
