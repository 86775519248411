import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { CoachBasic, IClassCoachPhaseProps } from 'types/dashboard/parentDashboard';

import {
  InfoIconBlue,
  MobileRightArrowAdv,
  MobileRightArrowBasic,
  RightLabelArrow,
  UpLabelArrow,
} from 'assets/svg';

import RenderCoachLevels from './RenderCoachLevels';
import ShowCurrentStatusInfo from './ShowCurrentStatusInfo';
import { Avatar } from 'components/common';

const CoachPhase = ({
  studentDetails,
  coachBasic,
  coachIntermediate,
  coachAdvance,
  secondsDifferenceInClassEnds,
  isClassEnds,
  isVisible,
  classId,
}: IClassCoachPhaseProps) => {
  const [isBasicLevel, setIsBasicLevel] = useState<boolean>(false);
  const [isInterLevel, setIsInterLevel] = useState<boolean>(false);
  const [isAdvLevel, setIsAdvLevel] = useState<boolean>(false);
  const [isClassEndAtAdv, setIsClassEndAtAdv] = useState<boolean>(false);
  const [isClassEndAtInter, setIsClassEndAtInter] = useState<boolean>(false);
  const [isClassEndAtBasic, setIsClassEndAtBasic] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sourceLabel, setSourceLabel] = useState<CoachBasic[]>();

  useEffect(() => {
    if (coachAdvance && coachAdvance?.length > 0) {
      if (
        (coachAdvance && coachAdvance[0]?.status === 'completed') ||
        coachAdvance[0]?.status === 'inProgress'
      ) {
        setIsClassEndAtAdv(secondsDifferenceInClassEnds <= 0 || isClassEnds);
        setIsAdvLevel(true);
      }
    }

    if (coachIntermediate && coachIntermediate?.length > 0) {
      if (
        (coachIntermediate && coachIntermediate[0]?.status === 'completed') ||
        coachIntermediate[0]?.status === 'inProgress'
      ) {
        setIsClassEndAtInter(!isAdvLevel && (secondsDifferenceInClassEnds <= 0 || isClassEnds));
        setIsInterLevel(true);
      }
    }

    if (coachBasic && coachBasic?.length > 0) {
      if (
        (coachBasic && coachBasic[0]?.status === 'completed') ||
        coachBasic[0]?.status === 'inProgress'
      ) {
        setIsClassEndAtBasic(
          !isAdvLevel && !isInterLevel && (secondsDifferenceInClassEnds <= 0 || isClassEnds),
        );
        setIsBasicLevel(true);
      }
    }
  }, [
    coachAdvance,
    coachBasic,
    coachIntermediate,
    isAdvLevel,
    isInterLevel,
    secondsDifferenceInClassEnds,
    isClassEnds,
  ]);

  const renderAvtar = () => {
    return (
      <Avatar
        image={studentDetails?.profileImage}
        firstName={studentDetails?.firstName}
        lastName={studentDetails?.lastName}
        className='object-cover w-full h-full border-red-500 rounded-full animate-pulse'
        initialsClassName={`w-full h-full p-1 rounded-full bg-red-500 border-red-500  text-white flex items-center justify-center`}
        initialsFontSizeClassName='text-[10px] xl:text-xs'
      />
    );
  };

  return (
    <>
      {/* {desktop view} */}
      <div className='hidden xl:flex flex-col h-[18rem] mt-2 relative'>
        {/* COACH BASIC UP ARROWS WITH LABEL */}
        {!isEmpty(coachBasic) && (
          <div>
            <img
              className={`absolute object-contain top-[65%] left-[14%]`}
              src={UpLabelArrow}
              alt='image'
            />
            <div className='items-center gap-4 bg-white text-primary-500 text-xs xl:text-sm font-semibold font-poppins px-2 py-1 rounded-md absolute z-40 top-[56%]  flex'>
              Foundation Q&A
              <div>
                <img
                  className='object-contain w-5 h-5 cursor-pointer'
                  src={InfoIconBlue}
                  width={12}
                  alt='Info'
                  hidden={coachBasic && coachBasic[0]?.status === 'notStarted'}
                  onClick={() => {
                    setOpenModal(true);
                    setSourceLabel(coachBasic?.map((data) => data));
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {/* COACH INTERMEDIATE DOWN ARROWS WITH LABEL */}
        {!isEmpty(coachIntermediate) && (
          <div>
            <img
              className={`absolute object-contain top-[40%] left-[33%] `}
              src={UpLabelArrow}
              alt='image'
            />
            <div
              className={`bg-white items-center gap-2 text-primary-500 text-xs xl:text-sm font-semibold font-poppins px-2 py-1 rounded-md absolute top-[30%] left-[31%] z-10 flex`}
            >
              Intermediate Q&A
              <div>
                <img
                  className='object-contain w-5 h-5 cursor-pointer'
                  src={InfoIconBlue}
                  width={12}
                  alt='Info'
                  hidden={coachIntermediate && coachIntermediate[0]?.status === 'notStarted'}
                  onClick={() => {
                    setOpenModal(true);
                    setSourceLabel(coachIntermediate?.map((data) => data));
                  }}
                />
              </div>{' '}
            </div>
          </div>
        )}

        {/* COACH ADVANCE DOWN ARROWS WITH LABEL */}
        {!isEmpty(coachAdvance) && (
          <div>
            <img
              className={`absolute object-contain top-[7%] left-[50%] `}
              src={UpLabelArrow}
              alt='image'
            />
            <span
              className={`bg-white items-center gap-2 text-primary-500 text-xs xl:text-sm font-semibold font-poppins px-2 py-1 rounded-md absolute top-[0%] left-[50%] z-10 flex`}
            >
              Advanced Q&A
              <div>
                <img
                  className='object-contain w-5 h-5 cursor-pointer'
                  src={InfoIconBlue}
                  width={12}
                  alt='Info'
                  hidden={coachAdvance && coachAdvance[0]?.status === 'notStarted'}
                  onClick={() => {
                    setOpenModal(true);
                    setSourceLabel(coachAdvance?.map((data) => data));
                  }}
                />
              </div>
            </span>
          </div>
        )}

        {/* COACH PHASE CARD STARTS HERE */}
        <div className='flex flex-col h-full mb-2 rounded-lg bg-knowledgeLevel-100'>
          <div className='flex justify-between'>
            <div className='font-poppins text-[10px] p-2 bg-knowledgeLevel-700 text-white rounded-tl-lg rounded-br-lg  tracking-widest'>
              ADVANCED
            </div>
            <div className='w-3 rounded-tl-lg bg-knowledgeLevel-800 h-86'></div>
          </div>
          <div className='flex items-center justify-end flex-1'>
            {!isEmpty(coachAdvance) && (
              <>
                <div
                  className={`absolute top-[19.7%] right-[33%] left-[41%] 2xl:left-[41.5%] bottom-0 w-[8%]  lg:h-[23%]  border-t-[8px] border-l-[8px]  ${isAdvLevel && coachAdvance && (coachAdvance[0]?.status === 'completed' || coachAdvance[0]?.status === 'inProgress') ? 'border-primary-500' : 'border-neutral-200 '} rounded-tl-3xl`}
                ></div>
                <div className={`flex items-center w-[48%]  mr-2`}>
                  {!isEmpty(coachAdvance) &&
                    coachAdvance?.map((data, idx) => {
                      return (
                        <div key={data?.evaluationId} className={`flex items-center w-full`}>
                          <div
                            className={`relative rounded-full ${data?.status === 'completed' ? 'bg-primary-500  border-primary-500' : 'bg-neutral-500 border-neutral-500'} w-[1.8rem] h-[1.6rem]`}
                          >
                            <div className='grid h-full place-items-center'>
                              <RenderCoachLevels
                                studentDetails={studentDetails}
                                currentLevelDetails={data}
                                classStatus={isClassEnds}
                                isClassEnds={isClassEndAtAdv}
                                level={isAdvLevel}
                                isVisible={isVisible}
                              />
                            </div>
                          </div>
                          {coachAdvance &&
                          (coachAdvance[idx + 1]?.status === 'inProgress' ||
                            coachAdvance[idx + 1]?.status === 'completed') ? (
                            <>
                              <div
                                key={data?.evaluationId}
                                className={`h-[8px] w-full ${isClassEndAtAdv || isAdvLevel ? 'bg-primary-500' : 'bg-neutral-200 '}`}
                              ></div>
                            </>
                          ) : (
                            <>
                              <div
                                key={data?.evaluationId}
                                className={`w-full h-2 ${isClassEndAtAdv || (data?.status === 'completed' && coachAdvance[idx]?.status === 'inProgress') ? 'bg-primary-500' : 'bg-neutral-200 '}`}
                              ></div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  <div
                    className={`h-[8px] w-full ${isClassEndAtAdv ? 'bg-primary-500' : 'bg-neutral-200 '}`}
                  ></div>
                  <div
                    className={`rounded-full ${isClassEndAtAdv ? 'bg-primary-500' : 'px-3 py-1 bg-neutral-500 border-neutral-500'}`}
                  >
                    <div className='grid h-full place-items-center'>
                      {isClassEndAtAdv ? (
                        renderAvtar()
                      ) : (
                        <span className='text-sm font-bold text-white'>I</span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className='w-3 h-full rounded-bl-lg bg-knowledgeLevel-800'></div>
          </div>
        </div>

        {/* {transition line from intermediate to advanced} */}
        {!isEmpty(coachIntermediate) && !isEmpty(coachAdvance) && (
          <div
            className={`absolute top-[42%] left-[36%] right-0 h-[15%] w-[6.3%] 2xl:w-[6.4%] border-b-[8px] border-r-[8px] ${isAdvLevel && coachAdvance && (coachAdvance[0]?.status === 'inProgress' || coachAdvance[0]?.status === 'completed') ? 'border-primary-500 z-10' : 'border-neutral-200'} rounded-br-3xl`}
          />
        )}

        {/* COACH INTERMEDIATE CARD */}
        <div className='flex flex-col h-full mb-2 rounded-lg bg-knowledgeLevel-100'>
          <div className='flex justify-between'>
            <div className='font-poppins text-[10px] p-2 bg-knowledgeLevel-500 text-white rounded-tl-lg rounded-br-lg tracking-widest'>
              INTERMEDIATE
            </div>
            <div className='w-3 rounded-tl-lg bg-knowledgeLevel-500 h-86'></div>
          </div>
          <div className='relative flex items-center justify-end flex-1 '>
            {!isEmpty(coachIntermediate) && (
              <>
                <div
                  className={`absolute w-[10%] 2xl:w-[9%] right-0 xl:left-[23.2%] 2xl:left-[24%] top-[44%] bottom-0 border-t-[8px] border-l-[8px]  ${coachIntermediate && (coachIntermediate[0]?.status === 'completed' || coachIntermediate[0]?.status === 'inProgress') ? 'border-primary-500' : 'border-neutral-200 '} rounded-tl-3xl`}
                ></div>
                <div className={`relative flex items-center mr-2 w-[65%]`}>
                  {coachIntermediate &&
                    coachIntermediate?.map((data, idx) => {
                      return (
                        <div key={data?.evaluationId} className={`flex items-center w-full`}>
                          <div
                            key={data?.evaluationId}
                            className={`rounded-full  ${data?.status === 'completed' ? 'bg-primary-500  border-primary-500' : 'bg-neutral-500 border-neutral-500'} w-[2rem] h-[1.6rem]`}
                          >
                            <div className='grid h-full place-items-center'>
                              <RenderCoachLevels
                                studentDetails={studentDetails}
                                classStatus={isClassEnds}
                                currentLevelDetails={data}
                                isClassEnds={isClassEndAtInter}
                                isVisible={isVisible}
                                level={isInterLevel}
                              />
                            </div>
                          </div>
                          {coachIntermediate &&
                          (coachIntermediate[idx + 1]?.status === 'inProgress' ||
                            coachIntermediate[idx + 1]?.status === 'completed') ? (
                            <>
                              <div
                                key={data?.evaluationId}
                                className={`h-[8px] w-full ${isClassEndAtInter || isInterLevel ? 'bg-primary-500 z-10' : 'bg-neutral-200 '}`}
                              ></div>
                            </>
                          ) : (
                            <>
                              <div
                                key={data?.evaluationId}
                                className={`h-[8px] w-full ${isClassEndAtInter ? 'bg-primary-500' : 'bg-neutral-200 '}`}
                              ></div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  <div
                    className={`h-[8px] w-full ${isClassEndAtInter ? 'bg-primary-500' : 'bg-neutral-200 '}`}
                  ></div>
                  <div
                    className={`rounded-full  ${isClassEndAtInter ? 'bg-primary-500' : 'px-3 py-1 bg-neutral-500 border-neutral-500'}`}
                  >
                    <div className='grid h-full place-items-center'>
                      {isClassEndAtInter ? (
                        renderAvtar()
                      ) : (
                        <span className='text-sm font-bold text-white'>I</span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className='w-3 h-full rounded-bl-lg bg-knowledgeLevel-500'></div>
          </div>
        </div>

        {/* {transition line from basic to intermediate} */}
        {!isEmpty(coachBasic) && !isEmpty(coachIntermediate) && (
          <div
            className={`absolute ${isClassEndAtBasic ? '' : 'z-10'} top-[63%] left-[16.5%] 2xl:left-[16%] right-0 h-[50px] w-[8%] 2xl:w-[9%] border-b-[8px] border-r-[8px] ${coachIntermediate && (coachIntermediate[0]?.status === 'inProgress' || coachIntermediate[0]?.status === 'completed') ? 'border-primary-500' : 'border-neutral-200'} rounded-br-3xl`}
          />
        )}

        {/* COACH BASIC CARD  */}
        <div className='flex flex-col justify-end h-full rounded-lg bg-knowledgeLevel-100'>
          <div className='relative flex items-center justify-end flex-1 '>
            {!isEmpty(coachBasic) && (
              <>
                <div
                  className={`absolute top-[-83%] lg:right-[83%] xl:right-[83.7%] 2xl:right-[84%] w-[18.9%] 2xl:w-[18%] h-[83px] border-b-[8px] border-l-[8px] rounded-bl-3xl ${coachBasic && (coachBasic[0]?.status === 'inProgress' || coachBasic[0]?.status === 'completed') ? 'border-primary-500' : 'border-neutral-200'}`}
                ></div>
                <div className={`flex items-center w-[85.5%]`}>
                  {coachBasic?.map((data, idx) => {
                    return (
                      <div key={data.evaluationId} className={`flex items-center w-full`}>
                        <div
                          className={`relative rounded-full ${data?.status === 'completed' ? 'bg-primary-500  border-primary-500' : 'bg-neutral-500 border-neutral-500'} w-[1.8rem] h-[1.6rem]`}
                        >
                          <RenderCoachLevels
                            studentDetails={studentDetails}
                            currentLevelDetails={coachBasic[idx]}
                            classStatus={isClassEnds}
                            isClassEnds={isClassEndAtBasic}
                            isVisible={isVisible}
                            level={isBasicLevel}
                          />
                        </div>
                        {coachBasic &&
                        (coachBasic[idx + 1]?.status === 'inProgress' ||
                          coachBasic[idx + 1]?.status === 'completed') ? (
                          <>
                            <div
                              className={`h-[8px] w-full ${isClassEndAtBasic || isBasicLevel ? 'bg-primary-500 z-10' : 'bg-neutral-200'}`}
                            ></div>
                          </>
                        ) : (
                          <div
                            className={`h-[8px] w-full ${isClassEndAtBasic || (isBasicLevel && data?.status === 'completed' && coachBasic[idx + 1]?.status === 'inProgress') ? 'bg-primary-500 z-10' : 'bg-neutral-200'}`}
                          ></div>
                        )}
                      </div>
                    );
                  })}
                  <div
                    className={`h-[8px] w-full ${isClassEndAtBasic ? 'bg-primary-500' : 'bg-neutral-200 '}`}
                  ></div>
                  <div
                    className={`rounded-full ${isClassEndAtBasic ? 'bg-primary-500' : 'px-3 py-1 bg-neutral-500 border-neutral-500'} mr-2`}
                  >
                    <div className='grid h-full place-items-center'>
                      {isClassEndAtBasic ? (
                        renderAvtar()
                      ) : (
                        <span className='text-sm font-bold text-white'>I</span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className='w-3 h-full rounded-tl-lg bg-knowledgeLevel-300'></div>
          </div>
          <div className='flex justify-between'>
            <div className='font-poppins text-[10px] p-2 bg-knowledgeLevel-300 text-white rounded-tr-lg rounded-bl-lg tracking-widest '>
              FOUNDATION
            </div>
            <div className='w-3 rounded-bl-lg bg-knowledgeLevel-300 h-86'></div>
          </div>
        </div>
      </div>

      {/* {mobile view} */}
      <div className='xl:hidden flex w-full h-[18rem] mt-5 gap-x-2 relative'>
        <div className='bg-knowledgeLevel-100 w-[3rem] h-full flex flex-col mb-2 rounded-lg justify-between'>
          <div className='flex flex-col items-center flex-1'>
            {!isEmpty(coachBasic) && (
              <>
                <div
                  className={`w-[6px] h-[10%] ${isBasicLevel && coachBasic && (coachBasic[0]?.status === 'inProgress' || coachBasic[0]?.status === 'completed') ? 'bg-primary-500' : 'bg-neutral-200 '}`}
                ></div>
                <div className='flex flex-col items-center h-[95%]'>
                  {coachBasic?.map((data, idx) => (
                    <>
                      <div
                        className={`rounded-full ${data?.status === 'completed' ? 'bg-primary-500  border-primary-500' : 'bg-neutral-500 border-neutral-500'} `}
                      >
                        <RenderCoachLevels
                          studentDetails={studentDetails}
                          currentLevelDetails={data}
                          isClassEnds={isClassEndAtBasic}
                          classStatus={isClassEnds}
                          isVisible={isVisible}
                        />
                      </div>
                      {coachBasic &&
                      (coachBasic[idx + 1]?.status === 'inProgress' ||
                        coachBasic[idx + 1]?.status === 'completed') ? (
                        <>
                          <div
                            key={data?.evaluationId}
                            className={`h-full w-[6px] ${isClassEndAtBasic || isBasicLevel ? 'bg-primary-500 z-10' : 'bg-neutral-200'}`}
                          ></div>
                        </>
                      ) : (
                        <>
                          <div
                            key={data?.evaluationId}
                            className={`h-full w-[6px] ${isClassEndAtBasic || (isBasicLevel && data?.status === 'completed' && coachBasic[idx]?.status === 'inProgress') ? 'bg-primary-500 z-10' : 'bg-neutral-200'}`}
                          ></div>
                        </>
                      )}
                    </>
                  ))}
                </div>
                <div className='mb-2'>
                  <div className='grid h-full place-items-center'>
                    {isClassEndAtBasic ? (
                      renderAvtar()
                    ) : (
                      <span className='px-3 py-[2px]  text-sm font-bold text-white rounded-full bg-neutral-500 border-neutral-500'>
                        I
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <span className='font-poppins text-[10px] bg-knowledgeLevel-300 text-white tracking-widest text-center rounded-b-lg'>
            FND LEVEL
          </span>
        </div>
        {!isEmpty(coachBasic) && (
          <img
            className={`absolute object-contain top-[2rem] left-[35px]`}
            src={MobileRightArrowBasic}
            alt='image'
          />
        )}

        {/* {transition line from basic to intermediate} */}
        {!isEmpty(coachBasic) && !isEmpty(coachIntermediate) && (
          <div
            className={`absolute top-[45px] left-[21px] right-0 h-[25px] w-[56px] border-b-[7px] border-l-[7px] ${isInterLevel && coachIntermediate && (coachIntermediate[0]?.status === 'inProgress' || coachIntermediate[0]?.status === 'completed') ? 'border-primary-500' : 'border-neutral-200'} rounded-bl-2xl`}
          />
        )}

        <div className='bg-knowledgeLevel-100 w-[3rem] h-full flex flex-col mb-2 rounded-lg justify-between'>
          <div className='flex flex-col items-center justify-end flex-1'>
            {!isEmpty(coachIntermediate) && (
              <>
                <div
                  className={`w-[7px] h-[8.2%] rounded-tr-2xl ${isInterLevel && coachIntermediate && (coachIntermediate[0]?.status === 'completed' || coachIntermediate[0]?.status === 'inProgress') ? 'bg-primary-500' : 'bg-neutral-200 '} `}
                ></div>
                <div className={`flex flex-col items-center h-[55%]`}>
                  {coachIntermediate?.map((data, idx) => {
                    return (
                      <>
                        <div
                          className={`rounded-full ${isInterLevel && data?.status === 'completed' ? 'bg-primary-500  border-primary-500' : 'bg-neutral-500 border-neutral-500'}`}
                        >
                          <RenderCoachLevels
                            studentDetails={studentDetails}
                            currentLevelDetails={data}
                            classStatus={isClassEnds}
                            isClassEnds={isClassEndAtInter}
                            isVisible={isVisible}
                          />
                        </div>

                        {coachIntermediate &&
                        (coachIntermediate[idx + 1]?.status === 'inProgress' ||
                          coachIntermediate[idx + 1]?.status === 'completed') ? (
                          <>
                            <div
                              className={`h-full w-[7px]  ${isClassEndAtInter || isInterLevel ? 'bg-primary-500 z-20' : 'bg-neutral-200 '}`}
                            ></div>
                          </>
                        ) : (
                          <div
                            className={`h-full w-[7px]  ${isClassEndAtInter ? 'bg-primary-500 z-20' : 'bg-neutral-200 '}`}
                          ></div>
                        )}
                      </>
                    );
                  })}
                </div>
                <div className='mb-2'>
                  <div className='grid h-full place-items-center'>
                    {isClassEndAtInter ? (
                      renderAvtar()
                    ) : (
                      <span className='px-3 py-[2px] text-sm font-bold text-white rounded-full bg-neutral-500 border-neutral-500'>
                        I
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <span className='font-poppins text-[10px] bg-knowledgeLevel-500 text-white tracking-widest text-center rounded-b-lg'>
            INTER LEVEL
          </span>
        </div>
        {!isEmpty(coachIntermediate) && (
          <img
            className={`absolute object-contain top-[6rem] left-[90px]`}
            src={RightLabelArrow}
            alt='image'
          />
        )}

        {/* {transition line from intermediate to advanced} */}
        {!isEmpty(coachAdvance) && (
          <div
            className={`absolute top-[6.7rem] left-[77px] right-0 h-[1rem] w-[56px] border-b-[7px] border-l-[6px] ${isAdvLevel && coachAdvance && (coachAdvance[0]?.status === 'inProgress' || coachAdvance[0]?.status === 'completed') ? 'border-primary-500 z-20' : 'border-neutral-200 z-0'} rounded-bl-2xl`}
          ></div>
        )}

        <div className='bg-knowledgeLevel-100 w-[3rem] h-full flex flex-col mb-2 rounded-lg justify-between'>
          <div className='flex flex-col items-center justify-end flex-1'>
            {!isEmpty(coachAdvance) && (
              <>
                <div
                  className={`w-[6px] h-[7%] ${isAdvLevel && coachAdvance && (coachAdvance[0]?.status === 'completed' || coachAdvance[0]?.status === 'inProgress') ? 'bg-primary-500' : 'bg-neutral-200 '} rounded-tr-2xl `}
                ></div>
                <div className='flex flex-col items-center h-[36%]'>
                  {coachAdvance?.map((data, idx) => {
                    return (
                      <>
                        <div
                          className={`rounded-full ${isAdvLevel && data?.status === 'completed' ? 'bg-primary-500  border-primary-500' : 'bg-neutral-500 border-neutral-500'}`}
                        >
                          <RenderCoachLevels
                            studentDetails={studentDetails}
                            currentLevelDetails={data}
                            isClassEnds={isClassEndAtAdv}
                            isVisible={isVisible}
                            classStatus={isClassEnds}
                          />
                        </div>
                        {coachAdvance &&
                        (coachAdvance[idx + 1]?.status === 'inProgress' ||
                          coachAdvance[idx + 1]?.status === 'completed') ? (
                          <>
                            <div
                              className={`h-full w-[6px] ${isClassEndAtAdv || isAdvLevel ? 'bg-primary-500' : 'bg-neutral-200 '} `}
                            ></div>
                          </>
                        ) : (
                          <>
                            <div
                              className={`h-full w-[6px] ${isClassEndAtAdv || (data?.status === 'completed' && coachAdvance[idx]?.status === 'inProgress') ? 'bg-primary-500' : 'bg-neutral-200 '} `}
                            ></div>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
                <div className='mb-2'>
                  <div className='grid h-full place-items-center'>
                    {isClassEndAtAdv ? (
                      renderAvtar()
                    ) : (
                      <span className='rounded-full bg-neutral-500 border-neutral-500 py-[2px] px-3 text-sm font-bold text-white'>
                        I
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <span className='font-poppins text-[10px] bg-knowledgeLevel-700 text-white tracking-widest text-center rounded-b-lg'>
            ADV LEVEL
          </span>
        </div>
        {!isEmpty(coachAdvance) && (
          <img
            className={`absolute object-contain bottom-[48%] w-8 left-[9rem]`}
            src={MobileRightArrowAdv}
            alt='image'
          />
        )}

        <div className='flex flex-col flex-1 w-full h-full mb-2 bg-white rounded-lg'>
          <div className='text-neutral-900 font-semibold font-poppins text-[10px]'>Coach Phase</div>
          {!isEmpty(coachBasic) && (
            <div className='flex tems-center gap-1 bg-neutral-50 text-primary-500 font-semibold text-[10px] font-poppins px-1 py-2 rounded-md ml-2 relative top-[0.9rem] max-w-[10rem]'>
              <div className='flex items-center gap-1'>
                Foundation Q&A
                {isBasicLevel && (
                  <div>
                    <img
                      className='cursor-pointer'
                      src={InfoIconBlue}
                      width={12}
                      alt='Info'
                      hidden={coachBasic && coachBasic[0]?.status === 'notStarted'}
                      onClick={() => {
                        setOpenModal(true);
                        setSourceLabel(coachBasic?.map((data) => data));
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {!isEmpty(coachIntermediate) && (
            <div className='flex tems-center gap-2 bg-neutral-50 text-primary-500 font-semibold text-[10px] font-poppins px-1 py-2 rounded-md ml-2 relative top-[15%] max-w-[10rem]'>
              <div className='flex items-center gap-1'>
                Intermediate Q&A
                {isInterLevel && (
                  <div>
                    <img
                      className='cursor-pointer'
                      src={InfoIconBlue}
                      width={12}
                      alt='Info'
                      hidden={coachIntermediate && coachIntermediate[0]?.status === 'notStarted'}
                      onClick={() => {
                        setOpenModal(true);
                        setSourceLabel(coachIntermediate?.map((data) => data));
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {!isEmpty(coachAdvance) && (
            <div className='flex tems-center gap-2 bg-neutral-50 text-primary-500 font-semibold text-[10px] font-poppins px-1 py-2 rounded-md ml-2 relative top-[22%] max-w-[10rem]'>
              <div className='flex items-center gap-1'>
                Advanced Q&A
                {isAdvLevel && (
                  <div>
                    <img
                      className='cursor-pointer'
                      src={InfoIconBlue}
                      width={12}
                      alt='Info'
                      hidden={coachIntermediate && coachIntermediate[0]?.status === 'notStarted'}
                      onClick={() => {
                        setOpenModal(true);
                        setSourceLabel(coachAdvance?.map((data) => data));
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {openModal && sourceLabel && (
        <ShowCurrentStatusInfo
          studentId={studentDetails?.id?.toString()}
          label={sourceLabel}
          openModal={openModal}
          setOpenModal={setOpenModal}
          classId={classId}
        />
      )}
    </>
  );
};

export default CoachPhase;
