import { datadogLogs } from '@datadog/browser-logs';
import { useCallback } from 'react';
import useMeeting from '../inClass/useMeeting';

const useInclassLogger = () => {
  const { classId, studentId, studentName } = useMeeting();

  const datadogLog = useCallback(
    (message: string, options?: Record<string, string>) => {
      datadogLogs?.logger.info(`${message}, studentId:${studentId} classId:${classId}`, {
        classId,
        studentName,
        clientId: studentId,
        ...options,
      });
    },
    [classId, studentId, studentName],
  );

  return { datadogLog };
};

export default useInclassLogger;
