import { BaseQueryApi, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

import { CONFIG, ROUTES } from 'configs';
import { datadogLogs } from '@datadog/browser-logs';
interface DefinitionExtraOptions {}

const customBaseQuery = async (
  args: FetchArgs,
  api: BaseQueryApi,
  extraOptions: DefinitionExtraOptions,
) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: CONFIG.API_URL,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    // prepareHeaders: (headers) => {
    //   if (headers.has('Authorization')) return headers;
    //   const loginToken = localStorage.getItem('loginToken');
    //   const tempToken = localStorage.getItem('tempToken');
    //   const tempAuthToken = localStorage.getItem('tempAuthToken');
    //   if (loginToken) {
    //     headers.set('Authorization', `Bearer ${loginToken}`);
    //   } else if (tempToken) {
    //     headers.set('Authorization', `Bearer ${tempToken}`);
    //   } else if (tempAuthToken) {
    //     headers.set('Authorization', `Bearer ${tempAuthToken}`);
    //   }
    //   return headers;
    // },
    credentials: 'include',
  })(args, api, extraOptions);

  if (baseResult?.meta?.response?.status !== 200) {
    datadogLogs.logger.error(`API call failed with status ${baseResult?.meta?.response?.status}`, {
      Arguments: args.body,
      URL: args.url,
      Status: baseResult?.meta?.response?.status,
      Response: baseResult.data,
    });
  }

  if ([419, 401].includes(baseResult?.meta?.response?.status ?? -1)) {
    if (![ROUTES.LOGIN, ROUTES.FORGET_PASSCODE]?.includes(window.location.pathname)) {
      localStorage.clear();
      window.location.href = ROUTES.LOGIN;
    }
  }

  return baseResult;
};

// Define a service using a base URL and expected endpoints
const apiSlice = createApi({
  baseQuery: customBaseQuery,
  refetchOnMountOrArgChange: false,
  endpoints: () => ({}),
});

export default apiSlice;
