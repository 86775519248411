import { useEffect, useState, useCallback, useContext } from 'react';
import { useMeeting, useSocketSubscribe } from 'hooks';
import { checkMediaPermissions, listenToMediaPermissions } from 'utils';
import { SocketContext } from 'contexts';
import { datadogLogs } from '@datadog/browser-logs';

export const useMediaDevices = () => {
  const { meeting, studentId, classId } = useMeeting();
  const socket = useContext(SocketContext);
  const [audioDevices, setAudioDevices] = useState<MediaDeviceInfo[]>([]);
  const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([]);
  const [speakerDevices, setSpeakerDevices] = useState<MediaDeviceInfo[]>([]);
  const [currentSelectedDevice, setCurrentSelectedDevice] = useState<{
    audio: string;
    video: string;
    speaker: string;
  }>({
    audio: '',
    video: '',
    speaker: '',
  });

  const [videoPermissionDenied, setVideoPermissionDenied] = useState<boolean>(false);
  const [audioPermissionDenied, setAudioPermissionDenied] = useState<boolean>(false);

  const fetchDevices = useCallback(async () => {
    try {
      const audioDevices = await meeting?.self?.getAudioDevices();
      if (audioDevices?.length > 0 && audioDevices[0].deviceId) {
        setAudioDevices(audioDevices);
      }
      const videoDevices = await meeting?.self?.getVideoDevices();
      if (videoDevices?.length > 0 && videoDevices[0].deviceId) {
        setVideoDevices(videoDevices);
      }
      const speakerDevices = await meeting?.self?.getSpeakerDevices();
      if (speakerDevices?.length > 0 && speakerDevices[0].deviceId) {
        setSpeakerDevices(speakerDevices);
      }
      const currentDevice = meeting?.self?.getCurrentDevices() ?? {};
      setCurrentSelectedDevice({
        audio: currentDevice?.audio?.deviceId,
        speaker: currentDevice?.speaker?.deviceId,
        video: currentDevice?.video?.deviceId,
      });
    } catch (error) {
      datadogLogs.logger.error(`Error fetching devices of ${studentId} and classId:${classId}`, {
        error: error,
        classId: classId,
        studentId: studentId,
      });
    }
  }, [classId, meeting?.self, studentId]);

  useEffect(() => {
    fetchDevices();
    const handleDeviceUpdate = () => {
      fetchDevices();
    };
    meeting?.self?.on('deviceListUpdate', handleDeviceUpdate);
    return () => {
      meeting?.self?.removeListener('deviceListUpdate', handleDeviceUpdate);
    };
  }, [fetchDevices, meeting]);

  // Get devices
  useEffect(() => {
    const fetchDevices = async () => {
      if (!videoPermissionDenied) {
        const videoDevices = await meeting?.self?.getVideoDevices();
        if (videoDevices?.length > 0 && videoDevices[0].deviceId) {
          setVideoDevices(videoDevices);
        }
      }

      if (!audioPermissionDenied) {
        const audioDevices = await meeting?.self?.getAudioDevices();
        const speakerDevices = await meeting?.self?.getSpeakerDevices();
        if (audioDevices?.length > 0 && audioDevices[0].deviceId) {
          setAudioDevices(audioDevices);
        }
        if (speakerDevices?.length > 0 && speakerDevices[0].deviceId) {
          setSpeakerDevices(speakerDevices);
        }
      }
    };

    const timeoutId = setTimeout(fetchDevices, 400);
    return () => clearTimeout(timeoutId); // Cleanup timeout
  }, [audioPermissionDenied, meeting?.self, videoPermissionDenied]);

  // Get permission denied
  useEffect(() => {
    const fetchMediaPermissions = async () => {
      const permissions = await checkMediaPermissions();
      setAudioPermissionDenied(permissions.audioPermissionDenied);
      setVideoPermissionDenied(permissions.videoPermissionDenied);
    };
    fetchMediaPermissions();
    listenToMediaPermissions(({ audioPermissionDenied, videoPermissionDenied }) => {
      setAudioPermissionDenied(audioPermissionDenied);
      setVideoPermissionDenied(videoPermissionDenied);
    });
  }, []);

  useEffect(() => {
    async function fetchAndUpdateDevices() {
      const fetchedAudioDevices = (await meeting?.self?.getAudioDevices()) || [];
      const fetchedSpeakerDevices = (await meeting?.self?.getSpeakerDevices()) || [];
      const fetchedVideoDevices = (await meeting?.self?.getVideoDevices()) || [];

      // Update state only once to avoid unnecessary re-renders
      if (fetchedAudioDevices?.length > 0 && fetchedAudioDevices[0].deviceId) {
        setAudioDevices(fetchedAudioDevices);
      }
      if (fetchedSpeakerDevices?.length > 0 && fetchedSpeakerDevices[0].deviceId) {
        setSpeakerDevices(fetchedSpeakerDevices);
      }
      if (fetchedVideoDevices?.length > 0 && fetchedVideoDevices[0].deviceId) {
        setVideoDevices(fetchedVideoDevices);
      }

      // Emit the devices info through the socket
      socket?.emit('studentDevices', {
        studentId: meeting?.self?.customParticipantId,
        devicesInfo: {
          audioDevices: fetchedAudioDevices,
          videoDevices: fetchedVideoDevices,
          speakerDevices: fetchedSpeakerDevices,
          currentSelectedDevice,
        },
      });
    }

    fetchAndUpdateDevices();

    const handleDeviceUpdate = () => {
      fetchAndUpdateDevices();
    };

    meeting?.self?.on('deviceListUpdate', handleDeviceUpdate);
    return () => {
      meeting?.self?.removeListener('deviceListUpdate', handleDeviceUpdate);
    };
  }, [meeting?.self, socket, currentSelectedDevice]);

  // Device change handler
  useEffect(() => {
    const deviceChangeHandler = async ({ device }: { device: MediaDeviceInfo }) => {
      const newSelectedDevice = { ...currentSelectedDevice };
      if (device.kind === 'audioinput') {
        newSelectedDevice.audio = device?.deviceId;
      } else if (device.kind === 'videoinput') {
        newSelectedDevice.video = device?.deviceId;
      } else if (device.kind === 'audiooutput') {
        newSelectedDevice.speaker = device?.deviceId;
      }
      socket?.emit('studentDevices', {
        studentId: meeting?.self?.customParticipantId,
        devicesInfo: {
          audioDevices,
          videoDevices,
          speakerDevices,
          currentSelectedDevice: newSelectedDevice,
        },
      });
      setCurrentSelectedDevice(newSelectedDevice);
    };
    meeting?.self?.on('deviceUpdate', deviceChangeHandler);
    return () => {
      meeting?.self?.removeAllListeners('deviceUpdate');
    };
  }, [meeting?.self, socket, audioDevices, videoDevices, speakerDevices, currentSelectedDevice]);

  // Handle Media device selection
  const handleGetStudentDevices = useCallback(() => {
    socket?.emit('studentDevices', {
      studentId: meeting?.self?.customParticipantId,
      devicesInfo: {
        audioDevices,
        videoDevices,
        speakerDevices,
        currentSelectedDevice,
      },
    });
  }, [
    socket,
    audioDevices,
    videoDevices,
    speakerDevices,
    currentSelectedDevice,
    meeting?.self?.customParticipantId,
  ]);

  // Handle Media device selection
  const handleMediaSelect = useCallback(
    async (device: MediaDeviceInfo) => {
      try {
        await meeting.self?.setDevice(device);
      } catch (error) {
        console.error('Error setting device:', error);
      }
    },
    [meeting?.self],
  );

  const handleSelectDevice = useCallback(
    (data: { device?: MediaDeviceInfo }) => {
      if (data?.device) handleMediaSelect(data.device);
    },
    [handleMediaSelect],
  );

  useSocketSubscribe('getStudentDevicesToClient', handleGetStudentDevices);
  useSocketSubscribe('selectDeviceToClient', handleSelectDevice);

  return {
    audioDevices,
    videoDevices,
    speakerDevices,
    currentSelectedDevice,
    fetchDevices,
    videoPermissionDenied,
    audioPermissionDenied,
    handleMediaSelect,
  };
};
