import { SadFace } from 'assets/gif';
import { Button } from 'components/common';
import InClassModal from 'components/inClass/Modal';
import { useMeeting, useInclassLogger } from 'hooks';
import { IEndMeetingModal } from 'types';

const EndMeetingModal = ({
  leaveMeetingModal,
  setLeaveMeetingModal,
  setActiveMorphcast,
}: IEndMeetingModal) => {
  const { meeting, studentName } = useMeeting();
  const { datadogLog } = useInclassLogger();
  const handleLeave = () => {
    setActiveMorphcast(false);
    meeting.leave();
    window.location.reload();
    datadogLog(`${studentName} clicked on leave`);
  };
  return (
    <InClassModal
      openModal={leaveMeetingModal}
      setOpenModal={setLeaveMeetingModal}
      color='bg-primary-850'
      className='rounded-[24px]'
    >
      <div className='flex flex-col items-center gap-2 w-fill'>
        <img src={SadFace} className='object-contain size-[70px]' alt='leave' />
        <p className='text-[32px] text-white'>You will miss the important topic!</p>
        <p className='text-xl text-white'>Are you sure you want to leave the Class?</p>
        <div className='flex justify-center gap-3 mt-4'>
          <Button
            text='Stay!'
            className='px-4 text-white rounded-[14px] bg-primary-900'
            onClick={() => setLeaveMeetingModal(false)}
          />
          <Button
            text='Leave'
            onClick={() => handleLeave()}
            className='px-4 text-white rounded-[14px] bg-semantic-red-500'
          />
        </div>
      </div>
    </InClassModal>
  );
};

export default EndMeetingModal;
