import { PresentationDataResult, PresentationItem, ProcessedItem, LectureItem } from 'types';

function processPresentationData(data: LectureItem[]): PresentationDataResult {
  const result: PresentationDataResult = { lastSlideNo: 0, slideData: [] };

  function processArray(array: PresentationItem[]): void {
    let slideCounter = 1;
    array
      .filter((item) => item.type !== 'evaluation')
      .forEach((item) => {
        const slideNoObj: { [key: number]: number } = {};
        item.thumbnails &&
          item.thumbnails.forEach((thumbnail) => {
            slideNoObj[thumbnail.slideNo] = slideCounter++;
          });
        const slideNumbers = Object.values(slideNoObj);
        const processedItem: ProcessedItem = {
          id: item.presentationId || '',
          name: item?.elementDetails?.name.toString() || '',
          firstSlideNo: Math.min(...slideNumbers),
          lastSlideNo: Math.max(...slideNumbers),
          slideNo: slideNoObj,
        };
        result.slideData.push(processedItem);
        result.lastSlideNo = Math.max(result.lastSlideNo, processedItem.lastSlideNo);
      });
  }

  if (Array.isArray(data)) {
    processArray(data);
  }

  return result;
}

export default processPresentationData;
