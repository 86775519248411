import {
  AccountHolderResponse,
  IViewProfileResponse,
  LoginRequest,
  LoginResponse,
  PassCodeResponse,
  UserResponse,
  VerifyPassCodeRequest,
  VerifyPassCodeResponse,
} from 'types';
import {
  ILoginOTPVerifyRequest,
  ILoginOTPVerifyResponse,
  ILoginViaOtpResponse,
  requestOTP,
} from 'types/auth';
import apiSlice from '.';

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    initialLogin: builder.mutation<LoginResponse, LoginRequest>({
      query: (body) => ({
        url: '/auth/student-guardians/verify-mobile-number',
        method: 'POST',
        body,
        keepUnusedDataFor: 0,
      }),
    }),
    passCodeStatus: builder.query<PassCodeResponse, string>({
      query: (userId) => ({
        url: `/auth/student-guardians/get-passcode-status?userId=${userId}`,
        keepUnusedDataFor: 0,
      }),
    }),
    verifyPassCode: builder.mutation<VerifyPassCodeResponse, VerifyPassCodeRequest>({
      query: (body) => ({
        url: '/auth/student-guardians/login-with-passcode',
        method: 'POST',
        body,
        keepUnusedDataFor: 0,
      }),
    }),
    getAccountHolder: builder.query<AccountHolderResponse, string>({
      query: () => ({
        url: `/auth/student-guardians/profile-list`,
        keepUnusedDataFor: 0,
      }),
    }),
    loginWithTempToken: builder.mutation<UserResponse, { memberId: object }>({
      query: ({ memberId }) => ({
        url: `/auth/student-guardians/login-with-token`,
        method: 'POST',
        body: memberId,
        keepUnusedDataFor: 0,
      }),
    }),
    getUserData: builder.query<IViewProfileResponse, void>({
      query: () => ({
        url: `/auth/student-guardians/view-profile`,
        keepUnusedDataFor: 0,
      }),
    }),

    /* Login via OTP API */
    requestOtp: builder.mutation<ILoginViaOtpResponse, requestOTP>({
      query: (body) => ({
        url: '/auth/student-guardians/request-otp',
        method: 'POST',
        body,
      }),
    }),

    LoginOTPVerify: builder.mutation<ILoginOTPVerifyResponse, ILoginOTPVerifyRequest>({
      query: (body) => ({
        url: '/auth/student-guardians/login-with-otp',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useInitialLoginMutation,
  useLazyPassCodeStatusQuery,
  useVerifyPassCodeMutation,
  useGetAccountHolderQuery,
  useLoginWithTempTokenMutation,
  useGetUserDataQuery,
  useRequestOtpMutation,
  useLoginOTPVerifyMutation,
} = extendedApiSlice;
