import { useDyteSelector } from '@dytesdk/react-web-core';
import { motion } from 'framer-motion';
import Cookie from 'js-cookie';

import { useGetClassJoinTokenQuery } from 'store/apiSlices/classes.apiSlice';
import { DyteAudioVisualizer, DyteAvatar, DyteParticipantTile } from '@dytesdk/react-ui-kit';
import { useGetStudentParentDetailsForInClassQuery } from 'store/apiSlices/studentDashboard.apiSlice';
import { ActiveMic, ActiveVideo, InClassUprioLogo, Mic, Video } from 'assets/svg';
import { Modal } from 'components/common';
import { ClassStarted, Topic } from 'components/inClass/Badges';
import InClassButton from 'components/inClass/Button';
import InClassModal from 'components/inClass/Modal';
import { ParentOnboarding } from 'components/screens';

import { getBrightness } from 'utils/getBrightness';
import { CONFIG, STUDENT_PATHS, USER_TYPE } from 'configs';
import { useMeeting, useWindowDimensions } from 'hooks';
import { checkMediaPermissions, listenToMediaPermissions } from 'utils';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useNavigate, useParams } from 'react-router-dom';
import ToggleButton from '../ToggleButton';
import MediaButton from '../MediaButtons';
import { datadogLogs } from '@datadog/browser-logs';
import { IImageCapture } from 'types/inClass/StudentSetupScreen';
import { useAddJoiningExperinceMutation } from 'store/apiSlices/inClass/inClassCommon.apiSlice';

declare const ImageCapture: {
  prototype: IImageCapture;
  new (track: MediaStreamTrack): IImageCapture;
};

const StudentSetupScreen = () => {
  // Get the auth token from the URL
  const { id } = useParams();
  const authToken = String(id);
  const { isMobileScreen } = useWindowDimensions();
  const { self, meeting, loginTempToken, meetingStartTime, audioStatus, videoStatus } =
    useMeeting();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [videoVisible, setVideoVisible] = useState<boolean>(true);
  const [isMobileModal, setIsMobileModal] = useState<boolean>(false);
  const [isParentOnboarding, setIsParentOnboarding] = useState<boolean>(false);
  const [isStudentInTime, setIsStudentInTime] = useState<boolean>(true);
  const [videoPermissionDenied, setVideoPermissionDenied] = useState<boolean>(false);
  const [audioPermissionDenied, setAudioPermissionDenied] = useState<boolean>(false);
  const [testDevices, setTestDevices] = useState<boolean>(false);
  const [cameraWorking, setCameraWorking] = useState<boolean>(false);
  const [micWorking, setMicWorking] = useState<boolean>(false);
  const [mediaTestRun, setMediaTestRun] = useState<boolean>(false);
  const [isModalOpenDeviceIssueFirstModal, setIsModalOpenDeviceIssueFirstModal] =
    useState<boolean>(false);
  const [isModalOpenDeviceIssueSecondModal, setIsModalOpenDeviceIssueSecondModal] =
    useState<boolean>(false);
  const [cameraLogTriggered, setCameraLogTriggered] = useState<boolean>(false);
  const [micLogTriggered, setMicLogTriggered] = useState<boolean>(false);

  // Trigger APi
  const [triggerAddJoinExperience] = useAddJoiningExperinceMutation();

  // API Call -> Get the meeting token
  const { data: JoinClassRoomData } = useGetClassJoinTokenQuery({
    authToken,
  });
  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: { duration: 0.2 },
    },
  };
  const startTime = new Date(meetingStartTime || '').getTime();
  const adjustedStartTime = startTime - 60000;

  const testCam = useCallback(async () => {
    let testCamera = false;
    try {
      const videoTrack = meeting.self.videoTrack;
      if (videoTrack) {
        const imageCapture = new ImageCapture(videoTrack);
        const frame = await imageCapture.grabFrame();
        testCamera = frame.width > 0 && frame.height > 0;
        if (!cameraLogTriggered) {
          setCameraLogTriggered(true);
          datadogLogs.logger.info(
            `Camera Test is failing for student ${JoinClassRoomData?.data?.studentId} and classId:${JoinClassRoomData?.data?.classId}`,
            {
              classId: JoinClassRoomData?.data?.classId,
              studentId: JoinClassRoomData?.data?.studentId,
            },
          );
          if (JoinClassRoomData?.data?.classId) {
            await triggerAddJoinExperience({
              classId: JoinClassRoomData?.data?.classId.toString(),
              JoiningExperinceBody: {
                facedIssue: !testCamera,
                deviceName: 'camera',
              },
            });
          }
        }
        setCameraWorking(testCamera);
        //! need add api to store status (`Camera status: ${cameraWorking ? 'Working' : 'Not detecting any image'}`);
        return testCamera;
      }
      setCameraWorking(testCamera);
      return testCamera;
    } catch (error) {
      datadogLogs.logger.error(
        `Camera test failed of ${JoinClassRoomData?.data?.studentId} and classId:${JoinClassRoomData?.data?.classId}`,
        {
          error: error,
          classId: JoinClassRoomData?.data?.classId,
          studentId: JoinClassRoomData?.data?.studentId,
        },
      );
      setCameraWorking(false);
      return false;
    }
  }, [
    JoinClassRoomData?.data?.classId,
    JoinClassRoomData?.data?.studentId,
    cameraLogTriggered,
    meeting.self.videoTrack,
    triggerAddJoinExperience,
  ]);

  const testMic = useCallback(async () => {
    try {
      const audioTrack = meeting.self.audioTrack;
      // Ensure the audio track is valid
      if (!audioTrack) {
        console.error('No audio track available.');
        return false; // Return false if no audio track
      }
      setMicWorking(false); // Set microphone status to false in the beginning for rerun the code
      let micTestPass = false;
      let testProcessed = false;
      // Create AudioContext
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();

      // Create a MediaStream from the audio track
      const mediaStream = new MediaStream([audioTrack]);

      // Create MediaStreamAudioSourceNode from the MediaStream
      const source = audioContext.createMediaStreamSource(mediaStream);

      // Create AnalyserNode
      const analyser = audioContext.createAnalyser();
      source.connect(analyser); // Connect the source to the analyser
      analyser.fftSize = 256;

      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      // Check for audio data over a short period
      return new Promise<boolean>((resolve) => {
        let checkCount = 0;
        const checkInterval = setInterval(async () => {
          if (!testProcessed) {
            analyser.getByteFrequencyData(dataArray);

            const realTestPass = dataArray.some((value) => value > 0);
            testProcessed = true;
            // Log and trigger DB action only if not already triggered
            if (!micLogTriggered) {
              if (JoinClassRoomData?.data?.classId) {
                setMicLogTriggered(true);
                datadogLogs.logger.info(
                  `Mic Test is failing for student ${JoinClassRoomData?.data?.studentId} and classId:${JoinClassRoomData?.data?.classId}`,
                  {
                    classId: JoinClassRoomData?.data?.classId,
                    studentId: JoinClassRoomData?.data?.studentId,
                  },
                );
                await triggerAddJoinExperience({
                  classId: JoinClassRoomData?.data?.classId.toString(),
                  JoiningExperinceBody: {
                    facedIssue: realTestPass ? false : true,
                    deviceName: 'mic',
                  },
                });
              }
            }
            if (dataArray.some((value) => value >= 0)) {
              setMicWorking(true); // Set microphone status to working
              micTestPass = true;
              clearInterval(checkInterval); // Stop the interval
              resolve(micTestPass); // Resolve with micTestPass
            } else if (++checkCount >= 10) {
              clearInterval(checkInterval); // Stop the interval after 1 second
              resolve(micTestPass); // Resolve with micTestPass
            }
          }
        }, 100);
      });
    } catch (error) {
      datadogLogs.logger.error(
        `Error testing microphone of ${JoinClassRoomData?.data?.studentId} and classId:${JoinClassRoomData?.data?.classId}`,
        {
          error: error,
          classId: JoinClassRoomData?.data?.classId,
          studentId: JoinClassRoomData?.data?.studentId,
        },
      );
      return false; // Return false on error
    }
  }, [
    JoinClassRoomData?.data?.classId,
    JoinClassRoomData?.data?.studentId,
    meeting.self.audioTrack,
    micLogTriggered,
    triggerAddJoinExperience,
  ]);

  useEffect(() => {
    if (!Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`)) {
      Cookie.set(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`, loginTempToken || '');
    }
  }, [loginTempToken]);

  /* Check for the audio and video permission */
  useEffect(() => {
    const fetchMediaPermissions = async () => {
      const permissions = await checkMediaPermissions();
      setAudioPermissionDenied(permissions.audioPermissionDenied);
      setVideoPermissionDenied(permissions.videoPermissionDenied);
    };
    fetchMediaPermissions();
    listenToMediaPermissions(({ audioPermissionDenied, videoPermissionDenied }) => {
      setAudioPermissionDenied(audioPermissionDenied);
      setVideoPermissionDenied(videoPermissionDenied);
    });
  }, []);

  /* API => get the student parent details */
  const { data: studentParentDetails } = useGetStudentParentDetailsForInClassQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const parentData =
    studentParentDetails &&
    _.isArray(studentParentDetails?.data) &&
    studentParentDetails?.data.filter((data) => data?.members?.userType === USER_TYPE.GUARDIAN)
      .length > 0;

  // Set name from meeting
  const userName = useDyteSelector((meeting) => meeting?.self?.name) ?? 'You';

  useEffect(() => {
    if (studentParentDetails && !parentData) {
      setIsModalOpen(true);
    }
  }, [parentData, studentParentDetails]);

  useEffect(() => {
    if (isParentOnboarding) {
      setIsModalOpen(false);
    }
  }, [isParentOnboarding]);

  const mediaTestPassed = cameraWorking && micWorking;
  const showMediaButton = mediaTestRun && (!mediaTestPassed || !mediaTestRun); // Update logic here

  const runMediaTest = useCallback(
    async ({ mic, cam }: { mic: boolean; cam: boolean }) => {
      let testCamResult = false;
      if (mic) {
        // testMicResult =  //!commented for now
        await testMic();
      }
      if (cam) {
        testCamResult = (await testCam()) || false;
      }

      setMediaTestRun(true);
      const testMedia = testCamResult; //testMicResult && //!commented for now

      return testMedia;
    },
    [testCam, testMic],
  );

  // Join meeting
  const joinMeeting = useCallback(
    async ({ isMediaTest }: { isMediaTest: boolean }) => {
      try {
        if (!isMediaTest) {
          await meeting?.joinRoom();
          return;
        }
        const testPassed = await runMediaTest({ mic: true, cam: true });
        if (testPassed) {
          await meeting?.joinRoom();
        } else {
          setTestDevices(true);
          setIsModalOpenDeviceIssueFirstModal(true);
        }
      } catch (error) {
        window.location.reload();
      }
    },
    [meeting, runMediaTest],
  );

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (showMediaButton || testDevices) {
          if (!mediaTestPassed) {
            setIsModalOpenDeviceIssueSecondModal(true);
            return;
          }
          joinMeeting({ isMediaTest: false });
        } else {
          joinMeeting({ isMediaTest: true });
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [joinMeeting, mediaTestPassed, showMediaButton, testDevices]);

  // Leave meeting, disabling the media devices before leaving
  const handleClose = () => {
    try {
      if (meeting?.self?.videoEnabled) {
        handleVideoToggle();
      }
      if (meeting?.self?.audioEnabled) {
        handleAudioToggle();
      }
      navigate(STUDENT_PATHS.STUDENT_DASHBOARD);
    } catch (error) {
      console.error('Error leaving meeting', error);
    }
  };

  // Toggle audio
  const handleAudioToggle = async () => {
    try {
      audioStatus ? await self?.disableAudio() : await self?.enableAudio();
    } catch (error) {
      console.error('Error toggling audio', error);
    }
  };

  // Toggle video
  const handleVideoToggle = async () => {
    try {
      videoStatus ? await self?.disableVideo() : await self?.enableVideo();
    } catch (error) {
      console.error('Error toggling video', error);
    }
  };
  const videoEnabled = useDyteSelector((m) => m.self.videoEnabled);

  useEffect(() => {
    if (!videoEnabled) return;

    const { videoTrack } = meeting.self;
    if (!videoTrack) return;
    const videoStream = new MediaStream();
    videoStream.addTrack(videoTrack);
    const video = document.createElement('video');
    video.style.width = '240px';
    video.style.height = '180px';
    video.muted = true;
    video.srcObject = videoStream;
    video.play();
    const canvas = document.createElement('canvas');
    canvas.width = 240;
    canvas.height = 180;
    const ctx = canvas.getContext('2d', { willReadFrequently: true })!;

    const interval = setInterval(() => {
      const brightness = getBrightness(video, canvas, ctx);
      if (brightness < 0.2) {
        setVideoVisible(false);
      } else {
        setVideoVisible(true);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [meeting?.self, videoEnabled]);

  useEffect(() => {
    if (isMobileScreen) {
      setIsMobileModal(true);
    } else {
      setIsMobileModal(false);
    }
  }, [isMobileScreen]);

  const lectureName =
    JoinClassRoomData?.data?.lectureConfigurationName ||
    JoinClassRoomData?.data?.classConfigurationName;

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      variants={containerVariants}
      className='flex items-center justify-center min-h-screen transition duration-100 ease-in translate-x-0'
    >
      <div className='pt-4 pb-8 bg-primary-850 relative rounded-3xl shadow-lg max-w-[46vw] min-w-[40vw] lg:max-w-[60vw] '>
        {/* Header */}
        {JoinClassRoomData && (
          <div className='flex items-center justify-between mx-6'>
            <div className='flex items-center justify-between '>
              <img src={InClassUprioLogo} alt='logo' />
              <div className='h-10 ml-4 mr-2 border-l-2 border-white opacity-20'></div>
              <Topic
                subject={JoinClassRoomData?.data?.subject}
                topic={lectureName || JoinClassRoomData?.data?.name}
              />
            </div>
            <ClassStarted startTime={new Date(JoinClassRoomData?.data?.meetingStartTime)} />
          </div>
        )}

        {/* Body */}
        <div className='flex flex-col items-center w-full my-6'>
          <div className='flex flex-col items-center py-6'>
            <p className='text-base text-primary-400 2xl:text-lg'>
              Please ensure that your video and audio remain active at all times.
            </p>
            {/* <p className='text-lg text-white 2xl:text-2xl'>Class now</p> */}
          </div>
          <div className='relative border border-[#62667A] rounded-xl'>
            {!videoVisible && videoEnabled && (
              <div className='absolute z-10 text-red-500 top-3 left-3'>Poor lighting!</div>
            )}
            <div className='absolute z-10 top-3 right-3'>
              <DyteAudioVisualizer
                size='sm'
                slot='start'
                participant={meeting?.self}
                className={`h-full p-3 text-white ${self?.audioEnabled ? 'bg-[#515352]' : 'bg-semantic-red-500'} rounded-full`}
              />
            </div>
            {/* Video */}
            <DyteParticipantTile
              size='xl'
              meeting={meeting}
              participant={meeting?.self}
              className='size-[240px] 2xl:size-[340px]'
            >
              <DyteAvatar size='xl' participant={meeting?.self} />
            </DyteParticipantTile>
            <p className='absolute text-white text-xs px-5 py-1 rounded-es-xl rounded-tr-xl bottom-0 left-0 bg-[#061221]'>
              {userName}
            </p>
          </div>
        </div>
        {/* Media Buttons */}
        {showMediaButton || testDevices ? ( // Simplified condition
          <MediaButton
            micWorking={micWorking}
            cameraWorking={cameraWorking}
            runMediaTest={runMediaTest}
            setMicLogTriggered={setMicLogTriggered}
            setCameraLogTriggered={setCameraLogTriggered}
          />
        ) : null}
        {/* Controls */}
        <div className='flex bg-primary-800 mx-6  rounded-xl items-center justify-between px-4 py-2 border border-[#fff5]'>
          {/* Cancel Button */}
          <div>
            <InClassButton title='Cancel' color='darkBlue' rounded={false} onClick={handleClose} />
          </div>
          <div className='flex items-center justify-center'>
            {!(showMediaButton || testDevices) && (
              <>
                {/* Video Toggle */}
                <ToggleButton
                  toggleStatus={videoStatus}
                  icon={Video}
                  activeIcon={ActiveVideo}
                  label={'Video Off'}
                  activeLabel={'Video On'}
                  isPermissionDenied={videoPermissionDenied}
                  onClick={handleVideoToggle}
                  mediaControl={true}
                  mediaType='video'
                  isSetup={true}
                />
                {/* Audio Toggle */}
                <ToggleButton
                  toggleStatus={audioStatus}
                  icon={Mic}
                  activeIcon={ActiveMic}
                  label={'Mic Off'}
                  activeLabel={'Mic On'}
                  onClick={handleAudioToggle}
                  isPermissionDenied={audioPermissionDenied}
                  mediaControl={true}
                  mediaType='audio'
                  isSetup={true}
                />
              </>
            )}
            {/* Join Now Button */}
            {showMediaButton || testDevices ? (
              <InClassButton
                color='green'
                title='Join Now'
                disabled={isStudentInTime && !mediaTestPassed}
                rounded={false}
                onClick={async () => {
                  if (!mediaTestPassed) {
                    setIsModalOpenDeviceIssueSecondModal(true);
                  } else {
                    joinMeeting({ isMediaTest: false });
                  }
                }}
                className='ml-4'
              />
            ) : (
              <InClassButton
                color='green'
                title='Join Now'
                disabled={isStudentInTime && !mediaTestPassed}
                rounded={false}
                onClick={() => joinMeeting({ isMediaTest: true })}
                className='ml-4'
              />
            )}
          </div>
        </div>
        {isStudentInTime && (
          <div className='absolute top-0 left-0 z-30 flex flex-col items-center justify-center text-white rounded-3xl backdrop-blur-md size-full'>
            You will be able to access the class in
            <Countdown
              date={adjustedStartTime}
              precision={0}
              renderer={({ minutes, seconds, completed }) => {
                if (completed) {
                  setIsStudentInTime(false);
                  return null;
                } else {
                  setIsStudentInTime(true);
                  return (
                    <span>
                      {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                    </span>
                  );
                }
              }}
            />
          </div>
        )}
      </div>

      <Modal
        heading='Parent Onboarding'
        children={
          <div>
            <ParentOnboarding isParentOnboarding={setIsParentOnboarding} />
          </div>
        }
        showCloseBtn={false}
        openModal={isModalOpen}
        setOpenModal={setIsModalOpen}
      />

      <InClassModal
        size={'md'}
        children={
          <div className='flex flex-col items-center justify-center gap-4 text-white'>
            <span className='text-lg'>
              {!videoStatus && !audioStatus
                ? 'Please turn on your video and mic. '
                : !videoStatus
                  ? 'Please turn on your video. '
                  : !audioStatus
                    ? 'Please turn on your mic. '
                    : ''}
              {!micWorking &&
                !cameraWorking &&
                'There seems to be an issue with the camera and microphone. Please select different sources to resolve it.'}
              {!micWorking &&
                cameraWorking &&
                'There seems to be an issue with the microphone. Please select a different source to resolve it.'}
              {micWorking &&
                !cameraWorking &&
                'There seems to be an issue with the camera. Please select a different source to resolve it.'}
              {/* {micWorking && cameraWorking && 'Both camera and microphone are working fine.'} */}
            </span>
            <div className='flex justify-end w-full'>
              <InClassButton
                color='green'
                title='Fix Issue'
                disabled={isStudentInTime && !mediaTestPassed}
                rounded={false}
                onClick={() => setIsModalOpenDeviceIssueFirstModal(false)}
                className='ml-4'
              />
            </div>
          </div>
        }
        showCloseBtn={false}
        openModal={isModalOpenDeviceIssueFirstModal}
        setOpenModal={setIsModalOpenDeviceIssueFirstModal}
      />

      <InClassModal
        size={'md'}
        children={
          <div className='flex flex-col items-center justify-center gap-4 text-white'>
            <span className='text-lg'>
              The issue hasn't been fixed yet. Please resolve it or join anyway.
            </span>
            <div className='flex'>
              <InClassButton
                color='dark'
                title='Fix Issue'
                disabled={isStudentInTime && !mediaTestPassed}
                rounded={false}
                onClick={() => setIsModalOpenDeviceIssueSecondModal(false)}
                className='ml-4'
              />
              <InClassButton
                color='green'
                title='Join Anyway'
                disabled={isStudentInTime && !mediaTestPassed}
                rounded={false}
                onClick={() => joinMeeting({ isMediaTest: false })}
                className='ml-4'
              />
            </div>
          </div>
        }
        showCloseBtn={false}
        openModal={isModalOpenDeviceIssueSecondModal}
        setOpenModal={setIsModalOpenDeviceIssueSecondModal}
      />

      <InClassModal
        showCloseBtn={false}
        size={'md'}
        openModal={isMobileModal}
        setOpenModal={setIsMobileModal}
      >
        <div className='text-white'>Please join the class using a laptop or desktop.</div>
      </InClassModal>
    </motion.div>
  );
};

export default StudentSetupScreen;
