import moment from 'moment';
import { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'flowbite-react';
import Slider from 'react-slick';

import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import {
  useGetClassDetailsQuery,
  useGetCurrentClassDetailsQuery,
} from 'store/apiSlices/studentDashboard.apiSlice';

import {
  CompassLight,
  DateIcon,
  MathBook,
  ScienceBook,
  ScienceIcon,
  SectionHeadingLogo,
  Timer,
  leftArrow,
  rightArrow,
} from 'assets/svg';
import { Button, Card } from 'components/common';

import {
  CLASS_STATUS,
  PARENT_PATHS,
  ROUTES,
  STUDENT_BATCH,
  STUDENT_PATHS,
  SUBJECTS,
  USER_TYPE,
} from 'configs';
import { formatTimeRange } from 'utils';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useWindowDimensions } from 'hooks';

const settings = {
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 1,
};

export default function FutureClassList() {
  const navigate = useNavigate();
  const { isMobileScreen } = useWindowDimensions();

  const sliderRef = useRef<Slider>(null);

  /* API => view profile to get the school data */
  const { data: profileData } = useGetUserDataQuery(undefined, { refetchOnMountOrArgChange: true });

  const userType = profileData?.data?.userType;
  const userId = profileData?.data?.id;

  /* API => get current class list */
  const { data: currentClass } = useGetCurrentClassDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const listOfClassData = currentClass?.data;

  /* API => get the Upcoming classes data */
  const { data: classDetailResponse } = useGetClassDetailsQuery({
    filter: {
      meetingEndTime: { $gte: listOfClassData?.currentIST || moment().toISOString() },
      classStatus: { $ne: CLASS_STATUS.ENDED },
    },
  });

  const handlePrevClick = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current?.slickNext();
  };

  const isClassActive = useCallback(
    (meetingStartTime: string, meetingEndTime: string, classStatus: string = '') => {
      return (
        (moment(listOfClassData?.currentIST).isBetween(
          moment(meetingStartTime),
          moment(meetingEndTime),
        ) ||
          moment(listOfClassData?.currentIST).isSame(moment(meetingStartTime))) &&
        classStatus !== CLASS_STATUS.ENDED
      );
    },
    [listOfClassData],
  );

  const handleJoin = (joinCode: string) => {
    if (joinCode) {
      navigate(ROUTES.CLASSROOM_INVITE.replace(':id', joinCode));
    }
  };

  return (
    <>
      {listOfClassData?.batchTypeDetails?.name !== STUDENT_BATCH.DEMO && (
        <div className='mb-10'>
          <div className='flex items-center justify-between'>
            <h1 className='items-center py-10 text-xl font-bold tracking-wider uppercase md:flex font-sora text-start md:text-lg lg:text-2xl xl:leading-6'>
              <img
                src={SectionHeadingLogo}
                alt='uprio section logo'
                className='my-4 mr-5 md:my-0'
              />
              Future Classes
            </h1>
            <div className='flex items-center gap-x-2'>
              <Button
                className='bg-neutral-25'
                icon={leftArrow}
                preIcon={true}
                onClick={handlePrevClick}
              />
              <span
                onClick={() =>
                  navigate(
                    userType === USER_TYPE.STUDENT
                      ? STUDENT_PATHS.STUDENT_CLASSES
                      : userType === USER_TYPE.GUARDIAN
                        ? PARENT_PATHS.ALLCLASSES.replace(':studentId', String(userId))
                        : '',
                  )
                }
                className='text-xs cursor-pointer body-semibold text-primary-850 hover:text-primary-500 md:text-sm'
              >
                view all
              </span>
              <Button
                className='bg-neutral-25'
                icon={rightArrow}
                preIcon={true}
                onClick={handleNextClick}
              />
            </div>
          </div>
          <Slider {...settings} slidesToShow={isMobileScreen ? 1 : 3} ref={sliderRef}>
            {classDetailResponse?.docs
              ?.filter(
                (classItem) =>
                  currentClass?.data?.id !== classItem?.id &&
                  currentClass?.data?.name !== classItem?.name,
              )
              .map((classItem) => (
                <div key={classItem.id} className='pr-2'>
                  <Card className='bg-neutral-100 rounded-2xl'>
                    <div className='flex justify-between p-3 md:p-4 lg:p-3 xl:p-4'>
                      {/* Class details  */}
                      <div className='flex flex-col justify-between ml-1 lg:ml-2 xl:ml-0'>
                        <div>
                          <Badge className='flex items-center p-1 truncate rounded-lg w-28 bg-primary-150'>
                            <span className='text-sm cap-semibold flex items-center gap-2 tracking-widest text-complementary-colors-50 md:text-base lg:text-xs font-semibold leading-[19.3px]'>
                              <img
                                src={
                                  listOfClassData?.subjectDetails?.name === SUBJECTS.MATHS
                                    ? CompassLight
                                    : listOfClassData?.subjectDetails?.name === SUBJECTS.SCIENCE
                                      ? ScienceIcon
                                      : CompassLight
                                }
                                alt='compass'
                                className='w-5 p-1 rounded-md bg-primary-500'
                              />
                              {listOfClassData?.subjectDetails?.name || SUBJECTS.MATHS}
                            </span>
                          </Badge>
                          <p className='mt-2 text-base truncate text-primary-900 display-bold md:text-lg lg:text-base xl:text-lg w-44'>
                            {classItem?.lectureConfigurationName ||
                              classItem?.classConfigurationName ||
                              classItem?.name ||
                              ''}
                          </p>
                        </div>
                        <div className='flex justify-between'>
                          <span className='bg-complementary-colors-25 flex items-center justify-start text-start text-neutral-800 font-poppins md:font-medium rounded-2xl p-1 md:px-3 lg:px-2 xl:px-3 text-[10px] whitespace-nowrap xl:text-xs'>
                            <img className='w-5 p-1 lg:w-4 xl:w-5' src={DateIcon} alt='Date' />
                            {/* 12 Feb 24 */}
                            {moment(classItem?.meetingStartTime).format('DD MMM YY')}
                          </span>
                          <span className='bg-complementary-colors-25 flex text-start items-center text-neutral-800 font-medium font-poppins rounded-2xl whitespace-nowrap p-1 lg:px-2 ml-2 text-[10px] xl:text-xs'>
                            <img className='w-5 p-1 lg:w-4 xl:w-5' src={Timer} alt='Time' />{' '}
                            {formatTimeRange(
                              classItem?.meetingStartTime,
                              classItem?.meetingEndTime,
                            )}
                          </span>
                        </div>
                      </div>

                      {/* Subject image */}
                      <div className='relative flex flex-col justify-between gap-2 rounded-2xl lg:ml-10'>
                        <img
                          className={`object-contain w-28 sm:w-32  ${
                            isClassActive(
                              classItem?.meetingStartTime,
                              classItem?.meetingEndTime,
                              classItem?.classStatus,
                            )
                              ? 'md:h-24 xl:h-[6.1rem] 2xl:h-[6.3rem] md:w-full'
                              : 'md:w-full md:h-full'
                          }`}
                          src={
                            listOfClassData?.subjectDetails?.name === 'Maths'
                              ? MathBook
                              : listOfClassData?.subjectDetails?.name === 'Science'
                                ? ScienceBook
                                : MathBook
                          }
                          alt=''
                        />

                        {/* Conditional rendering based on meeting time and current time */}
                        {isClassActive(
                          classItem?.meetingStartTime,
                          classItem?.meetingEndTime,
                          classItem?.classStatus,
                        ) ? (
                          <div>
                            <button
                              onClick={() =>
                                handleJoin(classItem?.studentJoinLinks[0]?.joinCode || '')
                              }
                              className={`px-4 py-1 w-full text-sm md:text-base font-medium text-white rounded-lg bg-semantic-green-500 hover:bg-semantic-green-600 `}
                            >
                              Join Now
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Card>
                </div>
              ))}
          </Slider>
        </div>
      )}
    </>
  );
}
