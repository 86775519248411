import { Card } from 'flowbite-react';
import AttentionChart from '../../AttentionChart';
import { IAggreagetedAttention } from 'types';

export const AggreagetedAttention = ({
  studentDetails,
  isPerformanceDetails,
  classId,
  aggregatedAttentions,
}: IAggreagetedAttention) => {
  return (
    <>
      <Card className='flex-1 xl:min-w-[400px] rounded-[20px]'>
        <h5
          className={`text-xl font-normal tracking-tight font-sora text-neutral-900 ${!isPerformanceDetails ? 'xl:mb-10 mb-8' : 'xl-mb-2 mb-2'}`}
        >
          {`${studentDetails?.firstName}'s Attention Meter`}
        </h5>

        <div>
          <div className='relative inline-block w-full'>
            <div
              className={`absolute flex flex-col items-center justify-between w-[10px] bottom-12 ml-[-15px] mr-[-15px] ${'h-[98px] text-[0.6rem]'}`}
            >
              <div className='label'>
                <span className='ml-4 text-neutral-400 font-poppins'>HIGH</span>
              </div>
              <div className=''>
                <span className='ml-4 text-neutral-400 font-poppins'>MID</span>
              </div>
              <div className=''>
                <span className='ml-4 text-neutral-400 font-poppins'>LOW</span>
              </div>
            </div>
            <div className='flex ml-4'>
              <AttentionChart
                classId={classId}
                xAxisRange={0}
                attentionChartHeight={140}
                classOver={true}
                aggregatedAttentions={aggregatedAttentions}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
