import { SadFace } from 'assets/gif';
import { Button } from 'components/common';
import InClassModal from 'components/inClass/Modal';
import { useMeeting, useInclassLogger } from 'hooks';
import { IEvalSubmissionModal } from 'types';

const EvalSubmissionModal = ({
  evalSubmissionModal,
  setEvalSubmissionModal,
  handleSubmit,
}: IEvalSubmissionModal) => {
  const { studentName } = useMeeting();
  const { datadogLog } = useInclassLogger();
  return (
    <InClassModal
      openModal={evalSubmissionModal}
      setOpenModal={setEvalSubmissionModal}
      color='bg-primary-850'
      className='rounded-[24px]'
    >
      <div className='flex flex-col items-center gap-2 w-fill'>
        <img src={SadFace} className='object-contain size-[70px]' alt='leave' />
        <p className='text-[32px] text-white text-center pb-5'>
          You have not answered all the poll questions
        </p>
        <p className='text-xl text-white'>Are you sure you want to submit?</p>
        <div className='flex justify-center gap-3 mt-4'>
          <Button
            text='Stay and complete'
            className='px-4 text-white rounded-[14px] bg-green-700'
            onClick={() => setEvalSubmissionModal(false)}
          />
          <Button
            text='Submit anyway'
            onClick={() => {
              handleSubmit();
              datadogLog(`${studentName} submitted poll without answering all questions`);
              setEvalSubmissionModal(false);
            }}
            className='px-4 text-white rounded-[14px] bg-primary-900'
          />
        </div>
      </div>
    </InClassModal>
  );
};

export default EvalSubmissionModal;
