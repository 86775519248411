import { Badge } from 'flowbite-react';
import { lowerCase } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Card from 'components/common/Card';
import { useGetCurrentStatusOfStudentDetailsQuery } from 'store/apiSlices/parentDashboard.apiSlice';
import { IStudentRenderProps } from 'types/dashboard/parentDashboard';

import { DateIcon, SubjectIconWhite } from 'assets/svg';

import { CLASS_OVER_BUFFER_TIME, CONFIG } from 'configs';
import AttentionChart from '../AttentionChart';
import ClassTimeline from '../ClassTimeline';
import StreamingStudent from './StreamingStudent';
import { Avatar } from 'components/common';

const OngoingClass = ({
  classData,
  isClassEnds,
  setBufferTime,
  setIsClassEnds,
}: IStudentRenderProps) => {
  const { studentId } = useParams();
  const serverTime = moment();

  const batchTypeDetails = classData?.data?.batchTypeDetails || { name: 'Demo' };
  const classId = classData?.data?.id;
  const tutorDetails = classData?.data?.tutorDetails[0];
  const meetingEndTime = classData?.data?.meetingEndTime;
  const secondsDifferenceInClassEnds = moment(meetingEndTime)
    .clone()
    .add(CLASS_OVER_BUFFER_TIME, 'minutes')
    ?.diff(serverTime, 'seconds');

  const [classStatus, setClassStatus] = useState<string>('');

  // check the class status
  /* if class status coming in class flow api is ended then we have to show class over screen otherwise give 60 mins of buffer */
  useEffect(() => {
    if (classStatus === 'ended') {
      setIsClassEnds(true);
    } else {
      setIsClassEnds(false);
      setBufferTime(CLASS_OVER_BUFFER_TIME);
    }
  }, [classStatus, setBufferTime, setIsClassEnds]);

  let tutorName = tutorDetails?.firstName
    ? tutorDetails?.firstName + ' ' + tutorDetails?.lastName
    : '';

  if (lowerCase(tutorDetails?.gender) === 'male') {
    tutorName = `Mr. ${tutorName}`;
  } else if (lowerCase(tutorDetails?.gender) === 'female') {
    tutorName = `Ms. ${tutorName}`;
  }

  const { data: currentStatus } = useGetCurrentStatusOfStudentDetailsQuery({
    classId: String(classId),
  });

  const selectedStudentStatus = currentStatus?.data.find(
    (data) => data.studentId === Number(studentId),
  );

  const currentState = selectedStudentStatus?.currentState || CONFIG?.ROOMS?.TEACH;

  return (
    <>
      <Card defaultColorCode='bg-neutral-50'>
        <Card className='w-full p-5 rounded-b-none rounded-t-xl' defaultColorCode='bg-[#14171C]'>
          <div className='grid grid-rows-1'>
            <div className='grid xl:grid-cols-4 gap-x-3'>
              <div className='xl:col-span-2'>
                <div className='flex flex-wrap'>
                  <h6 className='text-primary-500 font-poppins tracking-widest font-semibold text-[12px] sm:text-base'>
                    {batchTypeDetails.name === 'Regular'
                      ? 'ONGOING REGULAR CLASS'
                      : 'ONGOING DEMO CLASS'}
                  </h6>
                  <Badge className='inline ml-2 leading-3 text-white rounded-xl bg-semantic-red-500 '>
                    <span className='text-[12px] sm:text-sm'>LIVE</span>
                  </Badge>
                </div>
                <Card
                  className='flex w-full p-4 my-4 sm:h-72 rounded-xl'
                  defaultColorCode='bg-[#282E38]'
                >
                  <div className='grid flex-1 grid-rows-1'>
                    <div className='grid grid-cols-1 sm:grid-cols-4 gap-x-2'>
                      <div className='h-[12rem] sm:h-full sm:col-span-2 pr-2.5'>
                        <div className='w-full h-full bg-black rounded-xl'>
                          <StreamingStudent
                            classId={classData?.data?.id}
                            batchType={batchTypeDetails.name}
                          />
                        </div>
                      </div>
                      <div className='mt-5 sm:col-span-2 sm:my-0'>
                        <div className='flex-1'>
                          <div className='flex flex-wrap items-center'>
                            <span className='bg-[#3B8CF6] rounded-full p-3 inline-block w-8 lg:w-10 mr-2'>
                              <img
                                className='w-4 lg:w-5'
                                src={SubjectIconWhite}
                                alt='subjectIcon'
                              />
                            </span>
                            <h6 className='text-xs md:text-sm lg:text-lg text-[#62A3F8] font-sans font-medium'>
                              Maths
                            </h6>
                            <Badge className='rounded-xl px-2 md:px-4 inline mx-2 text-[#122D50] bg-white'>
                              <span className='text-[10px] xs:text-xs md:text-sm'>
                                {batchTypeDetails.name === 'Regular' ? 'Regular' : 'Demo'}
                              </span>
                            </Badge>
                          </div>
                          <div className='mt-2 sm:mt-4'>
                            <div className='flex flex-col justify-between'>
                              <p className='font-sans text-base font-bold text-white lg:text-xl sm:text-2xl'>
                                {classData?.data?.lectureConfigurationName ||
                                  classData?.data?.classConfigurationName ||
                                  classData?.data?.name}
                              </p>
                              <div className='mt-2 sm:mt-4'>
                                <span className='bg-[#D0D5DC] rounded-2xl p-1 px-2 text-[10px] sm:text-xs lg:text-sm'>
                                  <img className='inline w-5 p-1 pt-0' src={DateIcon} alt='date' />
                                  {moment(classData?.data?.meetingStartTime).format('DD MMM YYYY')}
                                </span>
                                <span className='bg-[#D0D5DC] rounded-2xl p-1 px-2 ml-1 text-[10px] sm:text-xs lg:text-sm'>
                                  {moment(classData?.data?.meetingStartTime).format('hh:mm A')}{' '}
                                  {'-'} {moment(classData?.data?.meetingEndTime).format('hh:mm A')}
                                </span>
                              </div>
                            </div>
                            <div className='flex gap-x-5 mt-[2.8rem]'>
                              <div className=''>
                                <Avatar
                                  firstName={classData?.data?.tutorDetails[0]?.firstName}
                                  lastName={classData?.data?.tutorDetails[0]?.lastName}
                                  image={classData?.data?.tutorDetails[0]?.profileImage}
                                  className='w-10 h-full rounded-md lg:w-16'
                                  initialsClassName='w-full py-2 px-3 h-full bg-primary-500 rounded-full flex items-center justify-center'
                                />
                              </div>
                              <div className=''>
                                <h6 className='font-sans text-base font-bold text-white'>
                                  {tutorName}
                                </h6>
                                <p className='font-sans text-base font-medium text-neutral-400'>
                                  {tutorDetails?.subjects &&
                                  Object.keys(tutorDetails?.subjects).length > 0
                                    ? tutorDetails?.subjects?.find(
                                        (subject) => subject?.primary === true,
                                      )?.name || tutorDetails?.subjects[0]?.name
                                    : 'Maths'}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              <div className='xl:col-span-2'>
                <h6 className=' text-primary-500 font-poppins tracking-widest font-semibold text-[12px] sm:text-base'>
                  PARTICIPATION MONITOR
                </h6>
                <div className='grid grid-rows-1 my-4'>
                  <div className='grid sm:grid-cols-4 sm:gap-x-2 gap-y-2'>
                    <Card
                      className='w-full col-span-4 p-[1.25rem] pt-2 rounded-xl'
                      defaultColorCode='bg-[#282E38]'
                    >
                      <div className='flex items-center'>
                        <div className='flex flex-col items-center justify-between flex-1 p-2 px-1 text-white md:flex-row'>
                          <h5 className='p-2 justify-center lg:ml-2 xs:text-xs sm:text-sm md:text-[14px] lg:text-[18px] font-poppins'>
                            Attention Meter
                          </h5>
                          {currentState !== CONFIG?.ROOMS?.TEACH && (
                            <div className='p-1 md:p-2 text-[10px] md:text-xs bg-orange-500 rounded-2xl'>
                              Attention is normalized while coach phase is going on
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='flex flex-col'>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <div className='absolute flex flex-col items-center justify-between w-[10px] h-[135px] bottom-12 ml-[-15px] mr-[-15px]'>
                            <div className='label'>
                              <span className='text-neutral-400 ml-5 text-[12px] sm:text-sm font-poppins'>
                                HIGH
                              </span>
                            </div>
                            <div className=''>
                              <span className='text-neutral-400 ml-5 text-[12px] sm:text-sm font-poppins'>
                                MID
                              </span>
                            </div>
                            <div className=''>
                              <span className='text-neutral-400 ml-5 text-[12px] sm:text-sm font-poppins'>
                                LOW
                              </span>
                            </div>
                          </div>
                          <div style={{ marginLeft: '20px' }}>
                            <AttentionChart
                              classId={classId || 0}
                              xAxisRange={0}
                              currentState={currentState}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
        {/* {class flow timelines} */}
        <ClassTimeline
          classData={classData}
          secondsDifferenceInClassEnds={secondsDifferenceInClassEnds}
          isClassEnds={isClassEnds}
          setClassStatus={setClassStatus}
        />
      </Card>
    </>
  );
};

export default OngoingClass;
