import { CustomTabsProps } from 'types/common';
import {
  NotSubmittedInactive,
  NotSubmittedActive,
  SubmittedInactive,
  SubmittedActive,
} from 'assets/svg';

function CustomTabs({ submittedState, setSubmittedState, setPageNumber }: CustomTabsProps) {
  return (
    <div className='flex text-sm justify-center md:justify-start gap-3 align-middle py-3'>
      <div
        onClick={() => {
          setSubmittedState(false);
          setPageNumber(1);
        }}
        className={`transition duration-200 border-b-2 pb-2 font-sora ${submittedState === false ? '!border-b-primary-500 !text-primary-500' : '!border-b-gray-400 text-gray-400'}  cursor-pointer transition duration-300`}
      >
        <div className='flex justify-center gap-2'>
          <span className='pl-4'>
            {submittedState === false ? (
              <img src={NotSubmittedActive} alt='Svg for non submitted tab when it is active' />
            ) : (
              <img src={NotSubmittedInactive} alt='Svg for non submitted tab when it is inactive' />
            )}
          </span>{' '}
          <span className='pr-4'>Not Submitted</span>
        </div>
      </div>

      <div
        onClick={() => {
          setSubmittedState(true);
          setPageNumber(1);
        }}
        className={`transition duration-200 border-b-2 pb-2 font-sora ${submittedState === true ? '!border-b-primary-500 text-primary-500' : 'border-b-gray-400 text-gray-400'} cursor-pointer transition duration-300`}
      >
        <div className='flex justify-center gap-2'>
          <span className='pl-4'>
            {submittedState === true ? (
              <img src={SubmittedActive} alt='Svg for submitted tab when it is active' />
            ) : (
              <img src={SubmittedInactive} alt='Svg for submitted tab when it is inactive' />
            )}
          </span>{' '}
          <span className='pr-4'>Submitted</span>
        </div>
      </div>
    </div>
  );
}

export default CustomTabs;
