import classNames from 'classnames';
import React from 'react';

interface HeaderProps {
  parentName: string;
  studentName: string;
  symbol: string;
  label?: string;
  classname?: string;
}

const Emoji: React.FC<{ symbol: string; label?: string }> = ({ symbol, label }) => (
  <span className='text-md md:text-2xl' role='img' aria-label={label || ''} aria-hidden={!label}>
    {symbol}
  </span>
);

export default function Header({ parentName, studentName, symbol, label, classname }: HeaderProps) {
  return (
    <div>
      <div
        className={classNames(
          'flex mx-2 xl:mx-10 py-6 items-top gap-x-2 border-b-2 border-neutral-150',
          classname,
        )}
      >
        <div className=''>
          <Emoji symbol={symbol} label={label} />
        </div>
        <div className=''>
          <div className='text-xs sm:text-sm lg:text-2xl font-sora font-normal capitalize lg:leading-9 text-neutral-700 max-w-fit'>
            Welcome Back, {parentName}!{' '}
            <span className='text-neutral-700 sm:text-xs text-[10px] lg:text-xl font-sora font-normal'>
              Here is everything that {studentName} is Upto!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
