import { ActiveMic, ActiveVideo, Mic, Speaker, TickGreen, Video, WrongRed } from 'assets/svg';
import { useMeeting } from 'hooks';
import { useMediaDevices } from 'hooks/inClass/useMediaDevices';
import ToggleButton from '../ToggleButton';
import { useCallback, useEffect, useState } from 'react';
import { Radio, Table } from 'flowbite-react';
import { tableCustomTheme } from 'utils/inClass/customTheme';

// { mediaDevice, className, handleAudioToggle }: IMediaSelectDropdownProps
const MediaButton = ({
  micWorking,
  cameraWorking,
  runMediaTest,
  setMicLogTriggered,
  setCameraLogTriggered,
}: {
  micWorking: boolean;
  cameraWorking: boolean;
  runMediaTest: ({ mic, cam }: { mic: boolean; cam: boolean }) => void;
  setMicLogTriggered: (value: boolean) => void;
  setCameraLogTriggered: (value: boolean) => void;
}) => {
  const [delayedMicStatus, setDelayedMicStatus] = useState<boolean>(micWorking);
  const [delayedCameraStatus, setDelayedCameraStatus] = useState<boolean>(cameraWorking);

  const { audioStatus, videoStatus, self } = useMeeting();
  // Getting meeting from useDyteMeeting
  const {
    audioDevices,
    videoDevices,
    speakerDevices,
    currentSelectedDevice,
    handleMediaSelect,
    audioPermissionDenied,
    videoPermissionDenied,
  } = useMediaDevices();
  // Toggle audio
  const handleAudioToggle = async () => {
    try {
      audioStatus ? await self?.disableAudio() : await self?.enableAudio();
    } catch (error) {
      console.error('Error toggling audio', error);
    }
  };

  // Toggle video
  const handleVideoToggle = async () => {
    try {
      videoStatus ? await self?.disableVideo() : await self?.enableVideo();
    } catch (error) {
      console.error('Error toggling video', error);
    }
  };

  const handleMediaChange = useCallback(
    async (device: MediaDeviceInfo) => {
      await handleMediaSelect(device);
      setTimeout(() => {
        if (device.kind === 'audioinput') {
          setMicLogTriggered(false); //this will allow the log run again;
          runMediaTest({ mic: true, cam: false });
        } else if (device.kind === 'videoinput') {
          setCameraLogTriggered(false); //this will allow the log run again;
          runMediaTest({ mic: false, cam: true });
        }
      }, 500);
    },
    [handleMediaSelect, runMediaTest, setCameraLogTriggered, setMicLogTriggered],
  );

  // This is for enabling the camera then run the test again
  useEffect(() => {
    if (videoStatus) {
      runMediaTest({ mic: false, cam: true });
    }
  }, [runMediaTest, videoStatus]);

  // This is for enabling the mic then run the test again
  useEffect(() => {
    if (audioStatus) {
      runMediaTest({ mic: true, cam: false });
    }
  }, [runMediaTest, audioStatus]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedMicStatus(micWorking);
    }, 500);

    return () => clearTimeout(timer);
  }, [micWorking]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayedCameraStatus(cameraWorking);
    }, 500);

    return () => clearTimeout(timer);
  }, [cameraWorking]);

  return (
    <div className='flex bg-primary-800 mx-6 mb-2 rounded-xl px-4 py-2 border border-[#fff5]'>
      {/* Video */}
      <div className='grid basis-1/3 w-full grid-rows-[auto,1fr]  border-white/50'>
        <div className='overflow-x-auto'>
          <Table hoverable theme={tableCustomTheme}>
            <Table.Body className='divide-y'>
              <div className='flex flex-col items-center justify-center row-span-1 p-2 pt-4'>
                <ToggleButton
                  toggleStatus={videoStatus}
                  icon={Video}
                  activeIcon={ActiveVideo}
                  label={`Video Off`}
                  activeLabel={`Video On`}
                  onClick={handleVideoToggle}
                  isPermissionDenied={videoPermissionDenied}
                  mediaControl={false}
                  mediaType={'video'}
                  isSetup={true}
                />
                {!videoStatus ? (
                  <div className='flex gap-2 break-words'>
                    <span className='pl-3 text-sm font-semibold text-yellow-500 break-words'>
                      Please turn on your video.
                    </span>
                    <a
                      className='text-white cursor-pointer font-semibold bg-[#1CC55A] rounded-lg px-[0.3rem]'
                      onClick={() => handleVideoToggle()}
                    >
                      Click to turn On
                    </a>
                  </div>
                ) : !delayedCameraStatus ? (
                  <span className='pl-3 text-sm font-semibold text-red-500 break-words'>
                    This video source is not working. Please change the source.
                  </span>
                ) : (
                  <span className='pl-3 text-sm text-green-500 break-words'>
                    This video source is working you can proceed.
                  </span>
                )}
              </div>
              <div className='flex flex-wrap items-center justify-center row-span-1 gap-1'>
                {videoDevices.map((device) => (
                  <Table.Row
                    key={device.deviceId}
                    className='flex items-center justify-center text-white cursor-pointer '
                    onClick={() => handleMediaChange(device)}
                  >
                    <Table.Cell className='p-2'>
                      <label htmlFor={device.deviceId} className='flex items-center cursor-pointer'>
                        <Radio
                          id={device.deviceId}
                          name='video'
                          value={device.deviceId}
                          checked={currentSelectedDevice?.video === device.deviceId}
                          onChange={() => handleMediaChange(device)}
                        />
                      </label>
                    </Table.Cell>
                    <Table.Cell className='flex items-center'>
                      <span className='block max-w-[120px] truncate'>{device.label}</span>
                      <div className='w-6 h-6 ml-2'>
                        {currentSelectedDevice?.video === device.deviceId ? (
                          <img
                            src={delayedCameraStatus ? TickGreen : WrongRed}
                            className='w-4 h-4'
                            alt='status icon'
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </div>
            </Table.Body>
          </Table>
        </div>
      </div>
      <div className='w-[0.1rem] bg-white grow'></div>
      {/* Mic */}
      <div className='grid basis-1/3 w-full grid-rows-[auto,1fr]  border-white/50'>
        <div className='overflow-x-auto'>
          <Table hoverable theme={tableCustomTheme}>
            <Table.Body className='divide-y'>
              <div className='flex flex-col items-center justify-center row-span-1 p-2 pt-4'>
                <ToggleButton
                  toggleStatus={audioStatus}
                  icon={Mic}
                  activeIcon={ActiveMic}
                  label={`Mic Off`}
                  activeLabel={`Mic On`}
                  onClick={handleAudioToggle}
                  isPermissionDenied={audioPermissionDenied}
                  mediaControl={false}
                  mediaType={'audio'}
                  isSetup={true}
                />
                {!audioStatus ? (
                  <div className='flex gap-2 break-words'>
                    <span className='pl-3 text-sm font-semibold text-yellow-500 break-words'>
                      Please turn on your mic.
                    </span>
                    <a
                      className='text-white cursor-pointer font-semibold bg-[#1CC55A] rounded-lg px-[0.3rem]'
                      onClick={() => handleAudioToggle()}
                    >
                      Click to turn On
                    </a>
                  </div>
                ) : !delayedMicStatus ? (
                  <span className='pl-3 text-sm font-semibold text-red-500 break-words'>
                    This mic source is not working. Please change the source.
                  </span>
                ) : (
                  <span className='pl-3 text-sm text-green-500 break-words'>
                    This mic source is working you can proceed.
                  </span>
                )}
              </div>
              <div className='flex flex-wrap items-center justify-center row-span-1 gap-1'>
                {audioDevices.map((device) => (
                  <Table.Row
                    key={device.deviceId}
                    className='flex items-center justify-center text-white cursor-pointer '
                    onClick={() => handleMediaChange(device)}
                  >
                    <Table.Cell className='p-2'>
                      <Radio
                        id={device.deviceId}
                        name='audio'
                        value={device.deviceId}
                        checked={currentSelectedDevice?.audio === device.deviceId}
                        onChange={() => handleMediaChange(device)}
                      />
                    </Table.Cell>
                    <Table.Cell className='flex'>
                      <span className='block max-w-[120px] truncate'>{device.label}</span>
                      <div className='w-6 h-6 ml-2'>
                        {currentSelectedDevice?.audio === device.deviceId ? (
                          <img
                            src={delayedMicStatus ? TickGreen : WrongRed}
                            alt='status icon'
                            className='w-4 h-4'
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </div>
            </Table.Body>
          </Table>
        </div>
      </div>
      <div className='w-[0.1rem] bg-white'></div>

      {/* Speaker */}
      <div className='grid basis-1/3 w-full grid-rows-[auto,1fr]'>
        <div className='overflow-x-auto'>
          <Table hoverable theme={tableCustomTheme}>
            <Table.Body className='divide-y'>
              <div className='flex items-center justify-center row-span-1 p-2 pt-4'>
                <ToggleButton
                  toggleStatus={true}
                  icon={Speaker}
                  activeIcon={Speaker}
                  label={`Speaker Off`}
                  activeLabel={`Speaker On`}
                  isPermissionDenied={audioPermissionDenied}
                  mediaControl={false}
                  mediaType={'speaker'}
                  isSetup={false}
                  className='cursor-default'
                />
              </div>
              <div className='flex flex-wrap items-center justify-center row-span-1 gap-1'>
                {speakerDevices.map((device) => (
                  <Table.Row
                    key={device.deviceId}
                    className='flex items-center justify-center text-white cursor-pointer '
                    onClick={() => handleMediaChange(device)}
                  >
                    <Table.Cell className='p-2'>
                      <Radio
                        id={device.deviceId}
                        name='speaker'
                        value={device.deviceId}
                        checked={currentSelectedDevice?.speaker === device.deviceId}
                        onChange={() => handleMediaChange(device)}
                      />
                    </Table.Cell>
                    <Table.Cell className='flex'>
                      <span className='block max-w-[120px] truncate'>{device.label}</span>
                      <div className='w-6 h-6 ml-2'>
                        {currentSelectedDevice?.speaker === device.deviceId ? (
                          <img src={TickGreen} alt='status icon' className='w-4 h-4' />
                        ) : (
                          ''
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </div>
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default MediaButton;
