import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';

import { PARENT_PATHS, ROUTES, STUDENT_PATHS, USER_TYPE, CONFIG } from 'configs';
import { useEffect } from 'react';
import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';

export default function Home() {
  const navigate = useNavigate();
  const loginToken = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);

  /* API -> for gettign the userData */
  const { data: profileData } = useGetUserDataQuery(undefined, { skip: !loginToken });
  const userData = profileData?.data;

  useEffect(() => {
    if (!loginToken) {
      navigate(ROUTES.LOGIN);
    } else if (userData) {
      switch (userData.userType) {
        case USER_TYPE.STUDENT:
          navigate(STUDENT_PATHS.STUDENT_DASHBOARD);
          break;
        case USER_TYPE.GUARDIAN:
          navigate(PARENT_PATHS.PARENT_DASHBOARD);
          break;
        default:
          navigate(ROUTES.LOGIN);
          break;
      }
    }
  }, [userData, loginToken, navigate]);
  return (
    <div className='flex items-center justify-center h-screen gap-4'>
      <button
        className='px-4 py-2 text-white w-24 bg-[linear-gradient(94deg,#656565_0.53%,#363636_100%)] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] rounded-lg'
        onClick={() => navigate(ROUTES.ACCOUNTS)}
      >
        Login
      </button>
      <button
        className='px-4 py-2 text-white w-24 bg-[linear-gradient(94deg,#656565_0.53%,#363636_100%)] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] rounded-lg'
        onClick={() => navigate(ROUTES.REGISTER)}
      >
        Register
      </button>
    </div>
  );
}
