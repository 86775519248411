import { useFormik } from 'formik';
import { useRef, useState } from 'react';
import Countdown from 'react-countdown';
import toast from 'react-hot-toast';
import { ErrorResponse } from 'react-router';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useResendOTPMutation, useVerifyOTPMutation } from 'store/apiSlices/register.apiSlice';
import { selectMobileNumber, setVerifyOTPResponseData } from 'store/slice/studentRegister.slice';

import { Button, OtpInput } from 'components/common';
import { useAppDispatch, useAppSelector } from 'hooks/store';

import { ArrowLeft, ExclamationLight, TickRoundedLight } from 'assets/svg';
import {
  APP_DEFAULT_MESSAGES,
  COMMON_ERROR,
  OTP_COUNTDOWN,
  OTP_SENT_SUCCESS,
  ROUTES,
  SENT_VERIFICATION_TEXT,
} from 'configs';

export default function OtpVerification() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  {
    /* //TODO: GO BACK TO PREVIOUS SCREEN NEED TO IMPLEMENT */
  }
  // const navigate = useNavigate();
  const mobileNumber = useAppSelector(selectMobileNumber);

  /* otp verification api call and response data */
  const [verifyOtpData, { isLoading }] = useVerifyOTPMutation();
  const [resendOtpRequest, { isSuccess }] = useResendOTPMutation();

  /* state for the maintain local states  */
  const [isValidOtp, setIsValidOtp] = useState<boolean | null>(null);
  const [isCountdownFinished, setIsCountdownFinished] = useState<boolean>(false);
  const countdownEndTime = useRef(Date.now() + OTP_COUNTDOWN);

  /* form validation for otp verification */
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { otp: '' },
    validationSchema: Yup.object({
      otp: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.OTP_REQUIRED),
    }),
    onSubmit: (values) => {
      /* verify otp using Rtk Query api slices */
      verifyOtpData({
        otp: values.otp,
        mobileNumber: mobileNumber || '',
        tempUser: true,
      })
        .unwrap()
        .then((payload) => {
          const { data } = payload;
          if (data?.tempId && data?.tempToken) {
            dispatch(setVerifyOTPResponseData({ ...payload, otpVerifiedState: true }));
          }
        })
        .catch((error) => {
          toast.error((error as ErrorResponse)?.data?.message || COMMON_ERROR);
          setIsValidOtp(false);
        });
    },
  });

  const handleResendOtp = () => {
    const toastId = toast.loading('Loading...');
    setIsCountdownFinished(false);
    countdownEndTime.current = Date.now() + OTP_COUNTDOWN;
    if (mobileNumber) {
      resendOtpRequest({ mobileNumber: mobileNumber })
        .unwrap()
        .then(() => {
          toast.success(OTP_SENT_SUCCESS, { id: toastId });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || 'Failed to send OTP', { id: toastId });
        });
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className='flex-1 h-full my-5'>
      {/* //TODO: GO BACK TO PREVIOUS SCREEN */}
      {/* <p onClick={() => navigate(ROUTES.REGISTER)} className='flex items-center cursor-pointer lg:text-base'>
        <img
          src={ArrowLeft}
          alt='Back'
        />
        <span className='ml-2 text-primary-500 body-semibold '>Back</span>
      </p> */}
      <div className='flex flex-col justify-start flex-1 h-full md:justify-around'>
        <div className='flex flex-col md:text-center md:justify-center md:items-center'>
          {/* //TODO: GO BACK TO PREVIOUS SCREEN NEED TO IMPLEMENT */}
          <div className='flex items-center gap-2 md:hidden'>
            <span className='p-2 rounded-lg'>
              <img src={ArrowLeft} alt='left arrow' className='object-contain w-4 h-4' />
            </span>
            <h2 className='my-2 text-2xl text-neutral-850 display-bold md:text-xl xl:text-[32px]'>
              OTP Verification
            </h2>
          </div>
          <h2 className='my-2 hidden md:block text-2xl text-neutral-850 display-bold md:text-xl xl:text-[32px]'>
            OTP Verification
          </h2>
          <h3 className='w-full my-4 leading-6 text-neutral-800 body-medium lg:text-sm xl:text-base'>
            {SENT_VERIFICATION_TEXT} <span className='font-bold leading-5'>+91 {mobileNumber}</span>
          </h3>
          <div className='mt-[32px] text-sm sm:mt-6 md:text-sm'>
            <p className='text-xs tracking-widest text-neutral-500 text-start cap-semibold lg:text-sm'>
              Verify Via OTP
            </p>
            <div className='mt-3'>
              <div className='flex flex-col gap-3 md:items-center'>
                <OtpInput
                  handleChange={(otp) => {
                    formik.setFieldValue('otp', otp);
                  }}
                  value={formik.values.otp}
                  isValid={formik.errors.otp ? false : isValidOtp}
                  fields={4}
                />
                {isSuccess ||
                  (formik?.errors?.otp && (
                    <span
                      className={`rounded-lg p-2 ${isValidOtp || isSuccess ? 'bg-semantic-green-500' : 'bg-semantic-red-500'}`}
                    >
                      <img
                        src={isValidOtp || isSuccess ? TickRoundedLight : ExclamationLight}
                        alt='tick'
                        className='object-contain w-4 h-4'
                      />
                    </span>
                  ))}
                {formik.errors.otp ? (
                  <p className='mt-2 leading-5 text-semantic-red-500 sm:text-sm body-regular'>
                    {formik.errors.otp}
                  </p>
                ) : (
                  <p className='text-xs body-regular text-neutral-600 lg:text-sm'>
                    Didn’t receive the OTP?{' '}
                    {isCountdownFinished ? (
                      <span
                        onClick={handleResendOtp}
                        className='leading-5 underline cursor-pointer lg:text-xs body-semibold text-primary-500'
                      >
                        Resend OTP
                      </span>
                    ) : (
                      <>
                        <span className='leading-5 text-primary-800 body-semibold lg:text-xs'>
                          Resend OTP In{' '}
                          <span className='w-32'>
                            <Countdown
                              date={countdownEndTime?.current}
                              precision={0}
                              renderer={({ minutes, seconds, completed }) => {
                                if (completed) {
                                  setIsCountdownFinished(true);
                                  return null;
                                } else {
                                  return (
                                    <span>
                                      {String(minutes).padStart(2, '0')}:
                                      {String(seconds).padStart(2, '0')}
                                    </span>
                                  );
                                }
                              }}
                            />
                          </span>
                        </span>
                      </>
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='mt-10 md:mt-0'>
          <p className='my-3 text-xs text-center text-black lg:text-xs body-medium'>
            By registering you agree to our{' '}
            <span
              onClick={() => navigate(ROUTES.TERMS_AND_CONDITION)}
              className='underline cursor-pointer text-primary-500'
            >
              terms and conditions
            </span>
          </p>
          <Button
            type='submit'
            isLoading={isLoading}
            className={`w-full mt-6 md:mt-0 bg-primary-500 focus:ring-0 ring-none outline-none  text-white enabled:hover:bg-primary-500 enabled:hover:text-white  `}
            text='Continue'
            disabled={formik.values.otp.length !== 4}
          />
        </div>
      </div>
    </form>
  );
}
