export function setupEnvironmentAndRedirects() {
  if (import.meta.env.VITE_VERCEL_ENV !== 'production') {
    const metaTags = `
      <meta name="robots" content="noindex, nofollow" />
      <meta name="googlebot" content="noindex, nofollow" />
    `;
    document.head.insertAdjacentHTML('beforeend', metaTags);
  }

  if (document.referrer.includes('google.com')) {
    window.location.href = 'https://coach.uprio.com';
  }
}
