import { datadogLogs } from '@datadog/browser-logs';
import { useCallback } from 'react';
import { LOG_TYPE } from 'configs';

type LogLevel = (typeof LOG_TYPE)[keyof typeof LOG_TYPE];

const useLogger = () => {
  const logInfo = useCallback((message: string, additionalData = {}, type: LogLevel) => {
    const logData = {
      ...additionalData,
    };

    if (type === LOG_TYPE.ERROR) {
      datadogLogs?.logger.error(message, logData);
    } else {
      datadogLogs?.logger.info(message, logData);
    }
  }, []);

  return { logInfo };
};

export default useLogger;
