import React from 'react';

import { BouncingDotsLoader, Button } from 'components/common';
import RenderEvalContent from 'components/inClass/RenderEvalContent';

import { ImageModalProps } from 'types';

const ImageModal: React.FC<ImageModalProps> = ({
  ImageData,
  questionData,
  totalQuestionsLength,
  selectedQuestionIndex,
  onRecapture,
  onDone,
}) => {
  return (
    <div className='flex items-center justify-center'>
      {!ImageData ? (
        <div className='flex items-center justify-center flex-1 mx-2 xl:mx-6'>
          <BouncingDotsLoader />
        </div>
      ) : (
        <div className='w-full bg-white rounded-lg shadow-lg'>
          <div className='p-6'>
            {questionData && (
              <div className='flex flex-col items-start justify-start pb-3 my-2 text-lg font-medium break-words md:flex-row md:items-center lg:text-xl'>
                <RenderEvalContent data={questionData} />
              </div>
            )}
            {ImageData?.map((data) => (
              <div key={data.annotated} className='flex justify-center mb-6'>
                <img src={data.annotated} alt='Captured' className='rounded-lg ' />
              </div>
            ))}

            <div className='flex justify-end gap-2 mt-4'>
              <Button
                text={'Recapture'}
                onClick={onRecapture}
                className='px-6 text-white transition-transform transform rounded-lg shadow-lg bg-primary-500 hover:scale-105 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-400'
              />
              <Button
                text={selectedQuestionIndex === totalQuestionsLength - 1 ? 'Done' : 'Next Question'}
                onClick={onDone}
                className='px-6 text-white transition-transform transform bg-green-500 rounded-lg shadow-lg hover:scale-105 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400'
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageModal;
