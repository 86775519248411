import { Label, Textarea } from 'flowbite-react';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { ErrorResponse } from 'react-router-dom';
import * as Yup from 'yup';
import Cookie from 'js-cookie';

import { DropdownOption } from 'components/common/InputDropdown';
import { Button, Input, InputDropdown, Loader, Radio } from 'components/common/index';

import {
  useGetBoardsQuery,
  useGetGradesQuery,
  useGetSchoolsQuery,
  useLazyGetCitiesQuery,
} from 'store/apiSlices/common.apiSlice';
import {
  useSetPasscodeMutation,
  useStudentRegistrationMutation,
} from 'store/apiSlices/register.apiSlice';
import {
  setPasscodeResponse,
  setStudentRegisterationData,
  setStudentRegistrationResponseData,
} from 'store/slice/studentRegister.slice';

import { useAppDispatch } from 'hooks/store';
import { City, IBoardsData, IGradesData, records } from 'types/common';

import { ExclamationWarn } from 'assets/svg';
import { APP_DEFAULT_MESSAGES, APP_REGEX, COMMON_ERROR, SCHOOL_NOT_LISTED, CONFIG } from 'configs';
import { useLocalStorage } from 'hooks';
import { IOnboardStudentDetailsProps } from 'types';

export default function StudentDetails({ setAutoSetPasscode }: IOnboardStudentDetailsProps) {
  /* Added the sorting logic for the boards */
  const { getFromLocalStorage, setToLocalStorage } = useLocalStorage();
  const tempToken = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);
  const mobileNumber = getFromLocalStorage('mobileNumber');
  const { data: boardsData, isLoading: isBoardLoading } = useGetBoardsQuery();
  const { data: gradesData, isLoading: isGradeLoading } = useGetGradesQuery();
  const [trigger, result] = useLazyGetCitiesQuery();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  /* API => for setting the passcode */
  const [setPasscodeData] = useSetPasscodeMutation();

  /* API => post the student details */
  const [postStudentDetails, { data: studentDetails, isSuccess, isLoading }] =
    useStudentRegistrationMutation();

  const [selectedGrade, setSelectedGrade] = useState<string>();
  const [selectedGradeName, setSelectedGradeName] = useState<string>('');
  const [citiesData, setCitiesData] = useState<DropdownOption[]>();
  const [selectedCity, setSelectedCity] = useState<number>();
  const [filteredCityData, setFilteredCityData] = useState<City[]>();
  const [address, setAddress] = useState<string>('');
  const [cityName, setCityName] = useState<string>('');

  /* API => get the registered school data */
  const { data: registeredSchool } = useGetSchoolsQuery(
    {
      schoolCity: selectedCity,
    },
    {
      skip: !selectedCity,
    },
  );

  /* state for the maintain local states of the sorting */
  const [sortedGrades, setSortedGrades] = useState<IGradesData[]>();
  const [sortedBoards, setSortedBoards] = useState<IBoardsData[]>();

  /* Added the sorting logic for the boards */
  useEffect(() => {
    if (gradesData) {
      const gradeDatas = gradesData?.data;
      const data = gradeDatas
        .filter((item) => !isNaN(parseInt(item?.name)))
        .sort((a, b) => parseInt(a?.name) - parseInt(b?.name));
      setSortedGrades(data);
    }
  }, [gradesData]);

  useEffect(() => {
    if (boardsData) {
      const data = boardsData?.data;
      const sortedData = [...data].sort((a: { id: number }, b: { id: number }) => {
        return a?.id - b?.id;
      });
      setSortedBoards(sortedData);
    }
  }, [boardsData]);

  // Save registration details step (Students)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      country: '',
      state: '',
      city: '',
      gradeId: '',
      boardId: '',
      schoolId: '',
      responseSchool: '',
      isSchoolCreated: true,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.FIRST_NAME_REQUIRED),
      lastName: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.LAST_NAME_REQUIRED),
      city: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.CITY_REQUIRED),
      gradeId: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.GRADE_REQUIRED),
      boardId: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.BOARD_REQUIRED),
      schoolId: Yup.string().required(APP_DEFAULT_MESSAGES?.VALIDATION?.SCHOOL_REQUIRED),
    }),
    onSubmit: (values) => {
      // const schoolData =
      //   values?.isSchoolCreated === true ? values?.schoolId : values?.responseSchool;
      const payload = {
        firstName: values?.firstName,
        school: values?.schoolId,
        lastName: values?.lastName,
        country: values?.country,
        state: values?.state,
        city: values?.city,
        gradeId: values?.gradeId,
        boardId: values?.boardId,
        isSchoolCreated: values?.isSchoolCreated,
      };

      if (!studentDetails && !isSuccess) {
        if (payload && tempToken) {
          dispatch(
            setStudentRegisterationData({
              data: payload,
              gradeName: selectedGradeName,
            }),
          );
          postStudentDetails({ data: payload })
            .unwrap()
            .then((data) => {
              dispatch(
                setStudentRegistrationResponseData({ ...data, studentRegistrationState: true }),
              );
              setToLocalStorage('studentGuardiansId', JSON.stringify(data?.data?.memberId));
              const passcode = Math.floor(1000 + Math.random() * 9000);
              setPasscodeData({
                passcode: passcode?.toString(),
                userMobileNumber: mobileNumber?.toString() ?? '',
              })
                .unwrap()
                .then((payload) => {
                  dispatch(setPasscodeResponse({ ...payload, passcodeState: true }));
                  setAutoSetPasscode(passcode?.toString());
                })
                .catch((error) => {
                  toast.error((error as ErrorResponse)?.data?.message || COMMON_ERROR);
                });
            })
            .catch((error) => {
              toast.error((error as ErrorResponse)?.data?.message || COMMON_ERROR);
            });
        }
      }
    },
  });

  /* setting the board value to the formik state */
  const handleBoardChange = (index: number) => {
    formik.setFieldValue('boardId', Number(index));
  };

  const handleCityChange = (value: string) => {
    if (value.length >= 3) {
      setCityName(value);
    }
  };

  useEffect(() => {
    if (cityName) {
      trigger({
        cityName: cityName || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityName]);

  useEffect(() => {
    if (result) {
      const filteredCityData = result?.data?.docs;
      setFilteredCityData(filteredCityData);
      const optionFilter = filteredCityData?.map((city: City) => ({
        label: city?.name + ', ' + city?.state_name,
        value: city?.id,
      }));
      setCitiesData(optionFilter);
    }
  }, [result]);

  /* setting the school value to the formik state */
  const handleSelectedCity = (options: DropdownOption): void => {
    if (options) {
      formik.setFieldValue('city', String(options.value));
      setSelectedCity(Number(options.value));
      const selectedCityData = filteredCityData?.find(
        (city: City) => city.id === Number(options.value),
      );
      formik.setFieldValue('state', selectedCityData?.state_id);
      formik.setFieldValue('country', selectedCityData?.country_id);
    }
  };

  /* setting the grade value to the formik state */
  const handleGradeSelect = (selectedGrade: string) => {
    formik.setFieldValue('gradeId', selectedGrade);
    setSelectedGrade(selectedGrade);
  };

  useEffect(() => {
    if (inputRef) {
      inputRef?.current?.focus();
    }
  }, [inputRef]);

  return (
    <>
      {isBoardLoading || isGradeLoading ? (
        <Loader className='relative' size={'lg'} />
      ) : (
        <form onSubmit={formik.handleSubmit} className='flex-1 h-full'>
          <div
            className={`flex flex-col justify-between flex-1 h-full md:py-4 mx-2 md:mx-5 lg:mx-0`}
          >
            {/* starting for name */}
            <div>
              <div className='flex flex-col gap-2 md:flex-row md:gap-4'>
                <div className='flex-1'>
                  <Input
                    ref={inputRef}
                    placeholder='First Name'
                    type='text'
                    id='firstName'
                    label='First Name*'
                    pattern={APP_REGEX?.PATTERN?.ALPHABET_INPUT}
                    name='firstName'
                    className='w-full px-2 pl-5 pr-5 leading-8'
                    onChange={(e) => formik.setFieldValue('firstName', e.target.value)}
                    value={formik.values?.firstName || ''}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.firstName && formik.errors.firstName ? true : false}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                      {APP_DEFAULT_MESSAGES.VALIDATION.FIRST_NAME_REQUIRED}
                    </p>
                  ) : null}
                </div>
                <div className='flex-1'>
                  <Input
                    placeholder='Last Name'
                    type='text'
                    id='lastName'
                    label='Last Name*'
                    pattern={APP_REGEX?.PATTERN?.ALPHABET_INPUT}
                    name='lastName'
                    className='w-full px-2 pl-5 pr-5 leading-8'
                    onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
                    value={formik.values?.lastName || ''}
                    onBlur={formik.handleBlur}
                    isValid={formik.touched.lastName && formik.errors.lastName ? true : false}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                      {APP_DEFAULT_MESSAGES.VALIDATION.LAST_NAME_REQUIRED}
                    </p>
                  ) : null}
                </div>
              </div>

              {/* Choosing boards */}
              <div className='mt-8'>
                <Label
                  value='Board*'
                  className='text-sm leading-5 uppercase cap-semibold text-neutral-500'
                />
                <div className='flex justify-start gap-3 mt-3'>
                  {sortedBoards?.map((board: IBoardsData) => (
                    <div
                      onClick={() => handleBoardChange(board?.id)}
                      className={`border-1 ring-neutral-150 lg:w-full ring-1 rounded-xl ${Number(formik.values?.boardId) === board?.id ? 'bg-primary-150' : 'bg-white'}`}
                      key={`board-${board.id}`}
                    >
                      <Radio
                        key={`board-${board.id}`}
                        id={`board-${board.id}`}
                        onChange={(e) =>
                          handleBoardChange(Number(e.target.id.replace('board-', '')))
                        }
                        name='board'
                        label={board.name}
                        isTimeSlotRadio={false}
                        checked={board.id === Number(formik.values?.boardId)}
                        lableClassName={`body-medium lg:text-base`}
                        className='flex items-center text-center rounded-sm hover:bg-neutral-100 hover:text-neutral-800'
                      />
                    </div>
                  ))}
                </div>
                {formik.touched.boardId && formik.errors.boardId ? (
                  <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                    {formik.errors.boardId}
                  </p>
                ) : null}
              </div>

              {/* Starting for grade */}
              <div className='mt-8'>
                <Label
                  value='Grade*'
                  className='text-sm leading-5 uppercase cap-semibold text-neutral-500'
                />
                <div className='flex flex-wrap xl:flex-nowrap justify-start xl:justify-between gap-2 mt-3 mx-[1px]'>
                  {sortedGrades?.map((grade: IGradesData) => (
                    <div
                      onClick={() => (
                        handleGradeSelect(grade?.value), setSelectedGradeName(grade?.name)
                      )}
                      className={`border-1 ring-neutral-150 xl:w-full ring-1 rounded-xl ${selectedGrade === grade.value ? 'bg-primary-150' : 'bg-white'}`}
                      key={`grade-${grade.id}`}
                    >
                      <Radio
                        id={`grade-${grade.id}`}
                        onChange={(e) =>
                          handleGradeSelect(String(e.target.id.replace('grade-', '')))
                        }
                        name='grade'
                        label={grade.name}
                        isTimeSlotRadio={false}
                        checked={grade.id === String(formik.values?.gradeId)}
                        className='flex items-center text-center rounded-sm hover:bg-neutral-100 hover:text-neutral-800'
                        lableClassName={`body-medium lg:text-base`}
                      />
                    </div>
                  ))}
                </div>
                {formik.touched.gradeId && formik.errors.gradeId ? (
                  <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                    {formik.errors.gradeId}
                  </p>
                ) : null}
              </div>

              {/* Select the Cities */}
              <div className='mt-8'>
                <Label
                  value='City*'
                  className='text-sm leading-5 uppercase cap-semibold text-neutral-500'
                />
                <InputDropdown
                  options={citiesData || []}
                  className='pl-5 pr-5 leading-8'
                  setSelectedOption={handleSelectedCity}
                  setEnteredValue={handleCityChange}
                  placeholder='Select city'
                />
                {formik.touched.city && formik.errors.city && (
                  <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                    {formik.errors.city}
                  </p>
                )}
              </div>

              {/* Select the Schools */}
              {formik.values?.city && (
                <>
                  <div className='mt-8'>
                    <Label
                      value='School*'
                      className='text-sm leading-5 uppercase cap-semibold text-neutral-500'
                    />
                    <div className='flex flex-wrap justify-start gap-3 mt-3'>
                      {registeredSchool?.data?.records?.map((school: records) => (
                        <div
                          onClick={() => (
                            formik.setFieldValue('schoolId', school?.id),
                            setAddress(school?.address)
                          )}
                          className={`border-1 ring-neutral-150 lg:w-full ring-1 rounded-xl ${Number(formik?.values?.schoolId) === school?.id ? 'bg-primary-150' : 'bg-white'}`}
                          key={`school-${school?.id}`}
                        >
                          <Radio
                            key={`school-${school?.id}`}
                            id={`school-${school?.id}`}
                            onChange={(e) =>
                              formik.setFieldValue(
                                'schoolId',
                                Number(e.target.id.replace('school-', '')),
                              )
                            }
                            name='school'
                            label={school?.name}
                            isTimeSlotRadio={false}
                            checked={school?.id === Number(formik?.values?.schoolId)}
                            lableClassName={`body-medium lg:text-base`}
                            className='flex items-center text-center rounded-sm hover:bg-neutral-100 hover:text-neutral-800'
                          />
                        </div>
                      ))}
                    </div>
                    {formik.touched.boardId && formik.errors.boardId ? (
                      <p className='pt-1 text-xs text-semantic-red-600 md:text-sm body-regular'>
                        {formik.errors.boardId}
                      </p>
                    ) : null}
                  </div>
                  <p className='flex items-center gap-2 font-poppins bg-semantic-orange-100 text-semantic-orange-800 px-5 py-2 my-6 rounded-xl font-bold text-left text-xs leading-[16.8px]'>
                    <img src={ExclamationWarn} alt='Error Exclamation' className='w-5 h-5' />
                    <span className='capitalize text-neutral-800'>{SCHOOL_NOT_LISTED}</span>
                  </p>
                </>
              )}

              {/* starting for board */}
              {address && (
                <div className='mt-8'>
                  <Label
                    value='address*'
                    className='text-sm leading-5 uppercase cap-semibold text-neutral-500'
                  />
                  <Textarea
                    className={`leading-8 pl-5 mt-3 p-2 text-neutral-800 body-medium text-base align-middle italic focus:border-neutral-150 focus:caret-neutral-150 focus:outline-none placeholder:text-start focus:placeholder-neutral-200 ring-neutral-150 focus:ring-0 pr-5 bg-neutral-25 rounded-lg`}
                    placeholder='Auto-populated as per the school selected'
                    disabled={true}
                    value={address || 'Auto-populated as per the school selected'}
                  />
                </div>
              )}
            </div>

            <div className='mt-5'>
              <Button
                type='submit'
                isLoading={isLoading}
                className={`w-full bg-primary-500  enabled:hover:bg-primary-500 text-white rounded-lg`}
                text='Continue'
                disabled={!address}
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
}
