import { UprioHeaderLogo } from 'assets/svg';
import { BouncingDotsLoader, Footer, Header } from 'components/common';
import { ClassOver, DashboardNav, LearningMaterials } from 'components/screens';
import { USER_TYPE } from 'configs';
import { useParams } from 'react-router-dom';
import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';
import {
  useGetPastClassDataOfStudentQuery,
  useGetSiblingParentListDetailsQuery,
} from 'store/apiSlices/parentDashboard.apiSlice';

const ParentDashboardClassOver = () => {
  const { studentId = '', classId = '' } = useParams();

  // Get User information
  const { currentData: userData, isLoading: isUserDataLoading } = useGetUserDataQuery();

  // Get Parent Siblings Info
  const { data: parentSiblingsData, isLoading: isParentSibilingsDataLoading } =
    useGetSiblingParentListDetailsQuery(undefined);
  const studentDetails = parentSiblingsData?.data?.find(
    (child) => child?.members?.id === Number(studentId),
  );
  const studentName = studentDetails?.members?.firstName || '';

  // Get Past ClassDetails
  const { data: classDetails, isLoading: isClassDetailsLoading } =
    useGetPastClassDataOfStudentQuery({
      studentId,
      classId,
    });

  const isLoading = isUserDataLoading || isParentSibilingsDataLoading || isClassDetailsLoading;

  return (
    <div className='flex flex-col flex-grow min-h-screen bg-neutral-25'>
      <DashboardNav type={USER_TYPE?.GUARDIAN} parentSiblingsData={parentSiblingsData} />
      {isLoading ? (
        <>
          <div className='flex items-center justify-center flex-1 mx-2 xl:mx-6'>
            <BouncingDotsLoader />
          </div>
        </>
      ) : (
        <div className='bg-neutral-50'>
          <Header
            parentName={`${userData?.data?.firstName} ${userData?.data?.lastName}`}
            studentName={studentName}
            symbol={'👋'}
          />
          <div className='mx-2 xl:mx-10 mb-[4rem]'>
            {classId && (
              <>
                <div className='flex flex-col items-start py-5 gap-y-2 xl:justify-start xl:flex-row xl:gap-x-2'>
                  <img className='h-6 w-23 xl:h-8 xl:w-28' src={UprioHeaderLogo} alt='heading' />
                  <span className='text-xl font-bold tracking-widest text-start xl:text-2xl font-sora text-neutral-800'>
                    CLASS DETAILS & SUMMARY
                  </span>
                </div>
                <ClassOver
                  classDetails={classDetails}
                  classId={classId}
                  studentId={studentId}
                  userType={USER_TYPE.GUARDIAN}
                />
              </>
            )}
            <div className=''>
              {classDetails && <LearningMaterials learningMaterial={classDetails.data} />}
            </div>
            {/* <PotentialCheck /> */}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ParentDashboardClassOver;
