import { Logo } from 'assets/svg';
import './index.css';

function WaterMark() {
  return (
    <div className='absolute z-50 water-mark opacity-20 grayscale'>
      <img src={Logo} className='object-cover watermark-img' alt='logo' width={650} />
    </div>
  );
}

export default WaterMark;
