import { Button as FlowbiteButton } from 'flowbite-react';
import classNames from 'classnames';

import { IButtonProps } from 'types';

const InClassButton = ({
  className,
  title,
  color,
  icon,
  label,
  rounded = true,
  ...props
}: IButtonProps) => {
  return (
    <div className='flex flex-col items-center w-fit'>
      <FlowbiteButton
        theme={{
          color: {
            default: 'text-white bg-[#292929]',
            blue: 'text-white bg-[#171851]',
            green: 'text-white bg-[#1CC55A]',
            red: 'text-white bg-[#F13A46]',
            darkBlue: 'text-white bg-primary-900',
            white: 'text-black shadow-sm bg-[#fff] ',
          },
          size: {
            md: `text-xs 2xl:text-sm ${rounded ? 'p-2 2xl:p-3' : 'px-5 py-3'}`,
          },
        }}
        className={classNames(
          `rounded-[14px] w-fit flex gap-4  focus:ring-0 ${rounded && 'rounded-full size-10 2xl:size-12'} hover:bg-opacity-80`,
          className,
        )}
        color={color ?? 'default'}
        {...props}
      >
        {icon && (
          <img
            src={icon}
            alt={title || label}
            className={`size-4 2xl:size-5 ${rounded ? '' : 'mr-2'}`}
          />
        )}
        {title}
      </FlowbiteButton>
      {label && (
        <p className='text-[10px] text-center w-[85px] mt-1 text-white font-bold'>{label}</p>
      )}
    </div>
  );
};

export default InClassButton;
