import apiSlice from 'store/apiSlices';
import { RawLecture } from 'types';

const lectureApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLecture: builder.query<RawLecture, { classId: string }>({
      query: ({ classId }) => ({
        url: `/v2/configs/${classId}`,
      }),
    }),
  }),
});

export const { useGetLectureQuery } = lectureApiSlice;
