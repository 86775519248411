import { FooterLogo } from 'assets/svg';

export const FOOTER_DATA = {
  logo: FooterLogo,
  data: [
    { name: 'Privacy Policy', link: '#' },
    { name: 'Terms & Conditions', link: '#' },
    { name: `All rights reserved  &copy;2024 UPRIO` },
  ],
};
