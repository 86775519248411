import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IPasscodeResponse,
  IStudentRegistration,
  IUpdateMobileNumber,
  requestOTP,
  responseOTP,
  responseStudentRegistration,
  responseVerifyOTP,
} from 'types/auth';
import { IBookDemoClassResponse, IDemoClassBook } from 'types/democlass';

// Define the initial state using the specific interfaces
interface IStudentGuardianRegisterState {
  requestOTP?: requestOTP;
  requestOTPResponse?: responseOTP;
  verifyOTP?: responseVerifyOTP;
  registrationData?: IStudentRegistration;
  registrationResponse?: responseStudentRegistration;
  demoClassResponse?: IBookDemoClassResponse;
  demoClassBookedData?: IDemoClassBook;
  passcodeResponse?: IPasscodeResponse;
  resetPasscodeResponse?: IPasscodeResponse;
  userType?: string;
  setUpdateMobileNumber?: IUpdateMobileNumber;
  loggedInStudentId?: string;
}

const initialState: IStudentGuardianRegisterState = {
  requestOTP: undefined,
  requestOTPResponse: undefined,
  verifyOTP: undefined,
  registrationData: undefined,
  registrationResponse: undefined,
  demoClassResponse: undefined,
  demoClassBookedData: undefined,
  passcodeResponse: undefined,
  resetPasscodeResponse: undefined,
  userType: undefined,
  setUpdateMobileNumber: undefined,
  loggedInStudentId: undefined,
};

export const studentRegisterSlice = createSlice({
  name: 'studentRegister',
  initialState,
  reducers: {
    setMobileRequest: (state, action: PayloadAction<requestOTP>) => {
      state.requestOTP = action.payload;
    },
    setRequestOTPResponseData: (state, action: PayloadAction<responseOTP>) => {
      state.requestOTPResponse = action.payload;
    },
    setVerifyOTPResponseData: (state, action: PayloadAction<responseVerifyOTP>) => {
      state.verifyOTP = action.payload;
    },
    setStudentRegisterationData: (state, action: PayloadAction<IStudentRegistration>) => {
      state.registrationData = action.payload;
    },
    setStudentRegistrationResponseData: (
      state,
      action: PayloadAction<responseStudentRegistration>,
    ) => {
      state.registrationResponse = action.payload;
    },
    setDemoClassResponseData: (state, action: PayloadAction<IBookDemoClassResponse>) => {
      state.demoClassResponse = action.payload;
    },
    setDemoClassBookedData: (state, action: PayloadAction<IDemoClassBook>) => {
      state.demoClassBookedData = action.payload;
    },
    setPasscodeResponse: (state, action: PayloadAction<IPasscodeResponse>) => {
      state.passcodeResponse = action.payload;
    },
    setResetPasscodeResponse: (state, action: PayloadAction<IPasscodeResponse>) => {
      state.resetPasscodeResponse = action.payload;
    },
    // Reset action
    reset: (state) => {
      state = initialState;
      return state;
    },
    setUserType: (state, action: PayloadAction<string>) => {
      state.userType = action.payload;
    },
    setLoggedInStudentId: (state, action: PayloadAction<string>) => {
      state.loggedInStudentId = action.payload;
    },
    setUpdateMobileNumber: (state, action: PayloadAction<IUpdateMobileNumber>) => {
      state.setUpdateMobileNumber = action.payload;
    },
  },
  selectors: {
    selectMobileNumber: (state) => state?.requestOTP?.mobileNumber,
    selectDemoClassBookedData: (state) => state?.demoClassBookedData,
    selectPasscodeResponseToken: (state) => state?.passcodeResponse?.data?.tempToken,
    selectRegisterationData: (state) => state?.registrationData?.data,
    selectResponseRegisterationData: (state) => state?.registrationResponse?.data?.memberId,
    selectUserType: (state) => state?.userType,
    selectLoggedInStudentId: (state) => state?.loggedInStudentId,
    selectMobileNumberUpdated: (state) => state?.setUpdateMobileNumber,
    selectGradeName: (state) => state?.registrationData?.gradeName,
  },
});

export const {
  setStudentRegisterationData,
  setStudentRegistrationResponseData,
  setMobileRequest,
  setRequestOTPResponseData,
  setVerifyOTPResponseData,
  setPasscodeResponse,
  setDemoClassResponseData,
  setDemoClassBookedData,
  setResetPasscodeResponse,
  reset,
  setUserType,
  setLoggedInStudentId,
  setUpdateMobileNumber,
} = studentRegisterSlice.actions;

export const {
  selectMobileNumber,
  selectDemoClassBookedData,
  selectPasscodeResponseToken,
  selectRegisterationData,
  selectResponseRegisterationData,
  selectUserType,
  selectLoggedInStudentId,
  selectMobileNumberUpdated,
  selectGradeName,
} = studentRegisterSlice.selectors;

export default studentRegisterSlice.reducer;
