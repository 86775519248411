import { useState, useCallback } from 'react';
import useMeeting from './useMeeting';
import { datadogLogs } from '@datadog/browser-logs';

const useWebcam = ({
  videoRef,
  videoBoxRef,
}: {
  videoRef: React.RefObject<HTMLVideoElement>;
  videoBoxRef: React.RefObject<HTMLDivElement>;
}) => {
  const [originalDimensions, setOriginalDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);

  const { meeting, classId, studentId } = useMeeting();

  // Enable the camera
  const enableCam = useCallback(async () => {
    const video = videoRef.current;
    if (!video || !meeting?.self?.videoTrack) return;

    try {
      const videoTrack = meeting.self.videoTrack;
      video.srcObject = new MediaStream([videoTrack]);

      const settings = videoTrack.getSettings();
      if (videoBoxRef.current) videoBoxRef.current.style.display = 'block';

      if (settings.height !== undefined && settings.width !== undefined) {
        setOriginalDimensions({ width: settings.width, height: settings.height });
      }
    } catch (err) {
      datadogLogs.logger.error(
        `Error enabling camera for notebookcapture: ${studentId} and classId:${classId}`,
        {
          error: err,
          classId: classId,
          studentId: studentId,
        },
      );
    }
  }, [classId, meeting.self.videoTrack, studentId, videoBoxRef, videoRef]);

  // Capture the screenshot
  const captureScreenshot = useCallback(async () => {
    const video = videoRef.current;
    if (!video || !originalDimensions) {
      console.error('Video element or dimensions are not set');
      return {
        image: { original: null },
        log: { message: 'Video element or dimensions are not set', state: false },
      };
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      console.error('Failed to get canvas context');
      return {
        image: { original: null },
        log: { message: 'Failed to get canvas context', state: false },
      };
    }

    canvas.width = originalDimensions.width;
    canvas.height = originalDimensions.height;
    context.drawImage(video, 0, 0);
    context.scale(-1, 1);
    context.translate(-originalDimensions.width, 0);

    const screenshotImage = new Image();
    screenshotImage.src = canvas.toDataURL();

    return {
      image: { original: canvas.toDataURL() },
      log: { message: 'Notebook captured successfully', state: true },
    };
  }, [originalDimensions, videoRef]);

  return { videoRef, enableCam, captureScreenshot };
};

export default useWebcam;
