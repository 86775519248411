import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';

import { useGetUserDataQuery } from 'store/apiSlices/login.apiSlice';

import { Button, Card } from 'components/common';

import { PARENT_PATHS, ROUTES, STUDENT_PATHS, USER_TYPE, CONFIG } from 'configs';
import { useAppSelector } from 'hooks/store';
import { selectLoggedInStudentId } from 'store/slice/studentRegister.slice';

const PageNotFound = () => {
  const navigate = useNavigate();
  const userId = useAppSelector(selectLoggedInStudentId);
  const loginToken = Cookie.get(`${CONFIG.VITE_BASE_DOMAIN_ENV}CoachToken`);
  /* API => view profile to get the school data */
  const { data: profileData } = useGetUserDataQuery(undefined, { skip: !loginToken });

  const userType = profileData?.data?.userType;

  // Handle back button click
  const handleBack = () => {
    if (userType && userId) {
      if (userType === USER_TYPE.STUDENT) {
        navigate(STUDENT_PATHS.STUDENT_DASHBOARD, { replace: true });
      } else if (userType === USER_TYPE.GUARDIAN) {
        navigate(PARENT_PATHS.PARENT_DASHBOARD.replace(':studentId', userId.toString()), {
          replace: true,
        });
      }
    } else {
      navigate(ROUTES.LOGIN, { replace: true });
    }
  };

  return (
    <Card className='flex items-center justify-center h-screen px-3 bg-neutral-100'>
      <div className='max-w-md p-8 bg-white rounded-lg shadow-lg'>
        <h1 className='mb-4 text-2xl text-center text-neutral-850 display-bold lg:text-3xl'>
          404 - Page Not Found
        </h1>
        <p className='text-center text-neutral-600 body-regular'>
          Oops! The page you are looking for does not exist.
        </p>
        <Button
          className='w-full mt-6 text-white bg-blue-500 rounded hover:bg-blue-600'
          onClick={handleBack}
          text='Go Back'
        />
      </div>
    </Card>
  );
};

export default PageNotFound;
