import { TickWhite } from 'assets/svg';
import React from 'react';

import './index.css';

interface IStepperProps {
  activeStep: number;
  stepperData: {
    name: string;
  }[];
}

export default function Stepper({ activeStep, stepperData }: IStepperProps) {
  return (
    <div className='px-2 pt-3 md:px-0'>
      <ol className='flex items-center justify-between text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base'>
        {stepperData.map((_, index) => (
          <React.Fragment key={index}>
            <li className='flex items-center text-sm'>
              <span
                className={`flex items-center justify-center w-7 h-7 rounded-full transition ease-in-out delay-500 ${
                  index < activeStep
                    ? 'bg-green-400 text-white'
                    : index === activeStep
                      ? 'bg-green-400 text-white'
                      : 'bg-gray-200 text-blue-800'
                }`}
              >
                {index < activeStep ? (
                  <img src={TickWhite} className='w-3 h-3' alt='Tick' />
                ) : (
                  index + 1
                )}
              </span>
            </li>
            {index < stepperData.length - 1 && (
              <span className='relative flex-1'>
                <hr
                  className={`rounded-3xl h-1 mx-1 ${
                    index < activeStep - 1
                      ? 'bg-green-400'
                      : index === activeStep - 1
                        ? 'bg-green-400 animate-fill'
                        : 'bg-neutral-200'
                  }`}
                />
              </span>
            )}
          </React.Fragment>
        ))}
      </ol>
      <hr className='w-full h-1 my-5 text-neutral-150' />
    </div>
  );
}
