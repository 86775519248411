import { ApexOptions } from 'apexcharts';
import { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { isEmpty } from 'lodash';

import { useSocketSubscribe, useWindowDimensions } from 'hooks';
import { CONFIG } from 'configs';

interface AttentionChartProps {
  classId: number | undefined;
  xAxisRange: number;
  attentionChartHeight?: number;
  classOver?: boolean;
  aggregatedAttentions?: number[];
  currentState?: string;
}

interface IxAxis {
  min: number;
  max: number;
}

const AttentionChart = ({
  attentionChartHeight,
  classOver,
  aggregatedAttentions,
  currentState,
}: AttentionChartProps) => {
  const { isMobileScreen } = useWindowDimensions();

  const [xAxisRange, setXAxisRange] = useState<IxAxis>({
    min: 0,
    max: 30,
  });

  const [series, setSeries] = useState<ApexAxisChartSeries>([
    {
      data: [0, 0, 0, 0],
      name: 'Attentions',
    },
  ]);

  const chartHeight = attentionChartHeight ? attentionChartHeight : 195;

  let borderRadius = 3;
  let backgroundColor = '#1E232A';
  let graphType: ApexChart['type'] = 'bar';
  if (classOver) {
    borderRadius = 1;
    backgroundColor = '#F5F7F8';
    graphType = 'area';
  }

  const options: ApexOptions = {
    colors: ['#00C2BB'],
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    chart: {
      type: graphType,
      height: chartHeight,
      zoom: {
        enabled: false,
      },
      background: backgroundColor,
      toolbar: { show: false },
      animations: {
        enabled: false,
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 350,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    stroke: {
      width: 1,
      curve: 'smooth',
    },
    xaxis: {
      min: xAxisRange.min,
      max: xAxisRange.max,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      min: 0,
      max: 100,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: '120%',
        distributed: false,
        borderRadius: borderRadius,
        horizontal: false,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        colorStops: [
          {
            offset: 1,
            color: '#00C2BB',
            opacity: 1,
          },
        ],
      },
    },
  };

  useEffect(() => {
    if (currentState !== CONFIG?.ROOMS?.TEACH) {
      setSeries((prevSeries) => {
        return [{ ...prevSeries[0], data: CONFIG?.DEFAULT_ATTENTION }];
      });
    }
  }, [currentState]);

  useEffect(() => {
    if (classOver && aggregatedAttentions) {
      if (!isEmpty(aggregatedAttentions)) {
        const seriesData = aggregatedAttentions.map((value, index) => ({
          x: index,
          y: value,
        }));
        setSeries([{ data: seriesData }]);
      } else {
        setSeries([{ data: [] }]);
      }
    }
  }, [aggregatedAttentions, classOver]);

  const handler = useCallback(({ message }: { message: string }) => {
    const parsedData = JSON.parse(message);
    if (parsedData?.attention >= 10) {
      const attention = parsedData.attention;
      setSeries((prevSeries) => {
        const newData = [...prevSeries[0].data, attention];
        return [{ ...prevSeries[0], data: newData }];
      });
    }
  }, []);

  const updateXAxisRangeCallback = useCallback(
    (dataLength: number) => {
      if (classOver) {
        setXAxisRange({ min: 0, max: dataLength });
      } else {
        let xMax = 30;
        if (isMobileScreen) {
          xMax = 10;
        }
        const newMin = Math.max(0, dataLength - xMax + 1);
        const newMax = Math.max(xMax, dataLength);
        setXAxisRange({ min: newMin, max: newMax });
      }
    },
    [classOver, isMobileScreen],
  );

  useEffect(() => {
    updateXAxisRangeCallback(series[0]?.data.length);
  }, [classOver, series, updateXAxisRangeCallback]);

  useSocketSubscribe<{ message: string }>('serverToClient', handler);

  return (
    <div className='w-full h-full overflow-hidden rounded-lg'>
      <ReactApexChart
        options={options}
        series={series}
        type={options.chart?.type}
        height={options?.chart?.height}
      />
    </div>
  );
};

export default AttentionChart;
